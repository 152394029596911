import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Form,
  Input,
  Col,
  FormGroup,
  InputGroup
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../../assets/css/custom.css";
import Header from "../../../components/Headers/Header.js";
import 'assets/css/themecustom-style.css';
import '@flowjs/flow.js';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import { usermanageService } from "../../../services/usermanageService";
const axios = require("axios");

const AddMasterKRO = (props) => {
  const [clientsearch, setclientsearch] = useState('');
  const [trans_data,settransData] = useState([]);
  const [state, setState] = useState({});
  const [disablestate, setdisablestate] = useState(false);
  const [newbox, setNewBox] = useState(0);
  const [defaultkey, setdefaultKey] = useState(0);
  const [eligibility, setEligible] = useState(1);
  const history = useHistory();
useEffect(() => {
  get_unique_userslist();
  getkrotypes();
}, []);

const [uniqueuserslist, setUniqueUsersData] = useState([]);
function get_unique_userslist(){
  usermanageService.getusersforchatbox().then((response)=>{
    setUniqueUsersData(response.data.users);
  });
}

const [krotypes, setKroTypes] = useState([]);
function getkrotypes(){
  usermanageService.getkrotypes().then((response)=>{
    setKroTypes(response.data.data);
  });
}

function showKROMaster(user=''){
    usermanageService.showKROMaster(user).then((response) => {
      
        if(response.status === 200){
          console.log(response.data)
          settransData(response.data.data)
          setState(response.data.data)      
          setdefaultKey(response.data.data.length + 1)
          setNewBox(response.data.data.length + 1)
          setEligible(response.data.kro_eligible)
        }else {
          toast.error('Something went wrong!');
        }
    });
}

const companyfilter = (e,index) => {
  setState({})
  settransData([])
 showKROMaster(e.value)
 setclientsearch(e.value)
};

function inputhandler(e,index) {

  let temp_st = {...state};
  let temp_ele = { ...temp_st[index] };
  temp_ele[e.target.name] = e.target.value;
  temp_ele['user_id'] = clientsearch;
  temp_st[index] = temp_ele;
  setState(temp_st);

}

function addsopform(e) {
  console.log(e);
  setdisablestate(true)
  e.preventDefault();
  usermanageService.updateUserMasterKroForm(state).then((response) => {
    if (response.status == 200) {
      toast.success(response.data.message);
      // location.reload();
    }
    setdisablestate(false)
  });
}

 
const addNewbox = ()=>{
  const setdata = newbox+1;
  setNewBox(setdata)
 
}
const saveEligibility = (e) => {
  setEligible(e.target.value)
  usermanageService.updateUserEligibility(e.target.value,clientsearch).then((response) => {
    if (response.status == 200) {
      toast.success(response.data.message);
      // location.reload();
    }
    setdisablestate(false)
  });
}
const krotyperating = ()=>{
  history.push("/admin/krotyperating/");
}
const archieveRows = (e,id) => {
  e.preventDefault();
  usermanageService.archivekrousermaster(id).then((response) => {
    if(response.status == 200){
     
      showKROMaster(clientsearch)
     toast.success(response.data.message);
      
     
    }else{
      toast.error(response.data.message);
    }
  });
}

  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
      {/* <Row className="align-items-center row">
            <Col xl="10">
            
              <Button
                className="dash-button "
                color="primary"
                type="button"
                onClick={() => krotyperating()}
              >
                {" "}
              + Type Rating{" "}
              </Button>{" "}
             
            </Col>
            
                   
          </Row><br></br> */}
        <Row className="mt-12" >
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
            
              <CardHeader className="border-0">
              <Row className="filter" style={{padding:'0 1%'}}>
               <div className="filter" style={{width:'80%'}}>
                   <div className="col-xl-6 col-md-6 col-xs-12">
                    <h3 className="clientformtag"> Select User</h3>
                   
                   <Select 
                   
                   className="input-group-alternative mb-3" 
                   name="user_id"
                   options={uniqueuserslist} // set list of the data
                   onChange={(e) => companyfilter(e,9)}
                   defaultValue={clientsearch}
                   searchable
                   isClearable={false}
                 />
                  </div>
                  
                    </div>
                </Row>
              </CardHeader>
             


            </Card>
          </div>
        </Row>
        <Row className="mt-12" >
          
        <Card className="shadow"  style={{width:"100%"}}>
          {(clientsearch != '') ? 
        <div className="row">
          <Col className="col-md-4">
              <FormGroup>
                <label>Select Eligibility</label>
                <select className="form-control" value={eligibility} name="target_type"   onChange={(e) => saveEligibility(e)}>
                          <option value="1">YES</option>
                          <option value="0">NO</option>
                </select>
              </FormGroup>
          </Col>
        </div>
        : null }
            <Form
                role="form"
                id="phase-selection-form"
                onSubmit={addsopform}
            
              >
               
              <Table  className="align-items-center table-flush" responsive>
              <thead>
                <tr style={{ border: "1px solid #000" }}>
                  <th style={{ border: "1px solid #000" }}>Sr.No.</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">Type</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">Target type</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">Target</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">% Weightage</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">Action</th>
                </tr>
              </thead>
                <tbody>
                {trans_data && trans_data.length && trans_data != null ? (
                    trans_data.map((item, i) =>
              <>
                  <tr className="whitespace" style={{border : "1px solid #000"}}>  
                  <td className="whitespace" style={{border : "1px solid #000"}}>{i+1}</td>
                    <td className="whitespace" style={{border : "1px solid #000"}}>
                        <select className="form-control" value={item.type_id} name="type_id"   onChange={(e) => inputhandler(e, i)}>
                                    {krotypes.map(type =>
                                      <option key={type.value} value={type.value}>{type.label}</option>
                                    )}
                        </select>
                    </td>
                    <td className="whitespace" style={{border : "1px solid #000"}}>
                    <select className="form-control" value={item.target_type} name="target_type"   onChange={(e) => inputhandler(e, i)}>
                                  <option value="per_day">Per day</option>
                                  <option value="per_job">Per job</option>
                        </select>
                    </td>
                    <td className="whitespace" style={{border : "1px solid #000"}}>
                    <Input
                            bsSize="sm"
                            name="target"
                            placeholder="target"
                            id="example-date-input"
                            type="number"
                            defaultValue={item.target}
                            onChange={(e) => inputhandler(e, i)}
                        />
                    </td>
                    <td className="whitespace" style={{border : "1px solid #000"}}>
                    <Input
                            bsSize="sm"
                            name="weightage"
                            placeholder="weightage"
                            id="example-date-input"
                            type="number"
                            defaultValue={item.weightage}
                            onChange={(e) => inputhandler(e, i)}
                        />
                    </td>
                    <td className="whitespace" style={{border : "1px solid #000"}}>
                    <Button className="btn btn-danger" onClick={(e) => { archieveRows(e,item.id);}}   class="btn btn-danger"><i class="fa fa-trash"></i></Button>
                    </td>
                  </tr>
              
                </>
            )
            ) : (
            null
            )}
            {(() => {
            const arr1 = [];
            for (let key = defaultkey; key < newbox; key++) {
                arr1.push(
                 <>
                  <tr className="whitespace" style={{border : "1px solid #000"}}>
                    <td style={{border : "1px solid #000"}}>{key}</td>
                    
                    <td style={{border : "1px solid #000"}}>
                      
                        <select className="form-control" name="type_id"  onChange={(e) => inputhandler(e,key)}>
                                  <option key="user_id_1" value="">Select Type</option>
                                    {krotypes.map(type =>
                                      <option key={type.value} value={type.value}>{type.label}</option>
                                    )}
                        </select>
                 
                      </td>
                      
                      <td className="whitespace" style={{border : "1px solid #000"}}>
                              <select className="form-control" name="target_type"  onChange={(e) => inputhandler(e,key)}>
                                  <option key="user_id_1" value="">Select target Type</option>
                                  <option value="per_day">Per day</option>
                                  <option value="per_job">Per job</option>
                              </select>
                      </td>
                    
                      <td className="whitespace"  style={{border : "1px solid #000"}}>
                        <Input
                            bsSize="sm"
                            name="target"
                            placeholder="target"
                            id="example-date-input"
                            type="number"
                            onChange={(e) => inputhandler(e,key)}
                        />
                      </td>
                      <td className="whitespace"  style={{border : "1px solid #000"}}>
                        <Input
                            bsSize="sm"
                            name="weightage"
                            placeholder="Weightage"
                            id="example-date-input"
                            type="number"
                            onChange={(e) => inputhandler(e,key)}
                        />
                      </td>
                  </tr>
                    </>
                );
            }
            return arr1;
            
        })()}
            </tbody>
              </Table>
              </Form>
            <br></br>
              
                <div class="d-flex">
                <Button color="primary" className="col-xl-2" style={{flex:"0 0 4.66667%"}} type="button" onClick={(e) => addNewbox()} >
            <i class="fa fa-plus"></i>
              </Button>
            <Button color="primary" className="col-xl-2" style={{flex:"0 0 4.66667%"}} type="button" onClick={addsopform} disabled={disablestate}>
            {disablestate ? 'Sending...' : 'Save'} 
              </Button>
              </div>
              
             
              </Card>
              </Row>

      </Container>
    </>
  );
};

 
export default AddMasterKRO;
