import { useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Radio
} from "reactstrap";
import swal from "sweetalert";
import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { AgGridReact, AllCommunityModules, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { userService } from "../../../services/authentication";
import { usermanageService } from "../../../services/usermanageService";
import { taskmanageService } from "../../../services/taskmanageService";
import "../../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { ExitStatus, forEachChild } from "typescript";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Buttoncomponent from 'components/Buttoncomponent.js';
import Select from 'react-select';

const Useradd = (props) => {
  const [userdata, setUserdata] = useState([]);
  const [user_roles, setUserroles] = useState([]);
  const [inputFields, setInputfields] = useState({otherclientemail:''});
  const [qatl_lists, setQatlLists] = useState([]);
  const [qatm_lists, setQatmLists] = useState([]);
  const [atl_lists, setAtlLists] = useState([]);
  const [displayqatl, setDisplayqatl] = useState(false);
  const [displayclientqatl, setDisplayClientqatl] = useState(false);
  const [displayatl, setDisplayatl] = useState(false);
  const [team_user, setTeamUser] = useState([]);
  const [sendmail,setsendmail] = useState(false);
  const [showradiobtn,setradiobtn] = useState(false);
  const [displayteamuser, setDisplayteamuser] = useState([false]);
  const history = useHistory();
  const [disablestate, setdisablestate] = useState(false);
 
  function inputhandler1(e,action) {
    let phseup = {}
    if(Array.isArray(e)){  
      if(action.name.name=='atl'){ 
      phseup = {...inputFields,atl : Array.isArray(e) ? e.map(x1 => x1.value) : []}
      }else{
        phseup = {...inputFields,phases : Array.isArray(e) ? e.map(x1 => x1.value) : []} 
      }
    }
    setInputfields(phseup);
  }


  function inputhandler(e) {
    if(e.target.name == 'roles'){
        setradiobtn(true);
        setsendmail(false);  
        // if(e.target.value == 13){
        //     setradiobtn(true);
        //     setsendmail(false);
        // }else{
        //     setradiobtn(false);
        //     setsendmail(false);
        // }
        
    }

    let phseup = {}
    if(Array.isArray(e)){     
      phseup = {...inputFields,qatl : Array.isArray(e) ? e.map(x => x.value) : []}
    }else{
      if(e.target.name == 'roles'){
        
        if(e.target.value == '5' || e.target.value == '7'){
          setDisplayqatl(false)
          setDisplayatl(false)
          setDisplayClientqatl(false);
          setDisplayteamuser(true);
          phseup = { ...inputFields, [e.target.name]: e.target.value }
        }
        // else if(e.target.value == '11'){
        //   setDisplayqatl(true)
        //   setDisplayatl(true)
        //   setDisplayClientqatl(false);
        //   setDisplayteamuser(false);
        //   phseup = { ...inputFields, [e.target.name]: e.target.value }
        // }
        else if(e.target.value == '13'){
          setDisplayClientqatl(true)
          setDisplayatl(false)
          setDisplayqatl(false)
          setDisplayteamuser(false);
          phseup = { ...inputFields, [e.target.name]: e.target.value }
        }
        else{
          if(e.target.value == '2' || e.target.value == '3' || e.target.value == '4' || e.target.value == '6' || e.target.value == '11' || e.target.value == '10'){
            setDisplayteamuser(true);
          }else{
            setDisplayteamuser(false);
          }
          setDisplayqatl(false)
          setDisplayatl(false)
          setDisplayClientqatl(false)
          phseup = { ...inputFields, [e.target.name]: e.target.value, phases : '' }
        }
      }else{
        phseup = { ...inputFields, [e.target.name]: e.target.value }    
      }
    }

    setInputfields(phseup);
    // console.log(inputFields);
  }

  useEffect(() => {
    getuser_list();
    getqatl_list();
    getatl_list();
    return () => {
      setInputfields({});
    };
  }, []);

  function getuser_list() {
    usermanageService.getusers().then((response) => {
      console.log(response.data);
         console.log(response.data.phases);
        // console.log(response.data.roles);
        // setUserphase(response.data.phases);
        setUserroles(response.data.roles);
        setTeamUser(response.data.team_users);
      // setUserdata(response.data);
    });
  }

  

  function getqatl_list() {
    taskmanageService.get_qa_analystteamleaderlist().then((response) => {
      
      console.log(response.data.data)
        setQatlLists(response.data.data);
        setQatmLists(response.data.tm);
        setInputfields({...inputFields, qatl: []});
      });
  }

  function getatl_list() {
    taskmanageService.getanalystteamleaderlist().then((response) => {
      console.log("satl==="+response.data.result)
      setAtlLists(response.data.result);
      setInputfields({...inputFields, atl: []});
      console.log(inputFields)
    });
  }


  function adduser(e){
    setdisablestate(true)
    e.preventDefault();
    // console.log(inputFields);
    // return;
    usermanageService.adduser(inputFields,sendmail).then((response)=>{
      if(response.status == 200){
         toast.success(response.data.message); 
         document.getElementById("adduser-form").reset();
         history.push('users');
      }else{
        console.log(response.data);
         toast.error(response.data.message);
      }
      setdisablestate(false)
    });
  }
  const styles = {
    'color':'#4a4444',
  };
  

  function  ismailsend(val) {
    if(val == 'yes'){
      setsendmail(true);
    }else{
      setsendmail(false);
    }
  } 
  

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12">
              <div class="row">
                  <div class="col-md-6"><h2>Add User</h2></div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-2 offset-md-10">
                        <Buttoncomponent/>
                      </div>
                    </div>
                  </div>
                </div>
                <br/>
              <Card className="card">
                <Form role="form" id="adduser-form" onSubmit={adduser}>
                <div className="row">
                    <div className="col-md-4">
                       <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Name"
                            type="text"
                            name="name"
                            onChange={inputhandler}
                            style={styles}
                            required
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              placeholder="Email"
                              type="text"
                              name="email"
                              onChange={inputhandler}
                              style={styles}
                              required
                            />
                          </InputGroup>
                        </FormGroup>
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              placeholder="Password"
                              type="text"
                              name="password"
                              onChange={inputhandler}
                              style={styles}
                              required
                            />
                          </InputGroup>
                        </FormGroup>
                    </div>
                    <div className="col-xl-4 col-md-4 col-xs-12">
                    <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                        <select class="form-control" name="department"  onChange={(e) => inputhandler(e)} required>
                              <option value=""> Department</option>
                                <option value="BookKeeping">BookKeeping</option>
                                <option value="SME">SME</option>
                                <option value="HR">HR</option>
                              </select>
                              </InputGroup>
                      </FormGroup> 
                  </div>
                    <div className="col-md-4">
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <select className="form-control" name="roles" onChange={inputhandler} placeholder="Role">
                              <option key="role_1"  value="">Select Role</option>
                              {user_roles.map(roles =>
                                  <option key={roles.role_id} value={roles.role_id}>{roles.role_name}</option>
                              )}
                            </select>
                          </InputGroup>
                        </FormGroup>
                    </div>
                    <div className="col-md-4" style={{ display: displayteamuser == true ? "block" : "none" }}>
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <select className="form-control" name="team_user" onChange={inputhandler} placeholder="User">
                              <option key="user_1"  value="">Select user manager for reporting</option>
                              {team_user.map(team_user =>
                                  <option key={team_user.id} value={team_user.id}>{team_user.name}</option>
                              )}
                            </select>
                          </InputGroup>
                        </FormGroup>
                    </div> 
                    
                   
                    <div className="col-md-4" style={{ display: displayclientqatl == true ? "block" : "none" }}>
                        <FormGroup>
                        <select className="form-control" name="client_qa" onChange={inputhandler}>
                               <option key="client_qa_1" value="">Select QA TL For job assignment</option>
                                {qatl_lists.map(qatl =>
                                  <option key={qatl.value} value={qatl.value}>{qatl.label}</option>
                                )}
                           </select>
                        </FormGroup>
                    </div>
                    <div className="col-md-4" style={{ display: displayclientqatl == true ? "block" : "none" }}>
                        <FormGroup>
                        <select className="form-control" name="client_qatm" onChange={inputhandler}>
                               <option key="client_qatm_1" value="">Select QA TM For job assignment</option>
                                {qatm_lists.map(qatm =>
                                  <option key={qatm.value} value={qatm.value}>{qatm.label}</option>
                                )}
                           </select>
                        </FormGroup>
                    </div>
                    <div className="col-md-4" style={{ display: displayqatl == true ? "block" : "none" }}>
                        <FormGroup>
                            <Select 
                            className="input-group-alternative mb-3" 
                            name="qatl" 
                            allowSelectAll={true} 
                            // value={qatl_lists.filter(obj => inputFields.qatl.includes(obj.value))} // set selected values
                            options={qatl_lists} // set list of the data
                            onChange={inputhandler}
                            isMulti
                            isClearable
                          />
                          {inputFields.qatl && <div style={{ marginTop: 20, lineHeight: '25px' }}>
                            <div><b>Selected Value: </b> {JSON.stringify(inputFields.qatl, null,2)}</div>
                              </div>}
                        </FormGroup>
                    </div>
                    <div className="col-md-4" style={{ display: displayatl == true ? "block" : "none" }}>
                        <FormGroup>
                            <Select 
                            className="input-group-alternative mb-3" 
                            name="atl" 
                            allowSelectAll={true} 
                            options={atl_lists}
                            onChange={inputhandler1}
                            isMulti
                            isClearable
                            />
                            {inputFields.atl && <div style={{ marginTop: 20, lineHeight: '25px' }}>
                            <div><b>Selected Value: </b> {JSON.stringify(inputFields.atl, null,2)}</div>
                              </div>}
                        </FormGroup>
                    </div>
                    <div className="col-md-4" style={{ display: displayclientqatl == true ? "block" : "none" }}>
                       <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              placeholder="Other Email"
                              type="text"
                              name="otherclientemail"
                              onChange={(e) => inputhandler(e)}
                              style={styles}
                              value={inputFields.otherclientemail}
                              required={(displayclientqatl == true) ? true : false }
                              
                            />
                          </InputGroup>
                        </FormGroup>
                    </div> 
                    </div>
                    <div className="row">
                    <div className="col-xl-3 col-md-4 col-xs-12">
                    <FormGroup>
                        <label>Joining date</label>
                         <input type="date"
                                      className="form-control"
                                      name="joining_date"
                                      onChange={(e) => inputhandler(e)}
                                      />
                    </FormGroup> 
                  </div>  
                  <div className="col-xl-3 col-md-4 col-xs-12">
                    <FormGroup>
                        <label>Exit date</label>
                         <input type="date"
                                      className="form-control"
                                      name="exit_date"
                                      onChange={(e) => inputhandler(e)}
                                      />
                    </FormGroup> 
                  </div>  
                    {showradiobtn ? (<div className="col-md-4">
                        <FormGroup>
                            <label>Send Email</label> 
                            <br/>
                            <div className="row">
                              <div className="col-md-3">
                                 <span style={{verticalAlign:'super'}}>Yes</span> <input type="radio" name="sendemail" value="yes"
                                  
                                 style={{width:'20px',height:'20px'}}
                                 onChange={() => ismailsend('yes')}
                                 />
                              </div>
                              <div className="col-md-3">
                                <span style={{verticalAlign:'super'}}>No</span> <input type="radio" name="sendemail" value="no" 
                                 style={{width:'20px',height:'20px'}}
                                 onChange={() => ismailsend('no')}
                                 />
                              </div>
                            </div> 
                          </FormGroup>
                    </div>) : (<></>) } 
                    
                    
                </div>
                <div class="row">
                
                    <div className="col-md-4">
                         <Button className="col-12 " color="primary" type="submit" disabled={disablestate}>
                         {disablestate ? 'Sending...' : 'Add user'} 
                         </Button>
                          
                    </div>
                </div>
                
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3"></InputGroup>
                </FormGroup>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Useradd;
