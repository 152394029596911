import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container,
    UncontrolledAlert,
    Modal,
  ModalBody,
  ModalFooter,
  Table
  } from "reactstrap";
  import React, { useState,useEffect } from "react";
  import { useHistory,useParams } from "react-router-dom";
  import {clientmanageService} from '../../../services/clientmanageService.js';
  import { ToastContainer, toast } from 'react-toastify';
  import { forEachChild } from "typescript";
  import Select from 'react-select';
  import { taskmanageService } from "../../../services/taskmanageService.js";
  const EditClient = () => {
    const history = useHistory();
    const params  = useParams();
    
    let initialStateData = {
      name:"",
      sub_client:"",
      year_end_date: "",
      raised_on: "",
      book_keeping_software:"",
      account_prod_software:"",
      initial_budget:"",
      client_deadline:"",
      due_date:"",
      user_id:'',
      files:[]

    };
    const [inputFields, setInputfields] = useState(initialStateData);
    useEffect(() => { 
       getclient();
    }, [] );
    const [freqdata, setfreqdata] = useState([]);
    const [weeklydiv, setweeklydiv] = useState(false);
    const [annuallydiv, setannuallydiv] = useState(false);
    const [other_report, setOtherReport] = useState(false);
    function inputhandler(e){
     
      if(Array.isArray(e)){   
        setInputfields({...inputFields,freq_date : Array.isArray(e) ? e.map(x => x.value) : []})  
        setOtherReport(false)
      }
      else{
        if((e.target.name == 'type' && e.target.value=='Other Reports') || e.target.name=='other_report' ){
          setOtherReport(true)
        }else{
          setOtherReport(false)
        }
        if(e.target.name == 'frequency' && e.target.value != 'OneOff'){
          setweeklydiv(false)
          setannuallydiv(false)
          setfreqdata([])
          if(e.target.value == 'Weekly'){
            const daydata = [{value:1,label : 'Monday'},{value:2,label :'Tuesday'},{value:3,label :'Wednesday'},{value:4,label :'Thursday'},{value:5,label :'Friday'},{value:6,label :'Saturday'},{value:7,label :'Sunday'}];
            setweeklydiv(true)
            setfreqdata(daydata)
          }
          if(e.target.value == 'Monthly' || e.target.value == 'Fortnightly'){
            let daydata = [];
            for(let i = 1; i <= 31; i++){
              if(i<=9 && i>=1){
                daydata.push({value : '0'+i,label : '0'+i})
              }else{
                daydata.push({value : i,label : i})
              }
            }
            setweeklydiv(true)
            setfreqdata(daydata)
          }
          if(e.target.value == 'Quarterly' || e.target.value == 'Annually'){
            const daydata = [{value:'01',label : 'Jan'},{value:'02',label :'Feb'},{value:'03',label :'March'},{value:'04',label :'April'},{value:'05',label :'May'},{value:'06',label :'June'},{value:'07',label :'July'},{value:'08',label :'Aug'},{value:'09',label :'Sept'},{value:10,label :'Oct'},{value:11,label :'Nov'},{value:12,label :'Dec'}];
            setannuallydiv(true)
            setfreqdata(daydata)
          }
          setInputfields({...inputFields,freq_date : [],freq_day : '',[e.target.name]:e.target.value});
        }else{
          setInputfields({...inputFields,[e.target.name]:e.target.value});
        }
      }
    } 
  
    function addclient(e){
      e.preventDefault();
      console.log(inputFields);
      // return;
      clientmanageService.createclient(inputFields).then((response)=>{
          if(response.status == 200){
            toast.success(response.data.message);   
             history.goBack();
          }else if(response.status == 201){
            toast.error(response.data.message); 
          }
      });
    }

    function getclient(){
      let task_id   = params.id; 
      let empty_obj = { }; 
      clientmanageService.getparticular_client(task_id).then((response) => {
          // console.log('response');
          //console.log(response);
          if(response.status == 200){
                empty_obj.name = response.data.data.name;
                empty_obj.sub_client = response.data.data.sub_client;
                empty_obj.year_end_date = response.data.data.year_end_date;
                empty_obj.raised_on = response.data.data.created_at;
                empty_obj.book_keeping_software = response.data.data.book_keeping_software;
                empty_obj.account_prod_software = response.data.data.account_prod_software;
                empty_obj.in_budget = response.data.data.in_budget;
                empty_obj.budget = response.data.data.budget;
                empty_obj.client_deadline = response.data.data.task_due_date;
                empty_obj.due_date = response.data.data.task_due_date; 
                empty_obj.frequency = response.data.data.frequency; 
                empty_obj.company_id = response.data.data.company_id;
                empty_obj.type = response.data.data.type; 
                empty_obj.vat_qe = response.data.data.vat_qe; 
                empty_obj.vat_qe_date = response.data.data.vat_qe_date; 
                empty_obj.vat_scheme = response.data.data.vat_scheme; 
                empty_obj.business_nature = response.data.data.business_nature; 
                empty_obj.priority_no = response.data.data.priority_no; 
                empty_obj.task_id = task_id;
               
                empty_obj.freq_date = (response.data.data.freq_date) ? response.data.data.freq_date.map(x => x) : []
                empty_obj.freq_day = response.data.data.freq_day;
                empty_obj.task_type = response.data.data.task_type;
                empty_obj.task_list = response.data.data.task_list;
                empty_obj.other_report = response.data.data.other_report;
                empty_obj.user_id = response.data.data.user_id;
                if(response.data.data.frequency == 'Weekly'){
                  const daydata = [{value:1,label : 'Monday'},{value:2,label :'Tuesday'},{value:3,label :'Wednesday'},{value:4,label :'Thursday'},{value:5,label :'Friday'},{value:6,label :'Saturday'},{value:7,label :'Sunday'}];
                  setweeklydiv(true)
                  setfreqdata(daydata)
                }
                if(response.data.data.frequency == 'Monthly' || response.data.data.frequency == 'Fortnightly'){
                  let daydata = [];
                  for(let i = 1; i <= 31; i++){
                    if(i<=9 && i>=1){
                      daydata.push({value : '0'+i,label : '0'+i})
                    }else{
                      daydata.push({value : i,label : i})
                    }
                  }
                  setweeklydiv(true)
                  setfreqdata(daydata)
                }
                if(response.data.data.frequency == 'Quarterly' || response.data.data.frequency == 'Annually' || response.data.data.frequency == 'Bimonthly' || response.data.data.frequency == 'Fourthmonthly'){
                  const daydata = [{value:'01',label : 'Jan'},{value:'02',label :'Feb'},{value:'03',label :'March'},{value:'04',label :'April'},{value:'05',label :'May'},{value:'06',label :'June'},{value:'07',label :'July'},{value:'08',label :'Aug'},{value:'09',label :'Sept'},{value:10,label :'Oct'},{value:11,label :'Nov'},{value:12,label :'Dec'}];
                  setannuallydiv(true)
                  setfreqdata(daydata)
                }
                if(response.data.data.type=='Other Reports'){
                  setOtherReport(true)
                }
                setDocumentLists(response.data.files);
                setInputfields(empty_obj);
                console.log(inputFields);
          }
      });
      // setparticularclient();
    }
  

    function cancel_form(){
         document.getElementById('addclient-form').reset();
         setInputfields(initialStateData);
         history.goBack(); 
    }
    const disablePastDate = () => {
      const today = new Date();
      const dd = String(today.getDate() + 1).padStart(2, "0") - 1;
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
  };
  
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  function onFileChange(e){
    const files = Array.from(e.target.files);
    console.log(e.target.files)
    setInputfields({ ...inputFields, [e.target.name]: files });
    console.log(inputFields)
  }
  const [documentlists, setDocumentLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [documentModalOpen, setdocumentModalOpen] = useState(false); 
  const downloadAllfile = (userid,taskid) => {
    setLoading(true)
    taskmanageService.download_all_file(userid,taskid).then((response) => {
      setLoading(false)
    });
  }
  
  const downloadfile = (path,name,extension,task_id) => {
    setLoading(true)
    taskmanageService.download_file(path,name,extension,task_id).then((response) => {
        setLoading(false)
    });
  }
  
  return (
        <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
            <Row className="justify-content-md-center">
                <Col lg="12" md="12">
                    <Card className="shadow">
                        <CardHeader className="bg-transparent">
                            <h3 className="mb-0">Edit Client</h3>
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-10">
                        
                        <Form role="form" id="addclient-form" onSubmit={addclient}>
                            <FormGroup>
                             <Row>
                             {user_info.role_id != 13 ? 
                            <>
                              <Col>
                               <label>Client</label> 
                                   <InputGroup className="input-group-alternative mb-3">
                                      <Input 
                                      placeholder="Client Name" 
                                      type="text" 
                                      name="name" 
                                      defaultValue={inputFields.name}
                                      // value={inputFields.name != undefined ? inputFields.name : ''}
                                      onChange={inputhandler}
                                      required
                                      />
                                  </InputGroup>      
                                  </Col>
                                  </> : null }
                                  <Col>
                                    <label>Sub Client</label> 
                                        <InputGroup className="input-group-alternative mb-3">
                                            <Input 
                                            placeholder="Sub Client Name" 
                                            type="text" 
                                            name="sub_client" 
                                            defaultValue={inputFields.sub_client}
                                            //value={inputFields.sub_client != undefined ? inputFields.sub_client : ''}
                                            onChange={inputhandler}
                                            required
                                            />
                                        </InputGroup>    
                                  </Col>
                             </Row>  
                            </FormGroup>
                            <FormGroup>
                            <Row>
                              <Col>
                              <label>Year-End date</label>
                                   <InputGroup className="input-group-alternative mb-3">
                                        <Input
                                        placeholder="Year - End Date"
                                        type="date"
                                        name="year_end_date"
                                        value={inputFields.year_end_date != undefined ? inputFields.year_end_date : ''}
                                        onChange={inputhandler}
                                        required
                                        />
                                    </InputGroup>  
                                </Col>
                                <Col>
                                <label>Bookkeeping Software</label>
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="Bookkeeping Software"
                                        type="text"
                                        name="book_keeping_software"
                                        value={inputFields.book_keeping_software != undefined ? inputFields.book_keeping_software : ''}
                                        onChange={inputhandler}
                                        required
                                        />
                                    </InputGroup>
                                </Col>
                            
                                <Col>
                                  <label>Invoice Processing Software</label> 
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="Invoice processing Software"
                                        type="text"
                                        name="account_prod_software"
                                        value={inputFields.account_prod_software != undefined ? inputFields.account_prod_software : ''}
                                        onChange={inputhandler}
                                        required
                                        />
                                    </InputGroup>
                                  </Col>
                                  </Row> 
                          </FormGroup>
                          <FormGroup>
                             <Row>
                             {user_info.role_id != 13 ? 
                            <>
                            <Col>
                               <label>QA Budget Hours</label> 
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="QA Budget"
                                        type="number"
                                        name="in_budget"
                                        defaultValue={inputFields.in_budget != undefined ? inputFields.in_budget : ''}
                                        onChange={inputhandler}
                                        required
                                        inputmode="numeric"
                                        />
                                    </InputGroup>
                                </Col>
                                {user_info.department == 'SME' ?
                                <>
                                  <Col>
                                  <label>IP Budget Hours</label> 
                                       <InputGroup className="input-group-alternative">
                                           <Input
                                           placeholder="IP Budget"
                                           type="number"
                                           name="ip_budget"
                                           value={inputFields.ip_budget != undefined ? inputFields.ip_budget : ''}
                                           onChange={inputhandler}
                                           required
                                           inputmode="numeric"
                                           />
                                       </InputGroup>
                                   </Col>
                                   <Col>
                                  <label>BR Budget Hours</label> 
                                       <InputGroup className="input-group-alternative">
                                           <Input
                                           placeholder="BR Budget"
                                           type="number"
                                           name="br_budget"
                                           value={inputFields.br_budget != undefined ? inputFields.br_budget : ''}
                                           onChange={inputhandler}
                                           required
                                           inputmode="numeric"
                                           />
                                       </InputGroup>
                                   </Col> </>
                                : null }
                                   
                                   </>
                                : null }
                                <Col>
                               <label>Total Budget Hours</label> 
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="Total Budget"
                                        type="number"
                                        name="budget"
                                        value={inputFields.budget != undefined ? inputFields.budget : ''}
                                        onChange={inputhandler}
                                        required
                                        inputmode="numeric"
                                        />
                                    </InputGroup>
                                </Col>
                            <Col>
                                 <label>Client Deadline</label> 
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="Client Deadline"
                                        type="date"
                                        name="client_deadline"
                                        defaultValue={inputFields.client_deadline != undefined ? inputFields.client_deadline : ''}
                                        onChange={inputhandler}
                                        required
                                        />
                                    </InputGroup>
                                </Col>
                                <Col>
                                <label>Type Of JOB</label> 
                                <select class="form-control" name="type"  value={inputFields.type} onChange={inputhandler} required>
                                  <option value=""> Select type</option>
                                  <option value="Bkg">Bkg</option>
                                  <option value="VAT">VAT</option>
                                  <option value="MA">MA</option>
                                  <option value="Bookkeeping Review">Bookkeeping Review</option>
                                  <option value="Bkg + VAT">Bkg + VAT</option>
                                  <option value="Bkg + MA">Bkg + MA</option>
                                  <option value="VAT + MA">VAT + MA</option>
                                  <option value="Bkg + VAT + MA">Bkg + VAT + MA</option>
                                  <option value="Migration">Migration</option>
                                  <option value="Bank Reconciliation">Bank Reconciliation</option>
                                  <option value="Payroll">Payroll</option>
                                  <option value="CIS Reconciliation">CIS Reconciliation</option>
                                  <option value="Other Reports">Other Reports</option>
                              </select>
                              <Input
                                  placeholder="Enter Other Report"
                                  type="text"
                                  name="other_report"
                                  
                                  onChange={inputhandler}
                                  value={inputFields.other_report != undefined ? inputFields.other_report : ''}
                                  style={{ display: other_report == false ? "none" : "flex" }}
                                  />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                             <Row>
                             {/* {user_info.role_id != 13 ? 
                            <> */}
                              <Col>
                                <label>Frequency</label> 
                                <select class="form-control" name="frequency" value={inputFields.frequency} onChange={inputhandler} required>
                              <option value=""> Select frequency</option>
                              <option value="OneOff">One Off</option>
                                <option value="Daily">Daily</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Fortnightly">Fortnightly</option>
                                <option value="Quarterly">Quarterly</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Annually">Annually</option>
                                <option value="Bimonthly">Bimonthly</option>
                                <option value="Fourthmonthly">Fourthmonthly</option>
                              </select>
                              </Col>
                              {(weeklydiv == true)?
                              <Col>
                                <label>Frequency values</label>
                                <Select 
                                    className="input-group-alternative mb-3" 
                                    name="freq_date" 
                                    allowSelectAll={true} 
                                    value={(inputFields.freq_date) ? freqdata.filter(obj => inputFields.freq_date.includes(obj.value)) : ''} // set selected values
                                    options={freqdata} // set list of the data
                                    onChange={inputhandler}
                                    isMulti
                                    isClearable
                                    required
                                  />
                                  {inputFields.freq_date && <div style={{ marginTop: 20, lineHeight: '25px' }}></div>}
                              </Col>
                              : null }
                              {(annuallydiv == true)?
                              <>
                              <Col md="1" >
                                <label>Day</label> 
                                 <select class="form-control" name="freq_day" value={inputFields.freq_day} onChange={inputhandler} required>
                                  <option value=""> Select Day</option>
                                  {(() => {
                                      let option = [];
                                      for (let i = 1; i <= 31; i++) {
                                        option.push(<option value={i}>{i}</option>);
                                      }
                                      return option;
                                    })()}
                                  </select>
                                  </Col>
                                  <Col >
                                    <label>Months</label>
                                <Select 
                                    className="input-group-alternative mb-3" 
                                    name="freq_date" 
                                    allowSelectAll={true} 
                                    value={(inputFields.freq_date) ? freqdata.filter(obj => inputFields.freq_date.includes(obj.value)) : ''} // set selected values
                                    options={freqdata} // set list of the data
                                    onChange={inputhandler}
                                    isMulti
                                    isClearable
                                    required
                                  />
                                  {inputFields.freq_date && <div style={{ marginTop: 20, lineHeight: '25px' }}></div>}
                              </Col>
                              </>
                              : null }
                              {/* </>
                              : null } */}
                             
                              
                            </Row> 
                          </FormGroup>
                          <FormGroup>
                             <Row>
                             <Col>
                                <label>VAT Quarter End</label> 
                                <select class="form-control" name="vat_qe" value={inputFields.vat_qe} onChange={inputhandler} required>
                              <option value=""> Select VAT quarter end</option>
                              <option value="NO VAT">NO VAT</option>
                              <option value="Monthly">Monthly</option>
                                <option value="Jan-Apr-Jul-Oct">Jan-Apr-Jul-Oct</option>
                                <option value="Feb-May-Aug-Nov">	Feb-May-Aug-Nov	</option>
                                <option value="Mar-Jun-Sep-Dec">Mar-Jun-Sep-Dec</option>
                              </select>
                              </Col>
                              <Col>
                                 <label>VAT Quarter End date</label> 
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="VAT Quarter End date"
                                        type="date"
                                        name="vat_qe_date"
                                        defaultValue={inputFields.vat_qe_date != undefined ? inputFields.vat_qe_date : ''}
                                        onChange={inputhandler}
                                        
                                        />
                                    </InputGroup>
                                </Col>
                             <Col>
                              <label>VAT Scheme</label> 
                              <InputGroup className="input-group-alternative">
                                <Input
                                  placeholder="VAT Scheme"
                                  type="text"
                                  name="vat_scheme"
                                  defaultValue={
                                    inputFields.vat_scheme != undefined
                                      ? inputFields.vat_scheme
                                      : ""
                                  }
                                  onChange={inputhandler}
                                  
                                />
                              </InputGroup>
                              </Col>
                              <Col>
                              <label>Priority</label> 
                                  <InputGroup className="input-group-alternative">
                                      <Input
                                      placeholder="Priority"
                                      type="number"
                                      name="priority_no"
                                      defaultValue={inputFields.priority_no != undefined ? inputFields.priority_no : ''}
                                      onChange={inputhandler}
                                      required
                                      inputmode="numeric"
                                      />
                                    </InputGroup>
                              </Col>
                              <Col>
                              <label>Scope of work</label>
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="Scope of work"
                                        type="text"
                                        name="business_nature"
                                        defaultValue={inputFields.business_nature != undefined ? inputFields.business_nature : ''}
                                        onChange={inputhandler}
                                        required
                                        />
                                    </InputGroup>
                              </Col>
                            </Row>
                            </FormGroup>
                            {user_info.role_id != 13 ? 
                            <>
                            <FormGroup>
                             <Row>

                             <Col md="3">
                                <label>Task Type</label> 
                                <select class="form-control" name="task_type" value={inputFields.task_type} onChange={inputhandler} >
                                  <option value=""> Select Task Type</option>
                                  <option value="Transition">Transition</option>
                                  <option value="Operations">Operations</option>
                                  <option value="E2E">E2E</option>
                                </select>
                              </Col>
                              <Col md="3">
                                <label>Task List</label> 
                                <select className="form-control"
                                value={inputFields.task_list}
                            name="task_list"
                             onChange={inputhandler}
                                   
                                  >
                             <option >Select Option</option>
                             <option value="Onboarding PPT">Onboarding PPT</option>
    <option value="Preparation Of Backlog Assessment Report">Preparation Of Backlog Assessment Report</option>
    <option value="SOP / Process Map Preparation">SOP / Process Map Preparation</option>
    <option value="Transition Tracker Update">Transition Tracker Update</option>
    <option value="Understanding Of Client Processes">Understanding Of Client Processes</option>
    <option value="Adhoc Reporting">Adhoc Reporting</option>
    <option value="Age Payable Review">Age Payable Review</option>
    <option value="Age Receivable Review">Age Receivable Review</option>
    <option value="Bank Payments">Bank Payments</option>
    <option value="Bank Reconciliation">Bank Reconciliation</option>
    <option value="Bank Upload">Bank Upload</option>
    <option value="Cash Flow Preparation">Cash Flow Preparation</option>
    <option value="CIS Reco">CIS Reco</option>
    <option value="Client Inbox / Credit Control">Client Inbox / Credit Control</option>
    <option value="Credit Card">Credit Card</option>
    <option value="Customer Statement Reconciliation">Customer Statement Reconciliation</option>
    <option value="Factoring Reco">Factoring Reco</option>
    <option value="Factoring Working & Upload">Factoring Working & Upload</option>
    <option value="Journal Vouchers">Journal Vouchers</option>
    <option value="Management Reports / Dashboard / PPT">Management Reports / Dashboard / PPT</option>
    <option value="Month End Checklist">Month End Checklist</option>
    <option value="Opening Balance Comparison / Opening Journal">Opening Balance Comparison / Opening Journal</option>
    <option value="Payment File Preparation">Payment File Preparation</option>
    <option value="Petty Cash">Petty Cash</option>
    <option value="Purchase Invoices">Purchase Invoices</option>
    <option value="Review & Other Process Review">Review & Other Process Review</option>
    <option value="Review Month End Checklist & MA">Review Month End Checklist & MA</option>
    <option value="Sales Invoices">Sales Invoices</option>
    <option value="Supplier Statement Reconciliation">Supplier Statement Reconciliation</option>
    <option value="Vat Return Review">Vat Return Review</option>
    <option value="Vat Working">Vat Working</option>
    <option value="Year End Checklist">Year End Checklist</option>
    <option value="Year End Checklist Review">Year End Checklist Review</option>
    <option value="Trade Entries">Trade Entries</option>
    <option value="Amendment">Amendment</option>
    <option value="Billing">Billing</option>
    <option value="Inter Company Reconciliation">Inter Company Reconciliation</option>
    <option value="Audit Support">Audit Support</option>
                             </select>
                              </Col>
                             
                              </Row>
                              </FormGroup>
                              </>
                              : null }
                              {user_info.role_id == 13 ? 
                              <FormGroup>
                                <Row>
                                <Col md="3">
                                <div>
                                <label>Other Document</label>
                                <input type="file" multiple  name="file" id="import_file" className="text-right"  onChange={onFileChange}  />
                                </div> <div><br/>
                                <button type="button" onClick={() => setdocumentModalOpen(true) } title="Download Documents" class=" btn btn-primary text-center" style={{textAlign:"center"}}>
              <i class="fas fa-eye" style={{fontSize:"14px"}}></i> View previous uploaded documents
            </button></div>
                                </Col>
                                </Row>
                                </FormGroup> : null }
                              <Modal className="taskdetailmodal"  isOpen={documentModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h3 className=" modal-title" id="exampleModalLabel">
              Previous uploaded documents 
              </h3>
              <button aria-label="Close" className=" close" type="button" onClick={() => setdocumentModalOpen(!documentModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{paddingTop:"0px"}}>
            {(loading == true) ? 
                      <a style={{color:"#2FC3B9"}}  >Downloading please wait.. </a>
                      :
            <button type="button" onClick={() => downloadAllfile(inputFields.user_id,inputFields.task_id)} title="Download All Documents" class="btn-icon-clipboard btn btn-secondary col-md-3" style={{textAlign:"center"}}>
              <i class="fas fa-download" style={{fontSize:"14px"}}></i> Download All Documents
            </button>
}
              <Table>
              <thead>
                  <tr>
                    <th className="whitespace">Document Type</th>
                    {/* <th className="whitespace">Remarks</th> */}
                    <th className="whitespace">Document</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                {documentlists.length > 0 && documentlists  ? (
                        documentlists.map((document, i) => (
                  <React.Fragment key={i}>
                  <tr key={i}>
                    <td className="whitespace">{document.doc_type}</td>
                    {/* <td className="whitespace">{document.remark}</td> */}
                    <td className="whitespace">
                      {(loading == true) ? 
                      <a style={{color:"#5d6cae"}}  >Downloading please wait.. </a>
                      :
                      <a style={{cursor:"pointer",color:"#5d6cae"}}  onClick={() => downloadfile(document.doc_path,document.file_name,document.extension,inputFields.task_id)} download><i className="fas fa-download"> </i> Download </a>
                      }
                    </td>
                    <td className="whitespace">{document.date}</td>
                  </tr>
                  </React.Fragment>
                  ))
                 ) : (
                  <tr>
                  <td>No Data Found</td>
                  </tr>
                )}
                </tbody>
              </Table>
            </ModalBody>
          </Modal>
                            <div className="col-12">
                               <Button className="mt-2" color="primary" type="submit">
                                Save
                               </Button>
                               <Button className="mt-2 ml-2" color="secondary " type="button" onClick={cancel_form}>
                                 Cancel
                               </Button>
                            </div>
                        </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        </div>
        </>
    );
  };
  
  export default EditClient;
  