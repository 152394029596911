const iState = {
    name: ""
  };
  
  const companysearch = (state = iState, action) => {
    if (action.type === "search") {
      return {
        name: action.payload
      };
    } else {
      return state;
    }
    return state;
  };
  export default companysearch;