import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { ToastContainer, toast } from 'react-toastify';
import { domainUrl} from '../../constant';
import '@flowjs/flow.js';
import Flow from "@flowjs/flow.js";
import { async } from "@firebase/util";
import { useHistory,useParams } from "react-router-dom";
import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
import { manageService } from "../../services/manageaccountservices";
import Select from 'react-select';
const axios = require("axios");

const EditAnnounce = (props) => {
  const params  = useParams();
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [clientdata, setClientdata] = useState([]);
  const [clientsearch, setclientsearch] = useState([{'label':'Select Client',value:'al_key_1'}]);
 

useEffect(() => {
 
  getsingleAnnouncement();
  
}, []);
let { id } = useParams();
const initialData = {
    id:'',
    name : '',
    status : 1,
    start_date:'',
    end_date:''
    
  }
const [inputFields, setInputfields] = useState(initialData);
function getsingleAnnouncement(){
    taskmanageService.getsingleAnnouncement(id).then((response)=>{
        if(response.status==200){
        setInputfields(response.data.data)
        }
  });
}

 


  function inputhandler(e) {
    setInputfields({ ...inputFields, [e.target.name]: e.target.value });
  }
function save_announcement(){
    taskmanageService.update_announcement(inputFields).then((response) => {
      if (response.status == 200) {
        toast.success(response.data.message);
        //location.reload();
       
      } else {
        for (const field in response.data) {
        toast.error(response.data[field][0]);
        }
      }

    });
  }
  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
    
        <Row className="mt-5">
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
            <form id="budgetform">
           
                   <>
              <CardHeader className="border-0">
              <Row >
               
                   
                  <div className="col-xl-6 col-md-6 col-xs-12" >
                  <h3 className="clientformtag"> Announcement</h3>
                  <Input type="textarea" rows="4"  defaultValue={inputFields.name }  placeholder="Announcement" name="name" onChange={inputhandler}  /> 
             
                </div>
                
                  
                
                </Row>
                
                <br></br>
                <Row > 
                <div className="col-xl-6 col-md-6 col-xs-12" >
                 <b> Status : </b>&nbsp;&nbsp;
                <input style={{maxWidth:"3%"}} type="radio" defaultValue={inputFields.status} className="col-1" name="status" value="1" onChange={inputhandler}  checked={inputFields.status == '1'} /> &nbsp;Active
                &nbsp;<input style={{maxWidth:"3%"}} type="radio" className="col-1" name="status" value="0" onChange={inputhandler} checked={inputFields.status == '0'} /> &nbsp;Deactive
                </div>
                  </Row> 
                  <br></br> 
                  <Row>
                  <div className="col-xl-6 col-md-6 col-xs-12" >
                  <h3 className="clientformtag">Start date</h3>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      placeholder="Start Date"
                      type="date"
                      name="start_date"
                      value={
                        inputFields.start_date != undefined
                          ? inputFields.start_date
                          : ""
                      }
                      onChange={inputhandler}
                      required
                    />
                  </InputGroup>
                  </div>
                  <div className="col-xl-6 col-md-6 col-xs-12" >
                  <h3 className="clientformtag">End date</h3>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      placeholder="End Date"
                      type="date"
                      name="end_date"
                      value={
                        inputFields.end_date != undefined
                          ? inputFields.end_date
                          : ""
                      }
                      onChange={inputhandler}
                      required
                    />
                  </InputGroup>
                  </div>
                  </Row>
                  <br></br>
              <Button type="button"
              className="btn btn-primary"
                      onClick={save_announcement}
                      style={{height:'36px',width:'60px',top:'8px',padding:'5px'}}
                    >
                   Save
                    </Button>
              </CardHeader>
              </>
              
              
              

</form>
            </Card>
          </div>
        </Row>
    

      </Container>
    </>
  );
};


export default EditAnnounce;
