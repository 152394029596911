import {apiUrl} from '../constant';
import {authtoken} from 'global.js';
const axios = require("axios");
export const reportmanageService = {
    getReportData,
    getReportDetails,
    download_report,
    getDailyReportData,
    getWeeklyReportData,
    getMonthlyReportData,
    getDailyReportDetails,
    getWeeklyReportDetails,
    getMothlyReportDetails,
    daily_report_export,
    weekly_report_export,
    monthly_report_export,
    getReportRejections,
    getQueryResponses,
    download_rejection_report,
    getDocumentReportData,
    document_report_export,
    download_external_rejection_report,
    getReportRejectionsExternal,
    archiveExternalccrp,
    getUserReportData,
    download_clienttransition_data,
    download_governance_data,
    getGovernnaceSummary,
    download_internal_governance_data,
    download_clienttransition_data_sme,
    download_internal_data ,
    getmanagerRejectionData
    
};

function archiveExternalccrp(id){
  return axios({
    method: "post",
    url: `${apiUrl}/deleteexternalrejection/${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}
function getDocumentReportData(){
  return axios({
    method: "get",
    url: `${apiUrl}/document-report`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
    })
    .catch(function (error) {
    });   
}
function getWeeklyReportData(phase){
  return axios({
      method: "get",
      url: `${apiUrl}/user-weekly-report?phase_id=${phase}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
} 
function getReportData(start_date,end_date,sortfield,sortorder){
  if(start_date!=null && end_date!=null){
    var sendData = '?start_date='+start_date+'&end_date='+end_date+'&sort='+sortfield+'&order='+sortorder;
  }else{
    var sendData = '?sort='+sortfield+'&order='+sortorder;
  }
    return axios({
        method: "get",
        url: `${apiUrl}/report-data${sendData}`,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
           if(response.status == 200){
                return response;  
           }
        }, error => {
          return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
}
function getUserReportData(type){
  // if(start_date!=null && end_date!=null){
  //   var sendData = '?start_date='+start_date+'&end_date='+end_date+'&sort='+sortfield+'&order='+sortorder;
  // }else{
  //   var sendData = '?sort='+sortfield+'&order='+sortorder;
  // }
    return axios({
        method: "get",
        url: `${apiUrl}/user-report-data?type=${type}`,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
           if(response.status == 200){
                return response;  
           }
        }, error => {
          return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
}
function getReportDetails(userid,type,status,page,start_date,end_date){
  if(start_date!=null && end_date!=null){
    var sendData = '&start_date='+start_date+'&end_date='+end_date;
  }else{
    var sendData = '';
  }
  let client =encodeURIComponent(userid);
    return axios({
        method: "get",
        url: `${apiUrl}/report-details?id=${client}&type=${type}&status=${status}&page=${page}${sendData}`,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
           if(response.status == 200){
                return response;  
           }
        }, error => {
          return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
}
function download_report(userid,type,status,start_date,end_date){
  if(start_date!=null && end_date!=null){
    var sendData = '&start_date='+start_date+'&end_date='+end_date;
  }else{
    var sendData = '';
  }
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/report-details-export?id=${userid}&type=${type}&status=${status}${sendData}`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `workflow-report-${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}

function getDailyReportData(phase){
   //user-daily-report
   return axios({
    method: "get",
    // responseType: 'blob', 
    url: `${apiUrl}/user-daily-report?phase_id=${phase}`,
    headers: { Authorization: `Bearer ${authtoken}`},
  })
  .then(function (response) {
      // console.log('daily report');
      // console.log(response);
      return response;
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });

}
function getMonthlyReportData(phase,start_date,end_date){
  if(start_date!=null && end_date!=null){
    var sendData = '&start_date='+start_date+'&end_date='+end_date;
  }else{
    var sendData = '';
  }
  return axios({
      method: "get",
      url: `${apiUrl}/user-monthly-report?phase_id=${phase}${sendData}`,
      headers: {Authorization: `Bearer ${authtoken}` }
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function getDailyReportDetails(userid,role_id,status,page,search,filter,filtertype,sortfield,sortorder){
  return axios({
      method: "get",
      url: `${apiUrl}/daily-report-details?user_id=${userid}&role_id=${role_id}&status=${status}&page=${page}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function getWeeklyReportDetails(company,phase,page,search,filter,filtertype,sortfield,sortorder){
  let client =encodeURIComponent(company);
  return axios({
      method: "get",
      url: `${apiUrl}/weekly-report-details?company=${client}&phase_id=${phase}&page=${page}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function getMothlyReportDetails(company,status,role_id,page,start_date,end_date){
  //console.log(company);
  let client =encodeURIComponent(company);
  let current_url= `${apiUrl}/monthly-report-details?status=${status}&company=${client}&page=${page}`;
  if(status==''){
    current_url = `${apiUrl}/monthly-report-details?company=${client}&page=${page}`;
  }else if(role_id==4 && status !=''){
    current_url = `${apiUrl}/monthly-report-details?status=${status}&user_id=${client}&page=${page}`;
  }else if(role_id==4 && status ==''){
    current_url = `${apiUrl}/monthly-report-details?user_id=${client}&page=${page}`; 
  }

  return axios({
      method: "get",
      url:current_url+`&start_date=${start_date}&end_date=${end_date}` ,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function daily_report_export(userid,type,status){
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/daily-report-export`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `daily-report-${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}

function weekly_report_export(userid,type,status){
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/weekly-report-export`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `daily-report-${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}

function monthly_report_export(start_date,end_date){
  if(start_date!=null && end_date!=null){
    var sendData = '?start_date='+start_date+'&end_date='+end_date;
  }else{
    var sendData = '';
  }
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/monthly-report-export${sendData}`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `monthly-report-${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}

function getReportRejections(page,filter,filtertype,datatype){
  // if(start_date!=null && end_date!=null){
  //   var sendData = '&start_date='+start_date+'&end_date='+end_date;
  // }else{
    var sendData = '';
  // }
  let filters = '';
  if(filter){
     filters = `&filters=${JSON.stringify(filter)}`;
  }
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
    return axios({
        method: "get",
        url: `${apiUrl}/rejection-report?page=${page}&search=${filters}${typefilter}&data_type=${datatype}${sendData}`,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
           if(response.status == 200){
                return response;  
           }
        }, error => {
          return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
}
function getQueryResponses(page){
  return axios({
    method: "get",
    url: `${apiUrl}/query-responses?page=${page}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });   
}
function download_rejection_report(filter,filtertype,hoddatatype){
  // if(start_date!=null && end_date!=null){
  //   var sendData = '&start_date='+start_date+'&end_date='+end_date;
  // }else{
    var sendData = '';
  // }
  let filters = '';
  let search = '';
  if(filter){
     filters = `&filters=${JSON.stringify(filter)}`;
  }
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/report-rejection-export?${sendData}&data_type=${hoddatatype}&search=${search}${filters}${typefilter}`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `rejection-report-${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}

function document_report_export(){
  var sendData = '';
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/report-document-export?${sendData}`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `document-report-${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}
function download_external_rejection_report(filter,filtertype){
  let filters = '';
  if(filter){
     filters = `&filters=${JSON.stringify(filter)}`;
  }
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/external-rejection-export?search=''${filters}${typefilter}`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `external-ccrp-report-${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}

function getReportRejectionsExternal(page,search,filter,filtertype,sortfield,sortorder){
  let filters = '';
  if(filter){
     filters = `&filters=${JSON.stringify(filter)}`;
  }
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
  return axios({
    method: "get",
    url: `${apiUrl}/external-rejection-report?page=${page}&search=${search}${filters}${typefilter}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });    
}
function download_clienttransition_data(){
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/clienttransition-data-export`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `client-transition-report-${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
}
function download_governance_data(filter,filtertype){
  let filters = '';
  let search = '';
  if(filter){
     filters = `&filters=${JSON.stringify(filter)}`;
  }
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/governance-data-export?search=${search}${filters}${typefilter}`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `governance-data-report-${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}
function getGovernnaceSummary(){
  return axios({
    method: "get",
    url: `${apiUrl}/governance-summary`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });     
}
function download_internal_governance_data(filter,filtertype){
  let filters = '';
  let search = '';
  if(filter){
     filters = `&filters=${JSON.stringify(filter)}`;
  }
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/internal-governance-data-export?search=${search}${filters}${typefilter}`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `internal-governance-data-report-${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}
function download_clienttransition_data_sme(page,search,filter,filtertype,sortfield,sortorder){
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/clienttransition-sme-data-export?page=${page}&search=${search}`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `client-transition-report-${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
}
function download_internal_data(filter,filtertype){
  let filters = '';
  let search = '';
  if(filter){
     filters = `&filters=${JSON.stringify(filter)}`;
  }
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/internal-data-export?search=${search}${filters}${typefilter}`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `internal-data-report-${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}
function getmanagerRejectionData(page,search,filter,filtertype,per_page){
  let filters = '';
  if(filter){
     filters = `&filters=${JSON.stringify(filter)}`;
  }
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
    return axios({
        method: "get",
        url: `${apiUrl}/getmanagerRejectionData?page=${page}&search=${search}${filters}${typefilter}&per_page=${per_page}`,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
           if(response.status == 200){
                return response;  
           }
        }, error => {
          return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        }); 
}