import { useState,useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect, useHistory } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  Toast,
} from "reactstrap";
import swal from 'sweetalert';
import {AgGridReact,AllCommunityModules,AgGridColumn} from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import {userService} from '../../services/authentication';
import '../../assets/css/custom.css';
import Header from "components/Headers/Header.js";
import { forEachChild } from "typescript";
import { connect } from "react-redux";
import {usermanageService} from '../../services/usermanageService';
// import {gettoken,onMessageListener} from '../../firebaseapp';
import { ToastContainer, toast } from 'react-toastify';


const Dashboard = (props) => {
  const history = useHistory();
  const [userdata, setUserdata] = useState([]);
  const [counts_data, setUsercount] = useState({
     usercount:0 
  });

  // const [show, setShow] = useState(false);
  // const [notification, setNotification] = useState({title: '', body: ''});
  // const [isTokenFound, setTokenFound] = useState(false);
  // gettoken(setTokenFound);
 //  const [loaduseredit,setcompo] = useState(false);
 
   useEffect(()=>{
     getuser_list();
   },[]);

  //  onMessageListener().then(payload => {
  //   setShow(true);
  //   setNotification({title: payload.notification.title, body: payload.notification.body})
  //   console.log(payload);
  //   toast.success(payload.notification.title + payload.notification.body,{ delay: 5000 });
  // }).catch(err => console.log('failed: ', err));

  function getuser_list(){
    usermanageService.getusers().then((response) => {
        setUserdata(response.data.users);
        setUsercount({
          usercount:response.data.users.length
        }); 
    }); 
  }

  return (
    <>
      <Header /> 
      {/* Page content */}
        <Container className="mt--7" fluid>
         
        <div className="row">
            <div className="col-xl-12">
            <div className="col-md-12 col-12">
            </div>
            <Row>
              <Col xl="3">
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                          Users
                        </h6>
                        <h1 style={{color:'#0060B8'}}>{counts_data.usercount}</h1>
                        <h2 className="mb-0" style={{color:'#0060B8'}}>No of Users</h2>
                      </div>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
             {/* <Col xl="3">
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                          Client
                        </h6>
                        <h1 style={{color:'#0060B8'}}>2</h1>
                        <h2 className="mb-0" style={{color:'#0060B8'}}>No of Clients</h2>
                      </div>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>  */}
            </Row>

          </div>
        </div>
      </Container>
    </>
  );
};

export default Dashboard;
