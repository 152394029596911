import { useState,useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect, useHistory,Link,useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  Input,
} from "reactstrap";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import {reportmanageService} from '../../services/reportmanageService';
import { taskmanageService } from "../../services/taskmanageService";
import '../../assets/css/custom.css';
import Header from "components/Headers/Header.js";
import { ToastContainer, toast } from "react-toastify";
import { manageService } from "../../services/manageaccountservices";
import { clientmanageService } from "../../services/clientmanageService.js";
import Select from 'react-select';

 const QuestionnaireDashboard = (props) => {
 const params  = useParams();
 const history = useHistory();
 const [clienttransdata, setClientTransdata] = useState([]);
 const [clienttranssummary, setClientTransSummary] = useState([]);
 const [currentPage, setCurrentPage] = useState(null);
 const [last_page, setLastPageData] = useState(0);
 const [error, setError] = useState(null)
 const [statuslist, setStatusdata] = useState([]);
  const [successMsg, setsuccessMsg] = useState(null);
  const [clientsearch, setclientsearch] = useState('');
  const [filterFields,setfilterFields] = useState([]);
  const [filtertype,setfiltertype] = useState('all');
  const [allclient, setAllclient] = useState(true);
  const errorDiv = error 
        ? <UncontrolledAlert className="alert-danger" fade={false}>
        {error}
        </UncontrolledAlert> 
        : '';
  const successDiv = successMsg 
        ? <UncontrolledAlert className="alert-success" fade={false}>
        {successMsg}
        </UncontrolledAlert> 
        : '';
    const [sortfield,setsortfield] = useState('');
  const [sortorder,setsortorder] = useState('asc');
  function get_status_list() {
    taskmanageService.getgovernancestatus().then((response) => {
      setStatusdata(response.data.status);
    });
  }
  function sortTable(field){
    console.log(field)
    setsortfield(field);
    if(sortorder=='asc'){
      setsortorder('desc');
    }else{
      setsortorder('asc');
    }
  }
  useEffect(()=>{
    get_status_list();
    getdata_list();
    get_unique_companieslist();
  },[sortorder]);

  
  const handlePageClick = (e, index) => {
    e.preventDefault();
    getdata_list(index,null);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    getdata_list(prev,null);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    getdata_list(next,null);
  };
  const getdata_list = async (pageNumber = 1,e,filterFields,filtertype='') => { 
    let  textbox = '';

    if(e != null){
       textbox = e.target.value;
    }else{
      textbox = clientsearch;
    }
   
    // const cloneValues = {...filterFields};
    clientmanageService.getClientTransition(pageNumber,textbox,filterFields,filtertype,sortfield,sortorder).then((response) => {
      
      setClientTransdata(response.data.client_transition_summary); 
        //  setCurrentPage(response.data.client_transition_summary.current_page);
        //  setLastPageData(response.data.client_transition_summary.last_page);
         setClientTransSummary(response.data.summary_report)
    });
  };
  
 
const formatDate = (dateStr) => {
  if(dateStr!='' && dateStr!= null){
  const [year, month, day] = dateStr.split('-');
  let newDate = `${day}-${month}-${year}`;
  return newDate;
  }else{
    return null;
  }
};
 
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  
  const add_client_transition = (client_id ='') => {
    history.push("/admin/addclienttransition/"+client_id);
 }

 const view_client_transitionform = (client_id,trans_id) => {
  history.push("/admin/viewclienttransitionform/"+client_id+"/"+trans_id);
}
 
  function filterallcom({type}){
    setfiltertype(type)
    // setmainfilter(type);
    console.log('fff',filterFields)
    getdata_list(1,null,filterFields,type);
  }
  function client_search(e){
    setclientsearch(e.target.value);
    getdata_list(1,e)

  }
  
  function downloadReport(){
    
    reportmanageService.download_clienttransition_data(filterFields,filtertype).then((response) => {
    
    });
  }
  function transition_log(){
    history.push("/admin/clienttransitionlog/");
  }
  const show_sub_client_sop = () => {
    history.push("/admin/questionnaire/");
  }
  const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
function get_unique_companieslist(){
  clientmanageService.getuniqueclient().then((response)=>{
    setUniqueCompaniesData(response.data);
  });
}
const [dash_data, setDashData] = useState([]);
const companyfilter = (e,index) => {
  clientmanageService.getquestionnaireDash(e.value).then((response) => {
    if(response.status==200){
      setDashData(response.data.data)
    }
  });

}
return (
    <>
      <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
        <div className="row">
            <div className="col-xl-12">
            <div className="col-md-12 col-12">
            </div>
            <Row className="mt-12" >
          <div className="col" >
            <Card className="shadow">
            {(uniquecompanieslist.length > 0 && (user_info.role_id != 13) && (params.client_id=='undefined' || params.client_id==undefined))?
                   <>
              <CardHeader className="border-0">
              <Row className="filter" style={{padding:'0 1%'}}>
               <div className="filter" style={{width:'80%'}}>
                   <div className="col-xl-6 col-md-6 col-xs-12">
                    <h3 className="clientformtag"> Client</h3>
                    <Select 
                   
                    className="input-group-alternative mb-3" 
                    name="user_id"
                    options={uniquecompanieslist} // set list of the data
                    onChange={(e) => companyfilter(e,9)}
                    defaultValue={clientsearch}
                    searchable
                    isClearable={false}
                  />
                  </div>
                  
                    </div>
                </Row>
              </CardHeader>
              </>
               : null }
              
            


            </Card>
          </div>
        </Row>
            <Row>
            
                
              <Col xl="12">
                <Card className="shadow">
                <CardHeader className="bg-transparent">
                  
                
                  <Row className="align-items-center">
                
                    
                 
                    <Col xl="2">
                    <Button
                className="dash-button "
                color="primary"
                type="button"
                onClick={() => show_sub_client_sop()}
              >
                {" "}
              questionnaire{" "}
              </Button> 
          </Col>
                  </Row>
                </CardHeader>
                
                  <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                
                    <th scope="col" className="whitespace"  onClick={(e) => sortTable('name')} >Sub Client Name</th>
                    <th scope="col" className="whitespace"  >Status</th>
                    
                    
                </thead>
                <tbody>
                  {dash_data &&
                    dash_data.length &&
                    dash_data != null ? (
                      dash_data.map((item, i) => (
                      <>
                      <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') } edit-type={item.edittype} edit-id={item.id}>
                          <td className="whitespace" >{item.name}  </td>
                          {(item[1] != 'NA') ? 
                            <td className="whitespace" style={{color: (item[status]!='Complete')?'#fb9540':'green'}} >{item['status']}  </td>
                            :
                            <td className="whitespace">{item[status]}  </td>
                          }
                          
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
                    </Table>
                    {/* <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter> */}
                </Card>
              </Col>
             
            </Row>
             
            </div>
        </div>

       
      </Container>
    </>
  );
}; 
export default QuestionnaireDashboard;
