import { useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,useParams
} from "react-router-dom";

import {
  Button,
  Card,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Container,
  Label,
} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { usermanageService } from "../../services/usermanageService";
import { taskmanageService } from "../../services/taskmanageService";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import Buttoncomponent from 'components/Buttoncomponent.js';
import Select from 'react-select';

const Useraddedit = (props) => {
  const [inputFields, setInputfields] = useState({
    name:'',
    email:'',
    role:'',
    phases:[],
    user_id:'',
    qatl:[],
    atl:[],
    team_user:''
  });
  let { userid } = useParams();
  const [disablestate, setdisablestate] = useState(false);

  function inputhandler(e) {
    let phseup = {}
   
        phseup = { ...inputFields, [e.target.name]: e.target.value }
    setInputfields(phseup);
  }

  useEffect(() => {
    getuser();
    // to resolve the memory leak
    return () => {
    };
  }, []);

  function getuser() {
    usermanageService.getsingleclient(userid).then((response) => {

  const datainitialFormState = { 
          user_id: response.data.user.id,
          name: response.data.user.name,
          email: response.data.user.email
      }

      setInputfields(datainitialFormState)
     
      console.log(response.data);
    
      setdefault_val(response);
      
    });
  }

  function setdefault_val(response){
    inputFields.name  = response.data.user.name;
    inputFields.email = response.data.user.email;
  }

  function edituser(e) {
    setdisablestate(true)
    e.preventDefault();
    usermanageService.editclient(inputFields).then((response) => {
      if(response.status == 200){
          toast.success(response.data.message);   
      }else{
          toast.success(response.data.message)
         }  
         setdisablestate(false)
    }); 
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12">
              <div class="row">
                <div class="col-md-6"><h2>Edit User</h2></div>
                <div class="col-md-6">
                <div class="row">
                  <div class="col-md-2 offset-md-10">
                     <Buttoncomponent/>
                  </div>
                </div>
                </div>
              </div>
              <br/>
              <Card className="card">
                <Form role="form" id="register-form" onSubmit={edituser}>
                <div className="row">
                  
                    <div className="col-md-6">
                       <FormGroup>
                       <Label>Name</Label>
                        <InputGroup className="input-group-alternative mb-3">
                          
                          <Input
                            defaultValue={inputFields.name}
                            placeholder="Name"
                            type="text"
                            name="name"
                            onChange={inputhandler}
                            required
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-6 ">
                       <FormGroup>
                       <Label>Email</Label>
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              defaultValue={inputFields.email}
                              placeholder="Email"
                              type="text"
                              name="email"
                              onChange={inputhandler}
                              required
                            />
                          </InputGroup>
                        </FormGroup>
                    </div>
                   
                    <div className="col-md-2">
                        <Label>&nbsp;</Label>
                         <Button className="col-12 " color="primary" type="submit" disabled={disablestate}>
                         {disablestate ? 'Sending...' : 'Update user'} 
                         </Button>
                    </div>
                </div>  
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3"></InputGroup>
                </FormGroup>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Useraddedit;
