import { useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledTooltip,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import swal from "sweetalert";
import { ToastContainer, toast } from 'react-toastify';
import { AgGridReact, AllCommunityModules, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { userService } from "../../../services/authentication";
import { usermanageService } from "../../../services/usermanageService";

import "../../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { forEachChild } from "typescript";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Buttoncomponent from 'components/Buttoncomponent.js';

const Phaselist = (props) => {
  const history = useHistory();
  const [userdata, setUserdata] = useState([]);
  const [phases, setPhases] = useState([]);
  const [inputFields, setInputfields] = useState({
    phase_name: "",
    desc: "",
  });
  //  const [loaduseredit,setcompo] = useState(false);

  useEffect(() => {
    // getuser_list();
    get_phases();
    return () => {
      setPhases([]);
      setUserdata([]);
    };
  }, []);

  function getuser_list() {
    usermanageService.getusers().then((response) => {
      setUserdata(response.data);
    });
  }

  const handleclick = (e, value) => {
    // setcompo(true);
    history.push({
      pathname: "editphase/"+e.id,
    });
  };

  const columns = [
    {
      name: "Sr No",
      selector: (row) => row.id,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Phase description",
      selector: (row) => row.desc,
    },
    {
      name: "Action",
      selector: (row) => row.id,
      cell: (response) => (
        <button onClick={handleclick.bind(this, response)} class="btn btn-success"><i class="fa fa-edit"></i></button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const customStyles = {
    rows: {
        style: {
             minHeight: '72px', // override the row height
        },
    },
    headCells: {
        style: {
            background:'#ebebeb',
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
  };
  const styles = {
    'color':'#4a4444',
  };
  function inputhandler(e) {
    // e.presist();
    setInputfields({ ...inputFields, [e.target.name]: e.target.value });
  }

  function get_phases(){
    usermanageService.getphasesfulllist().then((response) => {
      if(response.status == 200){
          setPhases(response.data.phases);     
       }
    }); 
  }

  function createphase(e) {
    e.preventDefault();
    
    usermanageService.createphase(inputFields).then((response) => {
       if(response.status == 200){
            toast.success(response.data.message);
            document.getElementById("phase-form").reset();
       }else{
            toast.error(response.data.message); 
       }        
    });
  }

  const data = phases;
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
              <div class="row">
                <div class="col-md-6"><h2>Phases</h2></div>
                <div class="col-md-6">
                <div class="row">
                  <div class="col-md-2 offset-md-10">
                     
                  </div>
                </div>
                </div>
              </div>
              <br/>
          </div>
          <div className="col-md-4 col-4">
            <Form role="form" id="phase-form" onSubmit={createphase}>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <Input
                    placeholder="Phase Name"
                    type="text"
                    name="phase_name"
                    style={styles}
                    onChange={inputhandler}
                    required
                  />
                </InputGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <Input
                    placeholder="Phase Description"
                    type="text"
                    name="desc"
                    style={styles}
                    onChange={inputhandler}
                    required
                  />
                </InputGroup>
                <Button className="col-12 " color="primary" type="submit">
                  Create phase
                </Button>
              </FormGroup>
            </Form>
          </div>
          <div className="col-md-8 col-8">
            <DataTable 
                columns={columns} 
                data={data} 
                customStyles={customStyles} 
             />
          </div>
       </div>
      </Container>
    </>
  );
};

export default Phaselist;
