import { useState,useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect, useHistory,useParams } from "react-router-dom";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container
  } from "reactstrap";
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import {AgGridReact,AllCommunityModules,AgGridColumn} from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import {userService} from '../../../services/authentication';
import {usermanageService} from '../../../services/usermanageService';

import '../../../assets/css/custom.css';
import Header from "components/Headers/Header.js";
import { forEachChild } from "typescript";
import { connect } from "react-redux";
import DataTable from 'react-data-table-component';
import Buttoncomponent from 'components/Buttoncomponent.js';


 const Editphase = (props) => {
 const history = useHistory();
 const [userdata, setUserdata] = useState([]);
 const [phases,setphases] = useState([]);
 const [inputFields, setInputfields] = useState({
    phase_name:"",
    desc:"",
  });
  const params = useParams();
//  const [loaduseredit,setcompo] = useState(false);

  useEffect(()=>{
    // getuser_list();
    get_phases();
  },[]);

  function inputhandler(e){
    setInputfields({...inputFields,[e.target.name]:e.target.value});
  } 

  function get_phases(){
    usermanageService.getsinglephase(params.phase_id).then((response) => {
        // console.log(response);
        setInputfields({
            phase_name : response.data.data[0].name,
            desc:response.data.data[0].desc
        });

    }); 
  }

  function editphase__(e){
      e.preventDefault();
      inputFields.phase_id = params.phase_id;
      usermanageService.editphase(inputFields).then((response)=>{
          if(response.status == 200){
             toast.success(response.data.message);
             history.goBack();
          }else{
             toast.success(response.data.message);
             document.getElementById("phase-form").reset();   
          } 
          console.log(response);   
      });  
  } 
 
 
  return (
    <>
      <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
        <div className="col-12">
          <div class="row">
            <div class="col-md-6"><h2>Edit Phase</h2></div>
            <div class="col-md-6">
            <div class="row">
              <div class="col-md-2 offset-md-10">
                  <Buttoncomponent/>
              </div>
            </div>
            </div>
          </div>
          <br/>
        </div>
        <br/>
        <div className="col-12">
           <div className="row">
            <div className="col-xl-12">

                  <Form role="form" id="phase-form" onSubmit={editphase__}>
                    <FormGroup>
                      <div className="row">
                      <div className="col-4">
                          <InputGroup className="input-group-alternative mb-3">
                               <Input 
                                defaultValue={inputFields.phase_name}
                                placeholder="Phase Name" 
                                type="text" 
                                name="phase_name" 
                                onChange={inputhandler}
                                required
                                style={{fontWeight:"600"}}
                                />
                           </InputGroup>
                      </div>
                      <div className="col-4">
                          <InputGroup className="input-group-alternative mb-3">
                               <Input 
                                defaultValue={inputFields.desc}
                                placeholder="Phase description" 
                                type="text" 
                                name="desc" 
                                onChange={inputhandler}
                                required
                                style={{fontWeight:"600"}}
                                />
                           </InputGroup>   
                      </div>
                      <div className="col-4">
                         <Button color="primary" type="submit">
                           Update Phase
                        </Button> 
                      </div>
                      </div>
                     </FormGroup>
                   </Form>
            
            </div>
           </div>
        </div>
      </Container>
    </>
  );
};

export default Editphase;
