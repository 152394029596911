import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import {userService} from '../../services/authentication';
import 'assets/css/themecustom-style.css';
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Form,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };

  const showHideSidebar = () => {
    setSidebarOpen(!sidebarOpen)
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  const [isOpen, setIsOpen] = useState(false);
  
    const toggle = () => {
      setIsOpen(!isOpen);
    };
    const [submenuOpen, setSubmenuOpen] = useState({});
    const [activeMenu, setActiveMenu] = useState(null);
    // Function to toggle the submenu open/close state
    const toggleSubmenu = (key,e) => {
      e.preventDefault();
      setActiveMenu(activeMenu == key ? null : key);
    
      setSubmenuOpen(prevState => ({
        ...prevState,
        [key]: !prevState[key]
      }));
    };
    const togglemenu = (key,e) => {
      
      setActiveMenu(activeMenu === key ? null : key);
     
    };
  // creates the links that appear in the left menu / Sidebar
  // const createLinks = (routes) => {
  //   return routes.map((prop, key) => {
  //     if(prop  != ''){
  //     return (
  //       <NavItem key={key}>
  //         <NavLink
  //           to={prop.layout + prop.path}
  //           tag={NavLinkRRD}
  //           onClick={closeCollapse}
  //           activeClassName="active"
  //         >
  //           <i className={prop.icon} />
  //           {prop.name}
  //         </NavLink>
  //       </NavItem>
  //     );
  //     }
  //   });
  // };
  const createLinks = (routes) => {
    // Render the menu items
 return routes.map((prop, key) => {
   const hasSubmenu = prop.submenu && prop.submenu.length > 0;
   const isActive = activeRoute(prop.layout + prop.path);
 
   if (hasSubmenu) {
     return (
      prop !== '' && (
       <NavItem key={key}>
         <NavLink
           to={prop.layout + prop.path}
           tag={NavLinkRRD}
           //activeClassName="active"
           className={submenuOpen[key] ? "active" : ""}
           onClick={(e) => toggleSubmenu(key,e)}
         >
           <i className={prop.icon} />
           {prop.name}
           <span className="ml-1">
                         <i className="fas fa-angle-down" /> 
                       </span>
         </NavLink>
         {/* Render submenu items if submenu is open */}
         {submenuOpen[key] &&  (
           <ul>
             {prop.submenu.map((item, index) => (
              item.name ? 
               <li key={index}>
                 <NavLink
                   to={item.layout + item.path}
                   tag={NavLinkRRD}
                
                   activeClassName={activeRoute(item.layout + item.path) ? "activesubmenu" : ""}
                 >
                   {item.name}
                 </NavLink>
               </li> : ''
             ))}
           </ul>
         )}
       </NavItem>
      )
     );
   } else {
 
     return (
      prop !== '' && (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            className={isActive && (activeMenu === key) ? "active" : ""}
            onClick={(e) => togglemenu(key, e)}
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      )
     );
   }
 });
};

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  function logout(){
    userService.logout().then((response)=>{
      if(response.status == 200 && response.data.success == true){
         window.location.href = '/';
      }
   }); 
  }

  return (
    <>
    {(sidebarOpen == false)?
 <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
     
    >
      <Container fluid>
<button class="navbar-toggler" onClick={showHideSidebar} style={{display:"block"}} type="button"><span class="navbar-toggler-icon"></span></button>
</Container>
</Navbar>
:
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        <button class="navbar-toggler" onClick={showHideSidebar} style={{display:"block"}} type="button"><span class="navbar-toggler-icon"></span></button>

        <h1>BK Workflow</h1>
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0 sider-logo" {...navbarBrandProps}>
           
          </NavbarBrand>
        ) : null}
        {/* User */}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-single-02" />
                <span>My profile</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href="#pablo" onClick={(e) => logout()}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  <h4>WorkFlow</h4>
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          
          <Form className="mt-4 mb-3 d-md-none">
            <InputGroup className="input-group-rounded input-group-merge">
            <Input placeholder="Search company..." id="companydatas" className="search-box" type="text"  onChange={props.searchcompany}/>
            </InputGroup>
          </Form>
          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}
          <li className="nav-item">
          <a className="nav-link" href="#" onClick={(e) => logout()}>
          <i className="fas fa-sign-out-alt text-th"></i>Signout</a></li>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
    } </>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
// export default Sidebar;
