import { useState, useEffect,useRef } from "react";
import { useHistory } from "react-router-dom";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Input
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../../assets/css/custom.css";
import Header from "../../../components/Headers/Header.js";
import 'assets/css/themecustom-style.css';
import '@flowjs/flow.js';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import { usermanageService } from "../../../services/usermanageService";


const KROTypeRating = (props) => {
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [data, setdata] = useState([]);
  const [filterFields,setfilterFields] = useState([]);
  const[active_role,setActiverole]=useState(2);


  const handlePageClick = (e, index) => {
    e.preventDefault();
    get_list(index);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_list(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_list(next);
  };

  const get_list = async (pageNumber = 1,e,filterFields,filtertype='',type='',active_role=2) => {
    usermanageService.KROTypeRatingList(pageNumber,type,active_role).then((response) => { 
      
      setdata(response.data.data);
      setActiverole(response.data.active_role)
        
    });
  };


  useEffect(() => {
    get_list();
    getkrotypes();
    getroles();
  }, []);
  const [krotypes, setKroTypes] = useState([]);
function getkrotypes(){
  usermanageService.getkrotypes().then((response)=>{
    setKroTypes(response.data.data);
  });
}
const [roles, setRoles] = useState([]);
function getroles(){
  usermanageService.getrolesforKROTypeRating().then((response)=>{
    setRoles(response.data.roles);
  });
}
  const editclick = (id,index) => {
    history.push("/admin/edit-kro-typerating/"+id);
    
  }
  const handleclick = (id) => {
    usermanageService.deleteKroTypeRating(id).then((response) => {
        if (response.status == 200) {
            toast.success(response.data.message);
            location.reload();
           
          } else {
            for (const field in response.data) {
            toast.error(response.data[field][0]);
            }
          }
    });
  }
//   function addtyperating(){
//     history.push('admin/addtyperating')
//   }
  const addtyperating = () =>{
    history.push("/admin/addtyperating");
  }

  const user_info = JSON.parse(localStorage.getItem("user-info"));
   function filterClickHandler(e) {
    settype(e.target.value)
    get_list(1,e,'','',e.target.value,active_role)
  }
  
  const ra_arr = {
    '1': 'Needs Action',
    '2': 'Below Expectation',
    '3': 'Meet Expectation',
    '4': 'Exceed Expectation',
    '5': 'Exceptional'
};

  const editField = (value, field, id,index,type) => {
    usermanageService.editTypeRatingRow(value, field, id).then((response) => {
      let tempData = { ...data };
      let tempElement = { ...tempData[type][index] };
      
      console.log(tempElement)
      tempElement.editing = false;
     
      if (field === 'rating') {
          tempElement[field] = value; 
          tempElement['rating_name'] = ra_arr[value]; 
      } else {
          tempElement[field] = value; 
      }
  

      tempData[type][index] = tempElement;
      setdata(tempData);
      if(response.status == 200){
        toast.success(response.data.message);
      }else{
          toast.error(response.data.message);
      }

     
    })
  }
  const handleeditRow = (index, category) => {
   
    if (user_info.role_id === 14) {
       
        const tempData = { ...data };

       
        tempData[category] = tempData[category].map((item, idx) =>
            idx === index ? { ...item, editing: true } : item
        );
       console.log(tempData)
        // Update state with the modified data
        setdata(tempData);
    }
};

  const [type,settype] = useState('');
  const cleartype = ()=>{
    settype('');
  }
  const copytyperating = (role_id,id)=>{
    history.push("/admin/addtyperating/"+role_id+"/"+id);

  }
  return (
    <>
    <Header />
    <Container className="mt--7" fluid>
    <Row>
    {user_info.role_id == 14 ? 
          <div className="col-3">
             <Button className="col-6 " color="primary" type="button" onClick={(e)=>{addtyperating('')}}>
                Add
             </Button>
          </div>:null}
        
          
          </Row>
          <Row className="mt-4">
            {roles.map((role)=>(
             
          <button type="button" className=" btn btn-secondary text-center" style={role.value == active_role ?
          { backgroundColor:'#5d6cae',color:'#fff'} : null} onClick={(e)=>{get_list(1,1,'','','',role.value);cleartype();}}>
            {role.label}

          </button>
              )

            )}
       
        
        </Row>
          <Row className="mt-1">
          <div className="col" style={{ padding: "0%" }}>
            <Card className="shadow">
              {/* <CardHeader className="border-0">
                <Row className="align-items-center row">
                <div className="col-xl-12 col-md-2 col-xs-12 d-flex">
                  <h3 className="mb-0" style={{ fontSize: "20px",width:"200px" }}>
                    KRO Type Rating
                  </h3>
                  <select className="form-control" style={{ width:"200px" }}  name="type_id" value={type}   onChange={(e) => filterClickHandler(e)}>
                            <option value="">Select Type</option>
                                    {krotypes.map(type =>
                                      <option key={type.value} value={type.value}>{type.label}</option>
                                    )}
                        </select>
                  </div>
                
                </Row><br></br>
              </CardHeader> */}
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {data && data !== null ? (
                Object.keys(data).map((type, index) => {
                  let typearr = type.split("@@");
                  let typename = typearr[0];
                  let type_id = typearr[1];

                  return (
                    <React.Fragment key={index}>
                      <Table className="align-items-center table-flush col-xl-5" style={{ marginRight: "20px", marginBottom: '5px' }}>
                        <thead style={{ border: "1px solid #000" }} className="thead-light">
                          <tr>
                          {user_info.role_id === 14 ?
                            <th colSpan={2} style={{ border: "1px solid #000", textAlign: "right" }}>
                              <Button color="primary" type="button" onClick={(e) => copytyperating(active_role, type_id)}>
                                <i className="fa fa-copy"></i>
                              </Button>
                            </th>:null}
                          </tr>
                        </thead>
                        <thead style={{ border: "1px solid #000" }} className="thead-light">
                          <tr>
                            <th colSpan={2} style={{ border: "1px solid #000", textAlign: "center" }}>{typename}</th>
                          </tr>
                        </thead>
                        <thead style={{ border: "1px solid #000" }} className="thead-light">
                          <tr>
                            <th style={{ border: "1px solid #000" }}>Target</th>
                            <th style={{ border: "1px solid #000" }}>Rating</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data[type].map((item, i) => (
                            <tr key={item.id}>
                              <td style={{ border: "1px solid #000" }} className="whitespace" onClick={() => handleeditRow(i, type)}>
                                {item.editing && user_info.role_id === 14 ? (
                                  <Input
                                    type="textarea"
                                    name="criteria"
                                    defaultValue={item.criteria}
                                    onBlur={(e) => editField(e.target.value, "criteria", item.id, i, type)}
                                  />
                                ) : (
                                  <span>{item.criteria}</span>
                                )}
                              </td>
                              <td style={{ border: "1px solid #000" }} className="whitespace" onClick={() => handleeditRow(i, type)}>
                                {item.editing && user_info.role_id === 14 ? (
                                  <select
                                    className="form-control"
                                    onChange={(e) => editField(e.target.value, 'rating', item.id, i, type)}
                                    defaultValue={item.rating}
                                  >
                                    <option value="1">Needs Action</option>
                                    <option value="2">Below Expectation</option>
                                    <option value="3">Meet Expectation</option>
                                    <option value="4">Exceed Expectation</option>
                                    <option value="5">Exceptional</option>
                                  </select>
                                ) : (
                                  <p>{item.rating_name}</p>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </React.Fragment>
                  );
                })
              ) : (
                null
              )}
            </div>

           
            </Card>
          </div>
        </Row>
     </Container>
    </>
  );
};


export default KROTypeRating;
