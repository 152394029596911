import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {apiUrl} from '../constant';
import {authtoken} from 'global.js';
import {authHeader} from '../helpers/auth-header';
const axios = require("axios");
export const manageService = {
      Addcomp,
      Getcomp,
      Getcompandapp,
      Getcompapplication,
      Editcomp,
      get_app_notification,
      get_allcompanies,
      get_clientusers,
      get_allcompanies_with_subclient
};

function Addcomp(user_id,company_name,applications){
    // var authtoken = JSON.parse(localStorage.getItem('user')).token;
    var bodyFormData = new FormData();
    bodyFormData.append("user_id", user_id);
    bodyFormData.append("company_name", company_name);
    bodyFormData.append("application", applications);
    
    return axios({
        method: "post",
        url: `${apiUrl}/addcompanyData`,
        data: bodyFormData,
        headers: { "Content-Type": "application/json",Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
           return response;
        })
        .catch(function (error) {
        //   console.log(response);
        });   
}


function Editcomp(user_id,company_id,company_name,applications){
  // var authtoken = JSON.parse(localStorage.getItem('user')).token;
  var bodyFormData = new FormData();
  bodyFormData.append("user_id", user_id);
  bodyFormData.append("company_id", company_id);
  bodyFormData.append("company_name", company_name);
  bodyFormData.append("application", applications);
  
  return axios({
      method: "post",
      url: `${apiUrl}/editcompany`,
      data: bodyFormData,
      headers: { "Content-Type": "application/json",Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
      //   console.log(response);
      });   
}



function Getcomp(_userid){

    return;
    var user_id = _userid;
    return axios({
        method: "get",
        url: `${apiUrl}/getcompanyData?user_id=${user_id}`,
        headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ` },
        })
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          return error;
        });     
}


function Getcompapplication(_companyid){
  // var  authtoken = JSON.parse(localStorage.getItem('user')).token;
  var _companyid = _companyid;
  return axios({
      method: "get",
      url: `${apiUrl}/getcompanyApplication?company_id=${_companyid}`,
      headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });     
}

function Getcompandapp(_companyid){
  // var  authtoken = JSON.parse(localStorage.getItem('user')).token;
  var _companyid = _companyid;
  return axios({
      method: "get",
      url: `${apiUrl}/getcompany?company_id=${_companyid}`,
      headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });     
}

function get_app_notification(){
  // var  authtoken = JSON.parse(localStorage.getItem('user')).token;
  var  user_id = JSON.parse(localStorage.getItem('user-info')).id;
  // var _companyid = _companyid;
  return axios({
      method: "get",
      url: `${apiUrl}/get_app_notification?user_id=${user_id}`,
      headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
      })
      .then(function (response) {
        localStorage.setItem("task_count", JSON.stringify(response.data.data));
        return response;
      })
      .catch(function (error) {
        return error;
      });      
}

function get_allcompanies(){
  var  user_id = JSON.parse(localStorage.getItem('user-info')).id;
  return axios({
  method: "get",
  url: `${apiUrl}/companies/get/all`,
  headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
    console.log(response);
    // localStorage.setItem("task_count", JSON.stringify(response.data.data));
    return response;
  })
  .catch(function (error) {
    return error;
  }); 
}


function get_allcompanies_with_subclient(){
  var  user_id = JSON.parse(localStorage.getItem('user-info')).id;
  return axios({
  method: "get",
  url: `${apiUrl}/getuniquecompanieswithsubclient`,
  headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
    console.log(response);
    // localStorage.setItem("task_count", JSON.stringify(response.data.data));
    return response;
  })
  .catch(function (error) {
    return error;
  }); 
}


function get_clientusers(){
  return axios({
    method: "get",
    url: `${apiUrl}/getclientuserlist`,
    headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      console.log(response);
      // localStorage.setItem("task_count", JSON.stringify(response.data.data));
      return response;
    })
    .catch(function (error) {
      return error;
    }); 
}