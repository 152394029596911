import { Button, Container, Row, Col } from "reactstrap";
import React, { useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import {apiUrl} from '../../constant';
import {userService} from '../../services/authentication';
// import './credentialstyle.css';
const axios = require("axios");

const UserHeader = () => {
  const history = useHistory();
  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-info opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="12" md="12">
              {/* <h1 className="display-2 text-white">Hello {user_data.data.name}</h1> */}
              {/* <p className="text-white mt-0 mb-5">
                This is your profile page. You can see the progress you've made
                with your work and manage your projects or assigned tasks
              </p> */}
              {/* <Button
                color="info"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                Edit profile
              </Button> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserHeader;
