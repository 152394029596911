import { useState,useEffect } from "react";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container,
    UncontrolledAlert,
    Alert
  } from "reactstrap";
import Select from 'react-select';
import { useParams } from "react-router-dom";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import {rolemanageService} from '../../../services/rolemanageService';
import '../../../assets/css/custom.css';
import Header from "components/Headers/Header.js";
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import Buttoncomponent from 'components/Buttoncomponent.js';

const Roleaddedit = (props) => {

const params = useParams();
const permissionData = []
const [ permissions, setPermissions ] = useState(permissionData);

const initialFormState = { id: null, name: '', permission: [] }
const [inputFields, setInputfields] = useState(initialFormState);
const [error, setError] = useState(null)
  const [successMsg, setsuccessMsg] = useState(null)
  const [show, setShow] = useState(false);
  const errorDiv = error 
        ? <UncontrolledAlert className="alert-danger" fade={false}>
        {error}
        </UncontrolledAlert> 
        : '';
  const successDiv = successMsg 
        ? <UncontrolledAlert className="alert-success" fade={false}>
        {successMsg}
        </UncontrolledAlert> 
        : '';
  const fetchData = () => {
    rolemanageService.fetchPermissions().then((response)=>{
      if(response.status === 200){
        setPermissions(response.data.data)
       
      }
    });
  }
  

  useEffect(()=>{
    if(params.id){
      getrolefunc();
    }
    fetchData();
  },[]);

  function inputhandler(e){
    if(e.target.name=='permission'){   
        if (e.target.checked) {
          setInputfields({
            ...inputFields,
            permission: [...inputFields.permission, parseInt(e.target.value)]
          });
        } else {
          setInputfields({
            ...inputFields,
            permission: inputFields.permission.filter((d) => d !== parseInt(e.target.value))
          });
        
        }
    }else{
      setInputfields({...inputFields,[e.target.name]:e.target.value});
    
    }
    console.log(inputFields)
  } 
  function getrolefunc(){
    rolemanageService.getrole(params.id).then((response) => {
      const datainitialFormState = { id: response.data.role.id, name: response.data.role.name }
      const selectdata = {...datainitialFormState,permission: Array.isArray(response.data.rolePermissions) ? response.data.rolePermissions.map(x => x.value) : []}
      setInputfields(selectdata);
    }); 
  }

  function rolesubmit(e) {
    e.preventDefault();
    if(params.id){
      rolemanageService.roleeditsubmit(params.id,inputFields).then((response)=>{
        getrolefunc();
        if(response.status == 200){
           setsuccessMsg(response.data.message);
           setShow(true)
           setError(null);
        }else{
         setError('Enter All required field');
         setsuccessMsg(null);
        }
     });
    }else{
      rolemanageService.roleaddsubmit(inputFields).then((response)=>{
        if(response.status == 200){
           setsuccessMsg(response.data.message);
           setError(null);
           setInputfields(initialFormState);
        }else{
         setError('Enter All required field');
         setsuccessMsg(null);
        }
     });
    }
   
  }
  

  return (
    <>
      <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
        <div className="row">
            <div className="col-xl-12">
              <div className="col-md-12 col-12">
                <div class="row">
                  <div class="col-md-6"><h2>Update Role</h2></div>
                  <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-2 offset-md-10">
                       <Buttoncomponent/>
                    </div>
                  </div>
                  </div>
                </div>
                <br/>
              {errorDiv}
  
              {show && <p onClick={() => setShow(false)}>{successDiv}</p>}
                  <Form role="form" method="POST" id="register-form" onSubmit={rolesubmit}>
                        <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                            <Input 
                            placeholder="Role Name" 
                            type="text" 
                            name="name"
                            value={inputFields.name}
                            onChange={inputhandler}
                            required
                            />
                        </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <div className="row">
                        {permissions &&
                                permissions.length &&
                                permissions != null ? (
                                  permissions.map((permission) =>
                                  <>
                                  <div  className="col-md-2">
                                  <input
                                  type="checkbox"
                                  name="permission"
                                  value={permission.value}
                                  checked={inputFields.permission.includes(permission.value)}
                                  onChange={(e) => inputhandler(e)}
                                /> {permission.label} <br></br></div>
                                </>
                                  )
                                ) : (
                                  <option>No data Found</option>
                                )}</div>
                        {/* <Select
                            className="input-group-alternative mb-3"
                            placeholder="Select Option"
                            name="permission"
                            allowSelectAll={true}
                            value={permissions.filter(obj => inputFields.permission.includes(obj.value))} // set selected values
                            options={permissions} // set list of the data
                            onChange={inputhandler} // assign onChange function
                            isMulti
                            isClearable
                          />
                          {inputFields.permission && <div style={{ marginTop: 20, lineHeight: '25px' }}>
                                <div><b>Selected Value: </b> {JSON.stringify(inputFields.permission, null, 2)}</div>
                              </div>} */}
                         
                        </FormGroup>
                        <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                           
                        </InputGroup>
                        </FormGroup>
                        <div className="text-center">
                        <button type="submit" className="btn btn-success mt-4" onClick={rolesubmit}>Update</button>
                    </div>
                   </Form>  
             </div>
            </div>
        </div>
      </Container>
    </>
  );
};

export default Roleaddedit;
