import { useState,useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect, useHistory } from "react-router-dom";
import {
  Button,
  Container,
  UncontrolledAlert,
  Card
} from "reactstrap";
import swal from 'sweetalert';
import {AgGridReact,AllCommunityModules,AgGridColumn} from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import {rolemanageService} from '../../../services/rolemanageService';

import '../../../assets/css/custom.css';
import Header from "components/Headers/Header.js";
import Roleaddedit from "./Roleaddedit.js";
import { forEachChild } from "typescript";
import { connect } from "react-redux";
import DataTable from 'react-data-table-component';
import Buttoncomponent from 'components/Buttoncomponent.js';


 const Role = (props) => {
 const history = useHistory();
 const [roledata, setRoledata] = useState([]);

 const [error, setError] = useState(null)
  const [successMsg, setsuccessMsg] = useState(null)
  const errorDiv = error 
        ? <UncontrolledAlert className="alert-danger" fade={false}>
        {error}
        </UncontrolledAlert> 
        : '';
  const successDiv = successMsg 
        ? <UncontrolledAlert className="alert-success" fade={false}>
        {successMsg}
        </UncontrolledAlert> 
        : '';
  useEffect(()=>{
    getrole_list();
  },[]);

  function getrole_list(){
    rolemanageService.getroles().then((response) => {
        // console.log(response.data); 
        setRoledata(response.data.roles); 
    }); 
  }

  const handleclick = (e,value) => {
    // setcompo(true);
     history.push({
      pathname: 'editrole/'+e.id,
      id : e.id
    });
  }

  const handleaddclick = () => {
    history.push({
      pathname: 'addrole'
    });
  }

  const handledelete = (e,value) => {
    console.log(e.id)
    rolemanageService.deleteRole(e.id).then((response) => {
      // console.log(response.data); 
      const newList = roledata.filter((item) => item.id !== e.id);
      setRoledata(newList);
      setsuccessMsg(response.data.message);
      setError(null);
  }); 
  }

  const [count, setCount] = useState(0);

  const columns = [
    {
      name: 'Sr No',
      selector: row => row.id,
     
    },
    {
        name: 'Name',
        selector: row => row.name,
       
    },
    // {
    //     name: 'Permissions',
    //     selector: row => row.permission.toString(),
      
    // },
    {
      name: 'Action',
      selector: row => row.id,
			cell: (response) => <button onClick={handleclick.bind(this, response)} class="btn btn-success"><i class="fa fa-edit"></i></button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      selector: row => row.id,
			cell: (response) => <button onClick={handledelete.bind(this,response)} class="btn btn-danger"><i class="fa fa-trash"></i></button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
];

const customStyles = {
  rows: {
      style: {
           minHeight: '72px', // override the row height
      },
  },
  headCells: {
      style: {
          background:'#ebebeb',
          paddingLeft: '8px', // override the cell padding for head cells
          paddingRight: '8px',
      },
  },
  cells: {
      style: {
          paddingLeft: '8px', // override the cell padding for data cells
          paddingRight: '8px',
      },
  },
};

  const data = roledata;
  
  return (
    <>
      <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
        <div className="col-12">
             <div class="row">
                <div class="col-md-6"><h2>Roles</h2></div>
                <div class="col-md-6">
                <div class="row">
                  <div class="col-md-2 offset-md-10">
                    
                  </div>
                </div>
                </div>
              </div>
              <br/>
           {/* <button class="col-2 mt-2 btn btn-primary" onClick={handleaddclick}>Add Role</button> */}
        </div>
        <br></br>  
        <div className="row">
            <div className="col-xl-12">
              <div className="col-md-12 col-12">
              {errorDiv}
              {successDiv}
                  <DataTable
                     columns={columns}
                     data={data}
                     customStyles={customStyles} 
                  />
               </div>
            </div>
        </div>
      </Container>
    </>
  );
};

export default Role;
