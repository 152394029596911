import { useState, useEffect } from "react";
import {  useHistory } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService";
import 'assets/css/themecustom-style.css';
import { clientmanageService } from "../../services/clientmanageService";

const ClientFrequency = (props) => {

 const history = useHistory();
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [frequencydata, setfrequencydata] = useState([]);

  const handlePageClick = (e, index) => {
    e.preventDefault();
    get_client_frequency(index);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_client_frequency(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_client_frequency(next);
  };

  const get_client_frequency = async (pageNumber = 1) => { 
   
    clientmanageService.getcllientfrequency(pageNumber).then((response) => {
      setfrequencydata(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setLastPageData(response.data.data.last_page);
    });
  };

  const restoreTask = (id,index) => {
    taskmanageService.restoreTask(id).then((response) => {
      if(response.status == 200){
        let state = [...frequencydata];
        delete state[index];
        setfrequencydata(state);
       toast.success(response.data.message);
      }else{
        toast.error(response.data.message);
      }
    });
  }

  useEffect(() => {
    get_client_frequency();
}, []);

const add_master = () => {
  history.push("/admin/addfreqmaster");
}
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        
      <Row className="align-items-center row">
            <Col xl="10">
           
              
            <Button
                className="dash-button "
                color="primary"
                type="button"
                onClick={() => add_master()}
              >
                {" "}
              + Master Data{" "}
              </Button>{" "}
            </Col>
            </Row>
        <Row className="mt-5">
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="filter" style={{padding:'0 3%'}}>
                    <h3 className="mb-0" style={{fontSize:'20px'}}>Master data list</h3><br></br>
                    <br></br>
                </Row>
               
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    <th scope="col">Company Name</th>
                    <th scope="col">Frequency</th>
                </thead>
                <tbody>
                  {frequencydata &&
                    frequencydata.length &&
                    frequencydata != null ? (
                      frequencydata.map((item, i) => (
                      <>
                     <tr className={((item.governance_freq == null || item.governance_freq == '') ? 'red-row' : 'blue-row') }>
                        <td>{item.name}</td>
                        <td>{item.governance_freq}</td>
                     </tr>
                      
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      
      </Container>
    </>
  );
};


export default ClientFrequency;
