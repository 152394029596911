import React, { useState } from 'react';
import axios from 'axios';
import { Button, FormGroup, Input, Form, UncontrolledAlert } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { userService } from '../../services/authentication';

const VerifyTwoFactor = ({ userId, onVerificationSuccess }) => {
  const [code, setCode] = useState('');
  const [error, setError] = useState(null);

  const handleVerify = (e) => {
    e.preventDefault();
    userService.verifyTwoFactor(userId,code).then((response)=>{
      if (response.status === 200) {
        toast.success('2FA verified successfully');
        onVerificationSuccess();
      }else{
        toast.error(response.data.error);
      }
   });
    
  };

  return (
    <div>
      <ToastContainer />
      <form onSubmit={handleVerify}>
        <FormGroup>
          <label htmlFor="code">Enter the code from your email:</label>
          <Input
            type="text"
            id="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
        </FormGroup>
        {error && <UncontrolledAlert className="alert-danger" fade={false}>{error}</UncontrolledAlert>}
        <Button type="submit">Verify</Button>
      </form>
    </div>
  );
};

export default VerifyTwoFactor;
