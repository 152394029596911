import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    
  } from "reactstrap";
  import React, { useState } from "react";
  import { useHistory } from "react-router-dom";
  import {apiUrl} from '../../constant';
  import {userService} from '../../services/authentication';
  import { ToastContainer, toast } from 'react-toastify';
  // import './credentialstyle.css';
  const axios = require("axios");
  
  const Onetimeresetpass = () => {
    const history = useHistory();
    // const history = useHistory();
  
    const [inputFieldEmail, setInputemail] = useState({
      email: "",
    });
  
    const [inputFieldpass, setInputpassword] = useState({
      password: "",
    });
  
    const [error,setError]=useState(null);
    const errorDiv = error ? (
      <UncontrolledAlert className="alert-danger" fade={false}>
        {error}
      </UncontrolledAlert>
    ) : (
      ""
    );
  
    const authenticate = (e) => {
      e.preventDefault();
      var oldpass = inputFieldEmail.oldpassword;
      var newpass    = inputFieldpass.newpassword;
      var repeatpass = inputFieldpass.repeatpassword;
      if(oldpass == '' || newpass == '' || repeatpass == ''){
        toast.error('All fields are Required !');
        return false;
      }
      var bodyFormData = new FormData();
      userService.login(inputFieldEmail.email,inputFieldpass.password).then((response)=>{
         if(response.status == 200){
          toast.success(response.data.message);
          // history.push('/admin');
         }else{
          toast.error("Invalid email or password!!!");
         }
      });
      return;
    }
  
    const inputsHandleremail = (e) => {
      setInputemail({ [e.target.name]: e.target.value });
    };
  
    const inputsHandlerpass = (e) => {
      setInputpassword({ [e.target.name]: e.target.value });
    };
  
  
    function redirect(path){
      //  path.preventDefault();
       history.push(path);    
    }
  
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <ToastContainer />
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <h1>Reset in</h1>
              </div>
              {errorDiv}
              <Form role="form" onSubmit={authenticate}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Old password"
                      type="password"
                      name="oldpassword"
                      autoComplete="new-email"
                      onChange={inputsHandleremail}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="New Password"
                      type="password"
                      name="newpassword"
                      autoComplete="new-password"
                      onChange={inputsHandlerpass}
                      required
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Repeat Password"
                      type="password"
                      name="repeatpassword"
                      autoComplete="new-password"
                      onChange={inputsHandlerpass}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    <span>Sign in</span>
                  </Button>       
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <p className="text-light" onClick={(e) => history.push('forgotpassword')}>
                <a
                  className="text-light"
                  href="/auth/register"
                  onClick={(e) => e.preventDefault()}
                >
                  <small>Forgot password?</small>
                </a>
              </p>
            </Col>
            <Col className="text-right" xs="6">
              <p className="text-light" onClick={(e) => history.push('register')}>
                <a
                  className="text-light"
                  href="/auth/register"
                  onClick={(e) => e.preventDefault()}
                >
                  <small>Create new account</small>
                </a>
              </p>
            </Col>
          </Row>
        </Col>
      </>
    );
  };
  
  export default Onetimeresetpass;
  