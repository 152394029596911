import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { ToastContainer, toast } from 'react-toastify';
import { domainUrl} from '../../constant';
import '@flowjs/flow.js';
import Flow from "@flowjs/flow.js";
import { async } from "@firebase/util";
import { useHistory,useParams } from "react-router-dom";
import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
import { manageService } from "../../services/manageaccountservices";
import Select from 'react-select';
const axios = require("axios");

const AddClientTransitionSme = (props) => {
  const params  = useParams();
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [clientdata, setClientdata] = useState([]);
  const [clientsearch, setclientsearch] = useState([{'label':'Select Client',value:'al_key_1'}]);
  const [trans_data,settransData] = useState([]);
  const [formdetails,setformdetails] = useState({});
  const [taskdetailPopup,settaskdetailPopup] = useState(false);
  const [allclient, setAllclient] = useState(true);
  const regionOption = useRef(null);
  const [changeclient,setchangeclient] = useState(false);
  const [status,setstatus] = useState([]);
  const [state, setState] = useState({});
  const [ctrans, setCurrentTrans] = useState('');
  const [disablestate, setdisablestate] = useState(false);
  


  const handlePageClick = (e, index) => {
    e.preventDefault();
    get_list(index);
  };

  const formatDate = (dateStr) => {
    if(dateStr!=null && dateStr!=''){

      const [year, month, day] = dateStr.split('-');
      let newDate = `${day}-${month}-${year}`;
      return newDate;
    }
  };
  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_list(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_list(next);
  };

  const get_list = async (pageNumber = 1,e) => { 
   
    let  textbox = '';
    if(e != null){
       textbox = e.label;
    }
    taskmanageService.getsubclientTasks(pageNumber,clientsearch,textbox).then((response) => {console.log(response.data.data.data)
      setClientdata(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setLastPageData(response.data.data.last_page);
    });
  };


useEffect(() => {
  get_list();
  get_unique_companieslist();
  if(params.client_id!='undefined' && params.client_id!=undefined){
      getTransitionData(params.client_id)
      getclientstatus();
  }
}, []);
console.log(trans_data)

// const generateForm = (id,client_id,trans_id,index) => {
//   setCurrentTrans(trans_id)
//   clientmanageService.getclienttransitionsmeData(id,client_id,trans_id).then((response) => {
//     console.log(response.data)
//     setformdetails(response.data);
//     setState(response.data);
//     console.log("f")
//     console.log(formdetails)
//     settaskdetailPopup(!taskdetailPopup);
//   })
// }
const [clientlist, setclientlist] = useState([]);
const [userlist, setuserlist] = useState([]);
function get_unique_companieslist(){
  clientmanageService.getuniqueclientforsme().then((response)=>{
    setclientlist(response.data.client);
    setuserlist(response.data.user);
  });
}

  function getTransitionData(clientname=''){
    //setsubclientlist([]);
    
    
    clientmanageService.getclienttransitionsmeData(clientname).then((response) => {
      
        if(response.status === 200){
          setchangeclient(true);
         
          settransData(response.data.list)
          
          setState({ ...state,list: response.data.list,client_id: response.data.client_id});
 
          
        }else {
          toast.error('Something went wrong!');
        }
    });
  }
  function getclientstatus(){
    taskmanageService.getclienttransitionstatus().then((res) => {
          
      if(res.status === 200){
        setstatus(res.data.status);
        console.log(status)
      }
  });
  }
  
  function setclient(e){ 
    
    getTransitionData(e.value);

  }


function inputhandler(e,index,key) {
   
  let temp_st = {...state.list};

  temp_st[key]['value'] = e.target.value;
  
  setState({ ...state, list: temp_st });
console.log(state)
}

function add_phase_selection(e) {
  setdisablestate(true)
  e.preventDefault();
  clientmanageService.updateTransitionForm(state).then((response) => {
    console.log(response);

    if (response.status == 200) {
      toast.success(response.data.message);
    }
    setdisablestate(false)
  });
}
function add_sme_data(e) {

    e.preventDefault();
    taskmanageService.addSmelistData(state).then((response) => {
      console.log(response);
  
      if (response.status == 200) {
        toast.success(response.data.message);
        
      }
      setdisablestate(false)
    });
  }
  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
    
        <Row className="mt-5">
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
            {(clientlist.length > 0 && (user_info.role_id != 13) && (params.client_id=='undefined' || params.client_id==undefined))?
                   <>
              <CardHeader className="border-0">
              <Row className="filter" style={{padding:'0 1%'}}>
               <div className="filter" style={{width:'80%'}}>
                   <div className="col-xl-6 col-md-6 col-xs-12">
                    <h3 className="clientformtag"> Client</h3>
                    <Select 
                   
                    className="input-group-alternative mb-3" 
                    name="client_id"
                    options={clientlist} // set list of the data
                    onChange={(e) => { setclient(e)}}
                    defaultValue={clientsearch}
                    searchable
                    isClearable={false}
                  />
                  </div>
                    </div>
                </Row>
              </CardHeader>
              </>
               : null }
              
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                {/* {changeclient == true && trans_data && trans_data.length
                          ?                               
                    trans_data.map((tdata,i) => (
                    <th>{tdata.label}</th>
                    ))
                    :
                     ( <p>No Data Found</p> )} */}
                    
                </thead>
                <tbody>
                  
                {changeclient == true && trans_data && trans_data.length
                          ?                               
                          trans_data.map((tdata,i) => (
                            <tr key={i} className={i % 2 === 0 ? 'purple-row' : 'orange-row'}>
    <td>{tdata.label}</td>
    <td className="whitespace">
      {i === 0 ? (

        <select
                                className="form-control"
                                onChange={(e) =>
                                         inputhandler(
                                         e,
                                         tdata.id,
                                         i
                                       )}
                                value={tdata.value}
                              >
                                <option value="0" key="0" >Select Status</option>
                                {userlist &&
                                userlist.length &&
                                userlist != null ? (
                                    userlist.map((user) =>
                                  
                                      <option key={user.value} value={user.value} >
                                        {user.label}
                                      </option>
                                  )
                                ) : (
                                  '<option value="0" key="0" >Select Status</option>'
                                )}
                              </select>
      ) : i === trans_data.length - 1 ? (
        <Input type="textarea" placeholder="Remarks" name ="remark"   value={tdata.value}
                    onChange={(e) =>
                        inputhandler(
                        e,
                        tdata.id,
                        i
                      )} />
      ) : (
        <select
className="form-control"
name="status"
onChange={(e) =>
    inputhandler(e,tdata.id,i)
}
value={(tdata.value) ? tdata.value : ''}
>
<option value="">Select</option>
<option value="0">Pending</option>
<option value="1">In Process</option>
<option value="2">Done</option>
<option value="3">N/A</option>
</select>
      )}
    </td>
  </tr>
                            ))
                          :
                           ( <p>No Data Found</p> )}
                </tbody>
                <br></br>
                {changeclient == true && trans_data && trans_data.length
                          ?    
                <Button color="primary" type="button" onClick={add_sme_data} disabled={disablestate}>
            {disablestate ? 'Sending...' : 'Save'} 
              </Button>:''}
              </Table>


            </Card>
          </div>
        </Row>
        

      </Container>
    </>
  );
};


export default AddClientTransitionSme;
