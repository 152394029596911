import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { toast } from 'react-toastify';
import { useHistory,useParams } from "react-router-dom";
import Select from 'react-select';
const axios = require("axios");

const AddMasterChecklist = (props) => {
  const params  = useParams();
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const [trans_data,settransData] = useState([]);
  const [formdetails,setformdetails] = useState({});
  const [taskdetailPopup,settaskdetailPopup] = useState(false);
  const [changeclient,setchangeclient] = useState(false);
  const [state, setState] = useState({});
  const [ctrans, setCurrentTrans] = useState('');
  const [cphase, setCurrentPhase] = useState('');
  const [csubcat, setCurrentSubcat] = useState('');
  const [disablestate, setdisablestate] = useState(false);
  const [phaselist, setphaselist] = useState([]);

  const [formData, setFormData] = useState([]);
  const [masterformData, setMasterFormData] = useState([]);
  const [file, setFile] = useState([]);

useEffect(() => {
  getTransitionData()
}, []);

const generateForm = (id,phase_id,index,sub_cat) => {
  setCurrentTrans(id)
  setCurrentPhase(phase_id)
  setCurrentSubcat(sub_cat)
  clientmanageService.generateFormChecklist(id,phase_id,sub_cat).then((response) => {
    console.log(response.data)
    setformdetails(response.data);
    setFormData(response.data)
    setNewBox(response.data.length)
    settaskdetailPopup(!taskdetailPopup)
  })
}
  function getTransitionData(){
  clientmanageService.getChecklistMaster().then((response) => {
      
        if(response.status === 200){
          setchangeclient(true);
          settransData(response.data.data)
          setState({})
          setFormData([])
          setphaselist(response.data.phases)
          
        }else {
          toast.error('Something went wrong!');
        }
    });
  }

const editField = (id, field, value,index) => {
  clientmanageService.updateChecklistMaster(id, field,value).then((response) => {
    let temp_st = [...trans_data];
    let temp_ele = { ...temp_st[index] };
   
    if(response.status == 200){
      getTransitionData();
        toast.success(response.data.message)
    }else{
        toast.error(response.data.error);
    }
    temp_ele.editing = false;
    temp_ele.mainediting = false;
    
  });
};
function inputhandler(e,index) {
  let temp_st = {...masterformData};
  let temp_ele = { ...temp_st[index] };
  temp_ele[e.target.name] = e.target.value;
  temp_st[index] = temp_ele;
  setMasterFormData(temp_st);

}
function inputhandlernew(e,rowIndex) {
  const { name, value } = e.target;
        setFormData(prevFormData => {
            // Create a copy of the previous state
            const newData = [...prevFormData];
            // Update the specific row with the new value
            newData[rowIndex] = {
                ...newData[rowIndex],
                [name]: value
            };
            newData[rowIndex]['master_id'] = ctrans;
            newData[rowIndex]['phase_id'] = cphase;
            newData[rowIndex]['frequency'] = csubcat;
            return newData;
        });
      // setState({ ...state, formData });
  }

  
  function addchecklistform(e) {
    console.log(e);
    setdisablestate(true)
    e.preventDefault();
    clientmanageService.updateChecklistForm(formData).then((response) => {
  
      if (response.status == 200) {
        toast.success(response.data.message);
        //location.reload();
      }
      setdisablestate(false)
    });
  }
  function addChecklistMaster(e) {
    console.log(e);
    setdisablestate(true)
    e.preventDefault();
    clientmanageService.addChecklistMaster(masterformData).then((response) => {
  
      if (response.status == 200) {
        toast.success(response.data.message);
        location.reload();
      }else{
        toast.error('All field is required.');
      }
      setdisablestate(false)
    });
  }
const [newbox, setNewBox] = useState(0);
 
const addNewbox = ()=>{
  const setdata = newbox+1;
  setNewBox(setdata)
 
}

const [newboxmaster, setNewBoxMaster] = useState(0);
 
const addNewboxMaster = ()=>{
  const setdata = newboxmaster+1;
  setNewBoxMaster(setdata)
 
}
const download_sample  = ()=>{
  clientmanageService.download_sample().then((response) => {
  });
}
const handleFileUpload = (event) => {
  const file = event.target.files[0];
  setFile(file)
};
const importfile = ()=>{
  clientmanageService.checklist_import(ctrans, cphase, file,csubcat).then((response) => {
    if (response.status === 200) {
        toast.success(response.data.message);
        settaskdetailPopup(false)
    } else {
        
        if (response.data.file && response.data.file.length > 0) {
            toast.error(response.data.file[0]); // Display the specific error message for 'file' field
        } else {
            toast.error('An error occurred while importing checklist.'); // Fallback error message
        }
    }
}).catch((error) => {
    toast.error('Error importing checklist: ' + error.message);
});

}
const handleclick = (id) => {

  clientmanageService.deleteCommonTableData(id,'checklist_form').then((response) => {
      if (response.status == 200) {
          toast.success(response.data.message);
          generateForm(ctrans.cphase);
          settaskdetailPopup(true)
         
        } else {
          for (const field in response.data) {
          toast.error(response.data);
          }
        }
  });
}
  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
      <h3>Checklist Master module</h3>
              
        <Row className="mt-5">
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
        
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    <th>Teams</th>
                    <th>Frequency</th>
                    <th scope="col">Checklist Form</th>
                    <th scope="col" className="whitespace">Last Updated Date</th>
                    
                </thead>
                <tbody>
           
                { trans_data && trans_data.length
                          ?                               
                          trans_data.map((tdata,i) => (
                              <tr key={i} className={((i % 2 == 0)? 'purple-row': 'orange-row' )}>
                                <td>{tdata.phase_name}</td>
                                <td>
                                <select class="form-control" name="frequency" defaultValue={tdata.frequency ? tdata.frequency : ""} onChange={(e) =>
                                      editField(
                                        tdata.id,
                                        "frequency",
                                        e.target.value,
                                        i
                                        )} 
                                    required>
                                    <option value=""> Select frequency</option>
                                    <option value="OneOff">One Off</option>
                                    <option value="Daily">Daily</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Fortnightly">Fortnightly</option>
                                    <option value="Quarterly">Quarterly</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Annually">Annually</option>
                                    <option value="Bimonthly">Bimonthly</option>
                                    <option value="Fourthmonthly">Fourthmonthly</option>
                              </select>
                              
                                </td>
                               
                                
                          <td>
                           <Button color="success" type="button" onClick={() => generateForm(tdata.id,tdata.phase_id,i,tdata.frequency)}>Update Form</Button>
                          
                          </td>
                             
                         
                          <td>
                          
                            <Input
                      type="date"
                      placeholder="Select date"
                      name="updated_at"
                      readonly
                      disabled
                      style={{color:"#000"}}
                      value={tdata.updated_date ? tdata.updated_date : ""}
                              
                      
                    />
</td>

                              </tr>
                            ))
                          :
                           ( <p>No Data Found</p> )}
                         
          {(() => {
            const arr1 = [];
            for (let key = 0; key < newboxmaster; key++) {
                arr1.push(
                  <>
                  <tr key={key} className={((key % 2 == 0)? 'purple-row': 'orange-row' )}>
                  <td>
                  <select className="form-control" name="phase_id"
                                    onChange={(e) => inputhandler(e,key)}
                                >
                                  <option value="">Select</option>
                                  {phaselist &&
                                phaselist.length &&
                                phaselist != null ? (
                                  phaselist.map((phase) =>
                                  
                                      <option key={phase.id} value={phase.id} >
                                        {phase.desc}
                                      </option>
                                  )
                                ) : (
                                  <option>No data Found</option>
                                )}
 
                            
                          </select>
                  </td>
                  <td>
                  <select class="form-control" name="frequency"  onChange={(e) => inputhandler(e,key)}  required>
                                    <option value=""> Select frequency</option>
                                    <option value="OneOff">One Off</option>
                                    <option value="Daily">Daily</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Fortnightly">Fortnightly</option>
                                    <option value="Quarterly">Quarterly</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Annually">Annually</option>
                                    <option value="Bimonthly">Bimonthly</option>
                                    <option value="Fourthmonthly">Fourthmonthly</option>
                              </select>
                               </td>
                 
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  </>
                  );
                }
                return arr1;
                
            })()}  
                </tbody>
              </Table>
              <br></br>
              <row>
              <Button color="primary" className="col-xl-1" style={{flex:"0 0 4.66667%"}} type="button" onClick={(e) => addNewboxMaster()} >
              <i class="fa fa-plus"></i>  Add 
              </Button>
              {(newboxmaster > 0) ?
              <Button color="primary" className="col-xl-1" style={{background:"rgb(2, 199, 121)",border:"rgb(2, 199, 121)"}} type="button" onClick={addChecklistMaster} disabled={disablestate}>
                {disablestate ? 'Sending...' : 'Save'} 
              </Button> : null }
              
              </row>
            </Card>
          </div>
        </Row>
        <Row>
          <Modal
            size="lg"
            className="taskdetailmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => settaskdetailPopup(!taskdetailPopup)}
            isOpen={taskdetailPopup}
          >
            <div className="modal-header">
            <h3> Checklist Form</h3>&nbsp;&nbsp;&nbsp;&nbsp;
            <div>
              <label><b>Import File</b></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a style={{color:'blue',cursor:'pointer'}} onClick={download_sample} download>Download Sample File</a><br></br>
              <input
                type="file"
                accept=".xls,.xlsx"
                onChange={handleFileUpload}
                style={{width:'200px'}}
              />
             
            <Button color="primary" type="button" style={{background:"rgb(2, 199, 121)",border:"rgb(2, 199, 121)"}} onClick={importfile}>
            Import
            </Button>  
              </div>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => settaskdetailPopup(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{paddingTop:'0px'}}>
           
            <Form
                role="form"
                id="phase-selection-form"
                onSubmit={addchecklistform}
            
              >
              <Table  className="align-items-center table-flush" responsive>
                <thead>
                  <tr style={{border : "1px solid #000"}}>
                     <th style={{border : "1px solid #000"}}>Sr.No.</th> 
                    <th style={{border : "1px solid #000"}} className="whitespace">Category</th>
                    {/* <th style={{border : "1px solid #000"}} className="whitespace">Sub Category</th> */}
                    <th style={{border : "1px solid #000"}} className="whitespace">Checklist</th>
                    {/* <th style={{border : "1px solid #000"}} className="whitespace">Responsibility</th> */}
                    <th style={{border : "1px solid #000"}} className="whitespace">Action</th>
                  </tr>
                </thead>
                <tbody>
                {(() => {
            const arr1 = [];
            for (let key = 0; key < newbox; key++) {
                arr1.push(
                  <>
                    
                  <tr className="whitespace" style={{border : "1px solid #000"}}>
                  
                  <td style={{border : "1px solid #000"}}>{key+1}</td>
                 <td style={{border : "1px solid #000"}}><Input
                            
                 bsSize="sm"
                 name="category"
                 placeholder="Category"
                 id="example-date-input"
                 type="textarea"
                 defaultValue={(formdetails[key]) ? formdetails[key].category : ''}
                 onChange={(e) => inputhandlernew(e,key)}
                /></td>
                {/* <td style={{border : "1px solid #000"}}><Input
                            
                    bsSize="sm"
                    name="sub_cat"
                    placeholder="Sub Category"
                    id="example-date-input"
                    type="textarea"
                    defaultValue={(formdetails[key]) ? formdetails[key].sub_cat : ''}
                    onChange={(e) => inputhandlernew(e,key)}
                    /></td>
                 */}
                
                  
                <td className="whitespace" style={{border : "1px solid #000"}}>
               
                <Input
                            
                            bsSize="sm"
                            name="checklist"
                            placeholder="Checklist"
                            id="example-date-input"
                            type="textarea"
                            defaultValue={(formdetails[key]) ? formdetails[key].checklist : ''}
                            onChange={(e) => inputhandlernew(e,key)}
                /> 
                  
             
                  </td>
              
                {/* <td className="whitespace" style={{width:"50%",border : "1px solid #000"}}>
                <select className="form-control" name="responsibility"
                style={{width:'150px'}}
                                  
                                onChange={(e) => inputhandlernew(e,key)}
                                defaultValue={(formdetails[key]) ? formdetails[key].responsibility : ''}
                                >
                                  <option value="">Select</option>
                                  <option  value="Auditpod" >Auditpod</option>
                                  <option  value="QA" >QA</option>
                                  <option  value="Auditpod/QA" >Auditpod/QA</option>
 
                            
                          </select>
                </td> */}
                <td className="whitespace" style={{border : "1px solid #000"}}>
                            <> 
                            <Button className="btn btn-danger" 
                            onClick={(e) =>
                              handleclick(
                                formdetails[key].id
                              )
                            } class="btn btn-success"><i class="fa fa-trash"></i></Button>
                            </>
                            
                                </td>
                </tr>
                  </>
                );
            }
            return arr1;
            
        })()}   

               
                </tbody>
              </Table>
              </Form>
              <div >
              <Button color="primary" className="col-xl-1" style={{flex:"0 0 4.66667%"}} type="button" onClick={(e) => addNewbox()} >
              <i class="fa fa-plus"></i>  Add 
              </Button>
              <Button color="primary" type="button" style={{background:"rgb(2, 199, 121)",border:"rgb(2, 199, 121)"}} onClick={addchecklistform} disabled={disablestate}>
            {disablestate ? 'Sending...' : 'Save'} 
              </Button>
              {/* <div>
              <div>
                <br></br>
                <p>OR</p>
              <label>Import File</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a style={{color:'blue',cursor:'pointer'}} onClick={download_sample} download>Download Sample File</a><br></br>
              <input
                type="file"
                accept=".xls,.xlsx"
                onChange={handleFileUpload}
              />
              <br></br>
              <br></br>
            <Button color="primary" type="button" style={{background:"rgb(2, 199, 121)",border:"rgb(2, 199, 121)"}} onClick={importfile}>
            Import
            </Button>  
              </div> */}
              </div>
            </ModalBody>
            <ModalFooter>
</ModalFooter>
           </Modal>
         
        </Row>

      </Container>
    </>
  );
};


export default AddMasterChecklist;
