import { useState,useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect, useHistory,Link,useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  PopoverBody, 
  UncontrolledPopover,
} from "reactstrap";
import swal from 'sweetalert';
import {AgGridReact,AllCommunityModules,AgGridColumn} from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import {userService} from '../../../services/authentication';
import {reportmanageService} from '../../../services/reportmanageService';
import '../../../assets/css/custom.css';
import Header from "components/Headers/Header.js";
import { forEachChild } from "typescript";
import { connect } from "react-redux";
import DataTable from 'react-data-table-component';
import Navbuttons from "components/Navigations/Navbuttons.js";
import { permissions, rolename, roletype } from "global.js";

 const DailyReportDetails = (props) => {
 const params  = useParams();
 const history = useHistory();
 const [reportDetailsdata, setReportDetailsData] = useState([]);
 const [currentPage, setCurrentPage] = useState(null);
 const [last_page, setLastPageData] = useState(0);
 const [error, setError] = useState(null)
  const [successMsg, setsuccessMsg] = useState(null)
  const errorDiv = error 
        ? <UncontrolledAlert className="alert-danger" fade={false}>
        {error}
        </UncontrolledAlert> 
        : '';
  const successDiv = successMsg 
        ? <UncontrolledAlert className="alert-success" fade={false}>
        {successMsg}
        </UncontrolledAlert> 
        : '';
  useEffect(()=>{
    getdetails_list();
  },[]);
  const [sortfield,setsortfield] = useState('');
  const [sortorder,setsortorder] = useState('asc');
  
  function sortTable(field){
    console.log(field)
    setsortfield(field);
    if(sortorder=='asc'){
      setsortorder('desc');
    }else{
      setsortorder('asc');
    }
  }
  const handlePageClick = (e, index) => {
    e.preventDefault();
    getdetails_list(index,null);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    getdetails_list(prev,null);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    getdetails_list(next,null);
  };
  const getdetails_list = async (pageNumber = 1,e) => { 
    let status ='';
    if(params.status!='undefined' && params.status!=undefined){
      status = params.status;
    }
    reportmanageService.getDailyReportDetails(params.userid,params.type,status,pageNumber).then((response) => {
      console.log(response); 
         setReportDetailsData(response.data.data.data); 
         setCurrentPage(response.data.data.current_page);
         setLastPageData(response.data.data.last_page);
    });
  };
  const formatDate = (dateStr) => {
    if(dateStr!=null && dateStr!=''){

      const [year, month, day] = dateStr.split('-');
      let newDate = `${day}-${month}-${year}`;
      return newDate;
    }
  };
  function downloadReport(){
    let status ='';
    if(params.status!='undefined' && params.status!=undefined){
      status = params.status;
    }
    reportmanageService.download_report(params.userid,params.type,status).then((response) => {
    
    });
  }
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  
return (
    <>
      <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
        <div className="row">
            <div className="col-xl-12">
            <div className="col-md-12 col-12">
            </div>
            <Row>
              <Col xl="12">
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h4 className="text-uppercase text-muted ls-1 mb-1">
                          Daily Report Details
                        </h4>
                      </div>
                      
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                
                    <th scope="col" className="whitespace" >Client Name</th>
                    <th scope="col" className="whitespace">Sub Client</th>
                    <th scope="col" className="whitespace">Raised by</th>
                    <th scope="col" className="sortclass" onClick={(e) => sortTable('due_date')} >Due Date</th>
                    <th scope="col" className="sortclass" onClick={(e) => sortTable('due_date')} >Due Days</th>
                    <th className="whitespace">Assign Lead</th>
                   
                    {user_info.role_id == 2 || user_info.role_id == 3 ? 
                    (<><th scope="col" className="whitespace">Analyst Lead</th>
                    <th className="whitespace" scope="col">Analyst TM</th></>
                    ) : 
                    <th className="whitespace" scope="col">Assign TM</th> }
                    <th scope="col">Comp. Date</th>
                    {user_info.role_id == 2 || user_info.role_id == 3 ? 
                    (<th scope="col">Comp. Hours</th>) : '' }
                    <th scope="col" className="whitespace">Status</th>
                    
                </thead>
                <tbody>
                  {reportDetailsdata &&
                    reportDetailsdata.length &&
                    reportDetailsdata != null ? (
                      reportDetailsdata.map((item, i) => (
                      <>
                      <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') } edit-type={item.edittype} edit-id={item.id}>
                          <th scope="row"  className="whitespace">
                            <span className="mb-0 text-sm"  style={{cursor:'pointer'}}  >
                              {item.name} ({item.type}) {" "} &nbsp;&nbsp;
                            </span>
                          </th>
                          
                          <td className="whitespace">{item.sub_client}</td>
                          <td className="whitespace">{item.raised_by}</td>
                          <td>{formatDate(item.due_date)}</td>
                          <td>{(item.status_id != 3 && item.status_id != 4 && item.status_id != 6)? item.due_days : ''}</td>
                          <td> {(item.lead!=null)?item.lead:''}</td>
                         
                          {user_info.role_id == 2 || user_info.role_id == 3 ? 
                            (<><td className="whitespace">{item.analystlead}</td>
                             <td className="whitespace">{item.analystmember}</td> </>
                            ) :  <td className="whitespace">{item.member}</td>
                          }
                          <td>{item.completed_date}</td>
                          {user_info.role_id == 2 || user_info.role_id == 3 ? 
                            (<td>{item.HOURS}</td>) : ''
                          }
                          <td>{item.status_name}</td>
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
                    </Table>
                    <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
                </Card>
              </Col>
             
            </Row>
             
            </div>
        </div>
      </Container>
    </>
  );
};
export default DailyReportDetails;
