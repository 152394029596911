
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {apiUrl} from '../constant';
import {authHeader} from '../helpers/auth-header';
import {authtoken} from 'global.js';
const axios = require("axios");
export const rolemanageService = {
    getroles,
    getrole,
    fetchPermissions,
    roleeditsubmit,
    deleteRole,
    roleaddsubmit
};

function getroles(){
    return axios({
        method: "get",
        url: `${apiUrl}/roles`,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
           if(response.status == 200){
                return response;  
           }
        }, error => {
          return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
}

function getrole(id){
    return axios({
      method: "get",
      url: `${apiUrl}/roles/`+id,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function fetchPermissions(){
  return axios({
    method: "get",
    url: `${apiUrl}/permission`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });  
}

function roleeditsubmit(id,role_data){
  role_data['permission'] = role_data['permission'].toString();
  var bodyFormData = role_data;
  console.log(role_data)
  return axios({
    method: "PUT",
    url: `${apiUrl}/roles/`+id,
    params : bodyFormData,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });  
}

function roleaddsubmit(role_data){
  role_data['permission'] = role_data['permission'].toString();
  return axios({
    method: "POST",
    url: `${apiUrl}/roles`,
    data : role_data,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}

function deleteRole(id){
  return axios({
    method: "DELETE",
    url: `${apiUrl}/roles/`+id,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });  
}


