import { useState, useEffect } from "react";
import React from "react";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import "assets/css/themecustom-style.css";
import { ToastContainer, toast } from "react-toastify";
import "@flowjs/flow.js";
import Flow from "@flowjs/flow.js";
import { async } from "@firebase/util";
import Files from "react-files";
import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
import ProgressBar from "@ramonak/react-progress-bar";

import { authtoken } from "global.js";
import { apiUrl } from "../../constant";
const axios = require("axios");
let initialFileData = [
  // doc_type => "",
  // remark => "",
  // uploadfiles=>[]
];
const Uploaddocument = (props) => {
  // const [files,setFiles] = useState([]);
  const [files, setFiles] = useState(initialFileData);
  const [imageSrc, setImageSrc] = useState(undefined);
  const [is_uploaded, setImageUploaded] = useState(false);
  const [percentage, setUploadingpercent] = useState(0);

  let initialStateData = {
    sub_client: "",
    year_end_date: "",
    book_keeping_software: "",
    account_prod_software: "",
    initial_budget: "",
    client_deadline: "",
    client_manager: "",
    remark: "",
    type: "",
    business_nature : "",
    vat_scheme : "",
    point_of_contact : "",
    vat_register : 0,
    bookkeeping_done : 1,
    need_bookkeeping : 0,
    access_details : "",
    ye_journals : 0,
    priority_no : "",
    plan : ""
  };
  let looparrc = [
    'Previous YE working file',
    'Previous YE Accounts Production TB',
    'PDF Bank Statements',
    'Payroll Reports',
    'VAT Returns',
    'SAGE Back-up',
    'Any Other Documents',
  ];
  const [inputFields, setInputfields] = useState(initialStateData);
  const [displaybookkeeping, setdisplaybookkeeping] = useState(false);
  let com_obj = { bg_color: "#c50923", label: "Uploading please wait..." };
  const [compobj, setcompobj] = useState(com_obj);
  const [uploadloop, setuploadloop] = useState(looparrc);
const [disablestate, setdisablestate] = useState(false);
  useEffect(() => {}, []);

  function inputhandler(e) {
    if(e.target.value == 0 && e.target.name == 'bookkeeping_done'){
      setdisplaybookkeeping(true);
    }else if(e.target.value == 1 && e.target.name == 'bookkeeping_done'){
      setdisplaybookkeeping(false)
      setInputfields({ ...inputFields, ['need_bookkeeping']: 0 });
    }
    setInputfields({ ...inputFields, [e.target.name]: e.target.value });
  }

  function inputhandler1(e,index) {
    let temp_state = [...files];
    let temp_element = { ...temp_state[index] };
    temp_element.remark = e.target.value;
    temp_state[index] = temp_element;
    setFiles(temp_state);
  }

  const updateFiles = (incommingFiles,index,type) => {
    console.log('incommingFiles',incommingFiles)
    var items = [];
    for (let i = 0; i < incommingFiles.length; i++) { // many times blank data come on incoming files
      if(incommingFiles[i].length > 0){
          incommingFiles[i].forEach(element => {
            items.push(element);
          });
      }
      else if(incommingFiles[i] != ''){
        items.push(incommingFiles[i]);
      }
     
    }
    let temp_state = [...files];
    let temp_element = { ...temp_state[index] };
    temp_element.uploadfiles = items;
    temp_element.doc_type = type;
    temp_state[index] = temp_element;
    setFiles(temp_state);
    console.log('u',temp_state)
  };
  const onDelete = (id,index) => {
    let temp_state = [...files];
    let temp_element = { ...temp_state[index] };
    temp_element.uploadfiles = files[index].uploadfiles.filter((x) => x.id !== id);
    if(temp_element.uploadfiles.length == 0){
      delete temp_state[index];
      if(files.length == 1){
        setFiles([]);
      }else{
        setFiles(temp_state);
      }
    }else{
      temp_state[index] = temp_element;
      setFiles(temp_state);
    }
  };
  const handleSee = (imageSource) => {
    setImageSrc(imageSource);
  };
  const handleClean = (files) => {
    console.log("list cleaned", files);
  };

  const filesUpload = (task_id) => {
    if (files.length <= 0) {
      return false;
    }
    const formData = new FormData();
    Object.keys(files).forEach((key) => {
      const file = files[key];
      
      Object.keys(file.uploadfiles).forEach((key1) => {
        const file1 = file.uploadfiles[key1];
        formData.append("doc_type"+key+key1,file.doc_type);
        formData.append("remark"+key+key1,(file.remark) ? file.remark : '');
        formData.append(
          "file["+key+key1+"]",
          new Blob([file1.file], { type: file1.file.type }),
          file1.file.name || "file_1"
        );
      });
    });
    formData.append('task_id',task_id);

    setUploadingpercent(0);
    axios({
      method: "post",
      url: `${apiUrl}/upload-document`,
      data: formData,
      headers: { Authorization: `Bearer ${authtoken}` },
      onUploadProgress: (progressEvent) => {
        console.log(progressEvent);
        setImageUploaded(true);
        const progress = (progressEvent.loaded / progressEvent.total) * 50;
        setUploadingpercent(progress);
      },
    })
      .then(
        function (response) {
          console.log(response)
          if (response.status == 200) {
            setTimeout(() => {
              // setImageUploaded(false);
              setUploadingpercent(100);
              setcompobj({ bg_color: "#0bb2b0", label: "Uploaded" });
              setFiles([]);
              setTimeout(() => {
                setImageUploaded(false);
                setUploadingpercent(0);
                setcompobj(com_obj);
               // toast.success('Job added Successfully');
               return true;
              }, 2000);
            }, 500);
          }
        },
        (error) => {
          return error.response;
        }
      )
      .catch(function (error) {});
  };
  // console.log('b',files)
  function addclientjob(e) {console.log(inputFields);console.log("file"+files)
  setdisablestate(true)
    e.preventDefault();
    if (inputFields.company_id != undefined) {
      clientmanageService.updatejobclient(inputFields).then((response) => {
        if (response.status == 200) {
          document.getElementById("addclient-form").reset();
          setInputfields(initialStateData);
          if(files.length <= 0){
              toast.success(response.data.message);   
          }
          
          get_list();
        } else if (response.status == 201) {
          toast.error(response.data.message);
        }
      });
    } else {
      clientmanageService.addjobclient(inputFields).then((response) => {
        console.log(response);
        if (response.status == 200) {
          setdisablestate(false)
          console.log('response');
          console.log(response.data.data);
          // filesUpload(response.data.data);
          document.getElementById("addclient-form").reset();
          setInputfields(initialStateData);
          // toast.success(response.data.message);
          // get_list();
          if (files.length > 0) {
             filesUpload(response.data.data)
              setTimeout(() => {
                document.getElementById("addclient-form").reset();
                setInputfields(initialStateData);
                toast.success(response.data.message);
              },2000);
              
          }else{
            document.getElementById("addclient-form").reset();
            setInputfields(initialStateData);
            toast.success(response.data.message);
          }
          
        } else if (response.status == 201) {
          toast.error(response.data.message);
        }
      });
    }
    
  }

  const cancelbtn = () =>{
    setdisablestate(false)
    setInputfields(initialStateData);
    setFiles([]);
  }

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };
  

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <div className="files">
        <div class="row">
          <div class="col-md-6"><h2>Add Job</h2></div>
        </div>
        <br></br>
        <div className="card card">
          <Form role="form" id="addclient-form" onSubmit={addclientjob}>
            <FormGroup>
              <Row>
                <div className="col-xl-4 col-md-4 col-xs-12">
                  <h3 className="clientformtag">Sub Client</h3>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      placeholder="Sub Client Name"
                      type="text"
                      name="sub_client"
                      value={
                        inputFields.sub_client != undefined
                          ? inputFields.sub_client
                          : ""
                      }
                      onChange={inputhandler}
                      required
                    />
                  </InputGroup>
                </div>
                <div className="col-xl-4 col-md-4 col-xs-12">
                  <h3 className="clientformtag">Year-End date</h3>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      placeholder="Year - End Date"
                      type="date"
                      name="year_end_date"
                      value={
                        inputFields.year_end_date != undefined
                          ? inputFields.year_end_date
                          : ""
                      }
                      onChange={inputhandler}
                      required
                    />
                  </InputGroup>
                </div>

                <div className="col-xl-4 col-md-4 col-xs-12">
                  <h3 className="clientformtag">Bookkeeping Software</h3>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Bookkeeping Software"
                      type="text"
                      name="book_keeping_software"
                      value={
                        inputFields.book_keeping_software != undefined
                          ? inputFields.book_keeping_software
                          : ""
                      }
                      onChange={inputhandler}
                      required
                    />
                  </InputGroup>
                </div>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <div className="col-xl-4 col-md-4 col-xs-12">
                  <h3 className="clientformtag">
                    <h3 className="clientformtag">
                      Accounts Production Software
                    </h3>
                  </h3>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Accounts Production Software"
                      type="text"
                      name="account_prod_software"
                      value={
                        inputFields.account_prod_software != undefined
                          ? inputFields.account_prod_software
                          : ""
                      }
                      onChange={inputhandler}
                      required
                    />
                  </InputGroup>
                </div>
                <div className="col-xl-4 col-md-4 col-xs-12">
                  <h3 className="clientformtag">
                    <h3 className="clientformtag">Budgeted Hours</h3>{" "}
                  </h3>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Budgeted Hours"
                      type="number"
                      name="initial_budget"
                      value={
                        inputFields.initial_budget != undefined
                          ? inputFields.initial_budget
                          : ""
                      }
                      onChange={inputhandler}
                      required
                      inputmode="numeric"
                    />
                  </InputGroup>
                </div>

                <div className="col-xl-4 col-md-4 col-xs-12">
                  <h3 className="clientformtag">
                    <h3 className="clientformtag">Expected Completion date</h3>
                  </h3>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Expected Completion date"
                      type="date"
                      name="client_deadline"
                      value={
                        inputFields.client_deadline != undefined
                          ? inputFields.client_deadline
                          : ""
                      }
                      onChange={inputhandler}
                      min={disablePastDate()}
                      required
                    />
                  </InputGroup>
                </div>
              </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                  <div className="col-xl-4 col-md-4 col-xs-12">
                    <h3 className="clientformtag">Nature of business</h3>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Nature of business"
                        type="text"
                        name="business_nature"
                        value={
                          inputFields.business_nature != undefined
                            ? inputFields.business_nature
                            : ""
                        }
                        onChange={inputhandler}
                        
                      />
                    </InputGroup>
                  </div>
                  <div className="col-xl-4 col-md-4 col-xs-12">
                    <h3 className="clientformtag">Wheather VAT Registered</h3>
                    <select
                      className="form-control"
                      name="vat_register"
                      value={inputFields.vat_register}
                      onChange={inputhandler}
                      required
                    >
                      <option value="1">
                        Yes
                      </option>
                      <option value="0">
                        No
                      </option>
                    </select>
                  </div>
                  <div className="col-xl-4 col-md-4 col-xs-12">
                    <h3 className="clientformtag">VAT Scheme</h3>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="VAT Scheme"
                        type="text"
                        name="vat_scheme"
                        value={
                          inputFields.vat_scheme != undefined
                            ? inputFields.vat_scheme
                            : ""
                        }
                        onChange={inputhandler}
                        
                      />
                    </InputGroup>
                  </div>
                </Row>
            </FormGroup>
            <FormGroup>
              <Row>
              {/* <div className="col-xl-4 col-md-4 col-xs-12">
                    <h3 className="clientformtag">Point of contact</h3>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Point of contact"
                        type="text"
                        name="point_of_contact"
                        value={
                          inputFields.point_of_contact != undefined
                            ? inputFields.point_of_contact
                            : ""
                        }
                        onChange={inputhandler}
                        
                      />
                    </InputGroup>
                  </div> */}
                  <div className="col-xl-4 col-md-4 col-xs-12">
                    <h3 className="clientformtag">Whether Accounts Production to be done? </h3>
                    <select
                      className="form-control"
                      name="account_production_done"
                      value={inputFields.account_production_done}
                      onChange={inputhandler}
                      required
                    >
                      <option value="1" >
                        Yes
                      </option>
                      <option value="0">
                        No
                      </option>
                    </select>
                  </div>
                  <div className="col-xl-4 col-md-4 col-xs-12">
                    <h3 className="clientformtag">Whether Book-keeping done? </h3>
                    <select
                      className="form-control"
                      name="bookkeeping_done"
                      value={inputFields.bookkeeping_done}
                      onChange={inputhandler}
                      required
                    >
                      <option value="1" >
                        Yes
                      </option>
                      <option value="0">
                        No
                      </option>
                    </select>
                  </div>
                  <div className="col-xl-4 col-md-4 col-xs-12" style={{ display: displaybookkeeping == true ? "block" : "none" }}>
                    <h3 className="clientformtag">Whether to be completed? </h3>
                    <select
                      className="form-control"
                      name="need_bookkeeping"
                      value={inputFields.need_bookkeeping}
                      onChange={inputhandler}
                      
                    >
                      <option value="1">
                        Yes
                      </option>
                      <option value="0">
                        No
                      </option>
                    </select>
                  </div>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
              <div className="col-xl-4 col-md-4 col-xs-12">
                    <h3 className="clientformtag">Access details (user id / password)</h3>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Access details"
                        type="text"
                        name="access_details"
                        value={
                          inputFields.access_details != undefined
                            ? inputFields.access_details
                            : ""
                        }
                        onChange={inputhandler}
                        
                      />
                    </InputGroup>
                  </div>
                  <div className="col-xl-4 col-md-4 col-xs-12">
                    <h3 className="clientformtag">Whether YE Journals to be accounted in Bkg Software?</h3>
                    <select
                      className="form-control"
                      name="ye_journals"
                      value={inputFields.ye_journals}
                      onChange={inputhandler}
                      required
                    >
                      <option value="1">
                        Yes
                      </option>
                      <option value="0">
                        No
                      </option>
                    </select>
                  </div>
                  <div className="col-xl-4 col-md-4 col-xs-12">
                    <h3 className="clientformtag">Priority No. </h3>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Priority No."
                        type="number"
                        name="priority_no"
                        value={
                          inputFields.priority_no != undefined
                            ? inputFields.priority_no
                            : ""
                        }
                        onChange={inputhandler}
                        required
                      />
                    </InputGroup>
                  </div>
              </Row>
            </FormGroup>
            
            <FormGroup>
              <Row>
                <div className="col-xl-4 col-md-4 col-xs-12">
                  <h3 className="clientformtag">Client Manager</h3>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      placeholder="Client Manager"
                      type="text"
                      name="client_manager"
                      value={
                        inputFields.client_manager != undefined
                          ? inputFields.client_manager
                          : ""
                      }
                      onChange={inputhandler}
                      
                    />
                  </InputGroup>
                </div>
                <div className="col-xl-4 col-md-4 col-xs-12">
                  <h3 className="clientformtag">Type</h3>
                  <InputGroup className="input-group-alternative">
                    <select
                      className="form-control"
                      name="type"
                      value={inputFields.type}
                      onChange={inputhandler}
                      required
                    >
                      <option key="" value="">
                        Select Type
                      </option>
                      <option key="YE" value="YE">
                        Year End
                      </option>
                      <option key="A" value="A">
                        Amends
                      </option>
                      <option key="O" value="O">
                        Opening Balances
                      </option>
                      <option key="N" value="N">
                        New
                      </option>
                    </select>
                  </InputGroup>
                </div>

               
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
              <div className="col-xl-12 col-md-12 col-xs-12">
                    <h3 className="clientformtag">Plan and approach to Job</h3>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Plan and approach to Job - Any Special Points to remember for specific area of accounts "
                        type="textarea"
                        name="plan"
                        value={
                          inputFields.plan != undefined
                            ? inputFields.plan
                            : ""
                        }
                        onChange={inputhandler}
                        
                      />
                    </InputGroup>
                  </div>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                
              <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <th>Document checklist</th>
                      <th>Remarks</th>
                      <th>Upload document</th>
                    </thead>
                    <tbody>
                    {uploadloop.length > 0 &&
                          uploadloop.map((item,i) => (
                    <tr key={i}>
                        <td><p>{item} </p></td>
                        <td> <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Remark"
                          type="textarea"
                          name="uploadremark"
                          onChange={(e)=> inputhandler1(e,i)}
                          
                        />
                      </InputGroup></td>
                        <td>
                        <Dropzone
                        key={i}
                        style={{ minHeight: "0px" }}
                        label={
                          <h1 key={i}>
                            <i className="ni ni-cloud-upload-96 text-th browse-icon"></i>
                            <p className="browse-title">
                              Drop your files or Browse 
                            </p>
                          </h1>
                        }
                        onChange={(e)=> updateFiles(e,i,item)}
                        minHeight="195px"
                        onClean={(e)=> handleClean(e,i)}
                        value={files.length > 0 &&
                          files.map((fileitem,key) => (
                            key == i ? fileitem.uploadfiles : '' ))}
                        header={false}
                        footer={false}
                        url=""
                        fakeUploading={false}
                        behaviour="add"
                        disableScroll
                      >
                        {files.length > 0 &&
                          files.map((file1,key) => (
                            key == i ?
                            file1.uploadfiles.map((file) => (
                            <FileItem
                              {...file}
                              key={file.id}
                              onDelete={(e)=> onDelete(e,i)}
                              onSee={(e)=> handleSee(e,i)}
                              resultOnTooltip
                              preview
                              info
                              hd
                            />
                            ))
                            : ''
                          ))}
                          <h1 style={{textAlign:"center"}}><i className="ni ni-cloud-upload-96 text-th browse-icon"></i><p className="browse-title">Drop your files or Browse</p></h1>
                      </Dropzone>
                      </td>
                    </tr>
                     ))}
                    
                    </tbody>
                  </Table>
                  
                 
              </Row>
              
            </FormGroup>

            <div class="row">
              <div className="col-12">
                <Button className="mt-2" color="primary" type="submit"  disabled={disablestate}>
                {disablestate ? 'Sending...' : 'Save'}
                </Button>
                <Button className="mt-2" color="warning" type="button" onClick={cancelbtn}>
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>  

          <FullScreenPreview
            imgSource={imageSrc}
            openImage={imageSrc}
            onClose={(e) => handleSee(undefined)}
          />

          <br></br>
          {is_uploaded ? (
            <ProgressBar
              completed={percentage}
              bgColor={compobj.bg_color}
              customLabel={compobj.label}
              className="progress_css"
            />
          ) : (
            ""
          )}
        </div>
      </Container>
     
    </>
  );
};

export default Uploaddocument;
