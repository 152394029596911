import { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService";
import 'assets/css/themecustom-style.css';

const Archive = (props) => {
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [archivedata, setArchivedata] = useState([]);

  const handlePageClick = (e, index) => {
    e.preventDefault();
    get_archive_list(index);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_archive_list(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_archive_list(next);
  };

  const get_archive_list = async (pageNumber = 1) => { 
   
    taskmanageService.getarchivetasks(pageNumber).then((response) => {
      setArchivedata(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setLastPageData(response.data.data.last_page);
    });
  };

  const restoreTask = (id,index) => {
    taskmanageService.restoreTask(id).then((response) => {
      if(response.status == 200){
        let state = [...archivedata];
        delete state[index];
        setArchivedata(state);
       toast.success(response.data.message);
      }else{
        toast.error(response.data.message);
      }
    });
  }

  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split('-');
    let newDate = `${day}-${month}-${year}`;
    return newDate;
  };

  useEffect(() => {
    get_archive_list();
}, []);


  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        
      
        <Row className="mt-5">
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="filter" style={{padding:'0 3%'}}>
                    <h3 className="mb-0" style={{fontSize:'20px'}}>Archived Task List</h3><br></br>
                    <br></br>
                </Row>
               
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
              
                    <th scope="col">Company Name</th>
                    <th scope="col">Sub Client</th>
                    <th scope="col">Raised by</th>
                    <th scope="col">Raised on</th>
                    <th scope="col">Due Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                </thead>
                <tbody>
                  {archivedata &&
                    archivedata.length &&
                    archivedata != null ? (
                      archivedata.map((item, i) => (
                      <>
                     <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') }>
                        <td>{item.name}</td>
                        <td>{item.sub_client}</td>
                        <td>{item.raised_by}</td>
                        <td>{item.raised_date}</td>
                        <td>{item.due_date}</td>
                        <td>{item.status_name}</td>
                        <td><Button
                                color="primary"
                                style={{
                                  background: "#0A5AC2",
                                  border: "#0A5AC2",
                                  borderRadius: "18px",
                                }}
                                size="sm"
                                type="button"
                                onClick={(e) =>
                                  restoreTask(
                                    item.id,i
                                  )
                                }
                              >
                                Restore
                              </Button>
                          </td>
                     </tr>
                      
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      
      </Container>
    </>
  );
};


export default Archive;
