import { useState,useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect, useHistory,Link,useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
} from "reactstrap";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import {reportmanageService} from '../../services/reportmanageService';
import { taskmanageService } from "../../services/taskmanageService";
import '../../assets/css/custom.css';
import Header from "components/Headers/Header.js";
import { ToastContainer, toast } from "react-toastify";
import { manageService } from "../../services/manageaccountservices";
import { clientmanageService } from "../../services/clientmanageService.js";

 const ManagerRejection = (props) => {
 const params  = useParams();
 const history = useHistory();
 const [reportRejectiondata, setReportRejectionData] = useState([]);
 const [currentPage, setCurrentPage] = useState(null);
 const [last_page, setLastPageData] = useState(0);
 const [error, setError] = useState(null)
 const [statuslist, setStatusdata] = useState([]);
  const [successMsg, setsuccessMsg] = useState(null);
  const [clientsearch, setclientsearch] = useState('');
  const [filterFields,setfilterFields] = useState([]);
  const [filtertype,setfiltertype] = useState('open');
  const [allclient, setAllclient] = useState(true);
const [errorcategory,seterrorcategory] = useState([]);
const [rowsPerPage, setRowsPerPage] = useState(25);
const [total_page,setTotalData] = useState(0);
const rowsPerPageOptions = [5, 10, 25, 50, 100];
  const errorDiv = error 
        ? <UncontrolledAlert className="alert-danger" fade={false}>
        {error}
        </UncontrolledAlert> 
        : '';
  const successDiv = successMsg 
        ? <UncontrolledAlert className="alert-success" fade={false}>
        {successMsg}
        </UncontrolledAlert> 
        : '';
  useEffect(()=>{
    
    getmanagerrejection_list();
   
  },[]);
  const [sortfield,setsortfield] = useState('');
  const [sortorder,setsortorder] = useState('asc');
  
  function sortTable(field){
    console.log(field)
    setsortfield(field);
    if(sortorder=='asc'){
      setsortorder('desc');
    }else{
      setsortorder('asc');
    }
  }
  const handlePageClick = (e, index) => {
    e.preventDefault();
    getmanagerrejection_list(index,null,'','',rowsPerPage);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    getmanagerrejection_list(prev,null,'','',rowsPerPage);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    getmanagerrejection_list(next,null,'','',rowsPerPage);
  };
  const getmanagerrejection_list = async (pageNumber = 1,e,filterFields,filtertype='',rowsPerPage='') => { 
    let  textbox = '';
    if(e != null){
       textbox = e.target.value;
    }
    const cloneValues = {...filterFields};
    
   
    setfilterFields(cloneValues);
    reportmanageService.getmanagerRejectionData(pageNumber,textbox,filterFields,filtertype,rowsPerPage).then((response) => {
      //console.log(response.data.data); 
         setReportRejectionData(response.data.data.data); 
         setCurrentPage(response.data.data.current_page);
         setLastPageData(response.data.data.last_page);
         setTotalData(response.data.data.total);
    });
  };
  const handlerejeditRow = (index) => {
    // if(phaseedit == true || taskedit == true || analystedit == true){
      let temp_state = [...reportRejectiondata];
      let temp_element = { ...temp_state[index] };
      temp_element.editing = true;
      temp_state[index] = temp_element;
      setReportRejectionData(temp_state);
      // }

  };
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
};

const formatDate = (dateStr) => {
  if(dateStr!='' && dateStr!= null){
  const [year, month, day] = dateStr.split('-');
  let newDate = `${day}-${month}-${year}`;
  return newDate;
  }else{
    return null;
  }
};
  function downloadReport(){
    
    reportmanageService.download_external_rejection_report(filterFields,filtertype).then((response) => {
    
    });
  }
 
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  
  const [taskdetails,settaskdetails] = useState({});
  const [taskdetailPopup,settaskdetailPopup] = useState(false);
  
  const taskDetail = (task_id,index) => {
    taskmanageService.gettaskdetail(task_id,'','').then((response) => {
      settaskdetails(response.data.data);
      settaskdetailPopup(!taskdetailPopup);
    })
  }
  const withouterrorreport = () =>{
    history.push({
      pathname: 'reportwithouterror',
    });
  }
  const rejectionsummary = () =>{
    history.push({
      pathname: 'rejectionsummary',
    });
  }
  const upload_doc_popup = () => {
    history.push("/admin/addmanagerrejection");
 }
 const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
 function get_unique_companieslist(){
  clientmanageService.getclientlist().then((response)=>{
     setUniqueCompaniesData(response.data);
   });
 }
 const [ subclientlist, setsubclientlist ] = useState([])
 
   function subclientList(clientname=''){
     clientmanageService.getsubclientlist(clientname).then((response) => {
         if(response.status === 200){
           setsubclientlist(response.data)
         }else {
           toast.error('Something went wrong!');
         }
     });
   }
   function setclient(e){
     subclientList(e.target.value)
     // var index = e.target.selectedIndex;
     // setclientsearch(e.target[index].text)
     setclientsearch(e.target.value);
     const cloneValues = {...filterFields};
     delete cloneValues[2];
    setfilterFields(cloneValues);
   }
   const companyfilter = (e,index) => {
    if(e.target.value == ''){
      setAllclient(true);
    }else{
      setAllclient(false);
    }
    const cloneValues = {...filterFields};
    cloneValues[index] = {
      ...cloneValues[index],
      ['field']: e.target.name,
      ['value']: e.target.value,
      ['operator']: '='
    };
    setfilterFields(cloneValues);
    getmanagerrejection_list(1,null,cloneValues,filtertype);
  }
  function filterallcom({type}){
    setfiltertype(type)
    // setmainfilter(type);
    getmanagerrejection_list(1,null,filterFields,type);
  }
  const handleRowsPerPageChange = (newRowsPerPage) => {

    setRowsPerPage(newRowsPerPage);
    
    setCurrentPage(1); 
    getmanagerrejection_list(1,null,filterFields,filtertype,newRowsPerPage)
  
  };
  const handleclick = (e,id) => {
    e.preventDefault();
     history.push({
      pathname: 'editManagerRejection/'+id,
    });
  }
return (
    <>
      <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
        <div className="row">
            <div className="col-xl-12">
            <div className="col-md-12 col-12">
            </div>
            <Row className="align-items-center row">
            <Col xl="10">
              <Button
                className="dash-button "
                color="primary"
                type="button"
                onClick={() => upload_doc_popup()}
              >
                {" "}
              + Add Manager Rejection{" "}
              </Button>{" "}
            </Col>
            {/* <Col xl="2">
                    <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                    </Button>
                    </Col> */}
                   
          </Row><br></br>
            <Row>
            
              <Col xl="12">
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                  

                    <Row className="align-items-center">
                  
                      <div className="col">
                        <h4 className="text-uppercase text-muted ls-1 mb-1">
                          Manager Rejection
                        </h4>
                      </div>
                 
                        <div className="col-xl-2 col-md-3 col-xs-12">
                        <h3 className="clientformtag"> Category</h3>
                        <select  className="form-control" name="category" onChange={(e) =>
                                          companyfilter(e,1)
                                        }>
                        <option value="">Select Category</option>
                        <option value="Workflow Management">Workflow Management</option>
                        <option value="Tap Off">Tap Off</option>
                        <option value="On Time Billing">On Time Billing</option>
                        <option value="Training - New Employees">Training - New Employees</option>
                        <option value="Query Resolution">Query Resolution</option>
                        <option value="Timesheet Analysis and Approval">Timesheet Analysis and Approval</option>
                        <option value="Client Satisfaction">Client Satisfaction</option>
                        <option value="CCRP Meetings">CCRP Meetings</option>
                        <option value="Weekly Client Calls">Weekly Client Calls</option>
                        <option value="Employee Retention">Employee Retention</option>
                        </select>
                        </div>
                        <div className="col-xl-2 col-md-3 col-xs-12">
                        <h5 className="clientformtag">Date</h5>
                        <input type="date"
                                      className="form-control"
                                      name="date"
                                      onChange={(e) =>
                                          companyfilter(e,2)
                                        }
                                      />
                        </div>
                      
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                  <th scope="col" className="whitespace" >Raised For</th>
                    <th scope="col" className="whitespace" >Raised By</th>
                    <th scope="col" className="whitespace">Category</th>
                    <th scope="col" className="whitespace">Date</th>
                    <th scope="col" className="whitespace">Remark</th>
                    <th scope="col" className="whitespace">No. of errors</th>
                    <th scope="col" className="whitespace">Action</th>
                   
                    
                </thead>
                <tbody>
                  {reportRejectiondata &&
                    reportRejectiondata.length &&
                    reportRejectiondata != null ? (
                      reportRejectiondata.map((item, i) => (
                      <>
                      <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') } edit-type={item.edittype} edit-id={item.id}>
                          <th scope="row" onClick={() => taskDetail(item.task_id, i)}  className="whitespace">
                            <span className="mb-0 text-sm"  style={{cursor:'pointer'}}  >
                              {item.rej_for_name} 
                            </span>
                          </th>
                          <td className="whitespace">{item.name}  </td> 
                          <td className="whitespace">{item.category}  </td>
                        <td className="whitespace">{item.date}</td>
                    
                          <td className="whitespace">{item.remarks}</td> 
                          <td className="whitespace">{item.no_error}</td> 
                          <td>
                            <Button className="btn btn-success" onClick={(e) => { handleclick(e,item.id);}}  class="btn btn-success"><i class="fa fa-edit"></i></Button>
                            
                            </td>
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
                    </Table>
                    <CardFooter className="py-4">
                    <div>
     
     {/* <Paginationcomponent
currentPage={currentPage}
totalItems={total_page}
rowsPerPage={rowsPerPage}
rowsPerPageOptions={rowsPerPageOptions}
handlePageClick={handlePageClick}
handlePreviousClick={handlePreviousClick}
handleNextClick={handleNextClick}
handleRowsPerPageChange={handleRowsPerPageChange}
/> */}
       </div>
              </CardFooter>
                </Card>
              </Col>
             
            </Row>
             
            </div>
        </div>

       
      </Container>
    </>
  );
};
export default ManagerRejection;
