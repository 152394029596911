import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import 'assets/css/themecustom-style.css';
import { useHistory,useParams } from "react-router-dom";
const axios = require("axios");
import {usermanageService} from '../../services/usermanageService';
import {taskmanageService} from '../../services/taskmanageService';
import { ToastContainer, toast } from 'react-toastify';

const Announce = (props) => {
  const history = useHistory();
  const adddocu = () =>{
    history.push({
      pathname: 'addannounce',
    });
  }
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [data, setdata] = useState([]);
  const [filterFields,setfilterFields] = useState([]);
  const [filtertype,setfiltertype] = useState('open');

  const handlePageClick = (e, index) => {
    e.preventDefault();
    get_client_doc_list(index);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_client_doc_list(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_client_doc_list(next);
  };

  const get_list = async (pageNumber = 1,e,filterFields,filtertype='') => {
    taskmanageService.getAnnouncement(pageNumber,filterFields,filtertype).then((response) => {
        setdata(response.data.data);
    });
  };

const companyfilter = (e,index) => {
  
  const cloneValues = {...filterFields};
  console.log(filterFields)
  cloneValues[index] = {
    ...cloneValues[index],
    ['field']: e.target.name,
    ['value']: encodeURIComponent(e.target.value),
    ['operator']: '='
  };
  console.log(cloneValues)
  setfilterFields(cloneValues);
  get_client_doc_list(1,null,cloneValues,filtertype);
}
  useEffect(() => {
    get_list();
  }, []);
  const editclick = (id,index) => {
    history.push({
        pathname: 'editannounce/'+id,
      });
  }
  const handleclick = (id) => {
    taskmanageService.deleteAnnouncement(id).then((response) => {
        if (response.status == 200) {
            toast.success(response.data.message);
            location.reload();
           
          } else {
            for (const field in response.data) {
            toast.error(response.data[field][0]);
            }
          }
    });
  }
  return (
    <>
    <Header />
    <Container className="mt--7" fluid>
    <Row>
          <div className="col-3">
             <Button className="col-12 " color="primary" type="button" onClick={adddocu}>
                Add
             </Button>
          </div>
          
          
          </Row>
          <Row className="mt-5">
          <div className="col" style={{ padding: "0%" }}>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center row">
                <div className="col-xl-8 col-md-2 col-xs-12">
                  <h3 className="mb-0" style={{ fontSize: "20px" }}>
                    Announcement List
                  </h3>
                  </div>
                
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <th scope="col">Name</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col">Active</th>
                  <th scope="col">Action</th>
                  
                </thead>
                <tbody>
                  {data && data.length && data != null ? (
                    data.map((item, i) =>
                      item != undefined ? (
                        <>
                          <tr
                            className={
                              i % 2 == 0
                                ? "blue-row"
                                : i % 3 == 0
                                ? "purple-row"
                                : "orange-row"
                            }
                          >
                            <td>{item.name}</td>
                            <td>{item.start_date}</td>
                            <td>{item.end_date}</td>
                            <td>{item.status==1 ? 'Active' : 'Deactive'}</td>
                            {/* <td>{item.doc_type}</td> */}
                            <td className="whitespace">
                            <> <Button className="btn btn-success" 
                            onClick={(e) =>
                              editclick(
                                item.id,i
                              )
                            } class="btn btn-success"><i class="fa fa-edit"></i></Button>
                            <Button className="btn btn-danger" 
                            onClick={(e) =>
                              handleclick(
                                item.id,i
                              )
                            } class="btn btn-success"><i class="fa fa-trash"></i></Button>
                            </>
                            
                                </td>
                           
                          </tr>
                        </>
                      ) : (
                        <></>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
     </Container>
    </>
  );
};


export default Announce;
