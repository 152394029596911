import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { ToastContainer, toast } from 'react-toastify';
import { domainUrl} from '../../constant';
import '@flowjs/flow.js';
import Flow from "@flowjs/flow.js";
import { async } from "@firebase/util";
import { useHistory,useParams } from "react-router-dom";
import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
import { manageService } from "../../services/manageaccountservices";
import Select from 'react-select';
const axios = require("axios");

const AddClientTransitionFields = (props) => {
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [clientdata, setClientdata] = useState([]);
  const [clientsearch, setclientsearch] = useState([{'label':'Select Client',value:'al_key_1'}]);
  const [trans_data,settransData] = useState([]);
  const [formdetails,setformdetails] = useState({});
  const [taskdetailPopup,settaskdetailPopup] = useState(false);
  const [allclient, setAllclient] = useState(true);
  const regionOption = useRef(null);
  const [changeclient,setchangeclient] = useState(false);
  const [status,setstatus] = useState([]);
  const [state, setState] = useState({});


  const handlePageClick = (e, index) => {
    e.preventDefault();
    get_list(index);
  };

  const formatDate = (dateStr) => {
    if(dateStr!=null && dateStr!=''){

      const [year, month, day] = dateStr.split('-');
      let newDate = `${day}-${month}-${year}`;
      return newDate;
    }
  };
  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_list(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_list(next);
  };

  const get_list = async (pageNumber = 1,e) => { 
   
    let  textbox = '';
    if(e != null){
       textbox = e.label;
    }
    taskmanageService.getsubclientTasks(pageNumber,clientsearch,textbox).then((response) => {console.log(response.data.data.data)
      setClientdata(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setLastPageData(response.data.data.last_page);
    });
  };


useEffect(() => {
get_transition_type();
}, []);

const generateForm = (id,client_id,trans_id,index) => {
  clientmanageService.generateForm(id,client_id,trans_id).then((response) => {
    console.log(response.data)
    setformdetails(response.data);
    setState(response.data);
    console.log("f")
    console.log(formdetails)
    settaskdetailPopup(!taskdetailPopup);
  })
}
const [transitiontype, setTransitionType] = useState([]);
function get_transition_type(){
  clientmanageService.gettransitionType().then((response)=>{
    setTransitionType(response.data.data);
  });
}

  function getTransitionData(clientname=''){
    //setsubclientlist([]);
    
    
    clientmanageService.gettransitionData(clientname).then((response) => {
      
        if(response.status === 200){
          setchangeclient(true);
          settransData(response.data)
    
          
        }else {
          toast.error('Something went wrong!');
        }
    });
  }
  function getclientstatus(){
    taskmanageService.getclientstatus().then((res) => {
          
      if(res.status === 200){
        setstatus(res.data.status);
        console.log(status)
      }
  });
  }
  function setclient(e){ 
    
    getTransitionData(e.value);
    getclientstatus();
    
    
  }
const companyfilter = (e,index) => {
 get_list(1,e);
 setAllclient(false)
};
const initialStateDataRejection = {
  id: "",
  type : "",
  value : "",
  comment : "",
  errors : []
};
const initiaPhaseData = {
  phases: [],
};
const initialStateData = {
  transition_type: "",
  transition_field:'',
  procedures:"",
  scenerio:""
  
};
const [inputFields, setInputfields] = useState(initialStateData);
const [inputFieldsRejection, setInputfieldsRejection] = useState(initialStateDataRejection);
const [inputPhasesRejection, setinputPhasesRejection] = useState(initiaPhaseData);
const [showRejection,setshowRejection] = useState(false);
const [rejectionindex,setrejectionindex] = useState('');
const [errorcategory,seterrorcategory] = useState([]);
const [phaselistsselection, setPhaseListsSelection] = useState([]);
const [inputPhases, setinputPhases] = useState(initiaPhaseData);
 
const [errorbox,seterrorbox] = useState([
  0
]);

const [rowsError, setRowsError] = useState(1);

const editField = (client_id,trans_id, field, value,index) => {
  clientmanageService.updateTransData(client_id, trans_id, field,value).then((response) => {
    let temp_st = [...trans_data];
    let temp_ele = { ...temp_st[index] };
   
    if(response.status == 200){
      getTransitionData(client_id);
        toast.success('Data updated successfully');

       
    }else{
        toast.error(response.data.message);
    }
    temp_ele.editing = false;
    temp_ele.mainediting = false;
    
    
    

    //temp_st[index] = temp_ele;
   // setDashboarddata(temp_st);
    //get_dashboard_data();
  });
};
// function inputhandler(e,index,key) {
//   let temp_st = {...state};
//   let temp_ele = { ...temp_st[key][index] };
//   temp_ele.remarks = e.target.value;
//   temp_st[key][index] = temp_ele;
//   setState(temp_st);

// }
function inputhandler(e) {
    setInputfields({ ...inputFields, [e.target.name]: e.target.value });
  }

function add_phase_selection(e) {
  e.preventDefault();
  clientmanageService.updateTransitionForm(state).then((response) => {
    // console.log(response);

    if (response.status == 200) {
      toast.success(response.data.message);
      }
  });
}
const [ transitionfields, setTransitionFields ] = useState([]);
const [displaysubclient, setDisplaysubclient] = useState(false);
const [ transitionfieldsdata, setTransitionFieldsData ] = useState([]);
function showsubclientfield(){
    setDisplaysubclient(true)
  }
function setfields(e){
    clientmanageService.getTransitionFields(e.target.value).then((response) => {
        // console.log(response);
    
        if (response.status == 200) {
            console.log(response.data.data)
            setTransitionFields(response.data.data)
          }
      });
    //setTransitionFields(e.target.value)
  }
  function getFieldData(e){
    
    clientmanageService.getTransitionFieldData(e.target.value).then((response) => {
        // console.log(response);
    
        if (response.status == 200) {
            
            setTransitionFieldsData(response.data.data);
            setInputfields(response.data.data);
          }
          console.log(inputFields)
      });
  }
  function addtransitionData(e){
    e.preventDefault();
    clientmanageService.addtransitionFieldData(inputFields).then((response) => {
        // console.log(response);
        if (response.status == 200) {
            document.getElementById('addform').reset();
            toast.success(response.data.success);
            setInputfields({});
        }
          
      });
  }
  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
    
        <Row className="mt-5">
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
              <CardHeader className="border-0">
              <Form role="form" id="addform" onSubmit={addtransitionData}>
            <FormGroup>
              <Row >
               
               <div className="col-xl-4 col-md-4 col-xs-12">
               <h3 className="clientformtag"> Transition Name</h3>
                    {(transitiontype.length > 0 )?
                    <select  class="form-control" name="transition_type" onChange={(e) => { inputhandler(e); setfields(e);}} required={(user_info.role_id != 13) ? true : false } >
                    <option value=""> Select Transition</option>
                    {(transitiontype).map((valuel,k) =>
                                  <option key={k} value={valuel.id}>
                                    {valuel.name}
                                  </option>
                              )}
                    </select> 
                          // <select className="form-control" name="user_id"onChange={(e) => { setclient(e);}}   >
                          //     <option value="" key="al_key_1">All Clients</option>
                          //     {(uniquecompanieslist).map((valuel,k) =>
                          //         <option key={k} value={valuel.name}>
                          //           {valuel.name}
                          //         </option>
                          //     )}
                          // </select>
                        : null }
                    </div>
                    <div className="col-xl-4 col-md-4 col-xs-12">
                  <h3 className="clientformtag">Transition Field</h3>
                  {/* <div className="col-xl-4 col-md-12 col-xs-12" style={{ display: displaysubclient == false ? "flex" : "none" }}> */}
                              <select style={{ display: displaysubclient == false ? "flex" : "none" }} class="form-control" name="transition_field" onChange={(e) => { inputhandler(e); getFieldData(e);}} required={(displaysubclient == false) ? true : false } >
                              <option value=""> Select Transition Field</option>
                              {transitionfields.map(res => (
                                  <option
                                    key={res.id}
                                    value={res.id}
                                  >
                                    {res.field_name}
                                  </option>
                                ))}
                              </select>
                              {/* </div> */}
                              {/* <div className="col-xl-2 col-md-12 col-xs-12"> */}
                                <p  style={{ display: displaysubclient == false ? "flex" : "none" }} > OR &nbsp;<b onClick={showsubclientfield}> Add Transition Field</b></p>
                                {/* </div> */}
                              {/* <div  className="col-xl-10 col-md-12 col-xs-12"> */}
                                 <InputGroup className="input-group-alternative mb-3" style={{ display: displaysubclient == true ? "flex" : "none" }} >
                                  <Input
                                    placeholder="Transition Field Name"
                                    type="text"
                                    name="transition_field"
                                    
                                    onChange={inputhandler}
                                    required={(displaysubclient == true) ? true : false }
                                  /> 
                                </InputGroup>  
                              {/* </div> */}
                  
                </div> 
                <div className="col-xl-4 col-md-4 col-xs-12">
               <h3 className="clientformtag">Scenerio</h3>
               <Input
                         
                         bsSize="sm"
                         name="scenerio"
                         placeholder="Scenerio"
                         id="example-date-input"
                         type="textarea"
                         onChange={inputhandler}
                         defaultValue={transitionfieldsdata.scenerio ? transitionfieldsdata.scenerio : ""}
                         
                         
                       />
                    </div>
                    <div className="col-xl-4 col-md-4 col-xs-12">
               <h3 className="clientformtag">Procedures	</h3>
               <Input
                         
                         bsSize="sm"
                         name="procedures"
                         placeholder="Procedures"
                         id="example-date-input"
                         type="textarea"
                         onChange={inputhandler}
                         defaultValue={transitionfieldsdata.procedures ? transitionfieldsdata.procedures : ""}
                         

                         
                       />
                    </div>

                    
                </Row>
                <div class="row">
              <div className="col-12">
                <Button className="mt-2" color="primary" type="submit"  >
                Save
                </Button>
                
              </div>
            </div>
            </FormGroup>
            </Form>
              </CardHeader>
              
              

            </Card>
          </div>
        </Row>
        
      </Container>
    </>
  );
};


export default AddClientTransitionFields;
