import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Col,
  FormGroup 
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../../assets/css/custom.css";
import Header from "../../../components/Headers/Header.js";
import 'assets/css/themecustom-style.css';
import '@flowjs/flow.js';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import { usermanageService } from "../../../services/usermanageService";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
const axios = require("axios");

const KROCalculation = (props) => {
  const params  = useParams();
  const [trans_data,settransData] = useState([]);
  const [state, setState] = useState({});
  const [disablestate, setdisablestate] = useState(false);
  const [review_date,setReviewDate] = useState('');
  const [newbox, setNewBox] = useState(0);
  const [kroapproval, setApproval] = useState(0);
  const [kromanagerapproval, setManagerApproval] = useState(0);
  const [usersearch, setusersearch] = useState({'label':'Select User',value:'al_key_1',manager:''});
  const months = [{value:'01',label : 'Jan'},{value:'02',label :'Feb'},{value:'03',label :'March'},{value:'04',label :'April'},{value:'05',label :'May'},{value:'06',label :'June'},{value:'07',label :'July'},{value:'08',label :'Aug'},{value:'09',label :'Sept'},{value:10,label :'Oct'},{value:11,label :'Nov'},{value:12,label :'Dec'}];
const [monthdata, setmonthdata] = useState(months);

const [uniqueuserslist, setUniqueUsersData] = useState([]);
const [sendData, setSendData] = useState([])
// const [accuracydata, setAccuracyData] = useState(0);
// const [productivitydata, setProductivityData] = useState(0);
// const [ontimedelay, setOntimeDelayData] = useState(0);
// const [ontimedelayqueriessent, setOntimeDelayDataQueriesSent] = useState(0);
// const [accuracyarrdata, setAccuracyArrData] = useState([]);
// const [productivityarrdata, setProductivityArrData] = useState([]);
const [user_data,setuserData] = useState([]);

const [transModalOpen, settransModalOpen] = useState(false);
function get_unique_userslist(){
  usermanageService.getusersforchatbox().then((response)=>{
    setUniqueUsersData(response.data.users);
  });
}

const [krotypes, setKroTypes] = useState([]);
function getkrotypes(){
  usermanageService.getkrotypes().then((response)=>{
    setKroTypes(response.data.data);
  });
}

const user_info = JSON.parse(localStorage.getItem("user-info"));
let user_id = user_info.id;
  if(params.user_id!='undefined' && params.user_id!=undefined){
    user_id = params.user_id;
  }
  let month_year ='';
  if(params.month_year!='undefined' && params.month_year!=undefined){
    month_year = params.month_year;
  }
const initialData = {
  user_id : user_id,
  month_year : month_year
}
const [inputFields, setInputfields] = useState(initialData);

function showKROCalculation(sync=0){
  settransData([])
  console.log(inputFields)
  
    usermanageService.showKROCalculation(inputFields,sync).then((response) => {
     
        if(response.status === 200){
       if(inputFields.user_id != ''){
        setusersearch({'label':response.data.user.name, value : response.data.user.id,manager : response.data.user.manager})
       }
          settransData(response.data.data)
          // setAccuracyData(response.data.accuracy)
          // setProductivityData(response.data.productivity)
          // setOntimeDelayData(response.data.on_time_delivery)
          // setOntimeDelayDataQueriesSent(response.data.on_time_delivery_queries_sent)
          setSendData(response.data.send_data)
          setReviewDate(response.data.review_date)
          setApproval(response.data.approval)
          setManagerApproval(response.data.manager_approval)
          setState(response.data.data)      
          setNewBox(response.data.data.length + 1)
          setuserData(response.data.user)
          setAttrition(response.data.user.attrition)
          // setAccuracyArrData(response.data.accuracy_arr)
          // setProductivityArrData(response.data.productivity_arr)
        }else {
          toast.error(response.data.message);
        }
    });
}
 function inputhandler1(e,field) {
  if(field=='month_year'){
    setInputfields((prevInputFields)=>({ ...prevInputFields, [field]: e.target.value } ));
  }else{
  setInputfields((prevInputFields) => ({
    ...prevInputFields,
    [field]: e.value,
  }));
  setusersearch({'label':e.label,value:e.value,manager:""})
  }

}
function inputhandler(e,index,type_id=null) {
 
  let temp_st = { ...state };
  let temp_ele = temp_st[index] ? { ...temp_st[index] } : {}; 
  
  if (e.target.name === 'rating') {
    temp_ele.rating = e.target.value;
  }
  
  temp_ele[e.target.name] = e.target.value;
  temp_ele.user_id = inputFields.user_id;

  if (temp_ele.unit == 'char' && e.target.value !== 'Y' && e.target.value !== 'N') {
    toast.error("Input must be 'Y' or 'N'");
    return;
}

  if(e.target.name == 'actual_target') {
    // calculate rating
    usermanageService.calculateRating(e.target.value,type_id).then((response) => {
      console.log(response.data.rating)
      let temp_trans_dta = [ ...trans_data ];
      let temp_trans_ele = { ...temp_trans_dta[index] }; 
        temp_trans_ele.rating = response.data.rating;
        temp_trans_ele.weighted_score = (response.data.rating * temp_trans_ele.weightage)/100;
        temp_trans_dta[index] = temp_trans_ele;

        console.log(temp_trans_dta)
        settransData(temp_trans_dta)

        temp_ele.rating = response.data.rating;
        temp_ele.weighted_score = (response.data.rating * temp_trans_ele.weightage)/100;
    });
  }
  
  if (temp_st[index]) {
    temp_st[index] = temp_ele;
  }
  console.log(temp_st)
  setState(temp_st);
 

}

function addsopform(e) {
  console.log(e);
  setdisablestate(true)
  e.preventDefault();
  usermanageService.updateUserKroCalculationForm(state,inputFields.month_year,attrition).then((response) => {
    if (response.status == 200) {
      toast.success(response.data.message);
      // location.reload();
    }
    setdisablestate(false)
  });
}

 
const addNewbox = ()=>{
  const setdata = newbox+1;
  setNewBox(setdata)
 
}
const approveKRO = () => {
  setdisablestate(true)
  usermanageService.approveKRO(inputFields).then((response) => {
    if (response.status == 200) {
      toast.success(response.data.message);
      setApproval(1)
    }
    setdisablestate(false)
  });
}

useEffect(() => {
  get_unique_userslist();
  getkrotypes();
  showKROCalculation()
}, []);

let totalFinalweightageValue = 0;
let totalFinalweightagescoreValue = 0;
const handleDownload = () => {

  const unit = "pt";
const size = "A4"; // Use A1, A2, A3 or A4
const orientation = "portrait"; // portrait or landscape

const marginLeft = 40;
const doc = new jsPDF(orientation, unit, size);
const pageWidth = doc.internal.pageSize.getWidth();
doc.setFontSize(15);

const title = "KRO";
const headers = [["Particulars", "Achieved","Rating","Weightage","Weighted Score"]];
const data1 = trans_data.map(item => [ item.type, item.actual_target, item.rating,item.weightage,item.weighted_score]);
const data = [["Employee Name",user_data.name],["Employee Email id",user_data.email],["Name of the Reviewer",user_data.manager_name],["Month",inputFields.month_year],["Review Date",review_date]];
const totalWidth = pageWidth - 80; // Subtracting 20 units for margins or padding

const column1Width = totalWidth * 0.4;
const column2Width = (totalWidth - column1Width);
let totalFinalweightageValue = trans_data.reduce((sum, item) => sum + parseFloat(item.weightage), 0);
let totalFinalweightagescoreValue = trans_data.reduce((sum, item) => sum + ((item.weighted_score) ? parseFloat(item.weighted_score) : 0), 0) ;
const ratinglabel = (totalFinalweightagescoreValue.toFixed(2) <= 1 ? "Needs Action" : (totalFinalweightagescoreValue.toFixed(2) <= 2.5 ? "Below Expectations" : (totalFinalweightagescoreValue.toFixed(2) <= 3.5 ? "Meets Expectations" : (totalFinalweightagescoreValue.toFixed(2) <= 4.5 ? "Exceed Expectation" : (totalFinalweightagescoreValue.toFixed(2) <= 5 ? "Exceptional" : "")) )));

// Function to calculate the height of the table based on the number of rows
function calculateTableHeight(content) {
  // Assuming each row has a fixed height, you can calculate the total height
  // by multiplying the number of rows by the height of each row
  const rowHeight = 10; // Adjust this value based on your actual row height
  const numRows = content.body.length; // Assuming body is an array of rows
  const totalHeight = numRows * rowHeight;

  return totalHeight;
}

const content = {
  theme: 'grid',
  startY: 50,
  body: data,
  columnStyles: {
    0: {
      cellWidth: column1Width,
    },
    1: {
      cellWidth: column2Width,
    }
  },

  bodyStyles: {
    cellPadding: 2, // Set cell padding
    lineWidth: 'bold',
    lineWidth: 0.5, // Set border line width for body
    lineColor: [0, 0, 0], // Set border line color for body
    fontStyle: 'bold'
  },

  showFoot: "lastPage", // Show footer on last page only
  margin: { top: 10 },
};


doc.setFontSize(16);
const fontFamily = 'Helvetica'; 
const fontWeight = 'bold'; 
const letterspacing = 2; 
doc.setFont(fontFamily, fontWeight);
doc.text(title, (pageWidth / 2), 35, 'center');
doc.setFontSize(13);
doc.setTextColor(0, 0, 0);
const fontFamilyo = 'Courier'; 
const fontWeighto = 'normal'; 
doc.setFont(fontFamilyo, fontWeight);
doc.autoTable(content);
let height = 0;

let currentHeight = 130; // Initial height for the first page
//currentHeight = height + 10;
    let content1 = {
      theme: 'grid',
      startY: currentHeight,
      margin: { bottom: 0 },
     head: headers,
       body: data1,
       foot: [
        ['Total','','', totalFinalweightageValue.toFixed(2)+' %',totalFinalweightagescoreValue.toFixed(2)] ,
        [ 'Average Rating' ,  { colSpan: 4, content: ratinglabel }] 
    ],
      headStyles : {fillColor : [115, 147, 179],textColor: [0, 0, 0],borderColor: [0, 0, 0],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0]},
      bodyStyles: { 
        cell: { 
          cellHeight: 18, 
          
        },
        lineWidth: 'bold',
        lineWidth: 0.5, 
        lineColor: [0, 0, 0], 
      },
      
      footStyles :{textColor: [0, 0, 0],fillColor : [255,255,255],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0],fontWeight: 'bold'},

    };
    let table1Height = calculateTableHeight(content1);
    let startYForSecondTable = content1.startY + table1Height + 100;
    doc.autoTable(content1);
   
    var tableHeader = [
      [
        { colSpan: 2, content: 'KRO Targets - Productivity', styles: { halign: 'center' } }
      ],
      [
        'Target', 'Rating'
      ]
    ];
    const data2 = [['Upto 75%','Needs Action'],['>75% to 90%','Below Expectation'],['>90% to 100%','Meet Expectation'],['>100% to 115%','Exceed Expectation'],['>115%','Exceptional']]
    let content2 = {
      theme: 'grid',
      // startY: startYForSecondTable,
      head: tableHeader,
      tableWidth: (pageWidth / 2),
      body: data2,
      headStyles : {fillColor : [255, 255, 255],textColor: [0, 0, 0],borderColor: [0, 0, 0],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0]},
      bodyStyles: { 
        cell: { 
          cellHeight: 18, 
          
        },
        lineWidth: 'bold',
        lineWidth: 0.5, 
        lineColor: [0, 0, 0], 
      },
      //footStyles : {fillColor : [255,255,255]},
      footStyles :{textColor: [0, 0, 0],fillColor : [255,255,255],lineWidth: 0.2, lineColor: [220,220,220],borderColor: [0, 0, 0],fontWeight: 'normal'},
      
    };

    const tableHeader3 = [
      [
        { colSpan: 3, content: 'Accuracy Sources', styles: { halign: 'center' } }
      ],
      [
        'Score - Associates/ Doers', 'Rating','Rating'
      ]
    ];
    
    const data3 = [['0 errors per job','Exceptional','5'],['Less than or equal to 1 error per job','Exceed Expectation','4'],['Less than or equal to 2 error per job','Meet Expectation','3'],['Less than or equal to 3 error per job','Below Expectation','2'],['More than 3 errors','Needs Action','1']]
    let table1Height2 = calculateTableHeight(content2);
    let startYForSecondTable2 = startYForSecondTable + table1Height2 + 112;
    let content3 = {
      theme: 'grid',
      // startY: startYForSecondTable2,
      head: tableHeader3,
      tableWidth: 500,
      body: data3,
      headStyles : {fillColor : [255, 255, 255],textColor: [0, 0, 0],borderColor: [0, 0, 0],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0]},
      bodyStyles: { 
        cell: { 
          cellHeight: 18, 
          
        },
        lineWidth: 'bold',
        lineWidth: 0.5, 
        lineColor: [0, 0, 0], 
      },
      foot: [
        [ { colSpan: 3, content: 'If the error rate is low and severity is high, it will be considered as rating 1'}] ,
        [ { colSpan: 3, content: 'If there are 4 or more than 4 external errors in a single job, Accuracy rating will be 1' } ] 
    ],
      footStyles :{fillColor : [255, 255, 255],textColor: [0, 0, 0],borderColor: [0, 0, 0],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0]},
      
    };
    //doc.autoTable(content3);
  

    if(trans_data && trans_data.length && trans_data != null){
      trans_data.map((item, i)=>{
        if(item.type_id ==1){
          doc.autoTable(content2);
        }
        if(item.type_id ==2){
          doc.autoTable(content3);
        }
      })
    }
        //table 5
        const tableHeader5 = [
          [
            { colSpan: 3, content: 'Retention', styles: { halign: 'center' } }
          ],
          [
            'Attrition %', 'Rating','Rating'
          ]
        ];
        const data5 = [['below 20 %','Exceptional','5'],['20% to 25%','Exceed Expectation','4'],['25% to 30%','Meet Expectation','3'],['30% to 40%','Below Expectation','2'],['More than 40%','Needs Action','1']]
        // let startYForSecondTable5 = startYForSecondTable + table1Height2 + 112;
        let content5 = {
          theme: 'grid',
          
          // startY: top_height1 + 20,
          head: tableHeader5,
          tableWidth: (pageWidth / 2),
          body: data5,
          headStyles : {fillColor : [255, 255, 255],textColor: [0, 0, 0],borderColor: [0, 0, 0],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0]},
          bodyStyles: { 
            cell: { 
              cellHeight: 16, 
              
            },
            lineWidth: 'bold',
            lineWidth: 0.5, 
            lineColor: [0, 0, 0], 
          },
          //footStyles : {fillColor : [255,255,255]},
          footStyles :{textColor: [0, 0, 0],fillColor : [255,255,255],lineWidth: 0.2, lineColor: [220,220,220],borderColor: [0, 0, 0],fontWeight: 'normal'},
          
        };
        doc.autoTable(content5);
        //table 5 end
        //table 6
        const tableHeader6 = [
          [
            { colSpan: 3, content: 'Accuracy Sources - Best practices', styles: { halign: 'center' } }
          ],
          [
            'Score - TLs', 'Rating','Rating'
          ]
        ];
        const data6 = [['0 errors','Exceptional','5'],['Less than or equal to 2 errors','Exceed Expectation','4'],['Less than or equal to 3 errors	','Meet Expectation','3'],['Less than or equal to 4 errors','Below Expectation','2'],['More than 4 errors','Needs Action','1']]
        // let startYForSecondTable6 = startYForSecondTable + table1Height2 + 212;
        let content6 = {
          theme: 'grid',
          
          // startY: top_height1 + 20,
          head: tableHeader6,
          tableWidth: (pageWidth / 2),
          body: data5,
          headStyles : {fillColor : [255, 255, 255],textColor: [0, 0, 0],borderColor: [0, 0, 0],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0]},
          bodyStyles: { 
            cell: { 
              cellHeight: 16, 
              
            },
            lineWidth: 'bold',
            lineWidth: 0.5, 
            lineColor: [0, 0, 0], 
          },
          //footStyles : {fillColor : [255,255,255]},
          footStyles :{textColor: [0, 0, 0],fillColor : [255,255,255],lineWidth: 0.2, lineColor: [220,220,220],borderColor: [0, 0, 0],fontWeight: 'normal'},
          
        };
        doc.autoTable(content6);
        //table 6 end
    const tableHeader4 = [
      [
        { colSpan: 2, content: 'Final Rating', styles: { halign: 'center' } }
      ],
      [
        'Target', 'Rating'
      ]
    ];
    const data4 = [['Less than or equal to 1','Needs Action'],['Less than or equal to 2.5','Below Expectation'],['Less than or equal to 3.5','Meet Expectation'],['Less than or equal to 4.5','Exceed Expectation'],['Less than or equal to 5','Exceptional']]
    
    let startYForSecondTable3 = startYForSecondTable + table1Height2 + 112;
    let content4 = {
      theme: 'grid',
      
      // startY: top_height1 + 20,
      head: tableHeader4,
      tableWidth: (pageWidth / 2),
      body: data4,
      headStyles : {fillColor : [255, 255, 255],textColor: [0, 0, 0],borderColor: [0, 0, 0],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0]},
      bodyStyles: { 
        cell: { 
          cellHeight: 16, 
          
        },
        lineWidth: 'bold',
        lineWidth: 0.5, 
        lineColor: [0, 0, 0], 
      },
      //footStyles : {fillColor : [255,255,255]},
      footStyles :{textColor: [0, 0, 0],fillColor : [255,255,255],lineWidth: 0.2, lineColor: [220,220,220],borderColor: [0, 0, 0],fontWeight: 'normal'},
      
    };
    doc.autoTable(content4);
   doc.save("billingreport.pdf");
 
};
const [defaultType, setdefaultType] = useState(0);
function showTransCalculation(type){
  setdefaultType(type)
  settransModalOpen(!transModalOpen)
}
const [attrition, setAttrition] = useState(0);
const saveAttrition = (e) => {
  usermanageService.saveAttrition(e.target.value,usersearch.value).then((response) => {
    if (response.status == 200) {
      toast.success(response.data.message);
      showKROCalculation();
    }
  });
}
  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
    
        <Row className="mt-12" >
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
            
              <CardHeader className="border-0">
              <Row className="filter" style={{padding:'0 1%'}}>
                   <div className="col-xl-4 col-md-3 col-xs-12">
                    <h3 className="clientformtag"> <b> User :</b>  {usersearch.label}</h3>
                   {(uniqueuserslist.length > 0) ? 
                   <Select 
                   
                   className="input-group-alternative mb-3" 
                   name="user_id"
                   options={uniqueuserslist} // set list of the data
                   onChange={(e) => inputhandler1(e,'user_id')}
                   value={usersearch}
                   searchable
                   isClearable={false}
                 />
                 : null }
                  </div>
                  <div className="col-xl-4 col-md-3 col-xs-12">
                    <h3 className="clientformtag"> Select month</h3>
                    <input type="month"
                                      className="form-control"
                                      name="month_year"
                                      onChange={(e) => { inputhandler1(e,'month_year')}}
                                      value={inputFields.month_year}
                                      options={monthdata}
                                      required
                                      />
                                       {inputFields.month_year && <div style={{ marginTop: 20, lineHeight: '25px' }}></div>}
                      
                                  </div>
                  
                  
                    <div className="col-xl-4 col-md-3 col-xs-12">
                      <h3></h3><br></br>
                      <Button className="col-xl-4 btn btn-primary"  onClick={(e) => showKROCalculation(e)}>Show</Button>
                    </div>
                    {/* </div> */}
                </Row>
              </CardHeader>
             


            </Card>
          </div>
        </Row>
        <Row className="mt-12" >
        
        <Card className="shadow"  style={{width:"100%"}}>
          <div>
          <Button
            className="dash-button"
            color="danger"
            type="button"
            
            onClick={() => handleDownload(0)}
            
          >
            Export to PDF
          </Button>
          <span
              color="primary"
              style={{
                color:
                kroapproval == "1"
                    ? "#02C779"
                    : "#FF1717",
                    textAlign: "center",
              }}
            >{(kroapproval== 1) ? 'Discussed & Approved by Reviewer ' : (kroapproval== 2) ? " Rejected By Reviewer" : "  "}</span>  
<br></br>
            <span
              color="primary"
              style={{
                color:
                kromanagerapproval == "1"
                    ? "#02C779"
                    : "#FF1717",
                    textAlign: "center",
              }}
            >{(kromanagerapproval== 1) ? 'Discussed & Approved by Manager' : (kromanagerapproval== 2) ? "Rejected By Manager" : "  "}</span>  
          {(kromanagerapproval == 2 || kroapproval == 2) ?
          <Button
            className="dash-button"
            color="danger"
            type="button"
            
            onClick={() => showKROCalculation(1)}
            
          >
            Sync Data
          </Button> : null }
          </div>
          <br/>
          <div>
          <Col className="col-md-4">
              <FormGroup>
                <label>Client Attrition</label>
                <select className="form-control"  name="client_attrition" value={attrition}  onChange={(e) => saveAttrition(e)}>

                          <option value="1">YES</option>
                          <option value="0">NO</option>
                </select>
              </FormGroup>
          </Col>
          </div><br/>
            <Form
                role="form"
                id="phase-selection-form"
                onSubmit={addsopform}
            
              >
              <Table  className="align-items-center table-flush" responsive>
              <thead>
                <tr style={{ border: "1px solid #000" }}>
                  <th style={{ border: "1px solid #000" }}>Sr.No.</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">Particulars</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">Achieved</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">Rating</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">% Weightage</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">Weighted score</th>
                </tr>
              </thead>
                <tbody>
                {trans_data && trans_data.length && trans_data != null ? (
                  <>
                    { trans_data.map((item, i) => {
                      totalFinalweightageValue += parseFloat(item.weightage);
                      totalFinalweightagescoreValue += ((item.weighted_score) ? parseFloat(item.weighted_score) : 0);
                      return (
                 
                  <tr className="whitespace" style={{border : "1px solid #000"}}>  
                    <td className="whitespace" style={{border : "1px solid #000"}}>{i+1}</td>
                    <td className="whitespace" style={{border : "1px solid #000"}}>
                      {item.type}
                    </td>
                    <td className="whitespace" style={{border : "1px solid #000"}}>
                    <Input
                            bsSize="sm"
                            name="actual_target"
                            placeholder="Achieved"
                            id="example-date-input"
                            type={(item.unit == 'char') ? "text" : "number"}
                            min="0"
                            readOnly="true"
                            maxLength={(item.unit == 'char') ? 1 : null} 
                            defaultValue={(item.actual_target) ? item.actual_target : (item.type_id==2) ? sendData.accuracy : (item.type_id == 1) ? sendData.productivity : (item.type_id==5) ? sendData.team_average : (item.type_id==6) ? sendData.retention : (item.type_id==4) ? sendData.bestpractices : (item.type_id == 7) ? sendData.on_time_delivery : null}
                            onChange={(e) => inputhandler(e, i,item.type_id)}
                            onClick={(e) => showTransCalculation(item.type_id)}
                        />
                    </td>
                    <td className="whitespace" style={{border : "1px solid #000"}}>
                    <Input
                            bsSize="sm"
                            name="rating"
                            placeholder="Rating"
                            id="example-date-input"
                            type="number"
                            readOnly
                            value={(item.rating) ? item.rating : ''}
                            onChange={(e) => inputhandler(e, i)}
                        />
                    </td>
                    <td className="whitespace" style={{border : "1px solid #000"}}>
                    <Input
                            bsSize="sm"
                            name="weightage"
                            placeholder="weightage"
                            id="example-date-input"
                            type="number"
                            readOnly
                            value={(item.weightage) ? item.weightage : ''}
                            onChange={(e) => inputhandler(e, i)}
                        />
                    </td>
                    <td className="whitespace" style={{border : "1px solid #000"}}>
                    <Input
                            bsSize="sm"
                            name="weighted_score"
                            placeholder="weightage score"
                            id="example-date-input"
                            type="number"
                            readOnly
                            value={(item.weighted_score) ? item.weighted_score :''}
                            onChange={(e) => inputhandler(e, i)}
                        />
                    </td>
                  </tr>
                 
                )
                    }
            )}
          </>
            ) : (
            null
            )}
          {trans_data.length > 0 ? 
                <>
                            <tr className="whitespace" style={{border : "1px solid #000"}}>
                              <td  className="whitespace" style={{border : "1px solid #000"}}></td>
                              <td  className="whitespace" style={{border : "1px solid #000"}}><b>Total</b></td>
                              <td  className="whitespace" style={{border : "1px solid #000"}} colSpan="2"></td>
                              <td  className="whitespace" style={{border : "1px solid #000"}}> <b>{totalFinalweightageValue.toFixed(2)} %</b></td>
                              <td  className="whitespace" style={{border : "1px solid #000"}}> <b>{totalFinalweightagescoreValue.toFixed(2)}</b></td>
                            </tr>
                            <tr className="whitespace" style={{border : "1px solid #000"}}>
                              <td  className="whitespace" style={{border : "1px solid #000"}}></td>
                              <td  className="whitespace" style={{border : "1px solid #000"}}><b>Average Rating</b></td>
                              <td  align="center" className="whitespace" style={{border : "1px solid #000"}} colSpan="4"><b>{(attrition==1) ? '1' : (totalFinalweightagescoreValue.toFixed(2) <= 1 ? "Needs Action" : (totalFinalweightagescoreValue.toFixed(2) <= 2.5 ? "Below Expectations" : (totalFinalweightagescoreValue.toFixed(2) <= 3.5 ? "Meets Expectations" : (totalFinalweightagescoreValue.toFixed(2) <= 4.5 ? "Exceed Expectation" : (totalFinalweightagescoreValue.toFixed(2) <= 5 ? "Exceptional" : "")) )))}</b></td>
                            </tr>
                            </>
                  : null }
            </tbody>
              </Table>
              </Form>
            <br></br>
              
                <div class="d-flex">
               {(kroapproval != 1 || kromanagerapproval == 2) ? 
            <Button color="primary" className="col-xl-2" style={{flex:"0 0 4.66667%"}} type="button" onClick={addsopform} disabled={disablestate}>
            
            {disablestate ? 'Sending...' : 'Save'} 
              </Button>  : null }
              {/* {(kroapproval != 0) ? 
              (kroapproval == 1) ? <Button
                                  color="primary"
                                  style={{
                                    background: "#02C779",
                                  border: "#02C779",
                                  }}
                                >R. Approved</Button> :
                                (usersearch.manager == user_info.id) ?
              <Button
                                color="primary"
                                className="col-xl-1"
                                style={{
                                  background: "#21c0f3",
                                  border: "#21c0f3",
                                }}
                                size="sm"
                                type="button"
                                onClick={(e) =>
                                  approveKRO()
                                }
                              >
                               Click to Approve
                              </Button>  : null 
                              : null } */}
              </div>
              <br></br>
              <Row>
              {trans_data && trans_data.length && trans_data != null ? (
                    trans_data.map((item, i) => 
                    (item.type_id == 1) ? ( // for productivty table 
             
              <Table className="align-items-center table-flush col-xl-3" style={{marginRight:"20px"}}>
                <thead style={{ border: "1px solid #000" }} className="thead-light">
                  <th colSpan={2} style={{ border: "1px solid #000",textAlign:"center" }} scope="col">KRO Targets - Productivity</th>
                </thead>
                <thead style={{ border: "1px solid #000" }} className="thead-light">
                  <th style={{ border: "1px solid #000" }} scope="col">Target</th>
                  <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>Upto 75%</td>
                    <td style={{ border: "1px solid #000" }}>Needs Action</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>{'>'}75% to 90%</td>
                    <td style={{ border: "1px solid #000" }}>Below Expectation</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>{'>'}90% to 100%</td>
                    <td style={{ border: "1px solid #000" }}>Meet Expectation</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>{'>'}100% to 115%</td>
                    <td style={{ border: "1px solid #000" }}>Exceed Expectation</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>{'>'}115%</td>
                    <td style={{ border: "1px solid #000" }}>Exceptional</td>
                  </tr>
                </tbody>
              </Table>
              ) :   (item.type_id == 2) ? ( // for productivty table 
              <Table className="align-items-center table-flush col-xl-4 " style={{marginRight:"20px"}}>
                <thead style={{ border: "1px solid #000" }} className="thead-light">
                  <th colSpan={3} style={{ border: "1px solid #000",textAlign:"center" }} scope="col">Accuracy Sources </th>
                </thead>	 	 
                <thead style={{ border: "1px solid #000" }} className="thead-light">
                  <th style={{ border: "1px solid #000" }} scope="col">Score - Associates/ Doers</th>
                  <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
                  <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>0 errors per job</td>
                    <td style={{ border: "1px solid #000" }}>Exceptional</td>
                    <td style={{ border: "1px solid #000" }}>5</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>Less than or equal to 1 error per job</td>
                    <td style={{ border: "1px solid #000" }}>Exceed Expectation</td>
                    <td style={{ border: "1px solid #000" }}>4</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>Less than or equal to 2 error per job</td>
                    <td style={{ border: "1px solid #000" }}>Meet Expectation</td>
                    <td style={{ border: "1px solid #000" }}>3</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>Less than or equal to 3 error per job</td>
                    <td style={{ border: "1px solid #000" }}>Below Expectation</td>
                    <td style={{ border: "1px solid #000" }}>2</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>More than 3 errors</td>
                    <td style={{ border: "1px solid #000" }}>Needs Action</td>
                    <td style={{ border: "1px solid #000" }}>1</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000",background:"#fff9b0" }} colspan={3}>If the error rate is low and severity is high, it will be considered as rating 1	 	</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000",background:"#fff9b0"  }} colspan={3}>If there are 4 or more than 4 external errors in a single job, Accuracy rating will be 1	 	</td>
                  </tr>
                </tbody>
              </Table>
              )
              :   (item.type_id == 4) ? ( // for best practices table 
              <Table className="align-items-center table-flush col-xl-4 " style={{marginRight:"20px"}}>
                <thead style={{ border: "1px solid #000" }} className="thead-light">
                  <th colSpan={3} style={{ border: "1px solid #000",textAlign:"center" }} scope="col">Accuracy Sources - Best practices </th>
                </thead>	 	 
                <thead style={{ border: "1px solid #000" }} className="thead-light">
                  <th style={{ border: "1px solid #000" }} scope="col">Score - TLs</th>
                  <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
                  <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>0 errors</td>
                    <td style={{ border: "1px solid #000" }}>Exceptional</td>
                    <td style={{ border: "1px solid #000" }}>5</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>Less than or equal to 2 errors</td>
                    <td style={{ border: "1px solid #000" }}>Exceed Expectation</td>
                    <td style={{ border: "1px solid #000" }}>4</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>Less than or equal to 3 errors</td>
                    <td style={{ border: "1px solid #000" }}>Meet Expectation</td>
                    <td style={{ border: "1px solid #000" }}>3</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>Less than or equal to 4 errors</td>
                    <td style={{ border: "1px solid #000" }}>Below Expectation</td>
                    <td style={{ border: "1px solid #000" }}>2</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>More than 4 errors</td>
                    <td style={{ border: "1px solid #000" }}>Needs Action</td>
                    <td style={{ border: "1px solid #000" }}>1</td>
                  </tr>
                 
                </tbody>
              </Table>
              )
              :   (item.type_id == 6) ? ( // for retention table 
              <Table className="align-items-center table-flush col-xl-4 " style={{marginRight:"20px"}}>
                <thead style={{ border: "1px solid #000" }} className="thead-light">
                  <th colSpan={3} style={{ border: "1px solid #000",textAlign:"center" }} scope="col">Retention </th>
                </thead>	 	 
                <thead style={{ border: "1px solid #000" }} className="thead-light">
                  <th style={{ border: "1px solid #000" }} scope="col">Attrition %</th>
                  <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
                  <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>below 20 %</td>
                    <td style={{ border: "1px solid #000" }}>Exceptional</td>
                    <td style={{ border: "1px solid #000" }}>5</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>20% to 25%</td>
                    <td style={{ border: "1px solid #000" }}>Exceed Expectation</td>
                    <td style={{ border: "1px solid #000" }}>4</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>25% to 30%</td>
                    <td style={{ border: "1px solid #000" }}>Meet Expectation</td>
                    <td style={{ border: "1px solid #000" }}>3</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>30% to 40%</td>
                    <td style={{ border: "1px solid #000" }}>Below Expectation</td>
                    <td style={{ border: "1px solid #000" }}>2</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>More than 40%</td>
                    <td style={{ border: "1px solid #000" }}>Needs Action</td>
                    <td style={{ border: "1px solid #000" }}>1</td>
                  </tr>
                </tbody>
              </Table>
              )
              : (item.type_id == 7) ? ( // for ontime delivery
              <Table className="align-items-center table-flush col-xl-4 " style={{marginRight:"20px"}}>
                <thead style={{ border: "1px solid #000" }} className="thead-light">
                  <th colSpan={3} style={{ border: "1px solid #000",textAlign:"center" }} scope="col">On Time delivery</th>
                </thead>	 	 
                <thead style={{ border: "1px solid #000" }} className="thead-light">
                  <th style={{ border: "1px solid #000" }} scope="col">No of jobs delayed</th>
                  <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
                  <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>0</td>
                    <td style={{ border: "1px solid #000" }}>Exceptional</td>
                    <td style={{ border: "1px solid #000" }}>5</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>1</td>
                    <td style={{ border: "1px solid #000" }}>Exceed Expectation</td>
                    <td style={{ border: "1px solid #000" }}>4</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>2</td>
                    <td style={{ border: "1px solid #000" }}>Meet Expectation</td>
                    <td style={{ border: "1px solid #000" }}>3</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>3</td>
                    <td style={{ border: "1px solid #000" }}>Below Expectation</td>
                    <td style={{ border: "1px solid #000" }}>2</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>4 and above</td>
                    <td style={{ border: "1px solid #000" }}>Needs Action</td>
                    <td style={{ border: "1px solid #000" }}>1</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000",background:"#fff9b0" }} colspan={3}>If the error rate is low and severity is high, it will be considered as rating 1	 	</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000",background:"#fff9b0"  }} colspan={3}>If there are 4 or more than 4 external errors in a single job, Accuracy rating will be 1	 	</td>
                  </tr>
                </tbody>
              </Table>
              )  : null )) : null }
               <Table className="align-items-center table-flush col-xl-3" >
                <thead style={{ border: "1px solid #000" }} className="thead-light">
                  <th colSpan={2} style={{ border: "1px solid #000",textAlign:"center" }} scope="col">Final Rating</th>
                </thead>
                <thead style={{ border: "1px solid #000" }} className="thead-light">
                  <th style={{ border: "1px solid #000" }} scope="col">Target</th>
                  <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>Less than or equal to 1</td>
                    <td style={{ border: "1px solid #000" }}>Needs Action</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>Less than or equal to 2.5</td>
                    <td style={{ border: "1px solid #000" }}>Below Expectation</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>Less than or equal to 3.5</td>
                    <td style={{ border: "1px solid #000" }}>Meet Expectation</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>Less than or equal to 4.5</td>
                    <td style={{ border: "1px solid #000" }}>Exceed Expectation</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>Less than or equal to 5</td>
                    <td style={{ border: "1px solid #000" }}>Exceptional</td>
                  </tr>
                </tbody>
              </Table>
              </Row> 
              </Card>
              </Row>
          <Modal  isOpen={transModalOpen} size="lg" scrollable={true}>
            <div className=" modal-header" style={{padding:"13px 1rem 1px 1rem"}}>
              <h5 className=" modal-title" id="exampleModalLabel">
              Details
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => settransModalOpen(!transModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{padding:"2px"}}>
              <Table className="align-items-center table-flush table">
                <thead>
                  {(defaultType == 1) ?
                  <tr>
                    <th><b>Target hours</b></th>
                    <th><b>Approved timesheet days</b></th>
                    <th><b>Idle hours</b></th>
                    <th><b>Total worked hours</b></th>
                    <th><b>Final productivity</b></th>
                  </tr>
                  : null }
                  {
                  (defaultType == 2 && (user_data.role_id == 5 || user_data.role_id == 7)) ?
                  <tr>
                    <th><b>Processed Count</b></th>
                    <th><b>Errors</b></th>
                    <th><b>Accuracy Percentage</b></th>
                  </tr>
                  :
                  (defaultType == 2) ?
                  <tr>
                    <th><b>Internal Errors</b></th>
                    <th><b>External Errors</b></th>
                    <th><b>Completed Jobs</b></th>
                    <th><b>Accuracy</b></th>
                  </tr>
                  : null }
                  {(defaultType == 4) ?
                  <tr>
                    <th><b>Errors</b></th>
                  </tr>
                  : null }
                  {(defaultType == 5) ?
                  <tr>
                    <th><b>User</b></th>
                    <th><b>Rating</b></th>
                  </tr>
                  : null }
                  {(defaultType == 6) ?
                  <tr>
                    <th><b>No. Of exits</b></th>
                    <th><b>Opening Team size</b></th>
                    <th><b>Closing Team size</b></th>
                    <th><b>Average Team size</b></th>
                    <th><b>Retention</b></th>
                  </tr>
                  : null }
                   {(defaultType == 7) ?
                  <tr>
                    <th><b>Total Queries sent jobs</b></th>
                    <th><b>Delay in delivery</b></th>
                  </tr>
                  : null }
                </thead>
                <tbody>
                {(defaultType == 1) ?
                  <tr>
                    <td>{sendData.productivity_arr.target}</td>
                    <td>{sendData.productivity_arr.timesheet_count}</td>
                    <td>{sendData.productivity_arr.ideal_hours}</td>
                    <td>{sendData.productivity_arr.totalworkedhrs}</td>
                    <td>{sendData.productivity_arr.final_productivity}</td>
                  </tr>
                : null }
                {(defaultType == 2 && (user_data.role_id == 5 || user_data.role_id == 7)) ?
                  <tr>
                    <td>{sendData.accuracy_arr.processed_count}</td>
                    <td>{sendData.accuracy_arr.errors}</td>
                    <td>{sendData.accuracy_arr.accuracy_per}</td>
                  </tr>
                : (defaultType == 2) ?
                <tr>
                  <td>{sendData.accuracy_arr.internal}</td>
                  <td>{sendData.accuracy_arr.external}</td>
                  <td>{sendData.accuracy_arr.completed_count}</td>
                  <td>{sendData.accuracy_arr.total}</td>
                </tr>
              : null }
                {(defaultType == 4) ?
                  <tr>
                    <td>{sendData.bestpractices}</td>
                  </tr>
                : null }
                {(defaultType == 5 && sendData.team_arr_data && sendData.team_arr_data.length && sendData.team_arr_data != null) ?
                  sendData.team_arr_data.map((item, i) =>
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.weighted_score}</td>
                  </tr>
                  )
                : null }
                {(defaultType == 6) ?
                  <tr>
                    <td>{sendData.retention_arr.no_of_exits}</td>
                    <td>{sendData.retention_arr.opening_team_size}</td>
                    <td>{sendData.retention_arr.closing_team_size}</td>
                    <td>{sendData.retention_arr.average_team_size}</td>
                    <td>{sendData.retention_arr.count_retention}</td>
                  </tr>
                : null }
                {(defaultType == 7) ?
                  <tr>
                  <td>{sendData.on_time_delivery_queries_sent}</td>
                    <td>{sendData.on_time_delivery}</td>
                  </tr>
                : null }
                </tbody>
              </Table>
            </ModalBody>
           
          </Modal>
      </Container>
    </>
  );
};

 
export default KROCalculation;
