import { useState, useEffect,useRef } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
  useParams
} from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from "reactstrap";
import { toast } from 'react-toastify';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { userService } from "../../services/authentication";
import { usermanageService } from "../../services/usermanageService";
import { clientmanageService } from "../../services/clientmanageService.js";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import Buttoncomponent from 'components/Buttoncomponent.js';
import Select from 'react-select';

const EditClientAppriciation = (props) => {
    const params  = useParams();
  const [disablestate, setdisablestate] = useState(false);
  

  function inputhandler(e,key) {
    if(key=='remark' || key=='sub_client'){
      setInputfields({ ...inputFields, [e.target.name]: e.target.value });
      }else{
          setInputfields({ ...inputFields, [key]: e.value });
      }
    //setInputfields({ ...inputFields, [e.target.name]: e.target.value });

}


  useEffect(() => {
    get_unique_companieslist();
    get_user_list();
    getsingleAppreciation();
  }, []);

  let { id } = useParams();
  const initialData = {
        id:'',
      client_id:'',
      user_id : '',
      remark : 1,
      
    }

  const [inputFields, setInputfields] = useState(initialData);
const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
function get_unique_companieslist(){
  clientmanageService.getuniqueclient().then((response)=>{
    setUniqueCompaniesData(response.data);
  });
}
const [userlist, setUserListData] = useState([]);
function get_user_list(){
    clientmanageService.getusers().then((response) => {
    setUserListData(response.data)
  });
}
function getsingleAppreciation(){

    clientmanageService.getsingleAppreciation(id).then((response)=>{
        if(response.status==200){
        setInputfields(response.data.data)
        subclientList(response.data.data.name)
        }
  });
}
function editclientappreciation(e){
    setdisablestate(true)
    e.preventDefault();
    clientmanageService.updateclientappreciation(inputFields).then((response)=>{
      
      if(response.status == 200){
         toast.success(response.data.message); 
         document.getElementById("adduser-form").reset();
         //setInputfields({});
      }else{
        console.log(response.data);
         toast.error(response.data.message);
      }
      setdisablestate(false)
    });
  }
  const styles = {
    'color':'#4a4444',
  };
  const [ subclientlist, setsubclientlist ] = useState([])

  function subclientList(clientname=''){
    clientmanageService.getsubclientlist(clientname).then((response) => {
        if(response.status === 200){
          setsubclientlist(response.data)
        }else {
          toast.error('Something went wrong!');
        }
    });
  }
  function setclient(e){ 
    console.log(e)
    //setclientsearch(e.value)
    subclientList(e.label)
    // getTransitionData(e.value);
   // getclientstatus();
    
    
  }
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12">
              <div class="row">
                  <div class="col-md-6"><h2>Client Appreciation</h2></div>
                  
                </div>
                <br/>
              <Card className="card">
              <Form role="form" id="adduser-form" onSubmit={editclientappreciation}>
                <div className="row">
                <div className="col-md-4">
                  <label>Select Client</label>
                {(uniquecompanieslist.length > 0)?
                     
                <select
                className="form-control"
                onChange={(e) => { setclient(e);inputhandler(e,'client_id')}}
                value={inputFields.client_id}
                name="client_id"
              >
                <option value="" key="client">Select Client</option>
                {uniquecompanieslist.map((user) =>
                
                    <option  value={user.value}>
                      {user.label}
                    </option>
                )}
              </select>
                        : null }
                </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                    <h3 className="clientformtag">Sub Client</h3>
                        <select  class="form-control" name="sub_client"  onChange={(e) => { inputhandler(e,'sub_client')}} value={inputFields.sub_client}>
                          <option value=""> Select Sub Client</option>
                          {subclientlist.map(res => (
                              <option
                                key={res.sub_client}
                                value={res.sub_client}
                              >
                                {res.sub_client}
                              </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                    <label>Select User</label>
                    {(userlist.length > 0)?
                
              //     <select
              //   className="form-control"
              //   onChange={(e) => { inputhandler(e,'user_id')}}
              //   value={inputFields.user_id}
              //   name="user_id"
              // >
              //   <option value="" key="client">Select User</option>
              //   {userlist.map((user) =>
                
              //       <option  value={user.value}>
              //         {user.label}
              //       </option>
              //   )}
              // </select>
              <Select 
              className="input-group-alternative mb-3" 
               name="user_id"
               options={userlist} // set list of the data
               onChange={(e) => { inputhandler(e,'user_id')}}
               searchable
               isClearable={false}
               value={userlist.find(obj => obj.value === inputFields.user_id) || ''} // set selected values
             />
                        : null }
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                    <h3 className="clientformtag"> Remark</h3>
                  <Input type="textarea" rows="4" cols="6" defaultValue={inputFields.remark }  placeholder="Remark" name="remark" onChange={(e) => { inputhandler(e,'remark')}}  /> 
             
                    </div>
                </div>
                <div className="row">
                <div className="col-md-2">
                        <label>&nbsp;</label>
                          <Button className="col-12 " color="primary" type="submit" disabled={disablestate}>
                          {disablestate ? 'Sending...' : 'Update'} 
                         </Button>
                    </div>
                    
                </div>
                
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3"></InputGroup>
                </FormGroup>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default EditClientAppriciation;
