import { useState, useEffect } from "react";
import ReactDatetime from "react-datetime";
const moment = require('moment');
import {
  useHistory
} from "react-router-dom";
import {
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { reportmanageService } from "../../../services/reportmanageService";
import "../../../assets/css/custom.css";
import Header from "components/Headers/Header.js";

const UsersReport = (props) => {
  let sdate = moment().startOf('month');
  var month = sdate._d.getMonth() + 1;
  var date = sdate._d.getDate();
  var year = sdate._d.getFullYear();
  var start_d = date + '-' + month + '-' + year;

  let edate = moment();
  var month = edate._d.getMonth() + 1;
  var date = edate._d.getDate();
  var year = edate._d.getFullYear();
  var end_d = date + '-' + month + '-' + year;

  const history = useHistory();
  const [reportdata, setReportdata] = useState([]);
  const [sortfield, setsortfield] = useState("");
  const [sortorder, setsortorder] = useState("asc");
 
  const [state, setState] = useState({startDate:sdate,endDate:edate});
  const [statesend, setStatesend] = useState({startDate:start_d,endDate:end_d});

  const [hoddatatype,sethoddatatype] = useState('qa');
  function sortTable(field) {
    console.log(field);
    setsortfield(field);
    if (sortorder == "asc") {
      setsortorder("desc");
    } else {
      setsortorder("asc");
    }
  }
  useEffect(() => {
    get_report_data('qa');
  },[sortorder]);

  function get_report_data(hoddatatype){
    reportmanageService.getUserReportData(hoddatatype).then((response) => {
      console.log(response.data.data)
      setReportdata(response.data); 
    });
  }

  function report_details(user_id,type,status=''){
    if(statesend.startDate!=null && statesend.endDate!=null && user_info.role_id == 16){
      var drange = "/"+statesend.startDate+"/"+statesend.endDate
    }else{
      var drange = "";
    }
     history.push({
       pathname: "report_details/"+user_id+"/"+type+drange+"/"+status,
     });
  }

  function break_line(paragraph){
     return paragraph.replaceAll("\n",'<br>');
  }

  function linkreport(path){
    history.push({
      pathname: path,
    });
 }
 function switchToType() {
  if(hoddatatype == 'qa'){
    sethoddatatype('phase')
    get_report_data('phase');
  }else{
    sethoddatatype('qa')
    get_report_data('qa');
  }
};
  console.log(reportdata)
// end
const user_info = JSON.parse(localStorage.getItem("user-info"));
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12"></div>
            <Row>
           
            <div className="col-2">
          <Button className="col-12 "  type="button" onClick={() => {linkreport('analytics_report')}}>
                 Report
             </Button>
             </div>
              { (reportdata.show == 1 ) ? 
             <>
            {user_info.role_id == 2 ||user_info.role_id == 3 || user_info.role_id == 11 || user_info.role_id == 14  ? 
            <div className="col-2">
          <Button className="col-12 " color="primary" type="button" onClick={() => {linkreport('userreport')}}>
                Team Report
             </Button>
          </div>
          : null }
          <div className="col-2">
          <Button className="col-12 "  type="button" onClick={() => {linkreport('weeklyreport')}}>
                Weekly Report
             </Button>
          </div>
          <div className="col-2">
          <Button className="col-12 " type="button" onClick={() => {linkreport('monthlyreport')}}>
                Monthly Report
             </Button>
          </div>
          <div className="col-3">
             <Button className="col-12 " type="button" onClick={() => {linkreport('documentreport')}}>
               Documents Report
             </Button>
          </div>
          </>
          : null } 
           {(user_info.role_id == 14) ?
        <Col xl="2"><br></br>
        <Button
          className="col-12 phase-button qatl-main-btn qatl-main-btn-sec"
          color="light"
          type="button"
          onClick={() => switchToType()}
        >
          {(hoddatatype == 'phase') ? 'Switch To QA' : 'Switch to POD' }
        </Button>{" "}
      </Col> : null }
              <Col xl="12"><br></br>
                  <Card style={{padding:'0px 0px 0px 0px'}}>
                  <Table className="align-items-center table-flush custom-style" responsive>
                  
                        
                  <thead className="thead-light">
                       <th>User</th>
                       <th>Not Started</th>
                       <th>In Progress</th>
                       <th>On Hold</th>
                       <th>Queries send</th>
                       <th>Completed</th>
                       <th>Action</th>
                  </thead>
               
              <tbody>
              {reportdata.data &&
                reportdata.data.length &&
                reportdata.data != null ? (
                  reportdata.data.map((res, index) => (
                    <tr style={{backgroundColor: (index % 2 == 0) ? '#F4F4F4' : '#FFFFFF'}}>
                       <td>{res.name}</td>
                       <td class="notstarted" onClick={() => { report_details(res.id,res.type,'Not Started') }}><span  class="onhold" >{res.NotStarted}</span></td>
                       <td class="inprogress" onClick={() => { report_details(res.id,res.type,'In progress') }}><span  class="inprogress" >{res.Inprogress}</span></td>
                       <td class="onhold" onClick={() => { report_details(res.id,res.type,'On hold') }}><span  class="onhold" >{res.Onhold}</span></td>
                       <td class="queriessent" onClick={() => { report_details(res.id,res.type,'Queries Sent') }}><span  class="queriessent" >{res.QueriesSent}</span></td>
                       <td class="complete" onClick={() => { report_details(res.id,res.type,'Complete') }}><span  class="complete" >{res.Complete}</span></td>
                       <td style={{textAlign:"left"}}> 
                          <button class="report-view-btn"  color="primary" 
                               size="sm" type="button" 
                               onClick={(e) =>{ report_details(res.id,res.type) }}>
                            View
                          </button>
                      </td>
                    </tr>
                ))
                ):'No records found' }
                </tbody>
              </Table>
                  </Card>
              </Col>
             </Row>
            </div>
        </div>
      </Container>
    </>
  );
};

export default UsersReport;
