import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup, 
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Container,
  UncontrolledAlert
} from "reactstrap";
import React, { useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import {clientmanageService} from '../../../services/clientmanageService.js';
import {todoService} from '../../../services/todoService.js';
import { toast } from 'react-toastify';
import ReactDatetime from "react-datetime";
import Select from 'react-select';
import { usermanageService } from "services/usermanageService.js";
const StopRecurring = () => {
  const history = useHistory();
  let initialStateData = {
    assigned_by:"",
    remark:""

  };
  const [inputFields, setInputfields] = useState(initialStateData);
  const [error, setError] = useState(null)
  
  const [successMsg, setsuccessMsg] = useState(null)
  const [disablestate, setdisablestate] = useState(false);

  function inputhandler(e,name){
      setInputfields({...inputFields,[name]:e.value});
  } 

  const [ clientlist, setclientlist ] = useState([])
  function clientList(id){
    todoService.get_assignuser(id).then((response)=>{
          if(response.status === 200){
            setclientlist(response.data.data)
            
          }else {
              toast.error('Something went wrong!');
          }
        });
  }

  function addtodo(e){
    e.preventDefault();
    setdisablestate(true)
    usermanageService.StopRecurring(inputFields).then((response)=>{
     
      if(response.status == 200){
         toast.success(response.data.message);  
         setInputfields(initialStateData)
      }else if(response.status == 201){
        
        toast.error(response.data.message); 
      }
      setdisablestate(false)
    });
  }

  function cancel_form(){
       document.getElementById('addclient-form').reset();
       setInputfields(initialStateData);
       history.goBack(); 
  }

  const user_info = JSON.parse(localStorage.getItem("user-info"));
  useEffect(() => { 
    clientList(user_info.id)
    }, [] ) 
    const disablePastDate = () => {
      const today = new Date();
      const dd = String(today.getDate() + 1).padStart(2, "0") - 1;
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
  };
  const [usersearch, setusersearch] = useState([{'label':'Select User',value:''}]);
  return (
      <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      <Container fluid>
          <Row className="justify-content-md-center">
              <Col lg="12" md="12">
                  <Card className="shadow">
                      <CardHeader className="bg-transparent">
                          <h3 className="mb-0">Stop Recurring</h3>
                      </CardHeader>
                      <CardBody className="px-lg-5 py-lg-10">
                     
                      <Form role="form" id="addclient-form" onSubmit={addtodo}>
                          <FormGroup>
                           <Row>
                           <Col lg="6" md="6">
                           <label>User</label>
                           <Select 
                    
                      className="input-group-alternative mb-3" 
                      name="assigned_by"
                      options={clientlist} // set list of the data
                      defaultValue={usersearch}
                      searchable
                      onChange={(e)=>inputhandler(e,'assigned_by')}
                      isClearable={false}
                    />

                              {/* <select  class="form-control" name="assigned_by" onChange={inputhandler}  >
                              <option value=""> Select User</option>
                              
                              {clientlist.map(res => (
                                  <option
                                    key={res.id}
                                    value={res.id}
                                  >
                                    {res.name}
                                  </option>
                                ))}
                              </select> */}
                              
                              </Col>
       
                           </Row>   
                          </FormGroup>
                          
                          <FormGroup>
                             <Row>
                             
                          
                             
                               </Row></FormGroup>
                              <div className="col-12">
                             <Button className="mt-2" color="primary" type="submit" disabled={disablestate}>
                             {disablestate ? 'Sending...' : 'Stop Recurring All jobs'} 
                             </Button>
                             <Button className="mt-2 ml-2" color="secondary " type="button" onClick={cancel_form} >
                               Cancel
                             </Button>
                          </div>
                      </Form>
                      </CardBody>
                  </Card>
              </Col>
          </Row>
      </Container>
      </div>
      </>
  );
};

export default StopRecurring;
