import { useState, useEffect } from "react";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import 'assets/css/themecustom-style.css';
import { useHistory } from "react-router-dom";
import axios from "axios";
import { usermanageService } from '../../services/usermanageService';
import { taskmanageService } from '../../services/taskmanageService';
import { ToastContainer, toast } from 'react-toastify';
import { clientmanageService } from "../../services/clientmanageService";
import { getAllJSDocTags } from "typescript";
import Select from 'react-select';
const GovernanceDashboard = (props) => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(null);
  const [lastPage, setLastPageData] = useState(0);
  const [data, setData] = useState({});
  const [misData, setMISData] = useState([]);
  const [misActionData, setMISActionData] = useState([]);
  const [months, setMonths] = useState([]);
  const [filterFields, setFilterFields] = useState([]);
  const [filterType, setFilterType] = useState('open');
  const [selectedmanager,setSelectedmanager]=useState('');
  const handlePageClick = (e, index) => {
    e.preventDefault();
    getClientDocList(index);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    getClientDocList(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    getClientDocList(next);
  };

  const getList = async (id='') => {
    clientmanageService.governanceCalldashboard(id).then((response) => {
        setData(response.data.data);
        setMonths(response.data.months);
    });
//     clientmanageService.getGovernanceMISdashboard(id).then((response) => {
//       setMISData(response.data.data);
//   });
//   clientmanageService.getGovernanceMISActiondashboard(id).then((response) => {
//     setMISActionData(response.data.data);
// });
  };

  const companyFilter = (e, index) => {
    const cloneValues = { ...filterFields };
    cloneValues[index] = {
      ...cloneValues[index],
      field: e.target.name,
      value: encodeURIComponent(e.target.value),
      operator: '='
    };
    
    setFilterFields(cloneValues);
    getClientDocList(1, null, cloneValues, filterType);
  };

  useEffect(() => {
    getList();
    getmanagerList();
  }, []);

  const editClick = (id) => {
    history.push({
      pathname: `editannounce/${id}`,
    });
  };

  const handleClick = (id) => {
    taskmanageService.deleteAnnouncement(id).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message);
        location.reload();
      } else {
        for (const field in response.data) {
          toast.error(response.data[field][0]);
        }
      }
    });
  };
  const handlerejeditRow = (index,client) => {
    // if(phaseedit == true || taskedit == true || analystedit == true){
      let temp_state = {...data};
      
      let temp_element = { ...temp_state[client][0] };
      
      temp_element.editing = true;
      temp_state[client][0]= temp_element;
      
      setData(temp_state);
      // }

  };
  const editRejField = (value, field, id,index) => {
    clientmanageService.editgovernancefreqdata(value, field, id).then((response) => {
      let temp_st = {...data};
      let temp_ele = { ...temp_st[id][0] };
      if(response.status == 200){
        toast.success(response.data.message);
      }else{
          toast.error(response.data.message);
      }
      temp_ele.editing = false;
      // temp_ele[field] = value;
      
    
       if (field != "status") {
        temp_ele[field] = value;
      }
      temp_st[id][0] = temp_ele;
      setData(temp_st);
    })
  }
  const [filtertype,setfiltertype] = useState('dashboard');
  const getData = (title) =>{
    if(title=='dashboard'){
      history.push({
        pathname: "/admin/governancedashboard"
      });
      
    }else{
      history.push({
        pathname: "/admin/misgovernancedata/"+title
      });
    }
  }
  // function companyinputhandler(e){
  //   getList(e.value)
  //   setSelectedmanager(selectedOption ? selectedOption.value : '');
   
  //  }
  const companyinputhandler = (selectedOption) => {
    setSelectedmanager(selectedOption ? selectedOption.value : null);
   // setSelectedmanagerLabel(selectedOption ? selectedOption : null);
    getList(selectedOption ? selectedOption.value : '');
  }
   const [manager,setmanager]=useState([]);
   const getmanagerList = ()=>{
     usermanageService.getmanager_list().then((response) => {
         setmanager(response.data.users)
     })
   }
   function downloadReport(){
   
        clientmanageService.governanceCalldashboard(selectedmanager,1).then((response) => {
            
        });
    
  }
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
      <Row >
        
      <button type="button"  className=" btn btn-secondary text-center" style={{backgroundColor:'#11cdef'}} onClick={() => getData('dashboard')}>
        Governance    Dashboard
      </button> 
      <button type="button"  className=" btn btn-secondary text-center" onClick={() => getData('mis-basedonrating')}>
      MIS- based on rating
      </button>
      <button type="button"  className=" btn btn-secondary text-center" onClick={() => getData('mis-onactionitem')}>
      MIS - on action item
      </button>
         


        </Row>
        <Row className="mt-2">
          <div className="col" style={{ padding: "0%" }}>
            <Card className="shadow">
              <CardHeader className="border-0">
              <Row className="align-items-center row">
                  <div className="col-xl-3 col-md-2 col-xs-12">
                    <h3><b>Governance Dashboard</b></h3>
                  <br></br></div>
                  <div className="col-xl-4 col-md-2 col-xs-12  d-flex">
                  <div className="col-xl-12">
                  <label>Select Manager</label>
                  <Select 
                            className="input-group-alternative mb-3" 
                            name="manager" 
                            allowSelectAll={true} 
                            options={manager} // set list of the data
                            onChange={companyinputhandler} 
                            required
                            
                            isClearable
                            
                          />
                          </div>
                          <div>
                           <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                  </Button>
                  </div>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                  <tr>
                    <th scope="col" className="whitespace" >Client Name</th>
                    <th scope="col" className="whitespace" >Frequency</th>
                    {Object.values(months).map((month, index) => (
                      <th scope="col" className="whitespace"  key={index}>{month}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(data).map((clientName,i) => {
                    const clientData = Array.isArray(data[clientName]) ? data[clientName] : [];
                    return (
                      <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') } key={clientName}>
                        <td className="whitespace"><b>{clientName}</b></td>
                        <td className="whitespace" onClick={()=> handlerejeditRow(i,clientName)}>
                    {clientData[0].editing ? (
                    <select class="form-control" name="frequency" onChange={(e) =>
                      editRejField(
                        e.target.value,
                        "governance_freq",
                        clientName,
                        i
                      )
                    } 
                    value={clientData[0]?.governance_freq} required>
                    <option value=""> Select frequency</option>
                      <option value="BiMonthly">Bi-Monthly</option>
                      <option value="Quarterly">Quarterly</option>
                      <option value="Monthly">Monthly</option>
                      <option value="Annually">Annually</option>
                      <option value="HalfYearly">HalfYearly</option>
                    </select> ) : (
                                <p><br></br>{clientData[0]?.governance_freq || 'N/A'}</p>
                              )} 
                              </td>
                        {/* <td className="whitespace">{clientData[0]?.governance_freq || 'N/A'}</td> Display governance_freq */}
                        {Object.keys(months).map((monthKey, index) => {
                          const monthData = clientData.find(item => `${item.year}-${String(item.month).padStart(2, '0')}` == monthKey);
                         
                          return (
                            <td className="whitespace" key={index}>
                              {monthData ? (monthData.rating == 0) ? 'No Show' : (monthData.rating == 5) ? 'Happy' :  (monthData.rating == 1) ? 'Not Happy' : monthData.rating : 0}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
              
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default GovernanceDashboard;
