import { useState, useEffect } from "react";
import {
  useHistory,
} from "react-router-dom";
import {
  Button,
  Container,
  FormGroup,
  Form,
  Input,
  InputGroup,
} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { usermanageService } from "../../../services/usermanageService";

import "../../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import DataTable from "react-data-table-component";

const Categorylist = (props) => {
  const [categories, setCategories] = useState([]);
  const [inputFields, setInputfields] = useState({
    id : "",
    name: "",
  });

  useEffect(() => {
    getcategory_list();
    return () => {
      setCategories([]);
    };
  }, []);

  function getcategory_list() {
    usermanageService.getcategories().then((response) => {
      setCategories(response.data);
      console.log(response.data)
    });
  }

  const handleclick = (e, value) => {
    const data = {
      id : e.id,
      name: e.name,
    };
    setInputfields(data);
  };

  const columns = [
    {
      name: "Sr No",
      selector: (row) => row.id,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Action",
      selector: (row) => row.id,
      cell: (response) => (
        <button onClick={handleclick.bind(this, response)} class="btn btn-success"><i class="fa fa-edit"></i></button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const customStyles = {
    rows: {
        style: {
             minHeight: '72px', // override the row height
        },
    },
    headCells: {
        style: {
            background:'#ebebeb',
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
  };
  const styles = {
    'color':'#4a4444',
  };
  function inputhandler(e) {
    // e.presist();
    setInputfields({ ...inputFields, [e.target.name]: e.target.value });
  }

  function createcategory(e) {
    e.preventDefault();
    
    usermanageService.createcategory(inputFields).then((response) => {
       if(response.status == 200){
        getcategory_list();
            toast.success(response.data.message);
            const data = {
              id : "",
              name: "",
            };
            setInputfields(data);
            document.getElementById("category-form").reset();
       }else{
            toast.error(response.data.message); 
       }        
    });
  }

  const data = categories;
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
              <div class="row">
                <div class="col-md-6"><h2>Categories</h2></div>
                <div class="col-md-6">
                <div class="row">
                  <div class="col-md-2 offset-md-10">
                     
                  </div>
                </div>
                </div>
              </div>
              <br/>
          </div>
          <div className="col-md-4 col-4">
            <Form role="form" id="category-form" onSubmit={createcategory}>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <Input
                    placeholder="Category Name"
                    type="text"
                    name="name"
                    style={styles}
                    value={inputFields.name}
                    onChange={inputhandler}
                    required
                  />
                </InputGroup>
                <Button className="col-12 " color="primary" type="submit">
                  Save category
                </Button>
              </FormGroup>
            </Form>
          </div>
          <div className="col-md-8 col-8">
            <DataTable 
                columns={columns} 
                data={data} 
                customStyles={customStyles} 
             />
          </div>
       </div>
      </Container>
    </>
  );
};

export default Categorylist;
