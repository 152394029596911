import { BrowserRouter, Route, Switch, Redirect, useHistory,useParams } from "react-router-dom";
import {
   Button,
   Card,
   CardHeader,
   CardBody,
   FormGroup,
   Form,
   Input,
   InputGroupAddon,
   InputGroupText,
   InputGroup,
   Row,
   Col,
   Container,
 } from "reactstrap";
const Buttoncomponent  = () => {
   const history = useHistory();
   return (
     <>
         <Button onClick={history.goBack}>Back</Button>     
     </>
   );      
};


export default Buttoncomponent;