import {apiUrl} from '../constant';
import {authtoken} from 'global.js';
const axios = require("axios");
export const firebasemanageService = {
   storeToken,
};
function storeToken(token){
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    return axios({
        method: "POST",
        url: `${apiUrl}/storeToken`,
        data: bodyFormData,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
          return response;
        }, error => {
         // return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
  }