import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { ToastContainer, toast } from 'react-toastify';
import { domainUrl} from '../../constant';
import '@flowjs/flow.js';
import Flow from "@flowjs/flow.js";
import { async } from "@firebase/util";
import { useHistory,useParams } from "react-router-dom";
import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
import { manageService } from "../../services/manageaccountservices";
import Select from 'react-select';
const axios = require("axios");

const Questionnaire = (props) => {
  const params  = useParams();
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const [clientdata, setClientdata] = useState([]);
  const [clientsearch, setclientsearch] = useState('');
  const [trans_data,settransData] = useState([]);
  const [formdetails,setformdetails] = useState({});
  const [formdetailsLen,setformdetailsLength] = useState(0);
  const [taskdetailPopup,settaskdetailPopup] = useState(false);
  const [allclient, setAllclient] = useState(true);
  const regionOption = useRef(null);
  const [changeclient,setchangeclient] = useState(false);
  const [status,setstatus] = useState([]);
  const [state, setState] = useState({});
  const [ctrans, setCurrentTrans] = useState('');
  const [disablestate, setdisablestate] = useState(false);

useEffect(() => {
  get_unique_companieslist();
  if(params.client_id!='undefined' && params.client_id!=undefined){
      //getTransitionData(params.client_id)
      getclientstatus();
  }
}, []);

const getquestionnaireData = (client_id,subclient) => {

  clientmanageService.getquestionnaireData(client_id,subclient).then((response) => {
    //console.log(response.data["15"][0]['remark'])
    setformdetails(response.data);
    // setformdetailsLength(Object.entries(response.data.list).length);
   
    //console.log(formdetails["15"][0]['remark'])
    
  })
}
const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
function get_unique_companieslist(){
  clientmanageService.getuniqueclient().then((response)=>{
    setUniqueCompaniesData(response.data);
  });
}

  function getTransitionData(clientname=''){
    //setsubclientlist([]);
    
    
    clientmanageService.gettransitionData(clientname).then((response) => {
      
        if(response.status === 200){
          setchangeclient(true);
          settransData(response.data)
    
          
        }else {
          toast.error('Something went wrong!');
        }
    });
  }
  function getclientstatus(){
    taskmanageService.getclienttransitionstatus().then((res) => {
          
      if(res.status === 200){
        setstatus(res.data.status);
        console.log(status)
      }
  });
  }
  const [ subclientlist, setsubclientlist ] = useState([])

  function subclientList(clientname=''){
    clientmanageService.getsubclientlist(clientname).then((response) => {
        if(response.status === 200){
          setsubclientlist(response.data)
        }else {
          toast.error('Something went wrong!');
        }
    });
  }
  function setclient(e){ 
    console.log(e)
    setclientsearch(e.value)
    subclientList(e.label)
    // getTransitionData(e.value);
    getclientstatus();
    
    
  }
  const [ subclient, setsubclient ] = useState([]);
const companyfilter = (e,index) => {
 setAllclient(false)
 getquestionnaireData(clientsearch,e.target.value)
 setsubclient(e.target.value);
};

const editField = (client_id,trans_id, field, value,index) => {
  clientmanageService.updateTransData(client_id, trans_id, field,value).then((response) => {
    let temp_st = [...trans_data];
    let temp_ele = { ...temp_st[index] };
   
    if(response.status == 200){
      getTransitionData(client_id);
        toast.success(response.data.success)
    }else{
        toast.error(response.data.error);
    }
    temp_ele.editing = false;
    temp_ele.mainediting = false;
    
  });
};
function inputhandler(e,index) {

  let temp_st = {...state};
  let temp_ele = { ...temp_st[index] };
  temp_ele.id = index;
  temp_ele.remarks = e.target.value;
  temp_st[index] = temp_ele;
  setState(temp_st);
  console.log(state)

}

function addsopform(e) {
  console.log(e);
  setdisablestate(true)
  e.preventDefault();
  clientmanageService.updateQuestionaireForm(state,clientsearch,subclient).then((response) => {
   

    if (response.status == 200) {
      toast.success(response.data.message);
     // location.reload();
    }
    setdisablestate(false)
  });
}

const [newbox, setNewBox] = useState(0);
 
const addNewbox = ()=>{
  const setdata = newbox+1;
  setNewBox(setdata)
 
}

  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
    
        <Row className="mt-12" >
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
            {(uniquecompanieslist.length > 0 && (user_info.role_id != 13) && (params.client_id=='undefined' || params.client_id==undefined))?
                   <>
              <CardHeader className="border-0">
              <Row className="filter" style={{padding:'0 1%'}}>
               <div className="filter" style={{width:'80%'}}>
                   <div className="col-xl-6 col-md-6 col-xs-12">
                    <h3 className="clientformtag"> Client</h3>
                    <Select 
                   
                    className="input-group-alternative mb-3" 
                    name="user_id"
                    options={uniquecompanieslist} // set list of the data
                    onChange={(e) => { setclient(e)}}
                    defaultValue={clientsearch}
                    searchable
                    isClearable={false}
                  />
                  </div>
                  <div className="col-xl-6 col-md-6 col-xs-12">
                     <h3 className="clientformtag">Sub Client</h3>
                        <select  class="form-control" name="sub_client"  onChange={(e) => companyfilter(e,9)}>
                          <option value=""> Select Sub Client</option>
                          {subclientlist.map(res => (
                              <option
                                key={res.sub_client}
                                value={res.sub_client}
                              >
                                {res.sub_client}
                              </option>
                            ))}
                        </select>
                    </div>
                    </div>
                </Row>
              </CardHeader>
              </>
               : null }
              
            


            </Card>
          </div>
        </Row>
        <Row className="mt-12" >
        <Card className="shadow"  style={{width:"100%"}}>
            <Form
                role="form"
                id="phase-selection-form"
                onSubmit={addsopform}
            
              >
              <Table  className="align-items-center table-flush question_table" cellspacing="0" cellpadding="0"  responsive>
             
              <thead>
                <tr style={{ border: "1px solid #000" }}>
                 
                  <th style={{ border: "1px solid #000" }} className="whitespace">Area</th>
                  <th style={{ border: "1px solid #000" }} colspan="2" className="whitespace">Question</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">Response  - (Yes/No ) detailed</th>
                </tr>
              </thead>
           
                <tbody>
              {/* {
              Object.entries(formdetails).map(([key, value],index) =>
              <>
                  {value.map((g,i) => (
                  <tr className="whitespace" style={{border : "1px solid #000"}}>
                   {
                      (i ==0)?
                      <><td style={{border : "1px solid #000"}}>{index+1}</td>
                      <td style={{border : "1px solid #000"}}>{key}</td></>
                      :<>
                      <td style={{border : "1px solid #000"}}></td>
                      <td style={{border : "1px solid #000"}}></td></>}
                    
                    
                  <td className="whitespace" style={{border : "1px solid #000"}}>
                 
                  {g.scenerio}
                    </td>
                
                  <td className="whitespace" style={{width:"50%",border : "1px solid #000"}}><Input
                              
                              bsSize="sm"
                              name="form-remarks"
                              placeholder="Remarks"
                              id="example-date-input"
                              type="textarea"
                              defaultValue={g.remarks ? g.remarks : ""}
                              onChange={(e) => inputhandler(e, i,key)}
                  /></td>
                  </tr>
                ))}
                </>
            )
            }
            {(() => {
            const arr1 = [];
            for (let key = 0; key < newbox; key++) {
                arr1.push(
                 <>
                    
                    <tr className="whitespace" style={{border : "1px solid #000"}}>
                    
                    <td style={{border : "1px solid #000"}}>{formdetailsLen+key+1}</td>
                   <td style={{border : "1px solid #000"}}><Input
                              
                   bsSize="sm"
                   name="field_name"
                   placeholder="Particular"
                   id="example-date-input"
                   type="textarea"
                   
                   onChange={(e) => inputhandlernew(e,key)}
                  /></td>
                  
                  
                    
                  <td className="whitespace" style={{border : "1px solid #000"}}>
                 
                  <Input
                              
                              bsSize="sm"
                              name="scenerio"
                              placeholder="Area"
                              id="example-date-input"
                              type="textarea"
                             
                              onChange={(e) => inputhandlernew(e,key)}
                  /> 
                    
               
                    </td>
                
                  <td className="whitespace" style={{width:"50%",border : "1px solid #000"}}><Input
                              
                              bsSize="sm"
                              name="remarks"
                              placeholder="Remarks"
                              id="example-date-input"
                              type="textarea"
                             
                              onChange={(e) => inputhandlernew(e,key)}
                  /></td>
                  </tr>
                    </>
                );
            }
            return arr1;
            
        })()} */}
<tr className="whitespace">
    <td rowspan="26">About the Client</td>
    <td className="whitespace">Nature of Business</td>
    <td></td>
    <td className="whitespace" style={{ width: "50%", border: "1px solid #000" }}>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["1"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 1)}
        />
    </td>
</tr>
<tr style={{ border: "1px solid #000" }}>
    <td style={{ border: "1px solid #000" }}>Year End</td>
    <td style={{ border: "1px solid #000" }}></td>
    <td className="whitespace" style={{ width: "50%", border: "1px solid #000" }}>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["2"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 2)}
        />
    </td>
</tr>
<tr style={{ border: "1px solid #000" }}>
    <td style={{ border: "1px solid #000" }}>VAT Registered ?</td>
    <td style={{ border: "1px solid #000" }}>Yes/No</td>
    <td className="whitespace" style={{ width: "50%", border: "1px solid #000" }}>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["3"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 3)}
        />
    </td>
</tr>
<tr style={{ border: "1px solid #000" }}>
    <td style={{ border: "1px solid #000" }}>
        <b>If VAT Registered - fill in below information - VAT Quarters & Scheme</b>
    </td>
    <td></td>
    <td></td>
</tr>
<tr style={{ border: "1px solid #000" }}>
    <td style={{ border: "1px solid #000" }}>VAT Quarters</td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["4"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 4)}
        />
    </td>
</tr>
<tr>
    <td rowspan="5">VAT Scheme</td>
    <td>Standard / Accrual</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["5"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 5)}
        />
    </td>
</tr>
<tr>
    <td>Cash</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["6"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 6)}
        />
    </td>
</tr>
<tr>
    <td>Flat Rate Cash</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["7"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 7)}
        />
    </td>
</tr>
<tr>
    <td>Partial Exemption</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["8"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 8)}
        />
    </td>
</tr>
<tr>
    <td>Others - Please specify</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["9"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 9)}
        />
    </td>
</tr>

<tr>
    <td>CIS registered </td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["10"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 10)}
        />
    </td>
</tr>

<tr>
    <td rowspan="5">Accounting Application/ Software  </td>
    <td>Xero</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["11"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 11)}
        />
    </td>
</tr>
<tr>
    <td>Sage</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["12"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 12)}
        />
    </td>
</tr>
<tr>
    <td>QuickBooks</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["13"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 13)}
        />
    </td>
</tr>
<tr>
    <td>MS Excel</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["14"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 14)}
        />
    </td>
</tr>
<tr>
    <td>Others</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["15"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 15)}
        />
    </td>
</tr>

<tr>
    <td rowspan="6">Invoice Processing Software </td>
    <td>Receipt Bank</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["16"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 16)}
        />
    </td>
</tr>
<tr>
    <td>Datamolino</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["17"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 17)}
        />
    </td>
</tr>
<tr>
    <td>Auto Entry</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["18"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 18)}
        />
    </td>
</tr>
<tr>
    <td>Entry Less</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["19"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 19)}
        />
    </td>
</tr>
<tr>
    <td>Direct Posting</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["20"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 20)}
        />
    </td>
</tr>
<tr>
    <td>Others-</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["21"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 21)}
        />
    </td>
</tr> 

<tr>
    <td rowspan="4">Frequency of Book Keeping</td>
    <td>Daily</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["22"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 22)}
        />
    </td>
</tr>
<tr>
    <td>Weekly</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["23"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 23)}
        />
    </td>
</tr>
<tr>
    <td>Fortnightly</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["24"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 24)}
        />
    </td>
</tr>
<tr>
    <td>Monthly</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["25"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 25)}
        />
    </td>
</tr>

<tr>
    <td rowspan="10">About the Client</td>
    <td rowspan="5">How will you send documents</td>
    <td>Scan via File Transfer Protocol (FTP)</td> 
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["26"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 26)}
        />
    </td>
</tr>
<tr>
    <td>Scan via Email</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["27"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 27)}
        />
    </td>
</tr>
<tr>
    <td> Scan Uploaded to Dropbox</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["28"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 28)}
        />
    </td>
</tr>
<tr>
    <td> Scan Uploaded to Google Drive</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["29"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 29)}
        />
    </td>
</tr>
<tr>
    <td> Scan Uploaded to One Drive</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["30"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 30)}
        />
    </td>
</tr>
<tr>
    <td > List down bank accounts</td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["31"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 31)}
        />
    </td>
</tr>
<tr>
    <td > List down credit cards
</td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["32"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 32)}
        />
    </td>
</tr>
<tr>
    <td > List down intermediary accounts
</td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["33"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 33)}
        />
    </td>
</tr>
<tr>
    <td > Does the company use any kind of factoring
</td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["34"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 34)}
        />
    </td>
</tr>
<tr>
    <td > Does company have assets on Hire Purchase
</td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["35"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 35)}
        />
    </td>
</tr>
<tr>
    <td rowspan="4">Scope of Work</td>
    <td rowspan="4">Sales</td>
    <td>Invoice Raising/ Credit Note Raising</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["36"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 36)}
        />
    </td>
</tr>
<tr>
    
    <td> Cash Allocation</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["37"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 37)}
        />
    </td>
</tr>
<tr>
    
    <td> Collection- Emails</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["38"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 38)}
        />
    </td>
</tr>
<tr>
    
    <td> Collection- Voice</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["39"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 39)}
        />
    </td>
</tr>
<tr>
<td rowspan="8">Scope of Work</td>
    <td rowspan="4">Purchases</td>
    <td>Invoice Processing</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["40"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 40)}
        />
    </td>
</tr>
<tr>
    
    <td> Cash Allocation</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["41"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 41)}
        />
    </td>
</tr>
<tr>
    
    <td> Payments</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["42"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 42)}
        />
    </td>
</tr>
<tr>
    
    <td> Supplier Help Desk</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["43"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 43)}
        />
    </td>
</tr>
 <tr>
    
    <td >Bank Reconciliation</td>
    <td>Bank Reconciliation</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["44"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 44)}
        />
    </td>
</tr> 
<tr>
    
    <td rowspan="3" >Payroll</td>
    <td> Payroll Processing</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["45"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 45)}
        />
    </td>
</tr>
<tr>
    
    
    <td> Payroll JE Posting</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["46"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 46)}
        />
    </td>
</tr> 
<tr>
    
    
    <td> Payments to Employees</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["47"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 47)}
        />
    </td>
</tr> 
<tr>
<td rowspan="5">Accounting</td>
    <td >List down any considerations for Sales accounting which needs specific<br/> attention or treatment</td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["48"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 48)}
        />
    </td>
</tr>
<tr>

    <td >List down any considerations for Purchases 
        accounting which needs <br/>specific attention or treatment</td>
<td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["49"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 49)}
        />
    </td>
</tr>
<tr>
<td >Do you want us to do Prepayments and Accruals?  <br/>
     If yes, please mention materiality levels and specific  
     accruals to be done <br/>including depreciation, 
     interest, HP and others</td>
<td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["50"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 50)}
        />
    </td>
</tr>
<tr>
<td>Does each payments need to be supported by invoice. <br/> 
    In case No, please provide guidance or direction-  
    This can be discussed<br/> on a conference call</td>
<td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["51"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 51)}
        />
    </td>
</tr>
<tr>
<td >Shall we post any payments without supporting  
    invoice details <br/>to suspense or Query sheet leaving  
    these unreconciled
</td>
<td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["52"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 52)}
        />
    </td>
</tr>
<tr>
<td rowspan="5">Others</td>
    <td >Do you have Standard Operating Process which can be shared with us.</td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["53"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 53)}
        />
    </td>
</tr>
<tr>
<td >Do you require any specific reporting- If yes, please list down frequency
</td>
<td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["54"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 54)}
        />
    </td>
</tr>
<tr>
<td >Do you require management reporting. If yes, please list down frequency
</td>
<td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["55"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 55)}
        />
    </td>
</tr>
<tr>
<td >Do you want us to file VAT Return. If yes, please provide gateway <br/>access details
</td>
<td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["56"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 56)}
        />
    </td>
</tr>
<tr>
<td >Name of the contact person at your end along with contact details
</td>
<td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["57"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 57)}
        />
    </td>
</tr>
<tr>
<td rowspan="2"><b>Time lines</b></td>
    <td ><b>What are the budgeted hours for the above client on a monthly basis</b></td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["58"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 58)}
        />
    </td>
</tr>

<tr>

    <td ><b>Deadline date for completion</b></td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["59"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 59)}
        />
    </td>
</tr>
<tr>
<td ><b>Special Points specific to client</b></td>
    <td ></td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["60"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 60)}
        />
    </td>
</tr>
                    
            </tbody>
              </Table>
              </Form>
            <br></br>
              
                <div class="d-flex">
                {/* <Button color="primary" className="col-xl-2" style={{flex:"0 0 4.66667%"}} type="button" onClick={(e) => addNewbox()} >
            Add<i class="fa fa-plus"></i>
              </Button> */}
            <Button color="primary" className="col-xl-2" style={{flex:"0 0 4.66667%"}} type="button" onClick={addsopform} disabled={disablestate}>
            {disablestate ? 'Sending...' : 'Save'} 
              </Button>
              </div>
              
             
              </Card>
              </Row>

      </Container>
    </>
  );
};

 
export default Questionnaire;
