import { useState, useEffect } from "react";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import 'assets/css/themecustom-style.css';
import { useHistory } from "react-router-dom";
import axios from "axios";
import { usermanageService } from '../../../services/usermanageService';
import { taskmanageService } from '../../../services/taskmanageService';
import { ToastContainer, toast } from 'react-toastify';
import { clientmanageService } from "../../../services/clientmanageService";
import Select from 'react-select';

const KRODashboard = (props) => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(null);
  const [lastPage, setLastPageData] = useState(0);
  const [data, setData] = useState({});
  const [userwiseData, setUserwiseData] = useState({});
  const [summaryData, setSummaryeData] = useState({});
  const [months, setMonths] = useState([]);
  const [selectedManager, setSelectedManager] = useState('');
  const [selectedTab, setSelectedTab] = useState(1);
  const today = new Date();
  const month = today.getMonth() + 1; // Months are zero-based
  const year = today.getFullYear();
  const formattedDate = `${year}-${month.toString().padStart(2, '0')}`;
  const [monthYear,setmonthYear] = useState(formattedDate);
  const companyfilter = (e) => {
    
    setfilterFields({ ...filterFields, [e.target.name]: e.target.value } );
  }
  useEffect(() => {
    getList();
    getmanagerList();
  }, []);

  const getList = async (id = '',month_year = monthYear) => {
    setSelectedTab(1)
    usermanageService.KRODashboarddata(id,month_year).then((response) => {
      setData(response.data.data);
      setMonths(response.data.months);
    });
  };

  const getListUserwise = async (id = '',month_year = monthYear) => {
    setSelectedTab(2)
    usermanageService.KRODashboarduserwisedata(id,month_year).then((response) => {
    
      setUserwiseData(response.data.data);
    });
  };
  const getRatingSummary = async (id = '',month_year = monthYear) => {
    setSelectedTab(3)
    usermanageService.KRODashboardsummary(id,month_year).then((response) => {
      setSummaryeData(response.data.data);
    });
  };

  const handleManagerChange = (e,filtertype='') => {
    if(filtertype=='month'){
      setmonthYear(e.target.value)
      if(selectedTab == 1){
        getList(selectedManager,e.target.value);
      }
      if(selectedTab == 2){
        getListUserwise(selectedManager,e.target.value);
      }
      if(selectedTab == 3){
        getRatingSummary(selectedManager,e.target.value);
      }
      
    }else{
      setSelectedManager(e ? e.value : '');
      if(selectedTab == 1){
        getList(e ? e.value : '',monthYear);
      }
      if(selectedTab == 2){
        getListUserwise(e ? e.value : '',monthYear);
      }
      if(selectedTab == 3){
        getRatingSummary(e ? e.value : '',monthYear);
      }
    }
   
  };

  const downloadReport = (type) => {

    if(type==1){
      usermanageService.KRODashboarddata(selectedManager,monthYear, 1).then((response) => {
        // handle download response
      });
    }else if(type==2){
      usermanageService.KRODashboarduserwisedata(selectedManager,monthYear, 1).then((response) => {
        // handle download response
      });
    }else{
      usermanageService.KRODashboardsummary(selectedManager,monthYear, 1).then((response) => {
        // handle download response
      });
    }
    
  };
  const [manager,setmanager]=useState([]);
  const getmanagerList = ()=>{
    usermanageService.getmanager_list().then((response) => {
        setmanager(response.data.users)
    })
  }
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Button type="button" className="btn btn-secondary text-center" style={{ backgroundColor:(selectedTab == 1) ? '#11cdef' : '' }} onClick={() => getList()}>
            KRO Dashboard
          </Button>
         <Button type="button" className="btn btn-secondary text-center" style={{ backgroundColor:(selectedTab == 2) ? '#11cdef' : '' }} onClick={() => getListUserwise()}>
            KRO User wise
          </Button>
          <Button type="button" className="btn btn-secondary text-center" style={{ backgroundColor:(selectedTab == 3) ? '#11cdef' : '' }} onClick={() => getRatingSummary()}>
           Rating Summary
          </Button>
        </Row>
        {(selectedTab == 1) ? 
        <Row className="mt-2">
          <div className="col" style={{ padding: "0%" }}>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center ">
                  <div className="col-xl-12 d-flex">
                   <div className="col-xl-3 col-md-2 col-xs-12"> 
                    <h3 className="mt-4"><b>KRO Dashboard</b></h3>
                  
                  </div> 
                  <div className="col-xl-4">
                      <label>Select Manager</label>
                      <Select
                        className="input-group-alternative mb-3"
                        name="manager"
                        options={manager}
                        onChange={handleManagerChange}
                        isClearable
                      />
                    </div>
                     <div className="col-xl-2" style={{marginTop:'20px'}}>
                      <Button
                        className="btn-icon-clipboard"
                        type="button"
                        onClick={(e)=>downloadReport(1)}
                        title="Download Report"
                      >
                        <i className="fas fa-download" style={{ fontSize: '14px' }}></i> Export
                      </Button>
                    </div> 
                  <div className="col-xl-4 col-md-6 col-xs-12  d-flex">
                   
                  </div>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr >
                    <th scope="col" className="whitespace"></th>
                    {Object.values(months).map((month, index) => (
                      <th scope="col" className="whitespace" key={index}>{month}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr className="blue-row">
                    <td className="whitespace"><b>Total KRO's due</b></td>
                    {Object.keys(months).map((monthKey, index) => (
                      <td className="whitespace" key={index}>
                        {data[monthKey] ? data[monthKey].total_due_kro : 0}
                      </td>
                    ))}
                  </tr>
                  <tr className="purple-row">
                    <td className="whitespace"><b>Pending for TL approval</b></td>
                    {Object.keys(months).map((monthKey, index) => (
                      <td className="whitespace" key={index}>
                        {data[monthKey] ? data[monthKey].pending_by_tl : 0}
                      </td>
                    ))}
                  </tr>
                  <tr className="orange-row">
                    <td className="whitespace"><b>Pending for Manager approval</b></td>
                    {Object.keys(months).map((monthKey, index) => (
                      <td className="whitespace" key={index}>
                        {data[monthKey] ? data[monthKey].pending_by_manager : 0}
                      </td>
                    ))}
                  </tr>
                  <tr className="blue-row">
                    <td className="whitespace"><b>Send to HR</b></td>
                    {Object.keys(months).map((monthKey, index) => (
                      <td className="whitespace" key={index}>
                        {data[monthKey] ? data[monthKey].send_to_hr : 0}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {/* Pagination can be added here if necessary */}
              </CardFooter>
            </Card>
          </div>
        </Row> : null }
        {(selectedTab == 2) ? 
        <Row className="mt-2">
          <div className="col" style={{ padding: "0%" }}>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col-xl-12 d-flex">
                  <div className="col-xl-4 col-md-3 col-xs-12">
                    <h3><b>KRO User wise Dashboard</b></h3>
                    
                  </div>
                  <div className="col-xl-8 col-md-4 col-xs-12 d-flex">
                    <div className="col-xl-4">
                      <label>Select Manager</label>
                      <Select
                        className="input-group-alternative mb-3"
                        name="manager"
                        options={manager}
                        onChange={handleManagerChange}
                        isClearable
                      />
                   </div>
                   <div className="col-xl-3 col-md-3 col-xs-12">
                        <h5 className="clientformtag">Select Month</h5>
                        <input type="month"
                          className="form-control"
                          name="fromdate"
                          onChange={(e) =>
                            handleManagerChange(e,'month')
                            }
                            value={monthYear}
                          />
                        </div>
                    <div className="col-xl-3 col-md-3 col-xs-12 pt-3">
                      <Button
                        className="btn-icon-clipboard"
                        type="button"
                        onClick={(e)=>downloadReport(2)}
                        title="Download Report"
                      >
                        <i className="fas fa-download" style={{ fontSize: '14px' }}></i> Export
                      </Button>
                    </div>
                    
                  </div>
                 
                  </div>
                </Row>
              </CardHeader>
              {Object.keys(userwiseData).map((userName, index) => {
                const userData = userwiseData[userName];
                return (
                  <>
                  <h2>{userName}</h2>
              <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col" className="whitespace"></th>
          {Object.entries(userData).map(([key, value]) => (
            <th scope="col" className="whitespace" key={key}>{key}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr className="blue-row">
          <td className="whitespace"><b>Total KRO's due</b></td>
          {Object.entries(userData).map(([key, value]) => (
            <td className="whitespace" key={`${key}-total_due_kro`}>
              {value.total_due_kro}
            </td>
          ))}
        </tr>
        <tr className="purple-row">
          <td className="whitespace"><b>Pending for TL approval</b></td>
          {Object.entries(userData).map(([key, value]) => (
            <td className="whitespace" key={`${key}-pending_by_tl`}>
              {value.pending_by_tl}
            </td>
          ))}
        </tr>
        <tr className="orange-row">
          <td className="whitespace"><b>Pending for Manager approval</b></td>
          {Object.entries(userData).map(([key, value]) => (
            <td className="whitespace" key={`${key}-pending_by_manager`}>
              {value.pending_by_manager}
            </td>
          ))}
        </tr>
        <tr className="blue-row">
          <td className="whitespace"><b>Send to HR</b></td>
          {Object.entries(userData).map(([key, value]) => (
            <td className="whitespace" key={`${key}-send_to_hr`}>
              {value.send_to_hr}
            </td>
          ))}
        </tr>
      </tbody>
    </Table>
    </>
                )
              })}
              
              <CardFooter className="py-4">
                {/* Pagination can be added here if necessary */}
              </CardFooter>
            </Card>
          </div>
        </Row> : null }

        {(selectedTab == 3) ? 
        <Row className="mt-2">
          <div className="col" style={{ padding: "0%" }}>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col-xl-3 col-md-2 col-xs-12">
                    <h3><b>KRO Summary</b></h3>
                    <br />
                  </div>
                  <div className="col-xl-8 col-md-2 col-xs-12 d-flex">
                    <div className="col-xl-3">
                      <label>Select Manager</label>
                      <Select
                        className="input-group-alternative mb-3"
                        name="manager"
                        options={manager}
                        onChange={handleManagerChange}
                        isClearable
                      />
                    </div>
                    <div className="col-xl-3 col-md-3 col-xs-12">
                        <h5 className="clientformtag">Select Month</h5>
                        <input type="month"
                          className="form-control"
                          name="fromdate"
                          onChange={(e) =>
                            handleManagerChange(e,'month')
                            }
                            value={monthYear}
                          />
                        </div>
                        <div className="col-xl-4 col-md-3 col-xs-12 pt-3">
                      <Button
                        className="btn-icon-clipboard"
                        type="button"
                        onClick={(e)=>downloadReport(3)}
                        title="Download Report"
                      >
                        <i className="fas fa-download" style={{ fontSize: '14px' }}></i> Export
                      </Button>
                    </div> 
                    
                  </div>
                </Row>
              </CardHeader>
              {Object.keys(summaryData).map((userName, index) => {
                const userData = summaryData[userName];
                return (
                  <>
                  <h2>{userName}</h2>
              <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col" className="whitespace"></th>
          <th>Exceptional</th>
          <th>Exceeds Expectation</th>
          <th>Meets Expectation</th>
          <th>Below Expectation</th>
          <th>Needs Action</th>
        </tr>
      </thead>
      <tbody>
     
                  {Object.entries(userData).map(([key, value]) => (
        <tr className="blue-row">
          
            <td className="whitespace" key={`${key}-user`}>
              {value.user}
            </td>
            <td className="whitespace" key={`${key}-expectional`}>
              {value.expectional}
            </td>
            <td className="whitespace" key={`${key}-exceeds_expectation`}>
              {value.exceeds_expectation}
            </td>
            <td className="whitespace" key={`${key}-meets_expectation`}>
              {value.meets_expectation}
            </td>
            <td className="whitespace" key={`${key}-below_expectation`}>
              {value.below_expectation}
            </td>
            <td className="whitespace" key={`${key}-needs_action`}>
              {value.needs_action}
            </td>
        </tr>
         ))}
        {/* <tr className="purple-row">
          <td className="whitespace"><b>Pending for TL approval</b></td>
          {Object.entries(userData).map(([key, value]) => (
            <td className="whitespace" key={`${key}-pending_by_tl`}>
              {value.pending_by_tl}
            </td>
          ))}
        </tr>
        <tr className="orange-row">
          <td className="whitespace"><b>Pending for Manager approval</b></td>
          {Object.entries(userData).map(([key, value]) => (
            <td className="whitespace" key={`${key}-pending_by_manager`}>
              {value.pending_by_manager}
            </td>
          ))}
        </tr>
        <tr className="blue-row">
          <td className="whitespace"><b>Send to HR</b></td>
          {Object.entries(userData).map(([key, value]) => (
            <td className="whitespace" key={`${key}-send_to_hr`}>
              {value.send_to_hr}
            </td>
          ))}
        </tr> */}
     
      </tbody>
    </Table>
    </>
        )
              })}
               
              
              <CardFooter className="py-4">
                {/* Pagination can be added here if necessary */}
              </CardFooter>
            </Card>
          </div>
        </Row> : null }
      </Container>
    </>
  );
};

export default KRODashboard;
