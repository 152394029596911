import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import React, { useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import {userService} from '../../services/authentication';
import SweetAlert from 'react-bootstrap-sweetalert';
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import { ExitStatus, forEachChild } from "typescript";

const Register = () => {

const rolesData = []
const [ roles, setRoles ] = useState(rolesData)
const phasesData = []
const [ phases, setPhases ] = useState(phasesData)
const fetchData = () => {
  userService.fetchRoles().then((response)=>{
    if(response.status === 200){
      setRoles(response.data.roles)
    }else {
        toast.error('Something went wrong!');
    }
  });

  userService.fetchPhases().then((response)=>{
    if(response.status === 200){
      setPhases(response.data.phases)
    }else {
      toast.error('Something went wrong!');
  }
  });
}

useEffect(() => { 
  fetchData()
  }, [] ) 

  
  const history = useHistory();
  const [inputFields, setInputfields] = useState({
    name:"",
    email: "",
    password:"",
    confirmpassword:"",
    roles:"",
    phase_id:""
  });

  function inputhandler(e){
    // e.presist();
    setInputfields({...inputFields,[e.target.name]:e.target.value});
    
  } 

  

  function signup(e){

    e.preventDefault();
    let name = inputFields.name;
    let email  = inputFields.email;
    let password = inputFields.password;
    let confirm = inputFields.confirmpassword;
    let roles = inputFields.roles;
    let phase_id = inputFields.phase_id;
    console.log(inputFields)
    userService.register(name,email,password,confirm,roles,phase_id).then((response)=>{
      console.log(response);
      if(response.status === 200){
        document.getElementById("register-form").reset(); 
        toast.success(response.data.message);
          swal({
            title: "Sign up!",
            text: response.data.message+' .Please login to continue',
            icon: "success",
            button: "OK",
          });
      }else if(response.status === 201){
        if (response.data.email) {
          toast.error(response.data.email[0]);
        }
        if (response.data.password) {
          toast.error(response.data.password[0])
        }
        if (response.data.roles) {
          toast.error(response.data.roles[0]);
        }
      }
    });
  }

  return (
    <>
    <ToastContainer />
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              {/* <small>Or sign up with credentials</small> */}
              <h2>Sign up</h2>
            </div>
            <Form role="form" id="register-form" onSubmit={signup}>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input 
                    placeholder="Name" 
                    type="text" 
                    name="name" 
                    onChange={inputhandler}
                    required
                   />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    name="email"
                    onChange={inputhandler}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    name="password"
                    onChange={inputhandler}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Confirm Password"
                    type="password"
                    autoComplete="confirmpassword"
                    name="confirmpassword"
                    onChange={inputhandler}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <select class="form-control" name="roles" id="role_id" onChange={inputhandler} required>
                  <option value=""> Select Role</option>
                  {roles.map(role => (
                      <option
                        key={role.id}
                        value={role.id}
                      >
                        {role.name}
                      </option>
                    ))}
                  </select>
                  <select class="form-control" name="phase_id" id="phase_id" onChange={inputhandler} >
                    <option value=""> Select Phase</option>
                  {phases.map(phase => (
                      <option
                        key={phase.id}
                        value={phase.id}
                      >
                        {phase.name}
                      </option>
                    ))}
                  </select>
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className="mt-4" color="primary" type="submit">
                  Create account
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
            <Col xs="6">
              <p className="text-light" onClick={(e) => history.push('login')}>
                <a
                  className="text-light"
                  href="#"
                  onClick={(e) => e.preventDefault()}
                >
                  <small>Singnin</small>
                </a>
              </p>
            </Col>
          </Row>
      </Col>
    </>
  );
};

export default Register;
