import { useState, useEffect } from "react";
import ReactDatetime from "react-datetime";

import {
  useHistory,
  Link,
} from "react-router-dom";
import {
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { reportmanageService } from "../../../services/reportmanageService";
import "../../../assets/css/custom.css";
import Header from "components/Headers/Header.js";

const DocumentReport = (props) => {
  const history = useHistory();
  const [reportdata, setReportdata] = useState([]);
  
  useEffect(() => {
    get_report_data();
  },[]);
  
  function get_report_data(){
    reportmanageService.getDocumentReportData().then((response) => {
      setReportdata(response.data); 
    });
  }
 
  
  function linkreport(path){
    history.push({
      pathname: path,
    });
 }
  function downloadReport(){
   reportmanageService.document_report_export().then((response) => {
    });
  }
  
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  //console.log(user_info)
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12"></div>
            <Row>
            <div className="col-2">
          <Button className="col-12 "  type="button" onClick={() => {linkreport('analytics_report')}}>
                 Report
             </Button>
             </div>
             { (reportdata.show == 1) ? 
             <>
              {user_info.role_id == 2 ||user_info.role_id == 3 || user_info.role_id == 11 || user_info.role_id == 14  ? 
            <div className="col-2">
          <Button className="col-12 " type="button" onClick={() => {linkreport('userreport')}}>
                Team Report
             </Button>
          </div>
          : null }
              <div className="col-2">
              <Button className="col-12 " type="button" onClick={() => {linkreport('weeklyreport')}} >
                    Weekly Report
                </Button>
              </div>
                <div className="col-2">
              <Button className="col-12 " type="button" onClick={() => {linkreport('monthlyreport')}}>
                    Monthly Report
                </Button>
              </div>
              <div className="col-3">
                <Button className="col-12 " type="button" color="primary"  >
                  Documents Report
                </Button>
              </div>
          <Col xl="12">
          <div   style={{maxWidth:'10%'}}>
                    <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                    </Button>
                    </div>
                    </Col>
                    </>
            : null }
          <br></br>
          <br></br>
          <br></br>
              <Col xl="12">
                  <Card style={{padding:'0px 0px 0px 0px'}}>
                    
                  <Table className="align-items-center table-flush custom-style" responsive>
                  
                        
                      <thead className="thead-light">
                           <th>Client</th>
                           <th>Sub Client</th>
                           <th>Bookkeeping Questionnaire</th>
                           <th>SOP</th>
                           <th>Budgets Working</th>
                           <th>Decision Making Matrix</th>
                           <th>VAT Policy</th>
                           <th>Remarks</th>
                      </thead>
                   
                  <tbody>
                  {reportdata.data &&
                    reportdata.data.length &&
                    reportdata.data != null ? (
                      reportdata.data.map((res, index) => (
                        <tr style={{backgroundColor: (index % 2 == 0) ? '#F4F4F4' : '#FFFFFF'}}>
                           <td>{res.name}</td>
                           <td>{res.sub_client}</td>
                           <td>{res.bk_questionnaire}</td>
                           <td>{res.sop}</td>
                           <td>{res.budget_working}</td>
                           <td>{res.decision_making}</td>
                           <td>{res.vat_policy}</td>
                           <td>{res.remarks}</td>
                        </tr>
                    ))
                    ):'No records found' }
                    </tbody>
                  </Table>
    
                  </Card>
              </Col>
             </Row>
            </div>
        </div>
      </Container>
    </>
  );
};

export default DocumentReport;
