
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {apiUrl} from '../constant';
import {authHeader} from '../helpers/auth-header';
import {authtoken} from 'global.js';
const axios = require("axios");
export const usermanageService = {
    getusers,
    getuserswithoutClient,
    adduser,
    createphase,
    getsingleuser,
    edituser,
    getphases,
    getsinglephase,
    editphase,
    download_report,
    getUserListforassign,
    addmanager,
    getphasesselect,
    getphasesfulllist,
    getcategories,
    createcategory,
    archiveuser,
    getarchiveusers,
    restoreUser,
    phasesList,
    showKROMaster,
    getkrotypes,
    updateUserMasterKroForm,
    getkrousers,
    showKROCalculation,
    updateUserKroCalculationForm,
    calculateRating,
    KROTypeRatingList,
    addKROTypeRating,
    getsingleKROTypeRating,
    updateKROTypeRating,
    deleteKroTypeRating,
    getkrolist,
    approveKRO,
    editKRORow,
    updateUserEligibility,
    getnotEligibleusers,
    krogetstatus,
    getusersforchatbox,
    getmanager_list ,
    KRODashboarddata,
    KRODashboarduserwisedata,
    KRODashboardsummary ,
    getassign_client ,
    addclientmanager,
    StopRecurring,
    archivekrousermaster,
    getroles,
    editTypeRatingRow,
    getrolesforKROTypeRating,
    saveAttrition,
    getclient,
    addclientuser,
    getsingleclient,
    editclient 
};

function getusers(search,role_value,phase_value){
    return axios({
        method: "get",
        url: `${apiUrl}/users?search=`+search+`&role_value=`+role_value+`&phase_value=`+phase_value,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
           if(response.status == 200){
                return response;  
           }
        }, error => {
          return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
}

function getphasesselect(){
  return axios({
      method: "get",
      url: `${apiUrl}/getphasesselect`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function getuserswithoutClient(){
  return axios({
      method: "get",
      url: `${apiUrl}/internal-team`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function getsingleuser(user_id){
  return axios({
    method: "get",
    url: `${apiUrl}/users/getuser?userid=`+user_id,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
            return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}

function adduser(userdata,sendemail = false){
  var userobj = JSON.stringify(userdata);
   console.log("=="+userdata.name+"=="+userdata.team_user);

  var bodyFormData = new FormData();
  // bodyFormData.append("user_data", userobj);
  bodyFormData.append("name", userdata.name);
  bodyFormData.append("email",userdata.email);
  bodyFormData.append("password",userdata.password);
  bodyFormData.append("role_id",userdata.roles);
  bodyFormData.append("phase_id",userdata.phases);
  bodyFormData.append("qtl_leads",userdata.qatl);
  bodyFormData.append("atl_leads",userdata.atl);
  bodyFormData.append("client_qa",userdata.client_qa ? userdata.client_qa : null);
  bodyFormData.append("client_qatm",userdata.client_qatm ? userdata.client_qatm : null);
  bodyFormData.append("manager",userdata.team_user);
  bodyFormData.append("sendemail",sendemail);
  bodyFormData.append("joining_date",userdata.joining_date);
  bodyFormData.append("exit_date",userdata.exit_date);
  bodyFormData.append("department",userdata.department);
  bodyFormData.append("otherclientemail",userdata.otherclientemail);
  
  return axios({
    method: "post",
    url: `${apiUrl}/users/create`,
    data:bodyFormData,
    headers: { "Content-Type": "application/json",Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
            return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
       return error;
      // console.log(error.response.status);
    });  
}

function createphase(phasedata){
  var phaseobj = phasedata;
  var bodyFormData = new FormData();
  bodyFormData.append("phase_name", phaseobj.phase_name);
  bodyFormData.append("desc", phaseobj.desc);
  return axios({
      method: "post",
      url: `${apiUrl}/users/createphase`,
      data:bodyFormData,
      headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
            return response;  
         }
      }, error => {
          return error.response;
        // console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function editphase(phasedata){
  var phaseobj = phasedata;
  var bodyFormData = new FormData();
  bodyFormData.append("phase_id", phaseobj.phase_id);
  bodyFormData.append("phase_name", phaseobj.phase_name);
  bodyFormData.append("desc", phaseobj.desc);

  return axios({
      method: "post",
      url: `${apiUrl}/users/editphase`,
      data:bodyFormData,
      headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
            return response;  
         }
      }, error => {
          return error.response;
        // console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function getphases(){
  return axios({
    method: "post",
    url: `${apiUrl}/users/getphases`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
       return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });   
}

function getphasesfulllist(){
  return axios({
    method: "post",
    url: `${apiUrl}/users/getphaseslist`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
       return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });   
}

function getsinglephase(phase_id){
  return axios({
    method: "get",
    url: `${apiUrl}/users/getsinglephase/`+phase_id,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
       return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}


function userroles(){
  return axios({
    method: "get",
    url: `${apiUrl}/getusers`,
    headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}

function edituser(userdata){
  var bodyFormData = new FormData();
  bodyFormData.append("user_id", userdata.user_id);
  bodyFormData.append("name", userdata.name);
  bodyFormData.append("email",userdata.email);
  bodyFormData.append("department",userdata.department);
  bodyFormData.append("role_id",userdata.role);
  bodyFormData.append("phase_id",userdata.phases);
  bodyFormData.append("select_phase_id",userdata.select_phase_id);
  bodyFormData.append("select_role_id",userdata.select_role_id);
  bodyFormData.append("selected_qtl",userdata.qatl);
  bodyFormData.append("selected_atl",userdata.atl);
  bodyFormData.append("client_qa",userdata.client_qa ? userdata.client_qa : null);
  bodyFormData.append("client_qatm",userdata.client_qatm ? userdata.client_qatm : null);
  bodyFormData.append("manager",userdata.team_user);
  bodyFormData.append("joining_date",userdata.joining_date);
  bodyFormData.append("exit_date",userdata.exit_date);
  bodyFormData.append("otherclientemail",userdata.otherclientemail);

  return axios({
    method: "post",
    url: `${apiUrl}/users/edit`,
    data:bodyFormData,
    headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });  
}

function download_report(){
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/users-export`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `users-report-${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}
function getUserListforassign(id){
  return axios({
      method: "get",
      url: `${apiUrl}/getUserListforassign/`+id,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.logaddmanager(error.response.status);
      });      
}
function addmanager(userdata){
  console.log(userdata);
  var userobj = JSON.stringify(userdata);
   console.log("=="+userdata.roles+"=="+userdata.qatl);

  var bodyFormData = new FormData();
  bodyFormData.append("roles", userdata.roles);
  bodyFormData.append("user_data",userdata.qatl);
  
  return axios({
    method: "post",
    url: `${apiUrl}/addmanager`,
    data:bodyFormData,
    headers: { "Content-Type": "application/json",Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
            return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
       return error;
      // console.log(error.response.status);
    });  
}

function getcategories(){
  return axios({
    method: "get",
    url: `${apiUrl}/getcategories`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
       return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function createcategory(data){
  var bodyFormData = new FormData();
  bodyFormData.append("id", data.id);
  bodyFormData.append("name", data.name);
  return axios({
      method: "post",
      url: `${apiUrl}/createcategory`,
      data:bodyFormData,
      headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
            return response;  
         }
      }, error => {
          return error.response;
        // console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}


function archiveuser(id){
  return axios({
    method: "delete",
    url: `${apiUrl}/users/${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}

function getarchiveusers(page){

  return axios({
      method: "get",
      url: `${apiUrl}/archive-user-list?page=${page}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function restoreUser(id){
  return axios({
    method: "get",
    url: `${apiUrl}/restore-user?id=${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}
function phasesList(){
  return axios({
    method: "GET",
    url: `${apiUrl}/phases-list`,
  })
    .then(function (response) {
       if(response.status == 200){
        return response;
       }
       return response;
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}

function showKROMaster(user){
  return axios({
    method: "get",
    url: `${apiUrl}/showKROMaster?user_id=${user}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function showKROCalculation(data,sync){
  return axios({
    method: "get",
    url: `${apiUrl}/showKROCalculation?user_id=${data.user_id}&month_year=${data.month_year}&sync=${sync}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function getkrotypes(user){
  return axios({
    method: "get",
    url: `${apiUrl}/getkrotypes`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function updateUserMasterKroForm(data,month){
  var bodyFormData = new FormData();
    bodyFormData.append("data", JSON.stringify(data));
    bodyFormData.append("month", month);
    return axios({
      method: "post",
      url: `${apiUrl}/updateMasterKroForm`,
      data: bodyFormData,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
}

function updateUserEligibility(eligible,user_id){
  var bodyFormData = new FormData();
  bodyFormData.append("kro_eligibility", eligible);
  bodyFormData.append("user_id", user_id);
  return axios({
    method: "post",
    url: `${apiUrl}/updateUserEligibility`,
    data: bodyFormData,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function updateUserKroCalculationForm(data,month,attrition){
  var bodyFormData = new FormData();
    bodyFormData.append("data", JSON.stringify(data));
    bodyFormData.append("month_year", month);
    bodyFormData.append("attrition", attrition);
    return axios({
      method: "post",
      url: `${apiUrl}/updateKroCalculationForm`,
      data: bodyFormData,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
}
function getkrousers(){
  return axios({
      method: "get",
      url: `${apiUrl}/getkrousers`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function calculateRating(data,type_id){
  return axios({
    method: "get",
    url: `${apiUrl}/calculateRating?type_id=${type_id}&actual_target=${data}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}
function KROTypeRatingList(page,type,active_role){
  return axios({
      method: "get",
      url: `${apiUrl}/KROTypeRatingList?page=${page}&type=${type}&active_role=${active_role}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function addKROTypeRating(inputs,data){
  var bodyFormData = new FormData();
    bodyFormData.append("data", JSON.stringify(data));
    bodyFormData.append("type_id", inputs.type_id);
    bodyFormData.append("roles", inputs.roles);
    return axios({
      method: "post",
      url: `${apiUrl}/addKROTypeRating`,
      data: bodyFormData,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
}
function getsingleKROTypeRating(id){
  return axios({
    method: "get",
    url: `${apiUrl}/getsingleKROTypeRating?id=`+id,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function updateKROTypeRating(data){
  return axios({
    method: "post",
    url: `${apiUrl}/updateKROTypeRating`,
    data: data,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function deleteKroTypeRating(id){
  return axios({
    method: "get",
    url: `${apiUrl}/deleteKroTypeRating/${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      console.log(response);
      // localStorage.setItem("task_count", JSON.stringify(response.data.data));
      return response;
    })
    .catch(function (error) {
      return error;
    }); 
}

function approveKRO(data){
  return axios({
    method: "post",
    url: `${apiUrl}/approveKRO`,
    data: data,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function editKRORow(value,field,id,user_id){
  var bodyFormData = new FormData();
  bodyFormData.append("month", id);
  bodyFormData.append("field", field);
  bodyFormData.append("value", value);
  bodyFormData.append("user_id", user_id);
  return axios({
      method: "POST",
      url: `${apiUrl}/kro-edit`,
      data: bodyFormData,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
        return response;
      }, error => {
       // return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function getnotEligibleusers(){
  return axios({
      method: "get",
      url: `${apiUrl}/getnotEligibleusers`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function krogetstatus(){
  return axios({
    method: "get",
    url: `${apiUrl}/krostatuslist`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function getusersforchatbox(){
  return axios({
    method: "get",
    url: `${apiUrl}/getusersformanager`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}


function getkrolist(page,data,sortfield,sortorder){
  return axios({
    method: "get",
    url: `${apiUrl}/krolisting?page=${page}&user_id=${data.user_id}&month_year=${data.month_year}&reviewer_id=${data.reviewer_id}&status=${data.status}&sort=${sortfield}&order=${sortorder}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function getmanager_list(){
  return axios({
    method: "get",
    url: `${apiUrl}/get_manager`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function KRODashboarddata(id,month_year,downloadexcel=0){
  if(downloadexcel==0){

  return axios({
    method: "get",
    url: `${apiUrl}/kroDashboard?user_id=${id}&month_year=${month_year}&downloadexcel=${downloadexcel}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
  }else{
    return axios({
      method: "get",
      responseType: 'blob', 
      url: `${apiUrl}/kroDashboard?user_id=${id}&downloadexcel=${downloadexcel}`,
      headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
    })
    .then(function (response) {
      const outputFilename = `KRO-Dashboard-report-${Date.now()}.xlsx`;
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
  // return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });  
  }
}
function KRODashboarduserwisedata(id,month_year,downloadexcel=0){
  if(downloadexcel==0){
  return axios({
    method: "get",
    url: `${apiUrl}/kroDashboardUserWise?user_id=${id}&month_year=${month_year}&downloadexcel=${downloadexcel}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
  }else{
    return axios({
      method: "get",
      responseType: 'blob', 
      url: `${apiUrl}/kroDashboardUserWise?user_id=${id}&month_year=${month_year}&downloadexcel=${downloadexcel}`,
      headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
    })
    .then(function (response) {
      const outputFilename = `KRO-Dashboard-Userwise-report-${Date.now()}.xlsx`;
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
  // return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });  
  } 
}
function KRODashboardsummary(id,month_year,downloadexcel=0){
  if(downloadexcel==0){
  return axios({
    method: "get",
    url: `${apiUrl}/KRODashboardsummary?user_id=${id}&month_year=${month_year}&downloadexcel=${downloadexcel}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
  }else{
    return axios({
      method: "get",
      responseType: 'blob', 
      url: `${apiUrl}/KRODashboardsummary?user_id=${id}&month_year=${month_year}&downloadexcel=${downloadexcel}`,
      headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
    })
    .then(function (response) {
      const outputFilename = `KRO-RatingSummary-report-${Date.now()}.xlsx`;
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
  // return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });  
  }   
}
function getassign_client(id){
  return axios({
    method: "get",
    url: `${apiUrl}/getassign_client?id=${id}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function addclientmanager(userdata){
  var bodyFormData = new FormData();
  bodyFormData.append("roles", userdata.roles);
  bodyFormData.append("user_data",userdata.clients);
  
  return axios({
    method: "post",
    url: `${apiUrl}/addclientmanager`,
    data:bodyFormData,
    headers: { "Content-Type": "application/json",Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
            return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
       return error;
      // console.log(error.response.status);
    });  
}
function StopRecurring(data){
  var bodyFormData = new FormData();
  bodyFormData.append("id", data.assigned_by);
  return axios({
      method: "post",
      url: `${apiUrl}/stopuserrecurring`,
      data: bodyFormData,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function archivekrousermaster(id){
  return axios({
    method: "get",
    url: `${apiUrl}/archivekrousermaster/${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}
function getroles(){
  return axios({
    method: "get",
    url: `${apiUrl}/getroles`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
}
function editTypeRatingRow(value,field,id){
  var bodyFormData = new FormData();
  bodyFormData.append("id", id);
  bodyFormData.append("field", field);
  bodyFormData.append("value", value);
  return axios({
      method: "POST",
      url: `${apiUrl}/editTypeRatingRow`,
      data: bodyFormData,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
        return response;
      }, error => {
       // return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function getrolesforKROTypeRating(){
  return axios({
    method: "get",
    url: `${apiUrl}/getrolesforKROTypeRating`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
}
function saveAttrition(attrition,user_id){
  var bodyFormData = new FormData();
  bodyFormData.append("attrition", attrition);
  bodyFormData.append("user_id", user_id);
  return axios({
    method: "post",
    url: `${apiUrl}/saveAttrition`,
    data: bodyFormData,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getclient(){
  return axios({
    method: "get",
    url: `${apiUrl}/getclients`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function addclientuser(userdata){
  
  var bodyFormData = new FormData();
  bodyFormData.append("name", userdata.name);
  bodyFormData.append("email",userdata.email);
  bodyFormData.append("password",userdata.password);
  
  return axios({
    method: "post",
    url: `${apiUrl}/addclientuser`,
    data:bodyFormData,
    headers: { "Content-Type": "application/json",Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
            return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
       return error;
      // console.log(error.response.status);
    }); 
 }
 function getsingleclient(user_id){
  return axios({
    method: "get",
    url: `${apiUrl}/getsingleclient?userid=`+user_id,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
            return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function editclient(userdata){
  var bodyFormData = new FormData();
  bodyFormData.append("user_id", userdata.user_id);
  bodyFormData.append("name", userdata.name);
  bodyFormData.append("email",userdata.email);

  return axios({
    method: "post",
    url: `${apiUrl}/editclient`,
    data:bodyFormData,
    headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });  
}