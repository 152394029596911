import { useState,useEffect } from "react";
import firebase,{ initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import {firebasemanageService} from './services/firebaseService';


const firebaseConfig = {
    apiKey: "AIzaSyDGB-jMVVydCN01hjQG9NklVMRATtixO1A",
    authDomain: "testing-app-b40fd.firebaseapp.com",
    databaseURL: "https://testing-app-b40fd.firebaseio.com",
    projectId: "testing-app-b40fd",
    storageBucket: "testing-app-b40fd.appspot.com",
    messagingSenderId: "162122575974",
    appId: "1:162122575974:web:21afe60795870e10d8fda7",
    measurementId: "G-HS3L0N38EE"
  };

//   // Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
export const gettoken = (setTokenFound) => {
  var firebase_token = localStorage.getItem('firebasetoken');
  // console.log(firebase_token);
  // console.log('firebase to');
  if(firebase_token == null || firebase_token == undefined){
    return getToken(messaging, {vapidKey: 'BKiBpQw0VaXCzV7fTCNRBFbZ_qCPZ5UqCF0xYrc5Xr-MRf4JK0Pe1wTtHerYbKbraPRf_AX1rVPnDKKjcOISS1c'}).then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        setTokenFound(true);
            firebasemanageService.storeToken(currentToken).then((res)=>{
              //  console.log(res); 
              localStorage.setItem('firebasetoken', currentToken);
            }).catch((err)=>{
                
            });
      
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
  }else {

  }
}


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

