import { useState, useEffect } from "react";
import React from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import 'assets/css/themecustom-style.css';
import { useHistory } from "react-router-dom";
import axios from "axios";
import { usermanageService } from '../../services/usermanageService';
import { taskmanageService } from '../../services/taskmanageService';
import { ToastContainer, toast } from 'react-toastify';
import { clientmanageService } from "../../services/clientmanageService";
import { getAllJSDocTags } from "typescript";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Select from 'react-select';
const MisGovernanceData = (props) => {
  const history = useHistory();
  const params = useParams();
  const [currentPage, setCurrentPage] = useState(null);
  const [lastPage, setLastPageData] = useState(0);
  const [data, setData] = useState({});
  const [misData, setMISData] = useState([]);
  const [months, setMonths] = useState([]);
  const [filterFields, setFilterFields] = useState([]);
  const [filterType, setFilterType] = useState('open');
  const [selectedmanager,setSelectedmanager]=useState('');
  const [selectedmanagerlabel,setSelectedmanagerLabel]=useState(null);
  
useEffect(() => {
    getList();
    getmanagerList();
  }, [params]);
  const handlePageClick = (e, index) => {
    e.preventDefault();
    getClientDocList(index);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    getClientDocList(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    getClientDocList(next);
  };

  const getList = async (id='') => {
    clientmanageService.governanceCalldashboard(id).then((response) => {
        //setData(response.data.data);
        setMonths(response.data.months);
    });
    if(params.type=='mis-basedonrating'){
        clientmanageService.getGovernanceMISdashboard(id).then((response) => {
        setMISData(response.data.data);
        });
    }else{
        clientmanageService.getGovernanceMISActiondashboard(id).then((response) => {
            setMISData(response.data.data);
        });
    }
  };

  const companyFilter = (e, index) => {
    const cloneValues = { ...filterFields };
    cloneValues[index] = {
      ...cloneValues[index],
      field: e.target.name,
      value: encodeURIComponent(e.target.value),
      operator: '='
    };
    setFilterFields(cloneValues);
    getClientDocList(1, null, cloneValues, filterType);
  };

 
  const [manager,setmanager]=useState([]);
  const getmanagerList = ()=>{
    usermanageService.getmanager_list().then((response) => {
        setmanager(response.data.users)
    })
  }

  const editClick = (id) => {
    history.push({
      pathname: `editannounce/${id}`,
    });
  };

  const handleClick = (id) => {
    taskmanageService.deleteAnnouncement(id).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message);
        location.reload();
      } else {
        for (const field in response.data) {
          toast.error(response.data[field][0]);
        }
      }
    });
  };
  const handlerejeditRow = (index,client) => {
    // if(phaseedit == true || taskedit == true || analystedit == true){
      let temp_state = {...data};
      
      let temp_element = { ...temp_state[client][0] };
      
      temp_element.editing = true;
      temp_state[client][0]= temp_element;
      
      setData(temp_state);
      // }

  };
  const editRejField = (value, field, id,index) => {
    clientmanageService.editgovernancefreqdata(value, field, id).then((response) => {
      let temp_st = {...data};
      let temp_ele = { ...temp_st[id][0] };
      if(response.status == 200){
        toast.success(response.data.message);
      }else{
          toast.error(response.data.message);
      }
      temp_ele.editing = false;
      // temp_ele[field] = value;
      
    
       if (field != "status") {
        temp_ele[field] = value;
      }
      temp_st[id][0] = temp_ele;
      setData(temp_st);
    })
  }
  const [filtertype,setfiltertype] = useState('dashboard');
  const getData = (title) =>{
    setmanager([])
    setSelectedmanager('');
    setSelectedmanagerLabel(null);
   if(title=='dashboard'){
      history.push({
        pathname: "/admin/governancedashboard"
      });
      
      
    }else{
      history.push({
        pathname: "/admin/misgovernancedata/"+title
      });
    }
  }


  const companyinputhandler = (selectedOption) => {
    setSelectedmanager(selectedOption ? selectedOption.value : null);
    setSelectedmanagerLabel(selectedOption ? selectedOption : null);
    getList(selectedOption ? selectedOption.value : '');
  }

   function downloadReport(){
    
    if(params.type=='mis-basedonrating'){
        clientmanageService.getGovernanceMISdashboard(selectedmanager,1).then((response) => {
        
        });
    }else{
        clientmanageService.getGovernanceMISActiondashboard(selectedmanager,1).then((response) => {
            
        });
    }
  }
  const getDetails = (month,title,username)=>{
    history.push({
      pathname: "/admin/misgovernancedatadetails/"+month+"/"+title+"/"+username
    });
  }
  const getDetailsaction = (month,title,username)=>{
    history.push({
      pathname: "/admin/misgovernanceactiondetails/"+month+"/"+title+"/"+username
    });
  }
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
      <Row >
        
      <button type="button"  className=" btn btn-secondary text-center" style={{backgroundColor:params.type=='' ? '#11cdef' : ''}} onClick={() => getData('dashboard')}>
        Governance    Dashboard
      </button> 
      <button type="button"  className=" btn btn-secondary text-center" style={{backgroundColor:params.type=='mis-basedonrating' ? '#11cdef' : ''}} onClick={() => getData('mis-basedonrating')}>
      MIS- based on rating
      </button>
      <button type="button"  className=" btn btn-secondary text-center" style={{backgroundColor:params.type=='mis-onactionitem' ? '#11cdef' : ''}} onClick={() => getData('mis-onactionitem')}>
      MIS - on action item
      </button>
         


        </Row>
        <Row className="mt-2">
          <div className="col" style={{ padding: "0%" }}>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center row">
                  <div className="col-xl-3 col-md-2 col-xs-12">
                    <h3><b>{params.type=='mis-basedonrating' ? 'MIS- based on ratings' : 'MIS- based on Action'}</b></h3>
                  <br></br></div>
                  <div className="col-xl-4 col-md-2 col-xs-12  d-flex">
                  <div className="col-xl-12">
                  <label>Select Manager</label>
                  <Select 
                            className="input-group-alternative mb-3" 
                            name="manager" 
                            allowSelectAll={true} 
                            options={manager} // set list of the data
                            value={selectedmanagerlabel}
                            onChange={companyinputhandler} 
                            required
                          />
                          </div>
                          <div>
                           <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                  </Button>
                  </div>
                  </div>
                </Row>
              </CardHeader>
            <hr></hr>
              {Object.keys(misData).map((userName, index) => {
                let alluser = Object.keys(misData)
                .map(userName => userName.split('@')[1])  
                .join('@').replace(/^@|@$/g, '').trim();
                let [user, userid] = userName !== 'All' ? userName.split('@') : [userName, alluser];
                const clientData = misData[userName];
                return (
                  <>
                  <h2>{user}</h2>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <th scope="col" className="whitespace"></th>
                      {Object.values(months).map((month, index) => (
                          <th scope="col" className="whitespace" key={index}>{month}</th>
                        ))}
                    </thead>
                    {params.type=='mis-basedonrating' ?
                    <tbody>
                      <tr className='blue-row'>
                        <td  className="whitespace">Total Calls </td>
                        {Object.keys(months).map((monthKey, index) => {
                          const monthData = clientData[monthKey];
                          return (
                            <td  className="whitespace" key={index} style={{cursor:'pointer'}} 
                            onClick={ () => getDetails(monthKey, 'total', userid) }
                            >
                              {monthData ? monthData.total_calls : 'NA'}
                            </td>
                          );
                        })}
                      </tr>
                      <tr className='blue-row'>
                        <td  className="whitespace"> Calls due</td>
                        {Object.keys(months).map((monthKey, index) => {
                          const monthData = clientData[monthKey];
                          return (
                            <td  className="whitespace" key={index} style={{cursor:'pointer'}} 
                            onClick={ () => getDetails(monthKey, 'due', userid) }
                            >
                              {monthData ? monthData.total_due_client_calls : 'NA'}
                            </td>
                          );
                        })}
                      </tr>
                      <tr className='orange-row'>
                        <td  className="whitespace">Happy clients </td>
                        {Object.keys(months).map((monthKey, index) => {
                          const monthData = clientData[monthKey];
                          return (
                            <td  className="whitespace" key={index} style={{cursor:'pointer'}} 
                            onClick={() => getDetails(monthKey, 5, userid) }
                            >
                              {monthData ? monthData.rating_5_count : 'NA'}
                            </td>
                          );
                        })}
                      </tr>
                      <tr className='blue-row'>
                        <td  className="whitespace">Not happy clients</td>
                        {Object.keys(months).map((monthKey, index) => {
                          const monthData = clientData[monthKey];
                         
                          return (
                            <td  className="whitespace" key={index} style={{cursor:'pointer'}}
                            onClick={() => getDetails(monthKey, 1, userid) }
                            >
                              {monthData ? monthData.rating_1_count : 'NA'}
                            </td>
                          );
                        })}
                      </tr>
                      <tr className='purple-row'>
                        <td  className="whitespace">No Show clients</td>
                        {Object.keys(months).map((monthKey, index) => {
                          const monthData = clientData[monthKey];
                         
                          return (
                            <td  className="whitespace" key={index} style={{cursor:'pointer'}} 
                            onClick={ () => getDetails(monthKey, 0, userid)}
                            >
                              {monthData ? monthData.rating_0_count : 'NA'}
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                    :
                    <tbody>
                      <tr className= 'blue-row'>
                        <td  className="whitespace">Total Action Items</td>
                        {Object.keys(months).map((monthKey, index) => {
                          const monthData = clientData[monthKey];
                          return (
                            <td  className="whitespace" key={index}
                            style={{cursor:'pointer'}}
                            onClick={ () => getDetailsaction(monthKey, 'total', userid) }
                            >
                              {monthData ? monthData.total_action_items : 0}
                            </td>
                          );
                        })}
                      </tr>
                      <tr className='orange-row'>
                        <td  className="whitespace">Not Started </td>
                        {Object.keys(months).map((monthKey, index) => {
                          const monthData = clientData[monthKey];
                          return (
                            <td  className="whitespace" key={index}
                            style={{cursor:'pointer'}}
                            onClick={ () => getDetailsaction(monthKey, 'Not_Started', userid) }
                            >
                              {monthData ? monthData.status_counts?.Not_Started : 0}
                            </td>
                          );
                        })}
                      </tr>
                      <tr className='blue-row'>
                        <td>In progress</td>
                        {Object.keys(months).map((monthKey, index) => {
                          const monthData = clientData[monthKey];
                         
                          return (
                            <td  className="whitespace" key={index}
                            style={{cursor:'pointer'}}
                            onClick={ () => getDetailsaction(monthKey, 'In_progress', userid) }
                            >
                              {monthData ? monthData.status_counts?.In_progress : 0}
                            </td>
                          );
                        })}
                      </tr>
                      <tr className='purple-row'>
                        <td>Closed</td>
                        {Object.keys(months).map((monthKey, index) => {
                          const monthData = clientData[monthKey];
                         
                          return (
                            <td  className="whitespace" key={index}
                            style={{cursor:'pointer'}}
                            onClick={ () => getDetailsaction(monthKey, 'Close', userid) }
                            >
                              {monthData ? monthData.status_counts?.Close : 0}
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                    }
                  </Table>
                  <br></br>
                  </>
                )
              })}
              <CardFooter className="py-4">
              
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default MisGovernanceData;
