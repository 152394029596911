import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  
} from "reactstrap";
import React, { useState,useEffect } from "react";
import { useHistory,useLocation } from "react-router-dom";
import {apiUrl} from '../../constant';
import {userService} from '../../services/authentication';
import { ToastContainer, toast } from 'react-toastify';
import VerifyTwoFactor from './VerifyTwoFactor'; // Import the VerifyTwoFactor component
// import './credentialstyle.css';
const axios = require("axios");

const Login = () => {
  const history = useHistory();
  // const location = useLocation();
  
  // const searchParams = new URLSearchParams(location.search);
  // const email = searchParams.get("email");
  // const encryptedPassword = searchParams.get("password");
  // const password = (encryptedPassword!=null) ? atob(decodeURIComponent(encryptedPassword)) : null;
  const [remember, setRemember] = useState(false);
  const [is2FARequired, setIs2FARequired] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const [userId, setUserId] = useState(null);
  const [inputFieldEmail, setInputemail] = useState({
    email: "",
  });

  const [inputFieldpass, setInputpassword] = useState({
    password: "",
  });

  const [error,setError]=useState(null);
  const errorDiv = error ? (
    <UncontrolledAlert className="alert-danger" fade={false}>
      {error}
    </UncontrolledAlert>
  ) : (
    ""
  );

  const authenticate = (e) => {
    setIsLoading(true); 
    e.preventDefault();
    var email = inputFieldEmail.email;
    var pass = inputFieldpass.password;
    
    if(email == '' || pass == ''){
      toast.error('All fields are Required !');
      return false;
    }
    var bodyFormData = new FormData();
    userService.login(inputFieldEmail.email,inputFieldpass.password,remember).then((response)=>{
       if(response.status == 200){
        if (response.data.status == '2fa_required') {
          setIs2FARequired(true);
          setUserId(response.data.user_id);
        } else {
        toast.success(response.data.message);
        }
        setIsLoading(false);
        // history.push('/admin');
       }else{
        toast.error("Invalid email or password!!!");
        setIsLoading(false);
       }
    });
    return;
  }

  const inputsHandleremail = (e) => {
    setInputemail({ [e.target.name]: e.target.value });
  };

  const inputsHandlerpass = (e) => {
    setInputpassword({ [e.target.name]: e.target.value });
  };


  function redirect(path){
    //  path.preventDefault();
     history.push(path);    
  }
  // useEffect(() => {
  
  //   if (email!=null && password!=null) {
  //     // Automatically submit the form when email and password are set
  //     authenticate();
  //   }
  // }, [location.search]);
  const handle2FAVerificationSuccess = () => {
    // Proceed with login after 2FA verification
    authenticate({ preventDefault: () => {} });
  };


  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <ToastContainer />
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <h1>Sign in</h1>
            </div>
            {errorDiv}
            {error && <UncontrolledAlert className="alert-danger" fade={false}>{error}</UncontrolledAlert>}
            {is2FARequired ? (
              <VerifyTwoFactor userId={userId} onVerificationSuccess={handle2FAVerificationSuccess} />
            ) : (
            <Form id="login-form" role="form" onSubmit={(e) => authenticate(e)}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    name="email"
                    autoComplete="new-email"
                    onChange={inputsHandleremail}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    name="password"
                    autoComplete="new-password"
                    onChange={inputsHandlerpass}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                  <Input
                    type="checkbox"
                    name="remember"
                    onChange={(e) => setRemember(e.target.checked)}
                  />
                  <label style={{fontSize:"13px"}}>
                    &nbsp; &nbsp;<span className="text-muted">Remember me</span>
                  </label>
                </FormGroup>
              
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                      <span>Loading...</span> // Simple text loader
                    ) : (
                      <span>Sign in</span>
                    )}
                </Button>       
              </div>
            </Form>
             )}
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <p className="text-light" onClick={(e) => history.push('forgotpassword')}>
              <a
                className="text-light"
                href="/auth/register"
                onClick={(e) => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </p>
          </Col>
          <Col className="text-right" xs="6">
            <p className="text-light" onClick={(e) => history.push('register')}>
              <a
                className="text-light"
                href="/auth/register"
                onClick={(e) => e.preventDefault()}
              >
                <small>Create new account</small>
              </a>
            </p>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
