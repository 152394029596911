import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { ToastContainer, toast } from 'react-toastify';
import { domainUrl} from '../../constant';
import '@flowjs/flow.js';
import Flow from "@flowjs/flow.js";
import { async } from "@firebase/util";
import { useHistory,useParams } from "react-router-dom";
import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
import { manageService } from "../../services/manageaccountservices";
import Select from 'react-select';
const axios = require("axios");

const AddClientTransition = (props) => {
  const params  = useParams();
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [clientdata, setClientdata] = useState([]);
  const [clientsearch, setclientsearch] = useState([{'label':'Select Client',value:'al_key_1'}]);
  const [trans_data,settransData] = useState([]);
  const [formdetails,setformdetails] = useState({});
  const [taskdetailPopup,settaskdetailPopup] = useState(false);
  const [allclient, setAllclient] = useState(true);
  const regionOption = useRef(null);
  const [changeclient,setchangeclient] = useState(false);
  const [status,setstatus] = useState([]);
  const [state, setState] = useState({});
  const [ctrans, setCurrentTrans] = useState('');
  const [disablestate, setdisablestate] = useState(false);


  const handlePageClick = (e, index) => {
    e.preventDefault();
    get_list(index);
  };

  const formatDate = (dateStr) => {
    if(dateStr!=null && dateStr!=''){

      const [year, month, day] = dateStr.split('-');
      let newDate = `${day}-${month}-${year}`;
      return newDate;
    }
  };
  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_list(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_list(next);
  };

  const get_list = async (pageNumber = 1,e) => { 
   
    let  textbox = '';
    if(e != null){
       textbox = e.label;
    }
    taskmanageService.getsubclientTasks(pageNumber,clientsearch,textbox).then((response) => {console.log(response.data.data.data)
      setClientdata(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setLastPageData(response.data.data.last_page);
    });
  };


useEffect(() => {
  get_list();
  get_unique_companieslist();
  if(params.client_id!='undefined' && params.client_id!=undefined){
      getTransitionData(params.client_id)
      getclientstatus();
  }
}, []);

const generateForm = (id,client_id,trans_id,index) => {
  setCurrentTrans(trans_id)
  clientmanageService.generateForm(id,client_id,trans_id).then((response) => {
    console.log(response.data)
    setformdetails(response.data);
    setState(response.data);
    console.log("f")
    console.log(formdetails)
    settaskdetailPopup(!taskdetailPopup);
  })
}
const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
function get_unique_companieslist(){
  clientmanageService.getuniqueclient().then((response)=>{
    setUniqueCompaniesData(response.data);
  });
}

  function getTransitionData(clientname=''){
    //setsubclientlist([]);
    
    
    clientmanageService.gettransitionData(clientname).then((response) => {
      
        if(response.status === 200){
          setchangeclient(true);
          settransData(response.data)
    
          
        }else {
          toast.error('Something went wrong!');
        }
    });
  }
  function getclientstatus(){
    taskmanageService.getclienttransitionstatus().then((res) => {
          
      if(res.status === 200){
        setstatus(res.data.status);
        console.log(status)
      }
  });
  }
  function setclient(e){ 
    
    getTransitionData(e.value);
    getclientstatus();
    
    
  }
const companyfilter = (e,index) => {
 get_list(1,e);
 setAllclient(false)
};
const initialStateDataRejection = {
  id: "",
  type : "",
  value : "",
  comment : "",
  errors : []
};
const initiaPhaseData = {
  phases: [],
};
const initialStateData = {
  task_id: "",
  analyst_id: "",
  due_date: "",
};
const [inputFields, setInputfields] = useState(initialStateData);
const [inputFieldsRejection, setInputfieldsRejection] = useState(initialStateDataRejection);
const [inputPhasesRejection, setinputPhasesRejection] = useState(initiaPhaseData);
const [showRejection,setshowRejection] = useState(false);
const [rejectionindex,setrejectionindex] = useState('');
const [errorcategory,seterrorcategory] = useState([]);
const [phaselistsselection, setPhaseListsSelection] = useState([]);
const [inputPhases, setinputPhases] = useState(initiaPhaseData);
 
const [errorbox,seterrorbox] = useState([
  0
]);

const [rowsError, setRowsError] = useState(1);

const editField = (client_id,trans_id, field, value,index) => {
  clientmanageService.updateTransData(client_id, trans_id, field,value).then((response) => {
    let temp_st = [...trans_data];
    let temp_ele = { ...temp_st[index] };
   
    if(response.status == 200){
      getTransitionData(client_id);
        toast.success(response.data.success)
    }else{
        toast.error(response.data.error);
    }
    temp_ele.editing = false;
    temp_ele.mainediting = false;
    
  });
};
function inputhandler(e,index,key) {
  let temp_st = {...state};
  let temp_ele = { ...temp_st[key][index] };
  temp_ele.remarks = e.target.value;
  temp_st[key][index] = temp_ele;
  setState(temp_st);

}

function add_phase_selection(e) {
  setdisablestate(true)
  e.preventDefault();
  clientmanageService.updateTransitionForm(state).then((response) => {
    console.log(response);

    if (response.status == 200) {
      toast.success(response.data.message);
    }
    setdisablestate(false)
  });
}
  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
    
        <Row className="mt-5">
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
            {(uniquecompanieslist.length > 0 && (user_info.role_id != 13) && (params.client_id=='undefined' || params.client_id==undefined))?
                   <>
              <CardHeader className="border-0">
              <Row className="filter" style={{padding:'0 1%'}}>
               <div className="filter" style={{width:'80%'}}>
                   <div className="col-xl-6 col-md-6 col-xs-12">
                    <h3 className="clientformtag"> Client</h3>
                    <Select 
                   
                    className="input-group-alternative mb-3" 
                    name="user_id"
                    options={uniquecompanieslist} // set list of the data
                    onChange={(e) => { setclient(e)}}
                    defaultValue={clientsearch}
                    searchable
                    isClearable={false}
                  />
                  </div>
                    </div>
                </Row>
              </CardHeader>
              </>
               : null }
              
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    <th>Documents</th>
                    <th scope="col">Applicability</th>
                    <th scope="col">Forms</th>
                    <th scope="col">Status</th>
                    <th scope="col">Last Updated By</th>
                    <th scope="col" className="whitespace">Last Updated Date</th>
                    <th scope="col" className="whitespace">Remark</th>
                    
                </thead>
                <tbody>
                  
                {changeclient == true && trans_data && trans_data.length
                          ?                               
                          trans_data.map((tdata,i) => (
                              <tr key={i} className={((i % 2 == 0)? 'purple-row': 'orange-row' )}>
                                <td>{tdata.name}</td>
                                {/* <td onClick={()=> handlemaineditRow(i)}>
                            {!tdata.updated_at ? (
                              <input type="date"
                              className="form-control"
                                defaultValue={tdata.updated_at}
                              
                                onBlur={(e) =>
                                  editField(
                                    tdata.client_id,
                                    tdata.trans_id,
                                    "updated_at",
                                    e.target.value,
                                    i
                                  )
                                }
                              /> 
                            ) : (
                              <span>{formatDate(tdata.updated_at)}</span>
                            )}
                            </td>  */}
                                <td  className="whitespace">
                                  <select className="form-control" name="applicability"
                                  
                                  onChange={(e) =>
                                  editField(
                                    tdata.client_id,
                                    tdata.trans_id,
                                    "applicability",
                                    e.target.value,
                                    i
                                    )
                                }
                                value={tdata.applicability  ? tdata.applicability : 2}
                                >
                                  <option value="">Select</option>
                                  <option  value="1" key="1">Yes</option>
                                  <option  value="0" key="0">No</option>
                                  <option  value="NA" key="2">Not Applicable</option>
 
                            
                          </select></td>
                          <td>
                            {tdata.applicability == 1 ?
                          <Button color="success" type="button" onClick={() => generateForm(tdata.id,tdata.client_id,tdata.trans_id, i)}>Generate Form</Button>
                          :
                          <Button color="secondary" type="button">Generate Form</Button>
                            }
                          </td>
                               <td >
                               <select style={{width:'100px'}} className="form-control" name="status" 
                              value={tdata.status ? tdata.status : ''}
                               onChange={(e) =>
                               editField(
                                 tdata.client_id,
                                 tdata.trans_id,
                                 "status",
                                 e.target.value,
                                 i
                                 )}
                                 
                               >
                              <option value="">Select Status</option>
                              {(status).map((valuel,k) =>
                                  <option key={k} value={valuel.id}>
                                    {valuel.name}
                                  </option>
                              )}
                          </select>
                          
                          </td> 
                          <td>
                          <input readonly
                      disabled
                      style={{color:"#000"}} className="form-control" type="text" name="update_by" defaultValue={tdata.updated_by ? tdata.updated_by : ""}  />
                          </td>
                          <td>
                          
                            <Input
                      type="date"
                      placeholder="Select date"
                      name="last_updated_date"
                      readonly
                      disabled
                      style={{color:"#000"}}
                      value={tdata.updated_date ? tdata.updated_date : ""}
                              
                                onBlur={(e) =>
                                  editField(
                                    tdata.client_id,
                                    tdata.trans_id,
                                    "updated_at",
                                    e.target.value,
                                    i
                                  )
                                }
                      
                    />
</td>
<td><Input
                         
                            bsSize="sm"
                            name="remarks"
                            placeholder="Remarks"
                            id="example-date-input"
                            type="textarea"
                            defaultValue={tdata.remarks ? tdata.remarks : ""}
                            onBlur={(e) =>
                              editField(
                                tdata.client_id,
                                tdata.trans_id,
                                "remarks",
                                e.target.value,
                                i
                              )
                            }

                            
                          /></td>
                              </tr>
                            ))
                          :
                           ( <p>No Data Found</p> )}
                </tbody>
              </Table>


            </Card>
          </div>
        </Row>
        <Row>
          <Modal
            size="lg"
            className="taskdetailmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => settaskdetailPopup(!taskdetailPopup)}
            isOpen={taskdetailPopup}
          >
            <div className="modal-header">
            <h3>Client Onboarding Checklist </h3>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => settaskdetailPopup(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{paddingTop:'0px'}}>
           
            <Form
                role="form"
                id="phase-selection-form"
                onSubmit={add_phase_selection}
            
              >
              <Table  className="align-items-center table-flush" responsive>
                <thead>
                  <tr style={{border : "1px solid #000"}}>
                     <th style={{border : "1px solid #000"}}>Sr.No.</th> 
                    <th style={{border : "1px solid #000"}} className="whitespace">Particulars</th>
                    <th style={{border : "1px solid #000"}} className="whitespace">scenario</th>
                    { ctrans != 1 ?
                    <th style={{border : "1px solid #000"}} className="whitespace">Procedures</th>
                    : null }
                    <th style={{border : "1px solid #000"}} className="whitespace">Client Remarks</th>
                    
                  </tr>
                </thead>
                <tbody>
                

      
      {
         Object.entries(formdetails).map(([key, value],index) =>
      
           
           <>
             {value.map((g,i) => (
            <tr className="whitespace" style={{border : "1px solid #000"}}>
              {(i ==0)?
              <><td style={{border : "1px solid #000"}}>{index+1}</td><td style={{border : "1px solid #000"}}>{key}</td></>:<><td style={{border : "1px solid #000"}}></td><td style={{border : "1px solid #000"}}></td></>}
              
             <td className="whitespace" style={{border : "1px solid #000"}}>{g.scenerio}</td>
             { ctrans != 1 ?
             <td className="whitespace" style={{border : "1px solid #000"}}>{g.procedures}</td>
             : null }
             <td className="whitespace" style={{width:"50%",border : "1px solid #000"}}><Input
                         
                         bsSize="sm"
                         name="form-remarks"
                         placeholder="Remarks"
                         id="example-date-input"
                         type="textarea"
                         defaultValue={g.remarks ? g.remarks : ""}
                         onChange={(e) => inputhandler(e, i,key)}
                         

                         
                       /></td>
             </tr>
           ))}
           </>
        //  </tr>
       )
      }
    


                        
                </tbody>
              </Table>
              </Form>
            </ModalBody>
            <ModalFooter><Button color="primary" type="button" onClick={add_phase_selection} disabled={disablestate}>
            {disablestate ? 'Sending...' : 'Save'} 
              </Button>
</ModalFooter>
           </Modal>
         
        </Row>

      </Container>
    </>
  );
};


export default AddClientTransition;
