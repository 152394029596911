import { useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import swal from "sweetalert";
import { AgGridReact, AllCommunityModules, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { userService } from "../../../services/authentication";
import { reportmanageService } from "../../../services/reportmanageService";
import "../../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { forEachChild } from "typescript";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Navbuttons from "components/Navigations/Navbuttons.js";
import { stringToArray } from "ag-grid-community";

const DailyReport = (props) => {
  const history = useHistory();
  const [reportdata, setReportdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [sortfield, setsortfield] = useState("");
  const [sortorder, setsortorder] = useState("asc");
  function sortTable(field) {
    console.log(field);
    setsortfield(field);
    if (sortorder == "asc") {
      setsortorder("desc");
    } else {
      setsortorder("asc");
    }
  }
  useEffect(() => {
    get_report_data();
  },[]);
  
  function get_report_data(e=''){
    if(e!=''){
      var phase = e.target.value;
    }else{
      var phase = '';
    }
    reportmanageService.getDailyReportData(phase).then((response) => {
      console.log(response.data) 
      setReportdata(response.data); 
    });
  }
  
  function report_details(user_id,role_id,status=''){
     history.push({
       pathname: "daily_report_details/"+user_id+"/"+role_id+"/"+status,
     });

  }

  function break_line(paragraph){
     return paragraph.replaceAll("\n",'<br>');
  }

  const allreport = () =>{
    history.push({
      pathname: 'analytics_report',
    });
  }
  
  const dailyreport = () =>{
    history.push({
      pathname: 'dailyreport',
    });
  }

  const weeklyreport = () =>{
    history.push({
      pathname: 'weeklyreport',
    });
  }
  const monthlyreport = () =>{
    history.push({
      pathname: 'monthlyreport',
    });
  }
  function downloadReport(){

    reportmanageService.daily_report_export().then((response) => {
    
    });
  }
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12"></div>
            <Row>
            <div className="col-2">
             <Button className="col-12 "  type="button" onClick={allreport}>
                 Report
             </Button> 
             </div>
             {user_info.role_id == 2 ||user_info.role_id == 3 || user_info.role_id == 11 || user_info.role_id == 14  ? 
            <div className="col-2">
          <Button className="col-12 " type="button" onClick={() => {linkreport('userreport')}}>
                Team Report
             </Button>
          </div>
          : null }
            <div className="col-3">
             <Button className="col-12 " color="primary" type="button" onClick={dailyreport}>
                Daily Report
             </Button>
          </div>
          { (reportdata.show == 1) ? 
             <>
          <div className="col-3">
          <Button className="col-12 " type="button" onClick={weeklyreport}>
                Weekly Report
             </Button>
          </div>
         
          <div className="col-3">
          <Button className="col-12 " type="button" onClick={monthlyreport}>
                Monthly Report
             </Button>
          </div>
          <Col xl="12">
          <div   style={{maxWidth:'10%'}}>
                    <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                    </Button>
                    </div>
                    </Col>
          </> : null }
          <br></br>
          <br></br>
          <br></br>
          
              <Col xl="12">
              
                  <Card style={{padding:'0px 0px 0px 0px',marginBottom:"10px"}}>
                    {user_info.role_id == 6 && reportdata.phases ? 
                      <select className="form-control col-3" onChange={(e) => get_report_data(e)}  name="value" >
                      <option value="" key="al_key_1">All Phases</option>
                      {(reportdata.phases).map((valuel,k) =>
                          <option key={k} value={valuel.id}>
                            {valuel.desc}
                          </option>
                      )}
                  </select>
                    : null}
                  <Table className="align-items-center table-flush custom-style" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th>Client</th>
                          <th>In progress</th>
                          <th>Completed</th>
                          <th>Not Started</th>
                          <th>On Hold</th>
                        </tr>
                      </thead>
                  <tbody>
                  {reportdata.data &&
                    reportdata.data.length &&
                    reportdata.data != null ? (
                      reportdata.data.map((user, index) => (
                        
                        <tr style={{backgroundColor: (index %2 ==0) ? '#F4F4F4' : '#FFFFFF'}}>
                            <td>{user.name}</td>
                            <td><span class="inprogress"  style={{cursor:'pointer'}} onClick={() => { report_details(user.id,user.role_id,'Inprogress') }}>{user.Inprogress != undefined ? user.Inprogress : 0}</span></td>
                            <td><span class="complete" style={{cursor:'pointer'}} onClick={() => { report_details(user.id,user.role_id,'Complete') }}>{user.Complete != undefined ? user.Complete : 0}</span></td>
                            <td><span class="notstarted" style={{cursor:'pointer'}} onClick={() => { report_details(user.id,user.role_id,'NotStarted') }}>{user.NotStarted != undefined ? user.NotStarted : 0}</span></td>
                            <td><span class="notstarted" style={{cursor:'pointer'}} onClick={() => { report_details(user.id,user.role_id,'Onhold') }}>{user.Onhold != undefined ? user.Onhold : 0}</span></td>
                            
                         </tr>
                        
                    ))
                    ):'No records found' }
                    </tbody>
                  </Table>
    
                  </Card>
                  {reportdata.phase0_exist && reportdata.phase0_exist==1 ? 
                  <Card style={{padding:'0px 0px 0px 0px'}}>
                  <Table className="align-items-center table-flush custom-style" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th>Client</th>
                          <th>Missing Info</th>
                          <th>All Info Received</th>
                          <th> Missing info but job can be started</th>
                        </tr>
                      </thead>
                  <tbody>
                  {reportdata.data1 &&
                    reportdata.data1.length &&
                    reportdata.data1 != null ? (
                      reportdata.data1.map((user1, index1) => (
                        
                        <tr style={{backgroundColor: (index1 %2 ==0) ? '#F4F4F4' : '#FFFFFF'}}>
                            <td>{user1.name}</td>
                            <td>{user1.missing_info}</td>
                            <td>{user1.all_info}</td>
                            <td>{user1.missing_but_job}</td>
                         </tr>
                        
                    ))
                    ):'No records found' }
                    </tbody>
                  </Table>
    
                  </Card>
                  :''}
              </Col>
             </Row>
            </div>
        </div>
      </Container>
    </>
  );
};

export default DailyReport;
