import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { ToastContainer, toast } from 'react-toastify';
import { domainUrl} from '../../constant';
import '@flowjs/flow.js';
import Flow from "@flowjs/flow.js";
import { async } from "@firebase/util";
import { useHistory,useParams } from "react-router-dom";
import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
import { manageService } from "../../services/manageaccountservices";
const axios = require("axios");

const AddExternalCCRP = (props) => {
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [clientdata, setClientdata] = useState([]);
  const [clientsearch, setclientsearch] = useState('');
  const [taskdetails,settaskdetails] = useState({});
  const [taskdetailPopup,settaskdetailPopup] = useState(false);
  const [allclient, setAllclient] = useState(true);
  const [disablestate, setdisablestate] = useState(false);
  const handlePageClick = (e, index) => {
    e.preventDefault();
    get_list(index);
  };

  const formatDate = (dateStr) => {
    if(dateStr!=null && dateStr!=''){

      const [year, month, day] = dateStr.split('-');
      let newDate = `${day}-${month}-${year}`;
      return newDate;
    }
  };
  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_list(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_list(next);
  };

  const get_list = async (pageNumber = 1,e) => { 
    let  textbox = '';
    if(e != null){
       textbox = e.target.value;
    }
    taskmanageService.getsubclientTasks(pageNumber,clientsearch,textbox).then((response) => {console.log(response.data.data.data)
      if(response.status === 200){
      setClientdata(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setLastPageData(response.data.data.last_page);
      }
    });
  };


useEffect(() => {
  
  get_unique_companieslist();
  //get_list();
}, []);

const taskDetail = (task_id,index) => {
  taskmanageService.gettaskdetail(task_id,'','').then((response) => {
    settaskdetails(response.data.data);
    settaskdetailPopup(!taskdetailPopup);
  })
}
const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
function get_unique_companieslist(){
  clientmanageService.getclientlist().then((response)=>{
    if(response.status === 200){
    setUniqueCompaniesData(response.data);
    }
  });
}
const [ subclientlist, setsubclientlist ] = useState([])

  function subclientList(clientname=''){
    clientmanageService.getsubclientlist(clientname).then((response) => {
        if(response.status === 200){
          setsubclientlist(response.data)
        }else {
          toast.error('Something went wrong!');
        }
    });
  }
  function setclient(e){
    subclientList(e.target.value)
    // var index = e.target.selectedIndex;
    // setclientsearch(e.target[index].text)
    setclientsearch(e.target.value)
  }
const companyfilter = (e,index) => {
 get_list(1,e);
 setAllclient(false)
};
const initialStateDataRejection = {
  id: "",
  type : "",
  value : "",
  comment : "",
  errors : []
};
const initiaPhaseData = {
  phases: [],
};
const initialStateData = {
  task_id: "",
  analyst_id: "",
  due_date: "",
};
const [inputFields, setInputfields] = useState(initialStateData);
const [inputFieldsRejection, setInputfieldsRejection] = useState(initialStateDataRejection);
const [inputPhasesRejection, setinputPhasesRejection] = useState(initiaPhaseData);
const [showRejection,setshowRejection] = useState(false);
const [rejectionindex,setrejectionindex] = useState('');
const [errorcategory,seterrorcategory] = useState([]);
const [phaselistsselection, setPhaseListsSelection] = useState([]);
const [errorbox,seterrorbox] = useState([
  0
]);
const [rowsError, setRowsError] = useState(1);
function openRejectionModal(value='',id='',type='',task_id='',index){
  get_error_category();
  setshowRejection(!showRejection);
  const dataRejection = {
    id: id,
    type : type,
    value : value,
    comment : '',
    errors : []
  };
  setInputfieldsRejection(dataRejection);
  if(id==''){
    seterrorbox([0])
    setRowsError(1)
  }
  setrejectionindex(index)
}
function get_error_category(){
  taskmanageService.geterrorcategories().then((response) => {
    seterrorcategory(response.data)
  })
}
const get_rejection_phase_selection = async (task_id,edittype,type=null) => {
  taskmanageService.getrejectionphaselists(task_id).then((response) => {
    if(type==null){
    setPhaseListsSelection(response.data.data);
    }
    let initdata = {
      task_id: task_id,
    };
    setInputfields(initdata);
    let cloneValues = [];
    if (response.data.data) {
      let pp = {};
      response.data.data.map((ele, i) => {
        if (ele.already_assign == true) {
          pp = {
            ["phase_id"]: ele.id,
            ["main_id"]: ele.main_id,
            ["assign_lead_id"]: ele.assign_lead_id,
            ["due_date"]: ele.due_date,
          };
          cloneValues[i] = pp;
        }
      });
    }
    setinputPhasesRejection(cloneValues);
  })
}
function add_phase_rejection_selection(){
  setdisablestate(true)
  setinputPhasesRejection(inputPhasesRejection);
  taskmanageService.rejectionclient(inputFieldsRejection, inputPhasesRejection).then((response) => {
    // console.log(response);
    if (response.status == 200) {
      toast.success(response.data.message);
     
      setshowRejection(!showRejection);
      setInputfieldsRejection(initialStateDataRejection)
      setinputPhasesRejection(initiaPhaseData)
      seterrorbox([0])
      setRowsError(1)
    } else if (response.status == 201) {
      toast.error(response.data.message);
    }
    setdisablestate(false)
  });
}
function inputtaskrejectionhandler(e,index) {
  const cloneValues = {...inputFieldsRejection};
    cloneValues.errors[index] = {
      ...cloneValues.errors[index],
      [e.target.name]: e.target.value,
    };
  console.log(cloneValues)
  
  setInputfieldsRejection(cloneValues);
  
  
}
const addCommetbox = ()=>{
  const setdata = rowsError+1;
    setRowsError(setdata)
  const errorsInput = [...errorbox];
  errorsInput[rowsError] = 0;
  seterrorbox(errorsInput)
}
  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
    
        <Row className="mt-5">
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
              <CardHeader className="border-0">
              <Row className="filter" style={{padding:'0 1%'}}>
               <div className="filter">
               <div className="col-xl-6 col-md-6 col-xs-12">
               <h3 className="clientformtag"> Client</h3>
                    {(uniquecompanieslist.length > 0 && (user_info.role_id != 13))?
                          <select className="form-control" name="user_id"onChange={(e) => { setclient(e);}}   >
                              <option value="" key="al_key_1">All Clients</option>
                              {(uniquecompanieslist).map((valuel,k) =>
                                  <option key={k} value={valuel.name}>
                                    {valuel.name}
                                  </option>
                              )}
                          </select>
                        : null }
                    </div>
                    <div className="col-xl-6 col-md-6 col-xs-12">
                     <h3 className="clientformtag">Sub Client</h3>
                        <select  class="form-control" name="sub_client"  onChange={(e) => companyfilter(e,9)}>
                          <option value=""> Select Sub Client</option>
                          {subclientlist.map(res => (
                              <option
                                key={res.sub_client}
                                value={res.sub_client}
                              >
                                {res.sub_client}
                              </option>
                            ))}
                        </select>
                    </div>
                    </div>
                </Row>
              </CardHeader>
              {(allclient == false || user_info.role_id == 13) ?
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    {user_info.role_id != 13 ? <th>Client</th> : null }
                    <th scope="col">Sub Client</th>
                    <th scope="col">YE Date</th>
                    <th scope="col">Priority</th>
                    <th scope="col">Raised on</th>
                    <th scope="col" className="whitespace">Date of Information last uploaded</th>
                    <th scope="col" className="whitespace">Due date</th>
                    <th scope="col" className="whitespace">Actual Date of Completion</th>
                    <th scope="col" className="whitespace">Status</th>
                    <th>Action</th>
                </thead>
                <tbody>
                  {clientdata &&
                    clientdata.length &&
                    clientdata != null ? (
                      clientdata.map((item, i) => (
                      <>
                     <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') }>
                     {user_info.role_id != 13 ?  <td onClick={() => taskDetail(item.id, i)} style={{cursor:'pointer'}}>{item.name} ({item != undefined ? item.type : ''})</td> : null }
                     <td style={{cursor:'pointer'}}>{item.sub_client}</td>
                        
                          <td>{formatDate(item.year_end_date)}</td>
                        <td>{item.priority_no}</td>
                        <td>{formatDate(item.raised_date)}</td>
                        <td>{item.doc_update_date}</td>
                        <td>{formatDate(item.due_date)}</td><td>{item.com_date}</td>
                        <td>{item.status_name}</td>
                        <td>
                        <Button
                                color="primary"
                                style={{
                                  border: "1px solid #FF6430",
                                  background:'none',
                                  color: "#FF1717",
                                  borderRadius: "18px",
                                }}
                                size="sm"
                                type="button"
                                onClick={(e) =>
                                  { openRejectionModal(
                                     2,
                                     item.id,
                                     item.edittype,
                                     item.task_id,i
                                   ), get_rejection_phase_selection(item.task_id,item.edittype,'deny') }
                                 }
                              >
                                Add rejection
                              </Button>
                        </td>
                     </tr>
                      
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
              </Table>
: 
null
} 
{(allclient == false || (user_info.role_id == 13))?
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
: null }
            </Card>
          </div>
        </Row>
        <Row>
          <Modal
          className="taskdetailmodal"
            bsSize="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => setshowRejection(!showRejection)}
            isOpen={showRejection}
          >
            <div className="modal-header">
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => openRejectionModal()}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <h3>Add comment for rejection</h3>
             
              <Form
                role="form"
                id="phase-selection-form"
                onSubmit={add_phase_rejection_selection}
              >
                
                  <>
                <Row>  <Col md="1">
            <a onClick={(e) => addCommetbox()}>+ ADD</a>
          </Col></Row><br></br>
        {(() => {
            const arr1 = [];
            for (let k = 0; k < rowsError; k++) {
                arr1.push(
                  <Row key={k}>
                  <Col md="2">
                          <Input
                            size="sm"
                            name={"category_id"}
                            type="select"
                            onChange={(e) => inputtaskrejectionhandler(e,k)}
                          >
                            <option>Category </option>
                            {errorcategory.map((category) => {
                              return (
                                <option value={category.id}>
                                  {category.name}
                                </option>
                              );
                            })}
                          </Input>
                        </Col>  
                        <Col md="1">
                          <Input
                            size="sm"
                            name={"source"}
                            type="select"
                            onChange={(e) => inputtaskrejectionhandler(e,k)}
                          >
                            <option>Source </option>
                           <option value="Email">Email</option>
                           <option value="Call">Call</option>
                           <option value="WPs">WPs</option>
                           <option value="Spreadsheet">Spreadsheet</option>
                           <option value="Governance Call">Governance Call</option>
                           <option value="Slack">Slack</option>
                          </Input>
                        </Col>  
                        <Col md="1">
                          <Input
                            size="sm"
                            name={"type"}
                            type="select"
                            onChange={(e) => inputtaskrejectionhandler(e,k)}
                          >
                            <option>Type </option>
                           <option value="Fetal">Fetal</option>
                           <option value="Non-Fetal">Non Fetal</option>
                          </Input>
                        </Col>  
                        <Col md="1">
                          <Input
                            size="sm"
                            name="no_error"
                            type="number"
                            min="1"
                            placeholder="0"
                            onChange={(e) => inputtaskrejectionhandler(e,k)}
                          ></Input>
                        </Col>  
                  <Col md="7">
                  <>
       
                  <Row>
                  <Col xl="3">
                  <Input
                  size="sm"
                  name={"created_at"}
                  required
                  placeholder="Feedback"
                  id="example-date-input"
                  type="date"
                  onChange={(e) => inputtaskrejectionhandler(e,k)}
                ></Input>
                </Col>
                    <Col xl="3">
                  <Input
                  size="sm"
                  name={"comment"}
                  required
                  placeholder="Feedback"
                  id="example-date-input"
                  type="textarea"
                  onChange={(e) => inputtaskrejectionhandler(e,k)}
                ></Input>
                </Col>
                <Col xl="3">
                <Input
                  size="sm"
                  name={"root_cause"}
                  required
                  placeholder="Root Cause"
                  id="example-date-input"
                  type="textarea"
                  onChange={(e) => inputtaskrejectionhandler(e,k)}
                ></Input>
                </Col>
                <Col xl="3">
                <Input
                  size="sm"
                  name={"action_plan"}
                  required
                  placeholder="Action Items"
                  id="example-date-input"
                  type="textarea"
                  onChange={(e) => inputtaskrejectionhandler(e,k)}
                ></Input>
                </Col>
                </Row><br></br>
                
          
    </>                         
                        </Col>
                       
                 </Row>
                );
            }
            return arr1;
            
        })()}
    </>  
    
                
               
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type="button"
                onClick={add_phase_rejection_selection}
                disabled={disablestate}
              >
                {disablestate ? 'Sending...' : 'Send Back'}
              </Button>
              <Button
                color="secondary"
                type="button"
                onClick={() => openRejectionModal()}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Row>

        <Row>
          <Modal
            size="lg"
            className="taskdetailmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => settaskdetailPopup(!taskdetailPopup)}
            isOpen={taskdetailPopup}
          >
            <div className="modal-header">
            <h3>Task Detail</h3>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => settaskdetailPopup(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{paddingTop:'0px'}}>
           
          {(taskdetails.company)?  <p><b>Book Keeping Software :</b> {taskdetails.company.book_keeping_software} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <b>Account Production Software</b> : {taskdetails.company.account_prod_software}</p>  :null }
           
              <Table className="align-items-center table-flush" responsive>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th className="whitespace">Assigned Leader</th>
                    <th className="whitespace">Assigned Member</th>
                    <th>Due date</th>
                    <th>Start date</th>
                    <th>Comp. date</th>
                    <th className="whitespace">Status</th>
                    <th>Approval</th>
                  </tr>
                </thead>
                <tbody>
                {taskdetails.analyst  ? (
                  <tr key="analyst" className="blue-row">
                    <td>Analyst</td>
                    <td className="whitespace">{taskdetails.analyst.leader}</td>
                    <td className="whitespace">{taskdetails.analyst.member}</td>
                    <td >{taskdetails.analyst.due_date}</td>
                    <td>{taskdetails.analyst.start_date}</td>
                    <td>{taskdetails.analyst.completed_date}</td>
                    <td className="whitespace">{taskdetails.analyst.status ? taskdetails.analyst.status.name : ""}</td>
                    <td>{taskdetails.analyst.approval==1 ? 'Approved' : taskdetails.analyst.approval==2 ? 'Denied' : ''}
                    {taskdetails.analyst.flag==1 ? 'Ready for review' : taskdetails.analyst.flag==2 ? 'Phases assigned by QA' : taskdetails.analyst.flag==3 ? 'Rejected by QA' : taskdetails.analyst.flag==4 ? 'Reviewed' : ''}
                    </td>
                  </tr>
                 
                 ) : (
                  null
                )}

                      {taskdetails.phases && taskdetails.phases.length
                          ?                               
                          taskdetails.phases.map((phase,d) => (
                              <tr key={d} className={((d % 2 == 0)? 'purple-row': 'orange-row' )}>
                                <td>{phase.phases_list.desc}</td>
                                <td  className="whitespace">{phase.leader}</td>
                                <td  className="whitespace">{phase.member}</td>
                                <td>{phase.due_date}</td>
                                <td>{phase.start_date}</td>
                                <td>{phase.completed_date}</td>
                                <td  className="whitespace">{phase.status ? phase.status.name : ""}</td>
                                <td>{phase.approval==1 ? 'Approved' : phase.approval==2 ? 'Denied' : ''}
                                    {phase.flag==1 ? 'Rejected' : ''}
                                </td>
                              </tr>
                            ))
                          :
                           ( <p>No Data Found</p> )}
                </tbody>
              </Table>
            </ModalBody>
           </Modal>
         
        </Row>
      </Container>
    </>
  );
};


export default AddExternalCCRP;
