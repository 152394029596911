import { useState, useEffect,useRef } from "react";
import { useParams } from "react-router-dom";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { toast } from 'react-toastify';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import Select from 'react-select';
const axios = require("axios");

const EditGovernanceCall = (props) => {
  
  const [clientdata, setClientdata] = useState([]);
  const [clientsearch, setclientsearch] = useState([{'label':'All Clients',value:'al_key_1'}]);
  const [disablestate, setdisablestate] = useState(false);

useEffect(() => {
  
  get_unique_companieslist();
  get_user_client_list();
  getsingledata();
  get_user_list();
  
}, []);

const months = [{value:'1',label : 'Jan'},{value:'2',label :'Feb'},{value:'3',label :'March'},{value:'4',label :'April'},{value:'5',label :'May'},{value:'6',label :'June'},{value:'7',label :'July'},{value:'8',label :'Aug'},{value:'9',label :'Sept'},{value:10,label :'Oct'},{value:11,label :'Nov'},{value:12,label :'Dec'}];
const [monthdata, setmonthdata] = useState(months);
let { id } = useParams();
const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
function get_unique_companieslist(){
  clientmanageService.getuniqueclient().then((response)=>{
    setUniqueCompaniesData(response.data);
  });
}
const initialData = {
  client_id : '',
  month : '',
  action_items : [],
  notes : [],
  client_attendees : [],
  corient_attendees : []
}
const [inputFields, setInputfields] = useState(initialData);

function getsingledata(){
  clientmanageService.getgovernance(id).then((response) => {
    setInputfields(response.data.data)
    setRateBox(response.data.data.action_items.length)
    setNoteBox(response.data.data.notes.length)
    setCorientBox(response.data.data.corient_attendees.length)
    setClientBox(response.data.data.client_attendees.length)
    setOriginalRateBox(response.data.data.action_items.length)
  })
}

const [userlist, setUserListData] = useState([]);
function get_user_list(){
    clientmanageService.getuserforweeklycall().then((response) => {
    setUserListData(response.data)
  });
}
 function inputhandler1(e,field) {
  setInputfields((prevInputFields) => ({
    ...prevInputFields,
    [field]: e.value,
  }));

}
function inputhandler(e) {
  let phseup = {}
  phseup = { ...inputFields, [e.target.name]: e.target.value }    
  setInputfields(phseup);
}
  function setclient(e){ 
    setclientsearch(e.label);
    setClientdata([]);
    setInputfields((prevInputFields) => ({
      ...prevInputFields,
      ['client_id']: e.value,
    }));
   
  }

  const [ratebox, setRateBox] = useState(0);
 
  const addRatebox = ()=>{
    const setdata = ratebox+1;
    setRateBox(setdata)
   
  }
  const removeRatebox = ()=>{
    if(ratebox > 0){
      const setdata = ratebox-1;
      setRateBox(setdata)
    }
  }
  const [notebox, setNoteBox] = useState(0);
  const [originalratebox, setOriginalRateBox] = useState(0);
  const addNotebox = ()=>{
    const setdata = notebox+1;
    setNoteBox(setdata)
   
  }
  const removeNotebox = ()=>{
    if(notebox > 0){
      const setdata = notebox-1;
      setNoteBox(setdata)
    }
  }
  const [corientbox, setCorientBox] = useState(0);
 
  const addCorientbox = ()=>{
    const setdata = corientbox+1;
    setCorientBox(setdata)
   
  }
  const removeCorientbox = ()=>{
    if(corientbox > 0){
      const setdata = corientbox-1;
      setCorientBox(setdata)
    }
  }
  
  function inputcorienthandler(e, index) {
    const { name, value } = e.target;
    const corient_attendees = [...inputFields.corient_attendees];
    if (!corient_attendees[index]) {
      corient_attendees[index] = {}; // Initialize the user object if it doesn't exist
    }
    corient_attendees[index] = value;
    setInputfields({ ...inputFields, corient_attendees });
  }

  const [clientbox, setClientBox] = useState(0);
  
  const addClientbox = ()=>{
    const setdata = clientbox+1;
    setClientBox(setdata)
   
  }
  const removeClientbox = ()=>{
    if(clientbox > 0){
      const setdata = clientbox-1;
      setClientBox(setdata)
    }
  }
  
  function inputclienthandler(e, index) {
    const { name, value } = e.target;
    const client_attendees = [...inputFields.client_attendees];
    if (!client_attendees[index]) {
      client_attendees[index] = {}; // Initialize the user object if it doesn't exist
    }
    client_attendees[index] = e.target.value;
    setInputfields({ ...inputFields, client_attendees });
  }

  function inputactionhandler(e, index) {
    const { name, value } = e.target;
    const action_items = [...inputFields.action_items];
    if (!action_items[index]) {
      action_items[index] = {}; // Initialize the user object if it doesn't exist
    }
    action_items[index][name] = value;
    setInputfields({ ...inputFields, action_items });
  }
  function inputnotehandler(e, index) {
    const { name, value } = e.target;
    const notes = [...inputFields.notes];
    if (!notes[index]) {
      notes[index] = {}; // Initialize the user object if it doesn't exist
    }
    notes[index][name] = value;
    setInputfields({ ...inputFields, notes });
  }

  function editgovernancecall(e){
    setdisablestate(true)
    e.preventDefault();
    clientmanageService.editgovernancetransdata(inputFields).then((response)=>{
      
      if(response.status == 200){
         toast.success(response.data.message); 
      }else{
        console.log(response.data);
         toast.error(response.data.message);
      }
      setdisablestate(false)
    });
  }
  
 // const [clientlist, setclientlist] = useState({'clients':''});
  const [label_disp_corient, setlablecorient] = useState(true);
  const [label_disp_client, setlableclient] = useState(true);
  const [userclientlist, setusersclientlist] = useState([]);
  function get_user_client_list(){
    clientmanageService.getusersclientlist().then((response)=>{
      console.log(response.data)
      setusersclientlist(response.data.users);
    });
  }
  
  const getMonthName = (monthValue) => {
   
    const selectedMonth = months.find(month => month.value == monthValue);
    return selectedMonth ? selectedMonth.label : '';
  };
  const selectedMonthName = getMonthName(inputFields.month);
  function inputactionresphandler(e, index){
   
    const action_items = [...inputFields.action_items];
    if (!action_items[index]) {
      action_items[index] = {}; // Initialize the user object if it doesn't exist
    }
    action_items[index]['responsibility'] = e.value;
    setInputfields({ ...inputFields, action_items });
  }
  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
    
        <Row className="mt-5">
       
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
            <Form role="form" id="adduser-form" onSubmit={editgovernancecall}>
              <CardHeader className="border-0">
              <Row >
                
              <div className="col-xl-3 col-md-4 col-xs-12">
                    <FormGroup>
                        <label>Governance call month :{selectedMonthName} {inputFields.year}</label>
                      
                      </FormGroup> 
                    <label className="clientformtag"> Client : {inputFields.client_name}</label>
                    
                    </div>

                    <div className="col-xl-3 col-md-2 col-xs-12">
                    <FormGroup>
                        <label>Rating</label>
                        <select class="form-control" name="rating" value={inputFields.rating}  onChange={(e) => inputhandler(e)} required>
                              <option value=""> Select Overall Satisfaction Level</option>
                                <option value="5"> Happy</option>
                                {/* <option value="4">Happy</option>
                                <option value="3">Neutral</option>
                                <option value="2">Not Happy</option> */}
                                <option value="1">Not Happy</option>
                                <option value="0">No Show</option>
                              </select>
                      </FormGroup> 
                  </div>
                  <div className="col-xl-3 col-md-2 col-xs-12">
                    <FormGroup>
                        <label>Department</label>
                        <select class="form-control" name="department" value={inputFields.department} onChange={(e) => inputhandler(e)} required>
                              <option value=""> Department</option>
                                <option value="BookKeeping">BookKeeping</option>
                                <option value="SME">SME</option>
                              </select>
                      </FormGroup> 
                  </div>
                  <div className="col-xl-3 col-md-2 col-xs-12">
                    <FormGroup>
                        <label>Meeting Title</label>
                        <Input
                     placeholder="Meeting Title"
                     type="text"
                     name="meeting_title"
                     onChange={(e) => inputhandler(e)}
                     value={inputFields.meeting_title}
                     required
                   />
                      </FormGroup> 
                  </div>
                    <br></br>
                  <div className="col-md-12">&nbsp;</div>
                  {(() => {
            const arr1 = [];
            for (let k = 0; k < corientbox; k++) {
                arr1.push(
                 <>
                    
                    <div className="col-md-3">
                       <FormGroup>
                       {/* <label>Level</label> */}
                       <div className="row">
                     
                  <div className="col-xl-10 col-md-12 col-xs-12">
                    <FormGroup>
                        <label>Name</label>
                        <div className="d-flex">
                        {(userclientlist && userclientlist.length > 0)?
                        
                        <InputGroup className="input-group-alternative mb-3">
                            <select className="form-control" name="team_user" placeholder="User" value={inputFields.corient_attendees[k] ? inputFields.corient_attendees[k] : ''} onChange={(e) => inputcorienthandler(e, k)}>
                              <option key="user_1"  value="">Select user manager for reporting</option>
                              {userclientlist.map(team =>
                                  <option key={team.value} value={team.value}>{team.label}</option>
                              )}
                            </select>
                           
                          </InputGroup>
                          
                        
                    
                    
                          : null }
                          
                        </div> 
                      </FormGroup>      
                  </div>

                 
                    
                    <Col md="1">
        {k >= inputFields.corient_attendees.length && (
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => removeCorientbox(k)}
        >
          <i className="fa fa-remove"></i>
        </button>
       )} 
    </Col>
                        </div>
                      </FormGroup>    
                    </div> 
                    </>
                );
            }
            return arr1;
            
        })()}
                    <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addCorientbox()}>+ Add Corient Attendees </a>
          </Col>
               

          <br></br>
                  <div className="col-md-12">&nbsp;</div>
                  {(() => {
            const arr1 = [];
            for (let k = 0; k < clientbox; k++) {
                arr1.push(
                 <>
                    
                    <div className="col-md-3">
                       <FormGroup>
                       {/* <label>Level</label> */}
                       <div className="row">
                     
                  <div className="col-xl-10 col-md-12 col-xs-12">
                    <FormGroup>
                        <label>Name</label>
                        <div className="d-flex">
                        { <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Name"
                            type="text"
                            name="name"
                            value={inputFields.client_attendees[k] ? inputFields.client_attendees[k] : ''}
                            onChange={(e) => inputclienthandler(e, k)}
                            
                          />
                        </InputGroup> }
                    
                          </div>
                      </FormGroup>      
                  </div>

                 
                    
                    <Col md="1">
      {k >= inputFields.client_attendees.length && (
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => removeClientbox(k)}
        >
          <i className="fa fa-remove"></i>
        </button>
       )}
    </Col>
                        </div>
                      </FormGroup>    
                    </div> 
                    </>
                );
            }
            return arr1;
            
        })()}
                    <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addClientbox()}>+ Add Client Attendees </a>
          </Col>
               
               

                  <br></br>
                  <div className="col-md-12">&nbsp;</div>
                  {(() => {
            const arr1 = [];
            for (let k = 0; k < ratebox; k++) {
                arr1.push(
                 <>
                    
                    <div className="col-md-12">
                       <FormGroup>
                       {/* <label>Level</label> */}
                       <div className="row">
                       <div className="col-xl-3 col-md-2 col-xs-12">
                       <FormGroup>
                        <label>Feedback</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Feedback"
                            type="text"
                            name="remarks"
                            defaultValue={inputFields.action_items[k] ? inputFields.action_items[k].remarks : ''}
                            onChange={(e) => inputactionhandler(e, k)}
                            
                          />
                        </InputGroup>
                      </FormGroup> 
                    
                  </div>
                  <div className="col-xl-3 col-md-2 col-xs-12">
                    <FormGroup>
                        <label>Responsibility</label>
                        {(k <= originalratebox) && (inputFields.action_items[k]?.responsibility_check) ?
                        <Input
                            placeholder="Responsibility"
                            type="text"
                            name="responsibility"
                            defaultValue={inputFields.action_items[k] ? inputFields.action_items[k].responsibility : ''}
                            onChange={(e) => inputactionhandler(e, k)}
                            
                          />
                          :
                        (userlist.length > 0)?
                    // <select  class="form-control" name="responsibility" value={inputFields.action_items[k] ? inputFields.action_items[k].responsibility : ''}
                    // onChange={(e) => inputactionhandler(e, k)} >
                    // <option value=""> Select User</option>
                    // {(userlist).map((valuel,k) =>
                    //               <option key={k} value={valuel.value}>
                    //                 {valuel.label}
                    //               </option>
                    //           )}
                    // </select>
                    <Select 
                    
                    className="input-group-alternative mb-3" 
                    name="responsibility"
                    options={userlist} // set list of the data
                    value={userlist.find(obj => obj.value === inputFields.action_items[k].responsibility) || ''} // set selected values
                    searchable
                    onChange={(e) => inputactionresphandler(e, k)}
                    isClearable={false}
                  />
                        : null }
                      </FormGroup> 
                  </div>
                  <div className="col-xl-2 col-md-2 col-xs-12">
                    <FormGroup>
                        <label>Target date</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Target date"
                            type="date"
                            name="target_date"
                            defaultValue={inputFields.action_items[k] ? inputFields.action_items[k].target_date : ''}
                            onChange={(e) => inputactionhandler(e, k)}
                            
                          />
                        </InputGroup>
                      </FormGroup> 
                  </div>
                       
                  <div className="col-xl-3 col-md-2 col-xs-12">
                  <FormGroup>
                        <label>Action Item</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Action Item"
                            type="text"
                            name="action_item"
                            defaultValue={inputFields.action_items[k] ? inputFields.action_items[k].action_item : ''}
                            onChange={(e) => inputactionhandler(e, k)}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                  </div>

                 
                    
                    <Col md="1">
                    <br></br>
        {k >= inputFields.action_items.length && (
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => removeRatebox(k)}
        >
          <i className="fa fa-remove"></i>
        </button>
       )} 
    </Col>
                        </div>
                      </FormGroup>    
                    </div> 
                    </>
                );
            }
            return arr1;
            
        })()}
                    <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addRatebox()}>+ Add action items </a>
          </Col>

          <br></br>
                  <div className="col-md-12">&nbsp;</div>
                  {(() => {
            const arr1 = [];
            for (let k = 0; k < notebox; k++) {
                arr1.push(
                 <>
                    
                    <div className="col-md-12">
                       <FormGroup>
                       {/* <label>Level</label> */}
                       <div className="row">
                     
                  <div className="col-xl-10 col-md-12 col-xs-12">
                    <FormGroup>
                        {/* <label>Note</label> */}
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Note"
                            type="textarea"
                            name="note"
                            defaultValue={inputFields.notes[k] ? inputFields.notes[k].note : ''}
                            onChange={(e) => inputnotehandler(e, k)}
                            required
                          />
                        </InputGroup>
                      </FormGroup>      
                  </div>

                 
                    
                    <Col md="1"><br></br>
        {k >= inputFields.notes.length && (
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => removeNotebox(k)}
        >
          <i className="fa fa-remove"></i>
        </button>
      )} 
    </Col>
                        </div>
                      </FormGroup>    
                    </div> 
                    </>
                );
            }
            return arr1;
            
        })()}
                    <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addNotebox()}>+ Add Notes </a>
          </Col>
               
                </Row>

                
                <div className="col-md-2">
                        <label>&nbsp;</label>
                        <Button className="col-12 " color="primary" type="submit" disabled={disablestate}>
                          {disablestate ? 'Sending...' : 'Save'} 
                         </Button>
                    </div>
                    
              </CardHeader>
              </Form>
             </Card>
             </div>
             
        </Row>
      </Container>
    </>
  );
};


export default EditGovernanceCall;
