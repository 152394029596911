import { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import {usermanageService} from '../../../services/usermanageService';
import "assets/css/themecustom-style.css";
import { ToastContainer, toast } from "react-toastify";

const Archive = (props) => {
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [archivedata, setArchivedata] = useState([]);
  const [fullloader,setLoader] = useState(false);

  const handlePageClick = (e, index) => {
    e.preventDefault();
    get_user_archive_list(index);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_user_archive_list(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_user_archive_list(next);
  };

  const get_user_archive_list = async (pageNumber = 1) => {
    usermanageService.getarchiveusers(pageNumber).then((response) => {
      setArchivedata(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setLastPageData(response.data.data.last_page);
    });
  };

  const restoreUser = (id, index) => {
    setLoader(true);
    usermanageService.restoreUser(id).then((response) => {
      if (response.status == 200) {
        let state = [...archivedata];
        delete state[index];
        setArchivedata(state);
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
      setLoader(false);
    });
  };

  useEffect(() => {
    get_user_archive_list();
  }, []);

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col" style={{ padding: "0%" }}>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="filter" style={{ padding: "0 25px" }}>
                  <h3 className="mb-0" style={{ fontSize: "20px" }}>
                    Archived User List
                  </h3>
                  <br></br>
                  <br></br>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Role</th>
                  <th scope="col">Action</th>
                </thead>
                <tbody>
                  {archivedata && archivedata.length && archivedata != null ? (
                    archivedata.map((item, i) =>
                      item != undefined ? (
                        <>
                          <tr
                            className={
                              i % 2 == 0
                                ? "blue-row"
                                : i % 3 == 0
                                ? "purple-row"
                                : "orange-row"
                            }
                          >
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.roles[0].name}</td>
                            <td>
                              <Button
                                color="primary"
                                style={{
                                  background: "#02C779",
                                  border: "#02C779",
                                  borderRadius: "18px",
                                }}
                                size="sm"
                                type="button"
                                onClick={(e) => restoreUser(item.id, i)}
                              >
                                Restore
                              </Button>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <></>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Archive;
