import { useState, useEffect } from "react";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { ToastContainer, toast } from 'react-toastify';
import '@flowjs/flow.js';
import { useHistory,useParams } from "react-router-dom";
import { manageService } from "../../services/manageaccountservices";

const ClientDetails = (props) => {
  const params  = useParams();
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [clientdata, setClientdata] = useState([]);
  const [filterFields,setfilterFields] = useState([]);
  const [statuslist, setStatusdata] = useState([]);
  const [filtertype,setfiltertype] = useState('Not Started');
  let history = useHistory();
  
  function get_status_list() {
    taskmanageService.getclientstatus().then((response) => {
      setStatusdata(response.data.status);
    });
  }
  const handlePageClick = (e, index) => {
    e.preventDefault();
    get_list(index);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_list(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_list(next);
  };

  const get_list = async (pageNumber = 1,e,filterFields,filtertype='') => { 
    let  textbox = '';
    if(e != null){
       textbox = e.target.value;
    }
    const cloneValues = {...filterFields};
    let status ='';
    if(params.status!='undefined' && params.status!=undefined){
      status = params.status;
      filtertype = status;
    }
    let user_id ='';
    if(params.userid!='undefined' && params.userid!=undefined){
      user_id = params.userid;
      cloneValues[9] = {
        ...cloneValues[9],
        ['field']: 'user_id',
        ['value']: user_id,
        ['operator']: '='
      };
    }
   
    setfilterFields(cloneValues);
    taskmanageService.getclientTasks(pageNumber,textbox,cloneValues,filtertype).then((response) => {console.log(response.data.data.data)
      setClientdata(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setLastPageData(response.data.data.last_page);
    });
  };


  const formatDate = (dateStr) => {
    if(dateStr!='' && dateStr!= null){
    const [year, month, day] = dateStr.split('-');
    let newDate = `${day}-${month}-${year}`;
    return newDate;
    }else{
      return null;
    }
  };


  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
};

useEffect(() => {
  get_status_list();
  get_list();
  get_unique_companieslist();
}, []);
const editField = (value, field, id, type, index,e='') => {
    let temp_stt = [...clientdata];
    let temp_elet = { ...temp_stt[index] };
  if(field == 'client_status' && value == "2" && (temp_elet['remarks'] == '' || !temp_elet['remarks'])){
    toast.error('Please fill remarks for on hold case');
  }else{
    taskmanageService.editRow(value, field, id, type).then((response) => {
      let temp_st = [...clientdata];
      let temp_ele = { ...temp_st[index] };
    
      if(response.status == 200){
        if (field == "client_status") {
            Object.entries(statuslist).filter(([k, v]) => v.id == value).reduce((acc, [k, v]) => {
              temp_ele['status_name'] = v.name;
              temp_ele['status_color_code'] = v.color_code;
          }, {});
            temp_ele['status_id'] = value;
            if(value==3){
              let newDate = new Date()
              let months = newDate.getMonth() + 1;
              let month = (months<10)?'0'+months:months;
              temp_ele['com_date'] = newDate.getDate()+'-'+month+'-'+newDate.getFullYear();
            }
            if(value != 2){
              temp_ele['remarks'] = '';
            }
          }
          toast.success(response.data.message);
      }else{
          toast.error(response.data.message);
      }
      temp_ele.mainediting = false;
      temp_st[index] = temp_ele;
      if (field != "client_status") {
        temp_ele[field] = value;
      }
      
      setClientdata(temp_st);
      get_dashboard_data();
    });
  }
};

const [taskdetails,settaskdetails] = useState({});
const [taskdetailPopup,settaskdetailPopup] = useState(false);
const [documentlists, setDocumentLists] = useState([]);
const [rejectionlists, setRejectionLists] = useState([]);
const [documentModalOpen, setdocumentModalOpen] = useState(false);
const [rejectionModalOpen, setrejectionModalOpen] = useState(false);
const [otherInfoModalOpen, setotherInfoModalOpen] = useState(false);
const user_info = JSON.parse(localStorage.getItem("user-info"));
const [allclient, setAllclient] = useState(true);
const taskDetail = (task_id,index) => {
  taskmanageService.gettaskdetail(task_id,'','').then((response) => {
    settaskdetails(response.data.data);
    setDocumentLists(response.data.files);
    setRejectionLists(response.data.rejections);
    settaskdetailPopup(!taskdetailPopup);
  })
}
const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
function get_unique_companieslist(){
  manageService.get_clientusers().then((response)=>{
    setUniqueCompaniesData(response.data);
  });
}
const companyfilter = (e,index) => {
  if(e.target.value == ''){
    setAllclient(true);
  }else{
    setAllclient(false);
  }
  const cloneValues = {...filterFields};
  cloneValues[index] = {
    ...cloneValues[index],
    ['field']: e.target.name,
    ['value']: e.target.value,
    ['operator']: '='
  };
  setfilterFields(cloneValues);
  get_list(1,null,cloneValues,filtertype);
}
const [loading, setLoading] = useState(false);
const downloadfile = (path,name,extension,task_id) => {
  setLoading(true)
  taskmanageService.download_file(path,name,extension,task_id).then((response) => {
    setLoading(false)
  });
}
const downloadAllfile = (userid,taskid) => {
  setLoading(true)
  taskmanageService.download_all_file(userid,taskid).then((response) => {
    setLoading(false)
  });
}

const handlemaineditRow = (index) => {
  let temp_state = [...clientdata];
  let temp_element = { ...temp_state[index] };
  temp_element.mainediting = true;
  temp_state[index] = temp_element;
  setClientdata(temp_state);
}
  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
     
        <Row className="mt-5">
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
             
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    <th>Client</th>
                    <th scope="col">Sub Client</th>
                    {/* <th scope="col"><i className="ni ni-chat-round"></i></th> */}
                    <th scope="col">YE Date</th>
                    <th scope="col" className="whitespace">Book-keeping software</th>
                    <th scope="col">Priority</th>
                    <th scope="col">Raised on</th>
                    <th scope="col" className="whitespace">Date of Information last uploaded</th>
                    <th scope="col" className="whitespace">Expected Date of Completion</th>
                    <th scope="col" className="whitespace">Corient Expected Completion Date</th>
                    <th scope="col" className="whitespace">Actual Date of Completion</th>
                    <th scope="col" className="whitespace">Status of the Job</th>
                    <th scope="col" className="whitespace">Corient Remarks</th>
                    <th scope="col" className="whitespace">Client Remarks</th>
                </thead>
                <tbody>
                  {clientdata &&
                    clientdata.length &&
                    clientdata != null ? (
                      clientdata.map((item, i) => (
                      <>
                     <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') }>
                    <td onClick={() => taskDetail(item.id, i)} style={{cursor:'pointer'}}>{item.name}</td> 
                     <td onClick={() => editjob_nav(item.id)} style={{cursor:'pointer'}}>{item.sub_client}</td>
                        {/* <td>
                          <div className="icon-container" style={{"position": "relative",'width':'20px'}}>
                          <img alt="..." src={require("../../assets/img/brand/chat-bubble.png")} style={{height:20, width:20}} id={item.id} onClick={(e) => addcommentform(item.id,i)}/>
                          {item.comment_count != null && item.comment_count > 0 ? (
                            <Badge className="badge-circle" color="danger" size="sm" style={{"position": "absolute","right": "-8px","bottom": "-7px","height":"1.0rem","width":"1.0rem","fontSize":"10px","color": "#fff","backgroundColor": "red"}}>
                              {item.comment_count}
                            </Badge>
                          ) : null}
                            </div>
                          </td> */}
                          
                       
                          <td>{formatDate(item.year_end_date)}</td>
                        <td className="whitespace">{item.book_keeping_software}</td>
                        <td>{item.priority_no}</td>
                        <td>{formatDate(item.raised_date)}</td>
                        <td>{item.doc_update_date}</td>
                        <td>{formatDate(item.due_date)}</td>
                        {user_info.role_id != 13 ?
                        <td  onClick={()=> handlemaineditRow(i)} >
                              {item.mainediting ? (
                                <input type="date"
                                className="form-control"
                                  defaultValue={item.corient_expected_completed_date}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "corient_expected_completed_date",
                                      item.id,
                                      'tasks',
                                      i
                                    )
                                  }
                                  min={disablePastDate()}
                                />
                              ) : (
                                <span>{(item.corient_expected_completed_date!='') ? formatDate(item.corient_expected_completed_date) : ''}</span>
                              )}
                            </td>
                        : <td>{(item.corient_expected_completed_date!='') ? formatDate(item.corient_expected_completed_date) : ''}</td> }
                        <td>{item.com_date}</td>
                        {user_info.role_id != 13 ?
                        <td>
                            {item.mainediting ? (
                            <select
                              style={{background: (item.status_color_code!=null)?`${item.status_color_code}`:'none',color:(item.status_color_code!=null)?'#fff':'#000'}}
                                className="form-control"
                                onChange={(e) =>
                                  editField(
                                    e.target.value,
                                    "client_status",
                                    item.id,
                                    'tasks',
                                    i
                                  )
                                }
                                defaultValue={item.status_id}
                              >
                                <option value="0" key="0" style={{background:'#fff',color:'#000'}}>Select Status</option>
                                {statuslist &&
                                statuslist.length &&
                                statuslist != null ? (
                                  statuslist.map((status) =>
                                  
                                      <option key={status.id} value={status.id} style={{background:`${status.color_code}`,color:'#fff'}}>
                                        {status.name}
                                      </option>
                                  )
                                ) : (
                                  <option>No data Found</option>
                                )}
                              </select> ) : (
                                <p onClick={()=> handlemaineditRow(i)}>{item.status_name}</p>
                              )}
                          </td>
                          :
                        <td>{item.status_name}</td> }
                        {user_info.role_id != 13 ? 
                        <td  onClick={()=> handlemaineditRow(i)} >
                        {item.mainediting ? (
                          <input type="text"
                          className="form-control"
                          defaultValue={item.remarks}
                          
                            onBlur={(e) =>
                              editField(
                                e.target.value,
                                "remarks",
                                item.id,
                                'tasks',
                                i
                              )
                            }
                          />
                        ) : (
                          <span>{item.remarks}</span>
                        )}
                      </td>
                        :
                        <td className="whitespace">{item.remarks}</td> }
                        {user_info.role_id == 13 ? 
                        <td  onClick={()=> handlemaineditRow(i)} >
                        {item.mainediting ? (
                          <input type="text"
                          className="form-control"
                          defaultValue={item.client_remarks}
                          
                            onBlur={(e) =>
                              editField(
                                e.target.value,
                                "client_remarks",
                                item.id,
                                'tasks',
                                i
                              )
                            }
                          />
                        ) : (
                          <span>{item.client_remarks}</span>
                        )}
                      </td>
                        :
                        <td className="whitespace">{item.client_remarks}</td> }
                     </tr>
                      
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
        
           {/* task detail popup */}
        <Row>
          <Modal
            size="lg"
            className="taskdetailmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => settaskdetailPopup(!taskdetailPopup)}
            isOpen={taskdetailPopup}
          >
            <div className="modal-header">
            <h3>Task Detail</h3>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => settaskdetailPopup(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{paddingTop:'0px'}}>
            {documentlists.length > 0 ?
            <button type="button" onClick={() => setdocumentModalOpen(true) } title="Download Documents" class="btn-icon-clipboard btn btn-secondary col-md-3" style={{textAlign:"center"}}>
              <i class="fas fa-eye" style={{fontSize:"14px"}}></i> View Documents
            </button>
            : null }
            {rejectionlists.length > 0 ?
            <button type="button" onClick={() => setrejectionModalOpen(true) } title="View Rejections" class="btn-icon-clipboard btn btn-secondary col-md-3" style={{textAlign:"center"}}>
              <i class="fas fa-eye" style={{fontSize:"14px"}}></i> View Rejections
            </button>
            : null }
             {taskdetails.user_id != null ?
            <button type="button" onClick={() => setotherInfoModalOpen(true) } title="Other Info" class="btn-icon-clipboard btn btn-secondary col-md-3" style={{textAlign:"center"}}>
              <i class="fas fa-eye" style={{fontSize:"14px"}}></i> Other Info
            </button>
            : null }
          {(taskdetails.company)?  <p><b>Book Keeping Software :</b> {taskdetails.company.book_keeping_software} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <b>Account Production Software</b> : {taskdetails.company.account_prod_software}</p>  :null }
           
              <Table className="align-items-center table-flush" responsive>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th className="whitespace">Assigned Leader</th>
                    <th className="whitespace">Assigned Member</th>
                    <th>Due date</th>
                    <th>Start date</th>
                    <th>Comp. date</th>
                    <th className="whitespace">Status</th>
                    <th>Approval</th>
                  </tr>
                </thead>
                <tbody>
                {taskdetails.analyst  ? (
                  <tr key="analyst" className="blue-row">
                    <td>Analyst</td>
                    <td className="whitespace">{taskdetails.analyst.leader}</td>
                    <td className="whitespace">{taskdetails.analyst.member}</td>
                    <td >{taskdetails.analyst.due_date}</td>
                    <td>{taskdetails.analyst.start_date}</td>
                    <td>{taskdetails.analyst.completed_date}</td>
                    <td className="whitespace">{taskdetails.analyst.status ? taskdetails.analyst.status.name : ""}</td>
                    <td>{taskdetails.analyst.approval==1 ? 'Approved' : taskdetails.analyst.approval==2 ? 'Denied' : ''}
                    {taskdetails.analyst.flag==1 ? 'Ready for review' : taskdetails.analyst.flag==2 ? 'Phases assigned by QA' : taskdetails.analyst.flag==3 ? 'Rejected by QA' : taskdetails.analyst.flag==4 ? 'Reviewed' : ''}
                    </td>
                  </tr>
                 
                 ) : (
                  null
                )}

                      {taskdetails.phases && taskdetails.phases.length
                          ?                               
                          taskdetails.phases.map((phase,d) => (
                              <tr key={d} className={((d % 2 == 0)? 'purple-row': 'orange-row' )}>
                                <td>{phase.phases_list.desc}</td>
                                <td  className="whitespace">{phase.leader}</td>
                                <td  className="whitespace">{phase.member}</td>
                                <td>{phase.due_date}</td>
                                <td>{phase.start_date}</td>
                                <td>{phase.completed_date}</td>
                                <td  className="whitespace">{phase.status ? phase.status.name : ""}</td>
                                <td>{phase.approval==1 ? 'Approved' : phase.approval==2 ? 'Denied' : ''}
                                    {phase.flag==1 ? 'Rejected' : ''}
                                </td>
                              </tr>
                            ))
                          :
                           ( <p>No Data Found</p> )}
                </tbody>
              </Table>
            </ModalBody>
           </Modal>
         
            <Modal className="taskdetailmodal"  isOpen={documentModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Documents 
              </h5>
              
              <button aria-label="Close" className=" close" type="button" onClick={() => setdocumentModalOpen(!documentModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
              
            </div>
            
            <ModalBody>
            {(loading == true) ? 
                      <a style={{color:"#2FC3B9"}}  >Downloading please wait.. </a>
                      :
            <button type="button" onClick={() => downloadAllfile(taskdetails.user_id,taskdetails.id)} title="Download All Documents" class="btn-icon-clipboard btn btn-secondary col-md-3" style={{textAlign:"center"}}>
              <i class="fas fa-download" style={{fontSize:"14px"}}></i> Download All Documents
            </button>
}
              <Table>
              <thead>
                  <tr>
                    <th className="whitespace">Document Checklist</th>
                    <th className="whitespace">Remarks</th>
                    <th className="whitespace">Document</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                {documentlists.length > 0 && documentlists  ? (
                        documentlists.map((document, i) => (
                  <React.Fragment key={i}>
                  <tr key={i}>
                    <td className="whitespace">{document.doc_type}</td>
                    <td className="whitespace">{document.remark}</td>
                    <td className="whitespace">
                    {(loading == true) ? 
                      <a style={{color:"#2FC3B9"}}  >Downloading please wait.. </a>
                      :
                      <a style={{cursor:"pointer",color:"#2FC3B9"}} onClick={() => downloadfile(document.doc_path,document.file_name,document.extension,taskdetails.id)} download><i className="fas fa-download"> </i> Download</a>
                    }
                    </td>
                    <td className="whitespace">{document.date}</td>
                  </tr>
                  </React.Fragment>
                  ))
                 ) : (
                  <tr>
                  <td>No Data Found</td>
                  </tr>
                )}
                </tbody>
              </Table>
            </ModalBody>
          </Modal>

          <Modal className="taskdetailmodal"  isOpen={rejectionModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Rejections 
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setrejectionModalOpen(!rejectionModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <Table>
              <thead>
                  <tr>
                    <th className="whitespace">Rejection for</th>
                    <th className="whitespace">Phase</th>
                    <th className="whitespace">Category</th>
                    <th className="whitespace">Comment</th>
                    <th className="whitespace">Status</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                {rejectionlists.length > 0 && rejectionlists  ? (
                        rejectionlists.map((rejection, i) => (
                  <React.Fragment key={i}>
                  <tr key={i} className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') }>
                    <td className="whitespace">{rejection.type}</td>
                    <td className="whitespace">{rejection.phase_name}</td>
                    <td className="whitespace">{rejection.cat_name}</td>
                    <td className="whitespace">{rejection.comment}</td>
                    <td className="whitespace" onClick={()=> handlerejeditRow(i)}>
                    {rejection.editing ? (
                    <select
                                className="form-control"
                                onChange={(e) =>
                                  editRejField(
                                    e.target.value,
                                    "status",
                                    rejection.id,
                                    i
                                  )
                                }
                                defaultValue={rejection.status}
                              >
                                <option value="0" key="0" >Select Status</option>
                                {statuslist &&
                                statuslist.length &&
                                statuslist != null ? (
                                  statuslist.map((status) =>
                                  
                                      <option key={status.id} value={status.id} >
                                        {status.name}
                                      </option>
                                  )
                                ) : (
                                  <option>No data Found</option>
                                )}
                              </select> ) : (
                                <p>{rejection.status_name}</p>
                              )} 
                              </td>
                  <td className="whitespace">{rejection.date}</td>
                  </tr>
                  </React.Fragment>
                  ))
                 ) : (
                  <tr>
                  <td>No Data Found</td>
                  </tr>
                )}
                </tbody>
              </Table>
            </ModalBody>
          </Modal>
          <Modal className="otherinfomodal"  isOpen={otherInfoModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              All Info 
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setotherInfoModalOpen(!otherInfoModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <p> Client Manager : {taskdetails.client_manager}</p><br></br>
              <p> Whether VAT register : {(taskdetails.vat_register == 1) ? 'YES' : 'NO'}</p><br></br>
              <p> VAT Scheme : {taskdetails.vat_scheme}</p><br></br>
              <p> Point of contact : {taskdetails.point_of_contact}</p><br></br>
              <p> Whether Bookkeeping Done : {(taskdetails.bookkeeping_done == 1) ? 'YES' : 'NO'}</p><br></br>
              <p> Whether to be completed bookkeeping? : {(taskdetails.need_bookkeeping == 1) ? 'YES' : 'NO'}</p><br></br>
              <p> Whether Account Production Done : {(taskdetails.account_production_done == 1) ? 'YES' : 'NO'}</p><br></br>
              <p> Whether YE Journals to be accounted in Bkg Software? : {(taskdetails.ye_journals == 1) ? 'YES' : 'NO'}</p><br></br>
              <p> Access Details : {taskdetails.access_details}</p><br></br>
              <p> Priority No. : {taskdetails.priority_no}</p><br></br>
              <p> Plan : {taskdetails.plan}</p><br></br>
              <p> Last document updated date : {taskdetails.last_doc_update_date}</p><br></br>
            </ModalBody>
          </Modal>
        </Row>
      </Container>
    </>
  );
};


export default ClientDetails;
