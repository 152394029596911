import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup, 
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Container,
  UncontrolledAlert
} from "reactstrap";
import React, { useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import {clientmanageService} from '../../../services/clientmanageService.js';
import {manageService} from '../../../services/manageaccountservices';
import { toast } from 'react-toastify';
import ReactDatetime from "react-datetime";
import Select from 'react-select';
import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
const AddClient = () => {
  const history = useHistory();
  let initialStateData = {
    name:"",
    sub_client:"",
    year_end_date: "",
    book_keeping_software:"",
    account_prod_software:"",
    initial_budget:"",
    client_deadline:"",
    files:[]
  };
  const [inputFields, setInputfields] = useState({});
  const [error, setError] = useState(null)
  
  const [successMsg, setsuccessMsg] = useState(null)
  const [displayclient, setDisplayclient] = useState(false);
  const [displaysubclient, setDisplaysubclient] = useState(false);
  const [other_report, setOtherReport] = useState(false);
  const errorDiv = error 
        ? <UncontrolledAlert className="alert-danger" fade={false}>
        {error}
        </UncontrolledAlert> 
        : '';
  const successDiv = successMsg 
        ? <UncontrolledAlert className="alert-success" fade={false}>
        {successMsg}
        </UncontrolledAlert> 
        : '';
  
  const [freqdata, setfreqdata] = useState([]);
  const [weeklydiv, setweeklydiv] = useState(false);
  const [annuallydiv, setannuallydiv] = useState(false);
  const [selectedclient, setselectedclient] = useState(null);
  
  function inputhandler(e){
    
   
    if(Array.isArray(e)){   
      setInputfields({...inputFields,freq_date : Array.isArray(e) ? e.map(x => x.value) : []})  
    }
    else{
      if((e.target.name == 'type' && e.target.value=='Other Reports') || e.target.name=='other_report' ){
        setOtherReport(true)
      }else{
        setOtherReport(false)
      }
      if(e.target.name == 'frequency' && e.target.value != 'OneOff'){
        setweeklydiv(false)
        setannuallydiv(false)
        setfreqdata([])
        if(e.target.value == 'Weekly'){
           const daydata = [{value:1,label : 'Monday'},{value:2,label :'Tuesday'},{value:3,label :'Wednesday'},{value:4,label :'Thursday'},{value:5,label :'Friday'},{value:6,label :'Saturday'},{value:7,label :'Sunday'}];
          setweeklydiv(true)
          setfreqdata(daydata)
        }
        if(e.target.value == 'Monthly' || e.target.value == 'Fortnightly'){
          let daydata = [];
          for(let i = 1; i <= 31; i++){
            if(i<=9 && i>=1){
              daydata.push({value : '0'+i,label : '0'+i})
            }else{
              daydata.push({value : i,label : i})
            }
          }
          setweeklydiv(true)
          setfreqdata(daydata)
        }
        if(e.target.value == 'Quarterly' || e.target.value == 'Annually' || e.target.value == 'Bimonthly' || e.target.value == 'Fourthmonthly'){
          const daydata = [{value:'01',label : 'Jan'},{value:'02',label :'Feb'},{value:'03',label :'March'},{value:'04',label :'April'},{value:'05',label :'May'},{value:'06',label :'June'},{value:'07',label :'July'},{value:'08',label :'Aug'},{value:'09',label :'Sept'},{value:10,label :'Oct'},{value:11,label :'Nov'},{value:12,label :'Dec'}];
          setannuallydiv(true)
          setfreqdata(daydata)
        }
        setInputfields({...inputFields,freq_date : [],freq_day : '',[e.target.name]:e.target.value});
      }else{
       if(e.target.name == 'name' ){
        setselectedclient(e.target.value)
        subclientList(e.target.value)
       }
       
        setInputfields({...inputFields,[e.target.name]:e.target.value});
      }
    }
    console.log("d")
    console.log(inputFields)
  } 
  function showclientfield(){
    setDisplayclient(true)
  }
  function showsubclientfield(){
    setDisplaysubclient(true)
  }
  const [ clientlist, setclientlist ] = useState([])
  const [ subclientlist, setsubclientlist ] = useState([])
  function clientList(){
    manageService.get_clientusers().then((response)=>{
          if(response.status === 200){
            setclientlist(response.data)
          }else {
              toast.error('Something went wrong!');
          }
        });
  }
  function subclientList(clientname=''){
    clientmanageService.getsubclientlist(clientname).then((response) => {
        if(response.status === 200){
          setsubclientlist(response.data)
        }else {
          toast.error('Something went wrong!');
        }
    });
  }
  function setclient(e){
    setselectedclient(e.target.value)
    subclientList(e.target.value)
  }

  function addclient(e){
    e.preventDefault();
    // console.log(inputFields);
    // return;
    
    clientmanageService.addclient(inputFields,files).then((response)=>{
      // console.log(response)
      if(response.status == 200){
         setsuccessMsg(response.data.message);
         document.getElementById('addclient-form').reset();
         setError(null);
         setInputfields(initialStateData);
         toast.success(response.data.message);  
         history.goBack(); 
      }else if(response.status == 201){
        setError('Enter All required field');
        setsuccessMsg(null);
        toast.error(response.data.message); 
      }
    });
  }

  function cancel_form(){
       document.getElementById('addclient-form').reset();
       setInputfields(initialStateData);
       history.goBack(); 
  }
  const handleClean = (files) => {
    console.log("list cleaned", files);
  };

  const user_info = JSON.parse(localStorage.getItem("user-info"));
  useEffect(() => { 
    clientList()
    if(user_info.role_id == 13){
      subclientList(user_info.name);
      setselectedclient(user_info.name)
      setInputfields({...inputFields,['name']:user_info.name});
    }
    }, [] ) 
    const disablePastDate = () => {
      const today = new Date();
      const dd = String(today.getDate() + 1).padStart(2, "0") - 1;
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
  };
  function getclientdetails(e){
    var clientname = '';
    if(user_info.role_id == 13){
      clientname = user_info.name;
    }else{
      clientname = selectedclient;
    }
    clientmanageService.getclientdetails(clientname,e.target.value).then((response) => {
      if(response.status === 200){
        if(response.data){
        console.log(response.data)
        setInputfields(response.data)
        }
      }else {
        toast.error('Something went wrong!');
      }
  });
  }
 
  // Example of initializing 'files' state properly
  const [files, setFiles] = useState({ files: [] });

 const updateFiles = (incomingFiles) => {
  console.log(incomingFiles)
    // Remove duplicates from incomingFiles based on some unique identifier like file name or id
    const uniqueIncomingFiles = incomingFiles.filter((file, index, self) =>
        index === self.findIndex((t) => (
            t.id === file.id // Use a unique identifier here
        ))
    );

    let tempState = { ...files }; // Shallow copy of 'files' state

    // Update 'files' state with the unique array of items
    tempState.files = [...tempState.files, ...uniqueIncomingFiles];

    setFiles(tempState); // Update 'files' state with the new array
  //   setInputfields(prevInputFields => ({
  //     ...prevInputFields,
  //     files: incomingFiles // Assuming 'files' is structured with a 'files' array
  // }));
   // console.log('Updated files state:', tempState);
};

  





const onDelete = (id) => {

  // Clone the 'files' state object
  let temp_state = { ...files };

  // Find the index of the file that needs to be deleted
  const index = temp_state.files.findIndex(file => file.id === id);

  if (index !== -1) {
    console.log(temp_state.files[index].id)
      // Filter out the file with matching id
      temp_state.files[index] = temp_state.files.filter(file => file.id !== id);

      // Check if there are no files left in the uploadfiles array
      if (temp_state.files[index].length === 0) {
          // Remove the entire entry if uploadfiles is empty
          temp_state.files.splice(index, 1);
      }

      // Update the 'files' state with the modified temp_state
      setFiles(temp_state);
  } else {
      console.warn(`File with id ${id} not found.`);
  }
};
function onFileChange(e){
  const files = Array.from(e.target.files);
  console.log(e.target.files)
  setInputfields({ ...inputFields, [e.target.name]: files });
  console.log(inputFields)
}

  return (
      <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      <Container fluid>
          <Row className="justify-content-md-center">
              <Col lg="12" md="12">
                  <Card className="shadow">
                      <CardHeader className="bg-transparent">
                          <h3 className="mb-0">Add Client</h3>
                      </CardHeader>
                      <CardBody className="px-lg-5 py-lg-10">
                      {errorDiv}
              {successDiv}
                      <Form role="form" id="addclient-form" onSubmit={addclient}>
                          <FormGroup>
                           <Row>
                           {user_info.role_id != 13 ? 
                            <>
                              <div className="col-xl-2 col-md-12 col-xs-12">
                                  <h3>Client</h3> 
                              </div>
                              <div className="col-xl-6 col-md-12 col-xs-12" style={{ display: displayclient == false ? "flex" : "none" }}>
                              <select  class="form-control" name="name" onChange={inputhandler} required={(displayclient == false) ? true : false } >
                              <option value=""> Select Client</option>
                              {clientlist.map(res => (
                                  <option
                                    key={res.name}
                                    value={res.name}
                                  >
                                    {res.name}
                                  </option>
                                ))}
                              </select>
                              </div>
                              </>
                              : null }
                              {/* <div  style={{ display: displayclient == false ? "flex" : "none" }} className="col-xl-4 col-md-12 col-xs-12">
                                <p> OR &nbsp;<b onClick={showclientfield}> Add New Client</b></p>
                                </div>
                              <div style={{ display: displayclient == true ? "flex" : "none" }} className="col-xl-10 col-md-12 col-xs-12">
                                 <InputGroup className="input-group-alternative mb-3">
                                    <Input 
                                    placeholder="Client Name" 
                                    type="text" 
                                    name="name" 
                                    value={inputFields.name != undefined ? inputFields.name : ''}
                                    onChange={inputhandler}
                                    required={(displayclient == true) ? true : false }
                                    />
                                </InputGroup>      
                              </div> */}
                           </Row>   
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <div className="col-xl-2 col-md-12 col-xs-12">
                                  <h3>Sub Client</h3> 
                              </div>
                              <div className="col-xl-6 col-md-12 col-xs-12" style={{ display: displaysubclient == false ? "flex" : "none" }}>
                              <select  class="form-control" name="sub_client" onChange={(e) => { inputhandler(e); getclientdetails(e);}} required={(displaysubclient == false) ? true : false } >
                              <option value=""> Select Sub Client</option>
                              {subclientlist.map(res => (
                                  <option
                                    key={res.sub_client}
                                    value={res.sub_client}
                                  >
                                    {res.sub_client}
                                  </option>
                                ))}
                              </select>
                              </div>
                              <div  style={{ display: displaysubclient == false ? "flex" : "none" }} className="col-xl-4 col-md-12 col-xs-12">
                                <p> OR &nbsp;<b onClick={showsubclientfield}> Add Sub Client</b></p>
                                </div>
                              <div style={{ display: displaysubclient == true ? "flex" : "none" }}  className="col-xl-10 col-md-12 col-xs-12">
                                 <InputGroup className="input-group-alternative mb-3">
                                    <Input 
                                    placeholder="Sub Client Name" 
                                    type="text" 
                                    name="sub_client" 
                                    value={inputFields.sub_client != undefined ? inputFields.sub_client : ''}
                                    onChange={inputhandler}
                                    required={(displaysubclient == true) ? true : false }
                                    />
                                </InputGroup>    
                              </div>
                           </Row>  
                          </FormGroup>
                          {user_info.role_id != 13 ? 
                          <>
                           <FormGroup style={{ display: displayclient == true ? "block" : "none" }}>
                          <Row>
                           <Col md="4">
                                <label>BK Questionnaire</label>
                                {/* <div className="col-xl-2 col-md-12 col-xs-12"> */}
                                <select class="form-control" name="bk_questionnaire" required={(displayclient == true) ? true : false } onChange={inputhandler} >
                                  <option value=""> Select </option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                                {/* </div> */}
                                </Col>
                                <Col md="4">
                                    <label>SOP</label> 
                                {/* <div className="col-xl-2 col-md-12 col-xs-12"> */}
                                <select class="form-control" name="sop" required={(displayclient == true) ? true : false }  onChange={inputhandler} >
                                  <option value=""> Select </option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                                {/* </div> */}
                                </Col>
                                <Col md="4">
                                <label>Budgets Working</label> 
                                
                                {/* <div className="col-xl-2 col-md-12 col-xs-12"> */}
                                <select class="form-control" name="budget_working" required={(displayclient == true) ? true : false }  onChange={inputhandler} >
                                  <option value=""> Select </option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                                {/* </div> */}
                                </Col>
                          </Row>
                          </FormGroup>
                          
                          <FormGroup style={{ display: displaysubclient == true ? "block" : "none" }}>
                          <Row>
                          <Col md="6">
                           <label>Decision-Making Matrix</label> 
                                <select class="form-control" name="decision_making"  onChange={inputhandler} >
                                  <option value=""> Select </option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                           </Col>
                           <Col md="6">
                             <label>VAT Policy</label> 
                                <select class="form-control" name="vat_policy"  onChange={inputhandler} >
                                  <option value=""> Select </option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                                </Col>
                           </Row>
                          </FormGroup>
                          
                          <FormGroup style={{ display: displaysubclient == true ? "block" : "none" }}>
                          <Row>
                            <Col>
                           <label>Remarks For above questions</label>
                                <textarea name="remarks" class="form-control" onChange={inputhandler} ></textarea>
                                </Col>
                            </Row>
                            <br></br>
                          </FormGroup>
                          </>
                          : null }
                          <FormGroup>
                            <Row>
                              <Col>
                              <label>Year-End date</label>
                                   <InputGroup className="input-group-alternative mb-3">
                                        <Input
                                        placeholder="Year - End Date"
                                        type="date"
                                        name="year_end_date"
                                        value={inputFields.year_end_date != undefined ? inputFields.year_end_date : ''}
                                        onChange={inputhandler}
                                        required
                                        />
                                    </InputGroup>  
                                </Col>
                                <Col>
                                <label>Bookkeeping Software</label>
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="Bookkeeping Software"
                                        type="text"
                                        name="book_keeping_software"
                                        value={inputFields.book_keeping_software != undefined ? inputFields.book_keeping_software : ''}
                                        onChange={inputhandler}
                                        required
                                        />
                                    </InputGroup>
                                </Col>
                            
                                <Col>
                                  <label>Invoice Processing Software</label> 
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="Invoice processing Software"
                                        type="text"
                                        name="account_prod_software"
                                        value={inputFields.account_prod_software != undefined ? inputFields.account_prod_software : ''}
                                        onChange={inputhandler}
                                        required
                                        />
                                    </InputGroup>
                                  </Col>
                                  </Row> 
                          </FormGroup>
                          <FormGroup>
                             <Row>
                             {user_info.role_id != 13 ? 
                          <>
                            <Col>
                               <label>QA Budget Hours</label> 
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="QA Budget"
                                        type="number"
                                        name="in_budget"
                                        value={inputFields.in_budget != undefined ? inputFields.in_budget : ''}
                                        onChange={inputhandler}
                                        required
                                        inputmode="numeric"
                                        />
                                    </InputGroup>
                                </Col>
                                {user_info.department == 'SME' ?
                                <>
                                <Col>
                                <label>IP Budget Hours</label> 
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="IP Budget"
                                        type="number"
                                        name="ip_budget"
                                        value={inputFields.ip_budget != undefined ? inputFields.ip_budget : ''}
                                        onChange={inputhandler}
                                        required
                                        inputmode="numeric"
                                        />
                                    </InputGroup>
                                </Col>
                                <Col>
                               <label>BR Budget Hours</label> 
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="BR Budget"
                                        type="number"
                                        name="br_budget"
                                        value={inputFields.br_budget != undefined ? inputFields.br_budget : ''}
                                        onChange={inputhandler}
                                        required
                                        inputmode="numeric"
                                        />
                                    </InputGroup>
                                </Col>
                                </>
                                : null }
                                </>
                                : null }
                                <Col>
                               <label>Total Budget Hours</label> 
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="Total Budget"
                                        type="number"
                                        name="budget"
                                        value={inputFields.budget != undefined ? inputFields.budget : ''}
                                        onChange={inputhandler}
                                        required
                                        inputmode="numeric"
                                        />
                                    </InputGroup>
                                </Col>
                            <Col>
                                 <label>Job Deadline</label> 
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="Client Deadline"
                                        type="date"
                                        name="client_deadline"
                                        value={inputFields.client_deadline != undefined ? inputFields.client_deadline : ''}
                                        onChange={inputhandler}
                                        // min={disablePastDate()}
                                        required
                                        />
                                    </InputGroup>
                                </Col>
                                {user_info.role_id != 13 ? 
                         
                             <Col>
                                    <label>Job added date</label> 
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="Job added date"
                                        type="date"
                                        name="created_at"
                                        value={inputFields.created_at != undefined ? inputFields.created_at : ''}
                                        onChange={inputhandler}
                                        />
                                    </InputGroup>
                            </Col> 
                            : null }
                            <Col>
                                <label>Type Of JOB</label> 
                                <select class="form-control" name="type"  onChange={inputhandler} required>
                                  <option value=""> Select type</option>
                                  <option value="Bkg">Bkg</option>
                                  <option value="VAT">VAT</option>
                                  <option value="MA">MA</option>
                                  <option value="Bookkeeping Review">Bookkeeping Review</option>
                                  <option value="Bkg + VAT">Bkg + VAT</option>
                                  <option value="Bkg + MA">Bkg + MA</option>
                                  <option value="VAT + MA">VAT + MA</option>
                                  <option value="Bkg + VAT + MA">Bkg + VAT + MA</option>
                                  <option value="Migration">Migration</option>
                                  <option value="Bank Reconciliation">Bank Reconciliation</option>
                                  <option value="Payroll">Payroll</option>
                                  <option value="CIS Reconciliation">CIS Reconciliation</option>
                                  <option value="Other Reports">Other Reports</option>
                              </select>
                              <Input
                                        placeholder="Enter Other Report"
                                        type="text"
                                        name="other_report"
                                        
                                        onChange={inputhandler}
                                        
                                        style={{ display: other_report == false ? "none" : "flex" }}
                                        />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                             <Row>
                              
                              {/* {user_info.role_id != 13 ? 
                          <> */}
                              <Col>
                                <label>Frequency</label> 
                                <select class="form-control" name="frequency"  onChange={inputhandler} required>
                              <option value=""> Select frequency</option>
                                <option value="OneOff">One Off</option>
                                <option value="Daily">Daily</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Fortnightly">Fortnightly</option>
                                <option value="Quarterly">Quarterly</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Annually">Annually</option>
                                <option value="Bimonthly">Bimonthly</option>
                                <option value="Fourthmonthly">Fourthmonthly</option>
                              </select>
                              </Col>
                              {(weeklydiv == true)?
                              <Col >
                                <label>Frequency values</label>
                                <Select 
                                    className="input-group-alternative mb-3" 
                                    name="freq_date" 
                                    allowSelectAll={true} 
                                    value={(inputFields.freq_date) ? freqdata.filter(obj => inputFields.freq_date.includes(obj.value)) : ''} // set selected values
                                    options={freqdata} // set list of the data
                                    onChange={inputhandler}
                                    isMulti
                                    isClearable
                                    required
                                  />
                                  {inputFields.freq_date && <div style={{ marginTop: 20, lineHeight: '25px' }}></div>}
                              </Col>
                              : null }
                              {(annuallydiv == true)?
                              <>
                              <Col md="1">
                                <label>Day</label> 
                                 <select class="form-control" name="freq_day" value={inputFields.freq_day} onChange={inputhandler} required>
                                  <option value=""> Select Day</option>
                                  {(() => {
                                      let option = [];
                                      for (let i = 1; i <= 31; i++) {
                                        option.push(<option value={i}>{i}</option>);
                                      }
                                      return option;
                                    })()}
                                  </select>
                                  </Col>
                                  <Col>
                                    <label>Months</label>
                                <Select 
                                    className="input-group-alternative mb-3" 
                                    name="freq_date" 
                                    allowSelectAll={true} 
                                    value={(inputFields.freq_date) ? freqdata.filter(obj => inputFields.freq_date.includes(obj.value)) : ''} // set selected values
                                    options={freqdata} // set list of the data
                                    onChange={inputhandler}
                                    isMulti
                                    isClearable
                                    required
                                  />
                                  {inputFields.freq_date && <div style={{ marginTop: 20, lineHeight: '25px' }}></div>}
                              </Col>
                              </>
                          : null }
                              {/* </>
                              : null } */}
                            </Row> 
                          </FormGroup>
                          
                          <FormGroup>
                             <Row>

                             <Col>
                                <label>VAT Quarter End</label> 
                                <select class="form-control" name="vat_qe" value={inputFields.vat_qe}  onChange={inputhandler} required>
                                  <option value=""> Select VAT quarter end</option>
                                  <option value="NO VAT">NO VAT</option>
                                  <option value="Monthly">Monthly</option>
                                  <option value="Jan-Apr-Jul-Oct">Jan-Apr-Jul-Oct</option>
                                  <option value="Feb-May-Aug-Nov">	Feb-May-Aug-Nov	</option>
                                  <option value="Mar-Jun-Sep-Dec">Mar-Jun-Sep-Dec</option>
                                </select>
                              </Col>
                              <Col>
                                 <label>VAT Quarter End date</label> 
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="VAT Quarter End date"
                                        type="date"
                                        name="vat_qe_date"
                                        defaultValue={inputFields.vat_qe_date != undefined ? inputFields.vat_qe_date : ''}
                                        onChange={inputhandler}
                                        
                                        />
                                    </InputGroup>
                                </Col>
                              <Col>
                              <label>VAT Scheme</label> 
                              <InputGroup className="input-group-alternative">
                                <Input
                                  placeholder="VAT Scheme"
                                  type="text"
                                  name="vat_scheme"
                                  value={
                                    inputFields.vat_scheme != undefined
                                      ? inputFields.vat_scheme
                                      : ""
                                  }
                                  onChange={inputhandler}
                                  
                                />
                              </InputGroup>
                              </Col>
                              <Col>
                              <label>Priority</label> 
                                  <InputGroup className="input-group-alternative">
                                      <Input
                                      placeholder="Priority"
                                      type="number"
                                      name="priority_no"
                                      value={inputFields.priority_no != undefined ? inputFields.priority_no : ''}
                                      onChange={inputhandler}
                                      required
                                      inputmode="numeric"
                                      />
                                    </InputGroup>
                              </Col>
                              <Col>
                              <label>Scope of work</label>
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="Scope of work"
                                        type="text"
                                        name="business_nature"
                                        value={inputFields.business_nature != undefined ? inputFields.business_nature : ''}
                                        onChange={inputhandler}
                                        required
                                        />
                                    </InputGroup>
                              </Col>
                            </Row>
                            </FormGroup>
                            {user_info.role_id != 13 ? 
                          <>
                            <FormGroup>
                             <Row>

                             <Col md="3">
                                <label>Task Type</label> 
                                <select class="form-control" name="task_type" value={inputFields.task_type} onChange={inputhandler} >
                                  <option value=""> Select Task Type</option>
                                  <option value="Transition">Transition</option>
                                  <option value="Operations">Operations</option>
                                  <option value="E2E">E2E</option>
                                </select>
                              </Col>
                              <Col md="3">
                                <label>Task List</label> 
                                <select className="form-control"
                            name="task_list"
                             onChange={inputhandler}
                                   
                                  >
                             <option >Select Option</option>
                             <option value="Onboarding PPT">Onboarding PPT</option>
    <option value="Preparation Of Backlog Assessment Report">Preparation Of Backlog Assessment Report</option>
    <option value="SOP / Process Map Preparation">SOP / Process Map Preparation</option>
    <option value="Transition Tracker Update">Transition Tracker Update</option>
    <option value="Understanding Of Client Processes">Understanding Of Client Processes</option>
    <option value="Adhoc Reporting">Adhoc Reporting</option>
    <option value="Age Payable Review">Age Payable Review</option>
    <option value="Age Receivable Review">Age Receivable Review</option>
    <option value="Bank Payments">Bank Payments</option>
    <option value="Bank Reconciliation">Bank Reconciliation</option>
    <option value="Bank Upload">Bank Upload</option>
    <option value="Cash Flow Preparation">Cash Flow Preparation</option>
    <option value="CIS Reco">CIS Reco</option>
    <option value="Client Inbox / Credit Control">Client Inbox / Credit Control</option>
    <option value="Credit Card">Credit Card</option>
    <option value="Customer Statement Reconciliation">Customer Statement Reconciliation</option>
    <option value="Factoring Reco">Factoring Reco</option>
    <option value="Factoring Working & Upload">Factoring Working & Upload</option>
    <option value="Journal Vouchers">Journal Vouchers</option>
    <option value="Management Reports / Dashboard / PPT">Management Reports / Dashboard / PPT</option>
    <option value="Month End Checklist">Month End Checklist</option>
    <option value="Opening Balance Comparison / Opening Journal">Opening Balance Comparison / Opening Journal</option>
    <option value="Payment File Preparation">Payment File Preparation</option>
    <option value="Petty Cash">Petty Cash</option>
    <option value="Purchase Invoices">Purchase Invoices</option>
    <option value="Review & Other Process Review">Review & Other Process Review</option>
    <option value="Review Month End Checklist & MA">Review Month End Checklist & MA</option>
    <option value="Sales Invoices">Sales Invoices</option>
    <option value="Supplier Statement Reconciliation">Supplier Statement Reconciliation</option>
    <option value="Vat Return Review">Vat Return Review</option>
    <option value="Vat Working">Vat Working</option>
    <option value="Year End Checklist">Year End Checklist</option>
    <option value="Year End Checklist Review">Year End Checklist Review</option>
    <option value="Trade Entries">Trade Entries</option>
    <option value="Amendment">Amendment</option>
    <option value="Billing">Billing</option>
    <option value="Inter Company Reconciliation">Inter Company Reconciliation</option>
    <option value="Audit Support">Audit Support</option>
                             </select>
                              </Col>
                              
                             
                              </Row>
                              </FormGroup>
                              </>
                              : null }
                               {user_info.role_id == 13 ? 
                              <FormGroup>
                                <Row>
                                <Col md="3">
                                <label>Other Document</label>
                                <input type="file" multiple name="file" id="import_file" className="text-right"  onChange={onFileChange}  />
                   
                                {/* <Dropzone
    style={{ minHeight: "0px" }}
    label={
        <h1>
            <i className="ni ni-cloud-upload-96 text-th browse-icon"></i>
            <p className="browse-title">
                Drop your files or Browse 
            </p>
        </h1>
    }
    onChange={(e) => updateFiles(e)}
    minHeight="195px"
    onClean={(e) => handleClean(e)}
    header={false}
    footer={false}
    url=""
    fakeUploading={false}
    behaviour="add"
    disableScroll
> */}
    {/* Render uploaded files using files state */}
{/* {files['files'].length > 0 &&
    files['files'].map((file) => (
        <FileItem
            {...file}
            key={file.id}
            onDelete={(e)=> onDelete(file.id)}
            
            resultOnTooltip
            preview
            info
            hd
        />
    ))
} */}


    {/* Default dropzone message */}
    {/* <h1 style={{ textAlign: "center" }}>
        <i className="ni ni-cloud-upload-96 text-th browse-icon"></i>
        <p className="browse-title">Drop your files or Browse</p>
    </h1>
</Dropzone> */}

                              </Col> 
                                </Row>
                              </FormGroup>
                                : null }
                          <div className="col-12">
                             <Button className="mt-2" color="primary" type="submit">
                              Save
                             </Button>
                             <Button className="mt-2 ml-2" color="secondary " type="button" onClick={cancel_form}>
                               Cancel
                             </Button>
                          </div>
                      </Form>
                      </CardBody>
                  </Card>
              </Col>
          </Row>
      </Container>
      </div>
      </>
  );
};

export default AddClient;
