import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
    Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { ToastContainer, toast } from 'react-toastify';
import { domainUrl} from '../../constant';
import '@flowjs/flow.js';
import Flow from "@flowjs/flow.js";
import { async } from "@firebase/util";
import { useHistory,useParams } from "react-router-dom";
import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
import { manageService } from "../../services/manageaccountservices";
import { usermanageService } from "../../services/usermanageService";
import Select from 'react-select';
import Buttoncomponent from 'components/Buttoncomponent.js';
const axios = require("axios");

const Merging = (props) => {
    const [userdatas, setUserdatad] = useState([]);
    const [clientlist, getClient] = useState([]);
    const [user_phases, setUserphase] = useState([]);
    const [inputFields, setInputfields] = useState({});

  
    function inputhandler2(e) {
    getassign_client(e.value);
      console.log(e)
      let phseup = {}
      phseup = { ...inputFields, roles: e.value } 
      setInputfields(phseup);
      console.log(inputFields)
    }
    function inputhandler1(e) {
      let phseup = {}
      if(Array.isArray(e)){   
      phseup = {...inputFields,atl : Array.isArray(e) ? e.map(x1 => x1.value) : []}
      }
      setInputfields(phseup);
    }
    function inputhandler(e) {
 
        let phseup = {}
        if(Array.isArray(e)){     
          phseup = {...inputFields,clients : Array.isArray(e) ? e.map(x => x.value) : []}
        }else{
          if(e.target.name == 'roles'){
            getqatl_list(e.target.value);
            
          }
            phseup = { ...inputFields, [e.target.name]: e.target.value }    
          
        }
        setInputfields(phseup);
        console.log(inputFields)
      }
  
    useEffect(() => {
        getClientList();
     // getqatl_list();
      return () => {
        setInputfields({});
      };
    }, []);
  
    function getClientList() {
        
      clientmanageService.getclient().then((response) => {
        getClient(response.data);
 
      });
    }
    const [ subclientlist, setsubclientlist ] = useState([])
    function getsubclient(e){
        let client={};
        client = {...inputFields,client : e.value};
        setInputfields(client);
        clientmanageService.getsubclient(e.value).then((response) => {
            if(response.status === 200){
              setsubclientlist(response.data)
            }else {
              toast.error('Something went wrong!');
            }
        });

    }
  
function inputsubclienthandler(e,label){
    let client = { ...inputFields };
    client[label] = e.value;
    setInputfields(client);
}
  
    const assignmanager = () =>{
      history.push({
        pathname: 'assignmanager',
      });
    }
    function addmanager(e){
      e.preventDefault();
      // console.log(inputFields);
      // return;
      clientmanageService.mergeClient(inputFields).then((response)=>{
        if(response.status == 200){
         
           toast.success(response.data.message); 
          
           document.getElementById("adduser-form").reset();
           setInputfields({});
        }else{
          console.log(response.data);
           toast.error(response.data.message);
        }
        
      });
    }
    const styles = {
      'color':'#4a4444',
    };
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12">
              <div class="row">
                  <div class="col-md-6"><h2>Merging Client</h2></div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-2 offset-md-10">
                       
                      </div>
                    </div>
                  </div>
                </div>
                <br/>
              <Card className="card">
              <Form role="form" id="adduser-form" onSubmit={addmanager}>
                <div className="row">
                    <div className="col-md-4">
                    <FormGroup>
                        <label>Client</label>
                            <Select 
                            className="input-group-alternative mb-3" 
                            name="client" 
                            allowSelectAll={true} 
                           
                            options={clientlist} // set list of the data
                            onChange={(e)=>{getsubclient(e)}}
                            

                            
                          />
                         
                            
                            
                        </FormGroup>
                        
                    </div>
                    
                    <div className="col-md-4" >
                      
                      <FormGroup>
                      <label>Merge To</label>
                            <Select 
                            className="input-group-alternative mb-3" 
                            name="merge_to" 
                            allowSelectAll={true} 
                            
                            options={subclientlist} // set list of the data
                           
                            onChange={(e)=>{inputsubclienthandler(e,'mergeto')}}
                            isClearable
                          />
                          
                            
                             
                        </FormGroup>
                    </div>
                    <div className="col-md-4" >
                      
                      <FormGroup>
                      <label>Merge With</label>
                            <Select 
                            className="input-group-alternative mb-3" 
                            name="merge_with" 
                            allowSelectAll={true} 
                            
                            options={subclientlist} // set list of the data
                            onChange={(e)=>{inputsubclienthandler(e,'mergewith')}}
                            
                            isClearable
                          />
                          
                            
                             
                        </FormGroup>
                    </div>
                    
                    
                </div>
                <div class="row">
                <div className="col-md-4">
                        
                          <Button className="col-12 " color="primary" type="submit">
                            Merge
                         </Button>
                    </div>
                    
                </div>
                
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3"></InputGroup>
                </FormGroup>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};


export default Merging;
