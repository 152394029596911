import { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService";
import 'assets/css/themecustom-style.css';

const Uploadedfiles = (props) => {
 
  useEffect(() => {
    getfiles();  
  }, []);

  const getfiles = () => { 
    taskmanageService.get_files_folders(task_id,user_id).then((res) => {
        console.log(res); 
    })  
  }


  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        
      
        <Row className="mt-5">
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="filter" style={{padding:'0 3%'}}>
                    <h3 className="mb-0" style={{fontSize:'20px'}}>Uploaded Files</h3><br></br>
                    <br></br>
                </Row>
               
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <th scope="col">File</th>
                  <th scope="col">Date modified</th>
                  <th scope="col">Action</th>
                </thead>
                <tbody>
                    <tr>
                       <td>test file</td>
                       <td>2021/09/02</td>
                       <td><button>Download</button></td> 
                    </tr>
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  {/* <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination> */}
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      
      </Container>
    </>
  );
};


export default Uploadedfiles;
