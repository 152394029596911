import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroup, 
    InputGroupAddon,
    InputGroupText,
    Row,
    Col,
    Container,
    UncontrolledAlert
  } from "reactstrap";
  import React, { useState,useEffect } from "react";
  import { useHistory } from "react-router-dom";
  import {clientmanageService} from '../../../services/clientmanageService.js';
  import {manageService} from '../../../services/manageaccountservices';
  import { toast } from 'react-toastify';
  import ReactDatetime from "react-datetime";
  import Select from 'react-select';
  const AddSubClient = () => {
    const history = useHistory();
    let initialStateData = {
      name:"",
      sub_client:"",
      year_end_date: "",
      book_keeping_software:"",
      account_prod_software:"",
      initial_budget:"",
      client_deadline:"",
    };
    const [inputFields, setInputfields] = useState({});
    const [error, setError] = useState(null)
    
    const [successMsg, setsuccessMsg] = useState(null)
    const [displayclient, setDisplayclient] = useState(false);
    const [displaysubclient, setDisplaysubclient] = useState(false);
    const [other_report, setOtherReport] = useState(false);
    const errorDiv = error 
          ? <UncontrolledAlert className="alert-danger" fade={false}>
          {error}
          </UncontrolledAlert> 
          : '';
    const successDiv = successMsg 
          ? <UncontrolledAlert className="alert-success" fade={false}>
          {successMsg}
          </UncontrolledAlert> 
          : '';
    
    const [freqdata, setfreqdata] = useState([]);
    const [weeklydiv, setweeklydiv] = useState(false);
    const [annuallydiv, setannuallydiv] = useState(false);
    const [selectedclient, setselectedclient] = useState(null);
    
    function inputhandler(e){
      
     
      if(Array.isArray(e)){   
        setInputfields({...inputFields,freq_date : Array.isArray(e) ? e.map(x => x.value) : []})  
      }
      else{
        if((e.target.name == 'type' && e.target.value=='Other Reports') || e.target.name=='other_report' ){
          setOtherReport(true)
        }else{
          setOtherReport(false)
        }
        if(e.target.name == 'frequency' && e.target.value != 'OneOff'){
          setweeklydiv(false)
          setannuallydiv(false)
          setfreqdata([])
          if(e.target.value == 'Weekly'){
             const daydata = [{value:1,label : 'Monday'},{value:2,label :'Tuesday'},{value:3,label :'Wednesday'},{value:4,label :'Thursday'},{value:5,label :'Friday'},{value:6,label :'Saturday'},{value:7,label :'Sunday'}];
            setweeklydiv(true)
            setfreqdata(daydata)
          }
          if(e.target.value == 'Monthly' || e.target.value == 'Fortnightly'){
            let daydata = [];
            for(let i = 1; i <= 31; i++){
              if(i<=9 && i>=1){
                daydata.push({value : '0'+i,label : '0'+i})
              }else{
                daydata.push({value : i,label : i})
              }
            }
            setweeklydiv(true)
            setfreqdata(daydata)
          }
          if(e.target.value == 'Quarterly' || e.target.value == 'Annually' || e.target.value == 'Bimonthly' || e.target.value == 'Fourthmonthly'){
            const daydata = [{value:'01',label : 'Jan'},{value:'02',label :'Feb'},{value:'03',label :'March'},{value:'04',label :'April'},{value:'05',label :'May'},{value:'06',label :'June'},{value:'07',label :'July'},{value:'08',label :'Aug'},{value:'09',label :'Sept'},{value:10,label :'Oct'},{value:11,label :'Nov'},{value:12,label :'Dec'}];
            setannuallydiv(true)
            setfreqdata(daydata)
          }
          setInputfields({...inputFields,freq_date : [],freq_day : '',[e.target.name]:e.target.value});
        }else{
         if(e.target.name == 'name' ){
          setselectedclient(e.target.value)
          subclientList(e.target.value)
         }
         
          setInputfields({...inputFields,[e.target.name]:e.target.value});
        }
      }
      console.log("d")
      console.log(inputFields)
    } 
    function showclientfield(){
      setDisplayclient(true)
    }
    function showsubclientfield(){
      setDisplaysubclient(true)
    }
    const [ clientlist, setclientlist ] = useState([])
    const [ subclientlist, setsubclientlist ] = useState([])
    function clientList(){
      manageService.get_clientusers().then((response)=>{
            if(response.status === 200){
              setclientlist(response.data)
            }else {
                toast.error('Something went wrong!');
            }
          });
    }
    function subclientList(clientname=''){
      clientmanageService.getsubclientlist(clientname).then((response) => {
          if(response.status === 200){
            setsubclientlist(response.data)
          }else {
            toast.error('Something went wrong!');
          }
      });
    }
    function setclient(e){
      setselectedclient(e.target.value)
      subclientList(e.target.value)
    }
  
    function addclient(e){
      e.preventDefault();
      // console.log(inputFields);
      // return;
      clientmanageService.addsubclient(inputFields).then((response)=>{
        // console.log(response)
        if(response.status == 200){
          //  setsuccessMsg(response.data.message);
           document.getElementById('addclient-form').reset();
        //    setError(null);
            setInputfields(initialStateData);
            toast.success(response.data.message);  
        //    history.goBack(); 
        }else if(response.status == 201){
          //setError('Enter All required field');
          setsuccessMsg(null);
          toast.error(response.data.message); 
        }
      });
    }
  
    function cancel_form(){
         document.getElementById('addclient-form').reset();
         setInputfields(initialStateData);
         history.goBack(); 
    }
  
    const user_info = JSON.parse(localStorage.getItem("user-info"));
    useEffect(() => { 
      clientList()
      if(user_info.role_id == 13){
        subclientList(user_info.name);
        setselectedclient(user_info.name)
        setInputfields({...inputFields,['name']:user_info.name});
      }
      }, [] ) 
      const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate() + 1).padStart(2, "0") - 1;
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };
    function getclientdetails(e){
      var clientname = '';
      if(user_info.role_id == 13){
        clientname = user_info.name;
      }else{
        clientname = selectedclient;
      }
      clientmanageService.getclientdetails(clientname,e.target.value).then((response) => {
        if(response.status === 200){
          if(response.data){
          console.log(response.data[0])
          setInputfields(response.data[0])
          }
        }else {
          toast.error('Something went wrong!');
        }
    });
    }
  
    return (
        <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
            <Row className="justify-content-md-center">
                <Col lg="12" md="12">
                    <Card className="shadow">
                        <CardHeader className="bg-transparent">
                            <h3 className="mb-0">Add Client</h3>
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-10">
                        {errorDiv}
                {successDiv}
                        <Form role="form" id="addclient-form" onSubmit={addclient}>
                            <FormGroup>
                             <Row>
                             {user_info.role_id != 13 ? 
                              <>
                                <div className="col-xl-2 col-md-12 col-xs-12">
                                    <h3>Client</h3> 
                                </div>
                                <div className="col-xl-6 col-md-12 col-xs-12" style={{ display: displayclient == false ? "flex" : "none" }}>
                                <select  class="form-control" name="name" onChange={inputhandler} required={(displayclient == false) ? true : false } >
                                <option value=""> Select Client</option>
                                {clientlist.map(res => (
                                    <option
                                      key={res.name}
                                      value={res.name}
                                    >
                                      {res.name}
                                    </option>
                                  ))}
                                </select>
                                </div>
                                </>
                                : null }
                                {/* <div  style={{ display: displayclient == false ? "flex" : "none" }} className="col-xl-4 col-md-12 col-xs-12">
                                  <p> OR &nbsp;<b onClick={showclientfield}> Add New Client</b></p>
                                  </div>
                                <div style={{ display: displayclient == true ? "flex" : "none" }} className="col-xl-10 col-md-12 col-xs-12">
                                   <InputGroup className="input-group-alternative mb-3">
                                      <Input 
                                      placeholder="Client Name" 
                                      type="text" 
                                      name="name" 
                                      value={inputFields.name != undefined ? inputFields.name : ''}
                                      onChange={inputhandler}
                                      required={(displayclient == true) ? true : false }
                                      />
                                  </InputGroup>      
                                </div> */}
                             </Row>   
                            </FormGroup>
                            <FormGroup>
                              <Row>
                                <div className="col-xl-2 col-md-12 col-xs-12">
                                    <h3>Sub Client</h3> 
                                </div>
                                
                               
                                <div   className="col-xl-6 col-md-12 col-xs-12">
                                   <InputGroup className="input-group-alternative mb-3">
                                      <Input 
                                      placeholder="Sub Client Name" 
                                      type="text" 
                                      name="sub_client" 
                                      value={inputFields.sub_client != undefined ? inputFields.sub_client : ''}
                                      onChange={inputhandler}
                                      required={(displaysubclient == true) ? true : false }
                                      />
                                  </InputGroup>    
                                </div>
                             </Row>  
                            </FormGroup>
                            {user_info.role_id != 13 ? 
                            <>
                             
                            
                            <FormGroup >
                            <Row>
                            <Col md="6">
                             <label>Decision-Making Matrix</label> 
                                  <select class="form-control" name="decision_making"  onChange={inputhandler} >
                                    <option value=""> Select </option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                  </select>
                             </Col>
                             <Col md="6">
                               <label>VAT Policy</label> 
                                  <select class="form-control" name="vat_policy"  onChange={inputhandler} >
                                    <option value=""> Select </option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                  </select>
                                  </Col>
                             </Row>
                            </FormGroup>
                            
                            <FormGroup >
                            <Row>
                              <Col>
                             <label>Remarks For above questions</label>
                                  <textarea name="remarks" class="form-control" onChange={inputhandler} ></textarea>
                                  </Col>
                              </Row>
                              <br></br>
                            </FormGroup>
                            </>
                            : null }
                            <FormGroup>
                              <Row>
                                <Col>
                                <label>Year-End date</label>
                                     <InputGroup className="input-group-alternative mb-3">
                                          <Input
                                          placeholder="Year - End Date"
                                          type="date"
                                          name="year_end_date"
                                          value={inputFields.year_end_date != undefined ? inputFields.year_end_date : ''}
                                          onChange={inputhandler}
                                          required
                                          />
                                      </InputGroup>  
                                  </Col>
                                  <Col>
                                  <label>Bookkeeping Software</label>
                                      <InputGroup className="input-group-alternative">
                                          <Input
                                          placeholder="Bookkeeping Software"
                                          type="text"
                                          name="book_keeping_software"
                                          value={inputFields.book_keeping_software != undefined ? inputFields.book_keeping_software : ''}
                                          onChange={inputhandler}
                                          required
                                          />
                                      </InputGroup>
                                  </Col>
                              
                                  <Col>
                                    <label>Invoice Processing Software</label> 
                                      <InputGroup className="input-group-alternative">
                                          <Input
                                          placeholder="Invoice processing Software"
                                          type="text"
                                          name="account_prod_software"
                                          value={inputFields.account_prod_software != undefined ? inputFields.account_prod_software : ''}
                                          onChange={inputhandler}
                                          required
                                          />
                                      </InputGroup>
                                    </Col>
                                    </Row> 
                            </FormGroup>
 
                            <div className="col-12">
                               <Button className="mt-2" color="primary" type="submit">
                                Save
                               </Button>
                               <Button className="mt-2 ml-2" color="secondary " type="button" onClick={cancel_form}>
                                 Cancel
                               </Button>
                            </div>
                        </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        </div>
        </>
    );
  };
  
  export default AddSubClient;
  