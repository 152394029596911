import { useState,useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect, useHistory,Link,useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  Input
} from "reactstrap";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import {reportmanageService} from '../../services/reportmanageService';
import { taskmanageService } from "../../services/taskmanageService";
import '../../assets/css/custom.css';
import Header from "components/Headers/Header.js";
import { ToastContainer, toast } from "react-toastify";
import { manageService } from "../../services/manageaccountservices";
import { clientmanageService } from "../../services/clientmanageService.js";

 const ClientTransition = (props) => {
 const params  = useParams();
 const history = useHistory();
 const [clienttransdata, setClientTransdata] = useState([]);
 const [clienttranssummary, setClientTransSummary] = useState([]);
 const [currentPage, setCurrentPage] = useState(null);
 const [last_page, setLastPageData] = useState(0);
 const [error, setError] = useState(null)
 const [statuslist, setStatusdata] = useState([]);
  const [successMsg, setsuccessMsg] = useState(null);
  const [clientsearch, setclientsearch] = useState('');
  const [filterFields,setfilterFields] = useState([]);
  const [filtertype,setfiltertype] = useState('all');
  const [allclient, setAllclient] = useState(true);
  const errorDiv = error 
        ? <UncontrolledAlert className="alert-danger" fade={false}>
        {error}
        </UncontrolledAlert> 
        : '';
  const successDiv = successMsg 
        ? <UncontrolledAlert className="alert-success" fade={false}>
        {successMsg}
        </UncontrolledAlert> 
        : '';
    const [sortfield,setsortfield] = useState('');
  const [sortorder,setsortorder] = useState('asc');
  function get_status_list() {
    taskmanageService.getgovernancestatus().then((response) => {
      setStatusdata(response.data.status);
    });
  }
  function sortTable(field){
    console.log(field)
    setsortfield(field);
    if(sortorder=='asc'){
      setsortorder('desc');
    }else{
      setsortorder('asc');
    }
  }
  useEffect(()=>{
    get_status_list();
    getdata_list();
  },[sortorder]);

  
  const handlePageClick = (e, index) => {
    e.preventDefault();
    getdata_list(index,null);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    getdata_list(prev,null);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    getdata_list(next,null);
  };
  const getdata_list = async (pageNumber = 1,e,filterFields,filtertype='') => { 
    let  textbox = '';

    if(e != null){
       textbox = e.target.value;
    }else{
      textbox = clientsearch;
    }
   
    // const cloneValues = {...filterFields};
    clientmanageService.getClientTransition(pageNumber,textbox,filterFields,filtertype,sortfield,sortorder).then((response) => {
      
      setClientTransdata(response.data.client_transition_summary); 
        //  setCurrentPage(response.data.client_transition_summary.current_page);
        //  setLastPageData(response.data.client_transition_summary.last_page);
         setClientTransSummary(response.data.summary_report)
    });
  };
  
 
const formatDate = (dateStr) => {
  if(dateStr!='' && dateStr!= null){
  const [year, month, day] = dateStr.split('-');
  let newDate = `${day}-${month}-${year}`;
  return newDate;
  }else{
    return null;
  }
};
 
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  
  const add_client_transition = (client_id ='') => {
    history.push("/admin/addclienttransition/"+client_id);
 }

 const view_client_transitionform = (client_id,trans_id,client_trans_id) => {
  history.push("/admin/viewclienttransitionform/"+client_id+"/"+trans_id+"/"+client_trans_id);
}

const show_sub_client_sop = () => {
  // history.push("/admin/subclientsop/");
    history.push("/admin/clienttransitiondash/");
}
 
  function filterallcom({type}){
    setfiltertype(type)
    // setmainfilter(type);
    console.log('fff',filterFields)
    getdata_list(1,null,filterFields,type);
  }
  function client_search(e){
    setclientsearch(e.target.value);
    getdata_list(1,e)

  }
  
  function downloadReport(){
    
    reportmanageService.download_clienttransition_data(filterFields,filtertype).then((response) => {
    
    });
  }
  function questionnairehandleclick(){

    history.push({
      pathname: "questionnaire-dash",
    });
  }
  
return (
    <>
      <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
        <div className="row">
            <div className="col-xl-12">
            <div className="col-md-12 col-12">
            </div>
            <Row className="align-items-center row">
            <Col xl="10">
            
              <Button
                className="dash-button "
                color="primary"
                type="button"
                onClick={() => add_client_transition()}
              >
                {" "}
              + Add Client Transition{" "}
              </Button>{" "}
               <Button
                className="dash-button "
                color="primary"
                type="button"
                onClick={() => show_sub_client_sop()}
              >
                {" "}
               Sub Client SOP's Dashboard{" "}
              </Button>
              <Button
                className="dash-button "
                color="primary"
                type="button"
                onClick={() => questionnairehandleclick()}
              >
                {" "}
                Client Questionnaire Dashboard{" "}
              </Button>
              {/* <Button
                className="dash-button "
                color="primary"
                type="button"
                onClick={() => add_client_transition_fields()}
              >
                {" "}
              + Add Client Transition Fields{" "}
              </Button> */}
             
            </Col>
           
            {/* <Col xl="2">
                    <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={showsummary}
                      style={{textAlign:'center'}}
                      title="Summary Report"
                    >
                    <i className="fas fa-eye" style={{fontSize:'14px'}}></i> Summary 
                    </Button>
                    </Col>
                     */}
          </Row><br></br>
            <Row>
            
              <Col xl="12">
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                  
                
                    <Row className="align-items-center">
                  
                      <div className="col">
                        <h4 className="text-uppercase text-muted ls-1 mb-1">
                          Client Transition Summary
                        </h4>
                      </div>
                   
                      
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                
                    <th scope="col" className="whitespace" >Transition  </th>
                    <th scope="col" className="whitespace" >Total Clients</th>
                    <th scope="col" className="whitespace" >Complete</th>
                    <th scope="col" className="whitespace" >Not Complete</th>
                    <th scope="col" className="whitespace">NA</th>
                    
                </thead>
                <tbody>
                  {clienttranssummary &&
                    clienttranssummary.length &&
                    clienttranssummary != null ? (
                      clienttranssummary.map((item, i) => (
                      <>
                      <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') } edit-type={item.edittype} edit-id={item.id}>
                          <td className="whitespace">{item.name}  </td>
                          <td className="whitespace">{item.total_clients}  </td>
                          <td className="whitespace">{item.total_complete}</td>
                          <td className="whitespace">{item.total_not_complete}</td>
                          <td className="whitespace">{item.total_applicability_na}</td>
   
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
                    </Table>
                    <br></br>
                    <CardHeader className="bg-transparent">
                  
                
                    <Row className="align-items-center">
                  
                      <div className="col-6">
                        <h4 className="text-uppercase text-muted ls-1 mb-1">
                          Client Transition Data
                        </h4>
                        

                      </div>
                      <div className="col-4"><Input placeholder="Search client..." id="companydata" name="compnayname" className="search-box" type="text" style={{fontWeight:"600",color:"black"}}
                onKeyUp={(e) =>client_search(e)}  
                /></div>
                   
                      <Col xl="2">
                  <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                  </Button>
            </Col>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                
                    <th scope="col" className="whitespace"  onClick={(e) => sortTable('name')} >Client Name</th>
                    <th scope="col" className="whitespace"  >Onboarding Checklist</th>
                    <th scope="col" className="whitespace"  >Decision making matrix - BK</th>
                    <th scope="col" className="whitespace" >VAT Policy</th>
                    {/* <th scope="col" className="whitespace" >Standard Operating procedure</th> */}
                    
                </thead>
                <tbody>
                  {clienttransdata &&
                    clienttransdata.length &&
                    clienttransdata != null ? (
                      clienttransdata.map((item, i) => (
                      <>
                      <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') } edit-type={item.edittype} edit-id={item.id}>
                          <td className="whitespace" onClick={() => add_client_transition(item.client_id )}>{item.name}  </td>
                          {(item[1]['status'] != 'NA') ? 
                            <td className="whitespace" style={{color: (item[1]['status']!='Complete')?'#fb9540':'green'}} onClick={() => view_client_transitionform(item.client_id,1,item[1]['client_trans_id'])}>{item[1]['status']}  </td>
                            :
                            <td className="whitespace">{item[1]['status']}  </td>
                          }
                          {(item[2]['status'] != 'NA') ? 
                            <td className="whitespace" style={{color: (item[2]['status']!='Complete')?'#fb9540':'green'}} onClick={() => view_client_transitionform(item.client_id,2,item[2]['client_trans_id'])}>{item[2]['status']}  </td>
                            :
                            <td className="whitespace">{item[2]['status']}  </td>
                          }
                          {/* {(item[3]['status'] != 'NA') ? 
                            <td className="whitespace" style={{color: (item[3]!='Complete')?'#fb9540':'green'}} onClick={() => view_client_transitionform(item.client_id,3,item[1]['client_trans_id'])}>{item[3]}  </td>
                            :
                            <td className="whitespace">{item[3]}  </td>
                          } */}
                          {(item[4]['status'] != 'NA') ? 
                            <td className="whitespace" style={{color: (item[4]['status']!='Complete')?'#fb9540':'green'}} onClick={() => view_client_transitionform(item.client_id,4,item[4]['client_trans_id'])}>{item[4]['status']}  </td>
                            :
                            <td className="whitespace">{item[4]['status']}  </td>
                          }
                          {/* {(item[5] != 'NA') ? 
                            <td className="whitespace" style={{color: (item[5]!='Complete')?'#fb9540':'green'}} onClick={() => view_client_transitionform(item.client_id,5)}>{item[5]}  </td>
                            :
                            <td className="whitespace">{item[5]}  </td>
                          }    */}
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
                    </Table>
                    {/* <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter> */}
                </Card>
              </Col>
             
            </Row>
             
            </div>
        </div>

       
      </Container>
    </>
  );
}; 
export default ClientTransition;
