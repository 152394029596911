import { useState,useEffect } from "react";
import React from 'react';
import ReactDatetime from "react-datetime";
import { BrowserRouter, Route, Switch, Redirect, useHistory,Link,useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  PopoverBody,
  UncontrolledPopover
} from "reactstrap";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import {reportmanageService} from '../../services/reportmanageService';
import { taskmanageService } from "../../services/taskmanageService";
import '../../assets/css/custom.css';
import Header from "components/Headers/Header.js";
import { ToastContainer, toast } from "react-toastify";
import { manageService } from "../../services/manageaccountservices";
import { clientmanageService } from "../../services/clientmanageService.js";

 const ExternalRejection = (props) => {
 const params  = useParams();
 const history = useHistory();
 const [reportRejectiondata, setReportRejectionData] = useState([]);
 const [currentPage, setCurrentPage] = useState(null);
 const [last_page, setLastPageData] = useState(0);
 const [error, setError] = useState(null)
 const [statuslist, setStatusdata] = useState([]);
  const [successMsg, setsuccessMsg] = useState(null);
  const [clientsearch, setclientsearch] = useState('');
  const [filterFields,setfilterFields] = useState([]);
  const [filtertype,setfiltertype] = useState('open');
  const [allclient, setAllclient] = useState(true);
  const [filterlist, setfilterlist] = useState([]);
  const [showfilterbox,setshowfilterbox] = useState(false);
  const [filterApplyFields,setfilterApplyFields] = useState([]);

  const [errorcategory,seterrorcategory] = useState([]);
  const errorDiv = error 
        ? <UncontrolledAlert className="alert-danger" fade={false}>
        {error}
        </UncontrolledAlert> 
        : '';
  const successDiv = successMsg 
        ? <UncontrolledAlert className="alert-success" fade={false}>
        {successMsg}
        </UncontrolledAlert> 
        : '';
  useEffect(()=>{
    get_status_list();
    getrejection_list();
    get_unique_companieslist();
    get_error_category();
  },[]);
  const [sortfield,setsortfield] = useState('');
  const [sortorder,setsortorder] = useState('asc');
  function get_status_list() {
    taskmanageService.getrejectionstatus().then((response) => {
      setStatusdata(response.data.status);
    });
  }

  function get_error_category(){
    taskmanageService.geterrorcategories().then((response) => {
      seterrorcategory(response.data)
    })
  }
  function sortTable(field){
    console.log(field)
    setsortfield(field);
    if(sortorder=='asc'){
      setsortorder('desc');
    }else{
      setsortorder('asc');
    }
  }
  const handlePageClick = (e, index) => {
    e.preventDefault();
    getrejection_list(index,null);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    getrejection_list(prev,null);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    getrejection_list(next,null);
  };
  const getrejection_list = async (pageNumber = 1,e,filterFields,filtertype='') => { 
    let  textbox = '';
    if(e != null){
       textbox = e.target.value;
    }
    const cloneValues = {...filterFields};
    let status ='';
    if(params.status!='undefined' && params.status!=undefined){
      status = params.status;
      filtertype = status;
    }
    let user_id ='';
    if(params.user_id!='undefined' && params.user_id!=undefined){
      user_id = params.user_id;
      cloneValues[9] = {
        ...cloneValues[9],
        ['field']: 'user_id',
        ['value']: user_id,
        ['operator']: '='
      };
    }
   
    setfilterFields(cloneValues);
    reportmanageService.getReportRejectionsExternal(pageNumber,textbox,filterFields,filtertype).then((response) => {
      //console.log(response.data.data); 
         setReportRejectionData(response.data.data.data); 
         setCurrentPage(response.data.data.current_page);
         setLastPageData(response.data.data.last_page);
    });
  };
  const handlerejeditRow = (index) => {
    // if(phaseedit == true || taskedit == true || analystedit == true){
      let temp_state = [...reportRejectiondata];
      let temp_element = { ...temp_state[index] };
      temp_element.editing = true;
      temp_state[index] = temp_element;
      setReportRejectionData(temp_state);
      // }

  };
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
};

const formatDate = (dateStr) => {
  if(dateStr!='' && dateStr!= null){
  const [year, month, day] = dateStr.split('-');
  let newDate = `${day}-${month}-${year}`;
  return newDate;
  }else{
    return null;
  }
};
  function downloadReport(){
    const cloneValues = {};
  
        if(statesend.startDate !=null && statesend.endDate !=null){
        cloneValues[0] = {
          ...cloneValues[0],
          ['op_name']: 'between',
          ['operator']: 'between',
          ['field'] : 'date',
          ['value'] : statesend.startDate + ' to ' + statesend.endDate
        };
      }
    reportmanageService.download_external_rejection_report(cloneValues,filtertype).then((response) => {
    
    });
  }
  const editRejField = (value, field, id,index) => {
    taskmanageService.editexternalRejectionRow(value, field, id).then((response) => {
      let temp_st = [...reportRejectiondata];
      let temp_ele = { ...temp_st[index] };
      if(response.status == 200){
        toast.success(response.data.message);
      }else{
          toast.error(response.data.message);
      }
      temp_ele.editing = false;
      // temp_ele[field] = value;
      if (field == "created_at") {
        temp_ele.date = value;
      }
      if (field == "approval") {
        if (value == 1) {
          temp_ele.approval = "Approved";
          temp_ele['flag'] = 0;
        } else if (value == 2) {
          temp_ele.approval = "Denied";
          temp_ele['flag'] = 2;
        }else{
          temp_ele.approval = "";
        }
      }
      if (field == "status" || field == "final_status") {
        Object.entries(statuslist).filter(([k, v]) => v.id == value).reduce((acc, [k, v]) => {
          if(field == 'status'){
            temp_ele['status_name'] = v.name;
            temp_ele['status_color_code'] = v.color_code;
            temp_ele['status_id'] = value;
          }else{
            temp_ele['final_status_name'] = v.name;
            temp_ele['fstatus_color_code'] = v.color_code;
            temp_ele['fstatus_id'] = value;
          }
          temp_ele['approval'] = 0;
            if(value==11){
              temp_ele['flag'] = 0;
            }
          
       }, {});
       }
       if (field == "category_id") {
        Object.entries(errorcategory).filter(([k, v]) => v.id == value).reduce((acc, [k, v]) => {
            temp_ele['category_name'] = v.name;
      }, {});
       }
       if (field != "status" && field != "approval") {
        temp_ele[field] = value;
      }
      temp_st[index] = temp_ele;
      setReportRejectionData(temp_st);
    })
  }

  const user_info = JSON.parse(localStorage.getItem("user-info"));
  
  const [taskdetails,settaskdetails] = useState({});
  const [taskdetailPopup,settaskdetailPopup] = useState(false);
  
  const taskDetail = (task_id,index) => {
    taskmanageService.gettaskdetail(task_id,'','').then((response) => {
      settaskdetails(response.data.data);
      settaskdetailPopup(!taskdetailPopup);
    })
  }
  const withouterrorreport = () =>{
    history.push({
      pathname: 'reportwithouterror',
    });
  }
  const rejectionsummary = () =>{
    history.push({
      pathname: 'rejectionsummary',
    });
  }
  const upload_doc_popup = () => {
    history.push("/admin/addextrejection");
 }
 const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
 function get_unique_companieslist(){
  clientmanageService.getclientlist().then((response)=>{
     setUniqueCompaniesData(response.data);
   });
 }
 const [ subclientlist, setsubclientlist ] = useState([])
 
   function subclientList(clientname=''){
     clientmanageService.getsubclientlist(clientname).then((response) => {
         if(response.status === 200){
           setsubclientlist(response.data)
         }else {
           toast.error('Something went wrong!');
         }
     });
   }
   function setclient(e){
     subclientList(e.target.value)
     // var index = e.target.selectedIndex;
     // setclientsearch(e.target[index].text)
     setclientsearch(e.target.value);
     const cloneValues = {...filterFields};
     delete cloneValues[2];
    setfilterFields(cloneValues);
   }
   const companyfilter = (e,index) => {
    if(e.target.value == ''){
      setAllclient(true);
    }else{
      setAllclient(false);
    }
    const cloneValues = {...filterFields};
    cloneValues[index] = {
      ...cloneValues[index],
      ['field']: e.target.name,
      ['value']: e.target.value,
      ['operator']: '='
    };
    setfilterFields(cloneValues);
    getrejection_list(1,null,cloneValues,filtertype);
  }
  function filterallcom({type}){
    setfiltertype(type)
    // setmainfilter(type);
    getrejection_list(1,null,filterFields,type);
  }
  function archieveRows(id){
    reportmanageService.archiveExternalccrp(id).then((response) => {
      if(response.status == 200){
        const newList = reportRejectiondata.filter((item) => item.id !== e.id);
        setReportRejectionData(newList);
       toast.success(response.data.message);
        
       
      }else{
        toast.error(response.data.message);
      }
    });
  }

const getFilterList = () => {
  taskmanageService.getinternalccrpfilterlist(1).then((response) => {
    setfilterlist(response.data.filter);
    setshowfilterbox(!showfilterbox);
  })
}
const applyFilter = (e='') => {
  getrejection_list(1,null,filterFields,filtertype);
  if(e==''){
    setfilterApplyFields(filterFields);
    document.getElementById('tooltipfilter').click();
  }else{
    setfilterApplyFields(filterFields);
  }
}
const removefromFilter = (index) => {
  let state = {...filterFields};
  console.log(filterFields)
  Object.entries(state) // converts each entry to [key, value]
    .filter(([k, v]) => v.field == index) // define the criteria to include/exclude items
    .reduce((acc, [k, v]) => {
       delete state[k];
       setfilterFields(state);
       setfilterApplyFields(state);
    }, {});
    applyFilter(1);
    //document.getElementById('tooltipfilter').click();
}


function filterInputHandler(e,index,op_name='') {

  if(e.target.id=='client'){
    var id = e.nativeEvent.target.selectedIndex;
    subclientList(e.nativeEvent.target[id].text)
  }
const cloneValues = {...filterFields};
  if(op_name!=''){
    cloneValues[index] = {
      ...cloneValues[index],
      ['op_name']: op_name,
    };
  }
  var index1 = e.target.selectedIndex;
  if(index1){
    cloneValues[index] = {
      ...cloneValues[index],
      ['val_name']: e.target[index1].text,
    };
  }
  cloneValues[index] = {
    ...cloneValues[index],
    [e.target.name]: e.target.value,
  };

  console.log('filter data');
  console.log(cloneValues);
  setfilterFields(cloneValues);

}
function filterClickHandler(name,value,index) {
  const cloneValues = {...filterFields};
  cloneValues[index] = {
    ...cloneValues[index],
    [name]: value,
  };
  setfilterFields(cloneValues);
  // console.log(filterFields)
}
function setEndDateRange(e,index,field){
  var month = e._d.getMonth() + 1;
  var date = e._d.getDate();
  var year = e._d.getFullYear();
  var dd = date + '-' + month + '-' + year;
  setStatesend({ ...statesend,endDate: dd });
  setState({ ...state,endDate: e });

  const cloneValues = {...filterFields};
   
      cloneValues[index] = {
        ...cloneValues[index],
        ['op_name']: 'between',
        ['field'] : field,
        ['value'] : statesend.startDate + ' to ' + dd
      };
  
  
    console.log('filter data');
    console.log(cloneValues);
    setfilterFields(cloneValues);
}
const [state, setState] = useState({startDate:null,endDate:null});
const [statesend, setStatesend] = useState({startDate:null,endDate:null});
function setStartDateRange(e,index){
  var month = e._d.getMonth() + 1;
  var date = e._d.getDate();
  var year = e._d.getFullYear();
  var dd = date + '-' + month + '-' + year;
  setStatesend({ ...statesend, startDate: dd })
  setState({ ...state, startDate: e })
}
const [datestate, setcustomdateState] = useState({});
function setEndDateRangeCustom(e,index,field){
  var month = e._d.getMonth() + 1;
  var date = e._d.getDate();
  var year = e._d.getFullYear();
  var dd = date + '-' + month + '-' + year;
  setStatesend({ ...statesend,endDate: dd });
  setState({ ...state,endDate: e });

  const cloneValues = {};
      cloneValues[index] = {
        ...cloneValues[index],
        ['op_name']: 'between',
        ['operator']: 'between',
        ['field'] : field,
        ['value'] : statesend.startDate + ' to ' + dd
      };
  
  
    setcustomdateState(cloneValues);
    getrejection_list(1, null, cloneValues);
}

function client_search(e,index){
  setclientsearch(e.target.value);
  const cloneValues = {...filterFields};
    cloneValues[index] = {
      ...cloneValues[index],
      ['field']: e.target.name,
      ['value']: e.target.value,
      ['operator']: '='
    };
    setfilterFields(cloneValues);
    getrejection_list(1,null,cloneValues,filtertype);

}
return (
    <>
      <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
        <div className="row">
            <div className="col-xl-12">
            <div className="col-md-12 col-12">
            </div>
            <Row className="align-items-center row">
            <Col xl="10">
              <Button
                className="dash-button "
                color="primary"
                type="button"
                onClick={() => upload_doc_popup()}
              >
                {" "}
              + Add CCRP{" "}
              </Button>{" "}
            </Col>
            <Col xl="2">
                    <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                    </Button>
                    </Col>
                   
          </Row><br></br>
            <Row>
            
              <Col xl="12">
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                  
                  <Row className="mt-12">
                  <div className="col-xl-3 col-md-3 col-xs-6">
          <button type="button" style={filtertype == 'open' ?
          { backgroundColor:'#0A5AC2',color:'#fff',fontFamily:'inherit',margin: '7px 0px'} : {fontFamily:'inherit',margin: '7px 0px'}} onClick={() => filterallcom({type:'open'})} className=" btn btn-secondary text-center">
            Open
          </button>
        
          <button type="button" style={filtertype == 'close' ?
          { backgroundColor:'#0A5AC2',color:'#fff',fontFamily:'inherit',margin: '7px 0px'} : {fontFamily:'inherit',margin: '7px 0px'}} onClick={() => filterallcom({type:'close'})} className=" btn btn-secondary text-center">
            Close
           
          </button>
          <button type="button" style={filtertype == 'all' ?
          { backgroundColor:'#0A5AC2',color:'#fff',fontFamily:'inherit',margin: '7px 0px'} : {fontFamily:'inherit',margin: '7px 0px'}} onClick={() => filterallcom({type:'all'})} className=" btn btn-secondary text-center">
            All
           
          </button>
          </div>
                
                  <div className="col-xl-3 col-md-3 col-xs-12">
                  <FormGroup style={{'margin-top':'10px'}}>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Start date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setStartDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup >
                  {/* <h5 className="clientformtag"> Client</h5> */}
                        {/* {(uniquecompanieslist.length > 0 && (user_info.role_id != 13))?
                              <select className="form-control" name="company" onChange={(e) => { companyfilter(e,1),setclient(e);}}   >
                                  <option value="" key="al_key_1">All Clients</option>
                                  {(uniquecompanieslist).map((valuel,k) =>
                                      <option key={k} value={valuel.name}>
                                        {valuel.name}
                                      </option>
                                  )}
                              </select>
                            : null } */}
                        </div>
                        <div className="col-xl-3 col-md-3 col-xs-12">
                        
                        <FormGroup style={{'margin-top':'10px'}}>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Start date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setEndDateRangeCustom(e,0,'date')}
                          />
                        </InputGroup>
                      </FormGroup>
                        </div>
                        <div className="col-xl-2 col-md-3 col-xs-12">
                        <FormGroup style={{'margin-top':'10px'}}>
                        <Input placeholder="Search client..." id="companydata" name="company" className="" type="text" style={{fontWeight:"600",color:"black",width:'200px'}}
                onKeyUp={(e) =>client_search(e,2)}  
                /></FormGroup>
                          </div>
                        {/* <div className="col-xl-2 col-md-3 col-xs-12">
                          
                        <h5 className="clientformtag">Sub Client</h5>
                            <select  class="form-control" name="sub_client"  onChange={(e) => companyfilter(e,2)}>
                              <option value=""> Select Sub Client</option>
                              {subclientlist.map(res => (
                                  <option
                                    key={res.sub_client}
                                    value={res.sub_client}
                                  >
                                    {res.sub_client}
                                  </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-xl-2 col-md-3 col-xs-12">
                        <h5 className="clientformtag">CCRP Meeting Date</h5>
                        <input type="date"
                                      className="form-control"
                                      name="ccrp_meeting_date"
                                      onChange={(e) =>
                                          companyfilter(e,3)
                                        }
                                      />
                        </div> */}
                        <div className="col-xl-3" style={{'text-align':'right'}}>
                      
                      <button onClick={(e) => getFilterList()}  type="button" className="btn-icon-clipboard" data-clipboard-text="bold-down" title="Copy to clipboard" style={{padding: 10,width:'130px'}}>
                        <div>
                          <img alt="..." src={require("../../assets/img/brand/filter.png")} style={{height:15, width:15}}/>
                          <span style={{"fontSize":16,fontFamily:"inter"}}>Filters</span>&nbsp;
                          {(showfilterbox) ? (<i className="ni ni-bold-up"></i>) : 
                            (<i className="ni ni-bold-down"></i>)}
                        </div>
                      </button>
                    </div>
                   
                      
        </Row>
        {(showfilterbox== true)?
                <Row style={{paddingLeft:'1%',textAlign:'right'}}>
                  <Col xl="12">
                   {(filterApplyFields) ?
                    (Object.values(filterApplyFields)).map((t,k) => (
                        <button key={k} className="btn btn-icon btn-3 btn-primary filter-button" type="button" style={{color:'#3F3F3F'}}>
                          <span className="btn-inner--text"><b>{t.field} #</b> {t.op_name} {(t.val_name)?t.val_name:t.value}</span>
                          <span className="btn-inner--icon" onClick={() => removefromFilter(t.field)}><i className="ni ni-fat-remove"></i></span>
                        </button>
                      )):null} 
                    <button style={{color:'#3F3F3F'}} type="button" className="btn btn-icon btn-primary filter-button" data-placement="right" id="tooltipfilter" >
                        +
                    </button>
                    {(filterlist.length > 0) ?
                    (<UncontrolledPopover placement="right" target="tooltipfilter">
                      <PopoverBody>
                      {filterlist.map((filter, i) => (
                      <React.Fragment key={i}>
                      <p key="para" data-placement="right" id={`tooltipraised-${i}`} style={{cursor:"pointer"}} name="field" value={filter.field} onClick={(e) => {filterClickHandler('field',filter.field,i),setfiltertype('')}}>{filter.field}</p>
                      {/* {(filter.values.length > 0)? */}
                      <UncontrolledPopover key={i} placement="right"  target={`tooltipraised-${i}`}>
                      <PopoverBody>
                         <Form>
                         {(filter.operators).map((operator,j) => (
                         <div key={j} className="custom-control custom-radio mb-3" style={{paddingLeft:'1rem'}}>
                          <input id="customRadio5"  value={operator.symbol} name="operator" type="radio" onChange={(e) => filterInputHandler(e,i,operator.value)} />
                          {/* <label className="custom-control-label" htmlFor="customRadio5"> */}
                           &nbsp;&nbsp; {operator.value}
                          {/* </label> */}
                        </div>
                         )) }
                          <div id="textboxes" >
                        {(filter.values.length > 0 || filter.field=='subclient')?
                          (filter.field=='subclient') ? 
                          <select className="form-control" name='value' onChange={(e) => filterInputHandler(e,i)}>
                              <option value="" key="al_key_1">Select subclient</option>
                              {subclientlist.map(res => (
                                  <option
                                    key={res.sub_client}
                                    value={res.sub_client}
                                  >
                                    {res.sub_client}
                                  </option>
                                ))}

                          </select> :
                          <select className="form-control" id={filter.field} name='value' onChange={(e) => filterInputHandler(e,i)}>
                              <option value="" key="al_key_1">Select values</option>
                              {(filter.values).map((valuel,k) =>
                                  <option key={k} value={valuel.id}>
                                    {valuel.name}
                                  </option>
                              )}
                          </select>
                        : (filter.type == 'daterange') ?  
                        <>
                        <Col xs={12}>
                        <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Start date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setStartDateRange(e,i)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "End date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setEndDateRange(e,i,filter.field)}
                          />
                        </InputGroup>
                      </FormGroup>
                      </Col>
                      </>
                        :
                        <input className="form-control" type={filter.type} name="value" onChange={(e) => filterInputHandler(e,i)} />
                        }
                        <br></br>
                        <button type="button" className="col-8 dash-button btn btn-primary" onClick={(e) => applyFilter()} > Apply </button>
                        {/* <button type="button" className="col-6 phase-button  btn btn-light">Cancel</button> */}
                         </div>
                         </Form>
                      </PopoverBody>
                    </UncontrolledPopover>
                       {/* :null }  */}
                     </React.Fragment>
                      ))}
                        </PopoverBody>
                    </UncontrolledPopover>):null }   
                  
                  </Col>  
                </Row> : null }

                    <Row className="align-items-center">
                  
                      <div className="col">
                        <h4 className="text-uppercase text-muted ls-1 mb-1">
                          Rejection Report
                        </h4>
                      </div>
                   
                      
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush table-resp" >
                  <thead className="thead-light thead-fixed">
                
                    <th scope="col" className="whitespace" >Client Name</th>
                    <th scope="col" className="whitespace">Sub Client</th>
                    <th scope="col" className="whitespace">Type</th>
                    <th scope="col" className="whitespace">Source</th>
                    <th scope="col" className="whitespace">Category</th>
                    <th scope="col" className="whitespace">Error count</th>
                    <th className="whitespace">Feedback</th>
                    
                    <th scope="col">Date</th>
                    <th scope="col" className="whitespace">QA Lead</th>
                    <th className="whitespace" scope="col">QA TM</th>
                    {/* <th className="whitespace" scope="col">Manager</th>
                    <th className="whitespace" scope="col">Error Raised by</th> */}
                    <th className="whitespace">Root Cause</th>
                    <th className="whitespace">Action Items</th>
                    <th scope="col" className="whitespace">QA Status</th>
                    <th scope="col" className="whitespace">Manager Status</th>
                    <th scope="col" className="whitespace">CCRP Meeting date</th>
                    <th>Action</th>
                    
                </thead>
                <tbody>
                  {reportRejectiondata &&
                    reportRejectiondata.length &&
                    reportRejectiondata != null ? (
                      reportRejectiondata.map((item, i) => (
                      <>
                      <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') } edit-type={item.edittype} edit-id={item.id}>
                          <th scope="row" onClick={() => taskDetail(item.task_id, i)}  className="whitespace">
                            <span className="mb-0 text-sm"  style={{cursor:'pointer'}}  >
                              {item.name} ({item.task_type}) {" "} &nbsp;&nbsp;
                            </span>
                          </th>
                          
                          <td className="whitespace">{item.sub_client}  </td>
                          <td className="whitespace" onClick={()=> handlerejeditRow(i)}>
                          {item.editing  ? (
                             <select
                               className="form-control"
                               onChange={(e) =>
                                editRejField(
                                   e.target.value,
                                   'type',
                                   item.id,
                                   i,e
                                 )
                               }
                               defaultValue={item.type}
                             >
                               <option value="Fetal">Fetal</option>
                                <option value="Non-Fetal">Non-Fetal</option>
                             </select>
                           ) : (item.type!=null)?item.type:''}
                         </td>
                          <td className="whitespace">{item.source}</td>
                          <td className="whitespace" onClick={()=> handlerejeditRow(i)}>
                          {item.editing  ? (
                             <select
                               className="form-control"
                               onChange={(e) =>
                                editRejField(
                                   e.target.value,
                                   'category_id',
                                   item.id,
                                   i,e
                                 )
                               }
                               defaultValue={item.cat_id}
                             >
                              {errorcategory.map((category) => {
                              return (
                                <option value={category.id}>
                                  {category.name}
                                </option>
                              );
                            })}
                             </select>
                           ) : (item.category_name!=null)?item.category_name:''}
                         </td>
                          <td className="whitespace"  onClick={()=> handlerejeditRow(i)} >
                          {item.editing  ? (
                          <input type="text"
                          defaultValue={item.no_error}
                          className="form-control"
                            onBlur={(e) =>
                              editRejField(
                                e.target.value,
                                "no_error",
                                item.id,
                                i
                              )
                            }
                          />
                        ) : (
                          <span>{item.no_error}</span>
                        )}
                      </td>
                      <td className="whitespace"  onClick={()=> handlerejeditRow(i)} >
                          {item.editing  ? (
                          <textarea type="text"
                          className="form-control"
                            onBlur={(e) =>
                              editRejField(
                                e.target.value,
                                "comment",
                                item.id,
                                i
                              )
                            }
                          >{item.comment}</textarea>
                        ) : (
                          <span>{item.comment}</span>
                        )}
                      </td>
                      <td  onClick={()=> handlerejeditRow(i)} >
                                    {item.editing ? (
                                      <input type="date"
                                      className="form-control"
                                        defaultValue={item.date}
                                      
                                        onBlur={(e) =>
                                          editRejField(
                                            e.target.value,
                                            "created_at",
                                            item.id,
                                            i
                                          )
                                        }
                                      />
                                    ) : (
                                      <span>{(item.date!='') ? formatDate(item.date) : ''}</span>
                                    )}
                                  </td>
                          {/* <td className="whitespace">{item.date}</td> */}
                          <td className="whitespace">{item.lead}</td>
                          <td className="whitespace">{item.member}</td>
                          {/* <td className="whitespace">{item.manager}</td> 
                          <td className="whitespace">{item.raised_error_by}</td>  */}
                          <td className="whitespace"  onClick={()=> handlerejeditRow(i)} >
                          {item.editing  ? (
                          <textarea type="text"
                          className="form-control"
                            onBlur={(e) =>
                              editRejField(
                                e.target.value,
                                "root_cause",
                                item.id,
                                i
                              )
                            }
                          >{item.root_cause}</textarea>
                        ) : (
                          <span>{item.root_cause}</span>
                        )}
                      </td>
                          <td className="whitespace" onClick={()=> handlerejeditRow(i)} >
                          {item.editing  ? (
                          <textarea type="text"
                          className="form-control"
                            onBlur={(e) =>
                              editRejField(
                                e.target.value,
                                "action_plan",
                                item.id,
                                i
                              )
                            }
                          >{item.action_plan}</textarea>
                        ) : (
                          <span>{item.action_plan}</span>
                        )}
                      </td>
                          <td className="whitespace" onClick={()=> handlerejeditRow(i)}>
                    {item.editing ? (
                    <select
                                className="form-control"
                                onChange={(e) =>
                                  editRejField(
                                    e.target.value,
                                    "status",
                                    item.id,
                                    i
                                  )
                                }
                                defaultValue={item.status}
                              >
                                <option value="0" key="0" >Select Status</option>
                                {statuslist &&
                                statuslist.length &&
                                statuslist != null ? (
                                  statuslist.map((status) =>
                                  
                                      <option key={status.id} value={status.id} >
                                        {status.name}
                                      </option>
                                  )
                                ) : (
                                  <option>No data Found</option>
                                )}
                              </select> ) : (
                                <p style={{color: (item.status_color_code!=null)?`${item.status_color_code}`:'#000'}}><br></br>{item.status_name}</p>
                              )} 
                              </td>  
                              <td className="whitespace" onClick={()=> handlerejeditRow(i)}>
                    {item.editing && (user_info.id == item.manager_id || (item.manager_id == null))  ? (
                    <select
                                className="form-control"
                                onChange={(e) =>
                                  editRejField(
                                    e.target.value,
                                    "final_status",
                                    item.id,
                                    i
                                  )
                                }
                                defaultValue={item.final_status}
                              >
                                <option value="0" key="0" >Select Status</option>
                                {statuslist &&
                                statuslist.length &&
                                statuslist != null ? (
                                  statuslist.map((status) =>
                                  
                                      <option key={status.id} value={status.id} >
                                        {status.name}
                                      </option>
                                  )
                                ) : (
                                  <option>No data Found</option>
                                )}
                              </select> ) : (
                             <p style={{color: (item.fstatus_color_code!=null)?`${item.fstatus_color_code}`:'#000'}}><br></br>{item.final_status_name}</p>
                              )} 
                              </td>      
                              <td  onClick={()=> handlerejeditRow(i)} >
                                    {item.editing ? (
                                      <input type="date"
                                      className="form-control"
                                        defaultValue={item.ccrp_meeting_date}
                                      
                                        onBlur={(e) =>
                                          editRejField(
                                            e.target.value,
                                            "ccrp_meeting_date",
                                            item.id,
                                            i
                                          )
                                        }
                                        min={disablePastDate()}
                                      />
                                    ) : (
                                      <span>{(item.ccrp_meeting_date!='') ? formatDate(item.ccrp_meeting_date) : ''}</span>
                                    )}
                                  </td>
                                  <td>
                                  {item.flag == 1 && (user_info.id == item.manager_id || (item.manager_id == null)) ? (
                                    <>
                                    <Button
                                      color="primary"
                                      style={{
                                        background: "#02C779",
                                        border: "#02C779",
                                        borderRadius: "18px",
                                      }}
                                      size="sm"
                                      type="button"
                                      onClick={(e) =>
                                        editRejField(
                                          1,
                                          "approval",
                                          item.id,
                                          i
                                        )
                                      }
                                    >
                                      Approve
                                    </Button>
                                  
                                    <Button
                                      color="primary"
                                      style={{
                                        border: "1px solid #FF6430",
                                        background:'none',
                                        color: "#FF1717",
                                        borderRadius: "18px",
                                      }}
                                      size="sm"
                                      type="button"
                                      onClick={(e) =>
                                        editRejField(
                                          2,
                                          "approval",
                                          item.id,
                                          i
                                        )
                                      }
                                      
                                    >
                                      Deny
                                    </Button>
                                    </>
                                  ) : (item.flag == 2)  ? <span style={{ color : "#FF1717",cursor : 'pointer' }}>Rejected </span> : null }
                                
                                {/* <Button className="btn btn-danger"  onClick={(e) =>
                                        archieveRows(
                                          item.id)
                                      }  class="btn btn-danger"><i class="fa fa-trash"></i></Button> */}
                                </td>
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
                    </Table>
                    <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
                </Card>
              </Col>
             
            </Row>
             
            </div>
        </div>

        <Row>
          <Modal
            size="lg"
            className="taskdetailmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => settaskdetailPopup(!taskdetailPopup)}
            isOpen={taskdetailPopup}
          >
            <div className="modal-header">
            <h3>Task Detail</h3>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => settaskdetailPopup(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{paddingTop:'0px'}}>
          {(taskdetails.company)?  <p><b>Book Keeping Software :</b> {taskdetails.company.book_keeping_software} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <b>Account Production Software</b> : {taskdetails.company.account_prod_software}</p>  :null }
              <Table>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th className="whitespace">Assigned Leader</th>
                    <th className="whitespace">Assigned Member</th>
                    <th>Due date</th>
                    <th>Start date</th>
                    <th>Comp. date</th>
                    <th className="whitespace">Status</th>
                    <th>Approval</th>
                  </tr>
                </thead>
                <tbody>
                {taskdetails.analyst  ? (
                  <tr key="analyst" className="blue-row">
                    <td>Analyst</td>
                    <td className="whitespace">{taskdetails.analyst.leader}</td>
                    <td className="whitespace">{taskdetails.analyst.member}</td>
                    <td >{taskdetails.analyst.due_date}</td>
                    <td>{taskdetails.analyst.start_date}</td>
                    <td>{taskdetails.analyst.completed_date}</td>
                    <td className="whitespace">{taskdetails.analyst.status ? taskdetails.analyst.status.name : ""}</td>
                    <td>{taskdetails.analyst.approval==1 ? 'Approved' : taskdetails.analyst.approval==2 ? 'Denied' : ''}
                    {taskdetails.analyst.flag==1 ? 'Ready for review' : taskdetails.analyst.flag==2 ? 'Phases assigned by QA' : taskdetails.analyst.flag==3 ? 'Rejected by QA' : taskdetails.analyst.flag==4 ? 'Reviewed' : ''}
                    </td>
                  </tr>
                 
                 ) : (
                  null
                )}

                      {taskdetails.phases && taskdetails.phases.length
                          ?                               
                          taskdetails.phases.map((phase,d) => (
                              <tr key={d} className={((d % 2 == 0)? 'purple-row': 'orange-row' )}>
                                <td>{phase.phases_list.desc}</td>
                                <td  className="whitespace">{phase.leader}</td>
                                <td  className="whitespace">{phase.member}</td>
                                <td>{phase.due_date}</td>
                                <td>{phase.start_date}</td>
                                <td>{phase.completed_date}</td>
                                <td  className="whitespace">{phase.status ? phase.status.name : ""}</td>
                                <td>{phase.approval==1 ? 'Approved' : phase.approval==2 ? 'Denied' : ''}
                                    {phase.flag==1 ? 'Rejected' : ''}
                                </td>
                              </tr>
                            ))
                          :
                           ( <p>No Data Found</p> )}
                </tbody>
              </Table>
            </ModalBody>
           </Modal>
        </Row>
      </Container>
    </>
  );
};
export default ExternalRejection;
