import { useState, useEffect } from "react";
import React from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import 'assets/css/themecustom-style.css';
import { useHistory } from "react-router-dom";
import axios from "axios";
import { usermanageService } from '../../services/usermanageService';
import { taskmanageService } from '../../services/taskmanageService';
import { ToastContainer, toast } from 'react-toastify';
import { clientmanageService } from "../../services/clientmanageService";
import { getAllJSDocTags } from "typescript";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Select from 'react-select';
const MisGovernanceDataDetails = (props) => {
  const history = useHistory();
  const params = useParams();
  const [currentPage, setCurrentPage] = useState(null);
  const [lastPage, setLastPageData] = useState(0);
  const [data, setData] = useState([]);
  const [misData, setMISData] = useState([]);
  const [months, setMonths] = useState([]);
  const [filterFields, setFilterFields] = useState([]);
  const [filterType, setFilterType] = useState('open');
  const [selectedmanager,setSelectedmanager]=useState('');
  const [selectedmanagerlabel,setSelectedmanagerLabel]=useState(null);
  
  
useEffect(() => {
    getList();

  }, [params]);
  const handlePageClick = (e, index) => {
    e.preventDefault();
    getClientDocList(index);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    getClientDocList(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    getClientDocList(next);
  };

  const getList = async () => {
    
    clientmanageService.governanceCalldashboarddetails(params.month,params.title,params.username).then((response) => {
        setData(response.data);
    });
    
  };

  const companyFilter = (e, index) => {
    const cloneValues = { ...filterFields };
    cloneValues[index] = {
      ...cloneValues[index],
      field: e.target.name,
      value: encodeURIComponent(e.target.value),
      operator: '='
    };
    setFilterFields(cloneValues);
    getClientDocList(1, null, cloneValues, filterType);
  };

 




  const companyinputhandler = (selectedOption) => {
    setSelectedmanager(selectedOption ? selectedOption.value : null);
    setSelectedmanagerLabel(selectedOption ? selectedOption : null);
    getList(selectedOption ? selectedOption.value : '');
  }

   function downloadReport(){
    
    if(params.type=='mis-basedonrating'){
        clientmanageService.getGovernanceMISdashboard(selectedmanager,1).then((response) => {
        
        });
    }else{
        clientmanageService.getGovernanceMISActiondashboard(selectedmanager,1).then((response) => {
            
        });
    }
  }
  const getDetails = (month,title,username)=>{
    history.push({
      pathname: "/admin/misgovernancedatadetails/"+month+"/"+title+"/"+username
    });
  }
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
      
        <Row className="mt-2">
          <div className="col" style={{ padding: "0%" }}>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center row">
                  <div className="col-xl-3 col-md-2 col-xs-12">
                    <h3><b>MIS Governance Details</b></h3>
                  <br></br></div>
                 
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                
                    <th scope="col" className="whitespace" >Client Name</th>
                    {(params.title!='due') ? 
                    <>
                    {(params.title !='total') ? 
                    <>
                    <th scope="col" className="whitespace">Month</th>
                    <th scope="col" className="whitespace">Call Count</th></> : ''}
                    <th scope="col" className="whitespace">Rating</th>
                    </>
                    :''}
                    
                </thead>
                <tbody>
                {data.data &&
                    data.data.length &&
                    data.data != null ? (
                      data.data.map((user, i) => (
                        (params.title=='due') ? 
                        <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') }>
                            <td>{user}</td>
                        </tr> :
                        (params.title=='total') ? 
                        <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') }>
                            <td>{user.name}</td>
                            <td>{user.result}</td>
                        </tr>  : 
                        <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') }>
                          <td>{user.client_name}</td>
                          <td>{user.month_year}</td>
                          <td>{user.rating}</td>
                          <td>{user.call_count}</td>
                        </tr>
                        
                        
                     ))
                     ):'No records found' }
                </tbody>
                    </Table>
                    {/* <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter> */}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default MisGovernanceDataDetails;
