import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroup, 
    InputGroupAddon,
    InputGroupText,
    Row,
    Col,
    Container,
    UncontrolledAlert
  } from "reactstrap";
  import React, { useState,useEffect } from "react";
  import { useHistory,useParams } from "react-router-dom";
  import {clientmanageService} from '../../../services/clientmanageService.js';
  import {todoService} from '../../../services/todoService.js';
  import { toast } from 'react-toastify';
  import ReactDatetime from "react-datetime";
  import Select from 'react-select';
  const CopyTodo = (props) => {
    const history = useHistory();
    let initialStateData = {
      assigned_by:"",
      remark:"",
      due_date:''
  
    };
    const [inputFields, setInputfields] = useState(initialStateData);
    const [error, setError] = useState(null)
    
    const [successMsg, setsuccessMsg] = useState(null)
    const params  = useParams();
    function inputhandler(e){
        setInputfields({...inputFields,[e.target.name]:e.target.value});
    } 
  
    const [ clientlist, setclientlist ] = useState([])
    function clientList(id){
      todoService.get_assignuser(id).then((response)=>{
            if(response.status === 200){
              setclientlist([...response.data.data, { value: user_info.id, label: 'self' }]);
              
            }else {
                toast.error('Something went wrong!');
            }
          });
    }
  

    function addtodo(e){
        e.preventDefault();
        todoService.addtodo(inputFields).then((response)=>{
         
          if(response.status == 200){
             toast.success(response.data.message);  
             history.goBack(); 
          }else if(response.status == 201){
            setError('Enter All required field');
            setsuccessMsg(null);
            toast.error(response.data.message); 
          }
        });
      }
      function cancel_form(){
        document.getElementById('addclient-form').reset();
        setInputfields(initialStateData);
        history.goBack(); 
   }
    const user_info = JSON.parse(localStorage.getItem("user-info"));
    useEffect(() => { 
    clientList()
      gettodo()
      }, [] ) 
      const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate() + 1).padStart(2, "0") - 1;
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };
    function gettodo(){
        todoService.gettodo(params.id).then((response)=>{
        
            if(response.status == 200){
                console.log(response.data.data)
                setInputfields(response.data.data)
            }
        });
    }
    function inputuserhandler(e,name){
      setInputfields({...inputFields,[name]:e.value});
  }
  const [usersearch, setusersearch] = useState([{'label':'Select User',value:''}]);
    return (
        <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
            <Row className="justify-content-md-center">
                <Col lg="12" md="12">
                    <Card className="shadow">
                        <CardHeader className="bg-transparent">
                            <h3 className="mb-0">Add Todo List</h3>
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-10">
                       
                        <Form role="form" id="addclient-form" onSubmit={addtodo}>
                            <FormGroup>
                             <Row>
                             <Col lg="6" md="6">
                             <label>User</label>
                             <Select 
                    
                    className="input-group-alternative mb-3" 
                    name="assigned_by"
                    options={clientlist} // set list of the data
                  
                   value={clientlist.find(obj => obj.value === inputFields.assigned_by) || ''} // set selected values
                 
                    searchable
                    onChange={(e) => inputuserhandler(e, 'assigned_by')}
                    isClearable={false}
                  />
                                {/* <select  class="form-control" name="assigned_by"   value={inputFields.assigned_by != undefined ? inputFields.assigned_by : ''} onChange={inputhandler}  >
                                <option value=""> Select User</option>
                                <option value={user_info.id}> Self</option>
                                {clientlist.map(res => (
                                    <option
                                      key={res.id}
                                      value={res.id}
                                    >
                                      {res.name}
                                    </option>
                                  ))}
                                </select> */}
                                
                                </Col>
         
                             </Row>   
                            </FormGroup>
                            
                            <FormGroup>
                              <Row>
                              <Col lg="6" md="6">
                                   <label>Due date</label> 
                                      <InputGroup className="input-group-alternative">
                                          <Input
                                          placeholder="Due date"
                                          type="date"
                                          name="due_date"
                                          onChange={inputhandler}
                                          min={disablePastDate()}
                                          value={inputFields.due_date != undefined ? inputFields.due_date : ''}
                                          required
                                          />
                                      </InputGroup>
                                  </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup>
                              <Row>
                                <Col lg="6" md="6">
                                <label>Todo</label>
                                <Input type="textarea" rows="1"  placeholder="Todo" name="remark"  value={inputFields.remark != undefined ? inputFields.remark : ''} onChange={inputhandler}  /> 
                                  </Col>
                                  
                                    </Row> 
                            </FormGroup>
                            <FormGroup>
                               <Row>
                               
                            
                               
                                 </Row></FormGroup>
                                <div className="col-12">
                               <Button className="mt-2" color="primary" type="submit">
                                Save
                               </Button>
                               <Button className="mt-2 ml-2" color="secondary " type="button" onClick={cancel_form}>
                                 Cancel
                               </Button>
                            </div>
                        </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        </div>
        </>
    );
  };
  
  export default CopyTodo;
  