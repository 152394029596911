import { BrowserRouter, Route, Switch, Redirect, useHistory,useParams } from "react-router-dom";
import React from 'react';
import { useEffect,useState } from 'react';
import 'assets/css/custom.css';

import { ToastContainer, toast } from 'react-toastify';
import PageLoder from 'components/Loader/PageLoader.js';
import {
   Button,
   Row,
   Col,
   Container,
   Modal,
   ModalHeader,
   ModalBody,
   ModalFooter,
   Form,
   Table ,
   Input 
} from "reactstrap";
import { clientmanageService } from "services/clientmanageService";

const Questionnairecomponent  = ({client_id,sub_client}) => {
   const history = useHistory();
   const user_info = JSON.parse(localStorage.getItem("user-info"));
   const [formdetails,setformdetails] = useState({});
   const [formdetailsLen,setformdetailsLength] = useState(0);
   const [disablestate, setdisablestate] = useState(false);

  const [stateq, setStateq] = useState({});
  function addsopform(e) {
    console.log(e);
    setdisablestate(true)
    e.preventDefault();

    clientmanageService.updateQuestionaireForm(stateq,client_id,sub_client).then((response) => {
    
  
      if (response.status == 200) {
        toast.success(response.data.message);
       // location.reload();
      }
      setdisablestate(false)
    });
  }
  function inputhandler(e,index) {
  
    let temp_st = {...stateq};
    let temp_ele = { ...temp_st[index] };
    temp_ele.id = index;
    temp_ele.remarks = e.target.value;
    temp_st[index] = temp_ele;
    setStateq(temp_st);
    console.log(stateq)
  
  }

  const getquestionnaireData = () => {

    clientmanageService.getquestionnaireData(client_id,sub_client).then((response) => {
      setformdetails(response.data);
    })
  }
  useEffect(() => {
    getquestionnaireData();
  },[]);

    return (
        <React.Fragment key={1}>
         <Form
                role="form"
                id="phase-selection-form"
                onSubmit={addsopform}
            
              >
              <Table  className="align-items-center table-flush question_table" cellspacing="0" cellpadding="0"  responsive>
             
              <thead>
                <tr style={{ border: "1px solid #000" }}>
                 
                  <th style={{ border: "1px solid #000" }} className="whitespace">Area</th>
                  <th style={{ border: "1px solid #000" }} colspan="2" className="whitespace">Question</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">Response  - (Yes/No ) detailed</th>
                </tr>
              </thead>
           
                <tbody>
              
<tr className="whitespace">
    <td rowspan="26">About the Client</td>
    <td className="whitespace">Nature of Business</td>
    <td></td>
    <td className="whitespace" style={{ width: "50%", border: "1px solid #000" }}>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["1"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 1)}
        />
    </td>
</tr>
<tr style={{ border: "1px solid #000" }}>
    <td style={{ border: "1px solid #000" }}>Year End</td>
    <td style={{ border: "1px solid #000" }}></td>
    <td className="whitespace" style={{ width: "50%", border: "1px solid #000" }}>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["2"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 2)}
        />
    </td>
</tr>
<tr style={{ border: "1px solid #000" }}>
    <td style={{ border: "1px solid #000" }}>VAT Registered ?</td>
    <td style={{ border: "1px solid #000" }}>Yes/No</td>
    <td className="whitespace" style={{ width: "50%", border: "1px solid #000" }}>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["3"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 3)}
        />
    </td>
</tr>
<tr style={{ border: "1px solid #000" }}>
    <td style={{ border: "1px solid #000" }}>
        <b>If VAT Registered - fill in below information - VAT Quarters & Scheme</b>
    </td>
    <td></td>
    <td></td>
</tr>
<tr style={{ border: "1px solid #000" }}>
    <td style={{ border: "1px solid #000" }}>VAT Quarters</td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["4"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 4)}
        />
    </td>
</tr>
<tr>
    <td rowspan="5">VAT Scheme</td>
    <td>Standard / Accrual</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["5"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 5)}
        />
    </td>
</tr>
<tr>
    <td>Cash</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["6"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 6)}
        />
    </td>
</tr>
<tr>
    <td>Flat Rate Cash</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["7"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 7)}
        />
    </td>
</tr>
<tr>
    <td>Partial Exemption</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["8"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 8)}
        />
    </td>
</tr>
<tr>
    <td>Others - Please specify</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["9"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 9)}
        />
    </td>
</tr>

<tr>
    <td>CIS registered </td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["10"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 10)}
        />
    </td>
</tr>

<tr>
    <td rowspan="5">Accounting Application/ Software  </td>
    <td>Xero</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["11"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 11)}
        />
    </td>
</tr>
<tr>
    <td>Sage</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["12"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 12)}
        />
    </td>
</tr>
<tr>
    <td>QuickBooks</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["13"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 13)}
        />
    </td>
</tr>
<tr>
    <td>MS Excel</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["14"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 14)}
        />
    </td>
</tr>
<tr>
    <td>Others</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["15"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 15)}
        />
    </td>
</tr>

<tr>
    <td rowspan="6">Invoice Processing Software </td>
    <td>Receipt Bank</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["16"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 16)}
        />
    </td>
</tr>
<tr>
    <td>Datamolino</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["17"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 17)}
        />
    </td>
</tr>
<tr>
    <td>Auto Entry</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["18"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 18)}
        />
    </td>
</tr>
<tr>
    <td>Entry Less</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["19"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 19)}
        />
    </td>
</tr>
<tr>
    <td>Direct Posting</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["20"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 20)}
        />
    </td>
</tr>
<tr>
    <td>Others-</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["21"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 21)}
        />
    </td>
</tr> 

<tr>
    <td rowspan="4">Frequency of Book Keeping</td>
    <td>Daily</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["22"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 22)}
        />
    </td>
</tr>
<tr>
    <td>Weekly</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["23"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 23)}
        />
    </td>
</tr>
<tr>
    <td>Fortnightly</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["24"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 24)}
        />
    </td>
</tr>
<tr>
    <td>Monthly</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["25"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 25)}
        />
    </td>
</tr>

<tr>
    <td rowspan="10">About the Client</td>
    <td rowspan="5">How will you send documents</td>
    <td>Scan via File Transfer Protocol (FTP)</td> 
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["26"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 26)}
        />
    </td>
</tr>
<tr>
    <td>Scan via Email</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["27"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 27)}
        />
    </td>
</tr>
<tr>
    <td> Scan Uploaded to Dropbox</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["28"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 28)}
        />
    </td>
</tr>
<tr>
    <td> Scan Uploaded to Google Drive</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["29"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 29)}
        />
    </td>
</tr>
<tr>
    <td> Scan Uploaded to One Drive</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["30"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 30)}
        />
    </td>
</tr>
<tr>
    <td > List down bank accounts</td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["31"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 31)}
        />
    </td>
</tr>
<tr>
    <td > List down credit cards
</td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["32"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 32)}
        />
    </td>
</tr>
<tr>
    <td > List down intermediary accounts
</td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["33"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 33)}
        />
    </td>
</tr>
<tr>
    <td > Does the company use any kind of factoring
</td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["34"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 34)}
        />
    </td>
</tr>
<tr>
    <td > Does company have assets on Hire Purchase
</td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["35"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 35)}
        />
    </td>
</tr>
<tr>
    <td rowspan="4">Scope of Work</td>
    <td rowspan="4">Sales</td>
    <td>Invoice Raising/ Credit Note Raising</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["36"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 36)}
        />
    </td>
</tr>
<tr>
    
    <td> Cash Allocation</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["37"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 37)}
        />
    </td>
</tr>
<tr>
    
    <td> Collection- Emails</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["38"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 38)}
        />
    </td>
</tr>
<tr>
    
    <td> Collection- Voice</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["39"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 39)}
        />
    </td>
</tr>
<tr>
<td rowspan="8">Scope of Work</td>
    <td rowspan="4">Purchases</td>
    <td>Invoice Processing</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["40"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 40)}
        />
    </td>
</tr>
<tr>
    
    <td> Cash Allocation</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["41"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 41)}
        />
    </td>
</tr>
<tr>
    
    <td> Payments</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["42"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 42)}
        />
    </td>
</tr>
<tr>
    
    <td> Supplier Help Desk</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["43"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 43)}
        />
    </td>
</tr>
 <tr>
    
    <td >Bank Reconciliation</td>
    <td>Bank Reconciliation</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["44"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 44)}
        />
    </td>
</tr> 
<tr>
    
    <td rowspan="3" >Payroll</td>
    <td> Payroll Processing</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["45"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 45)}
        />
    </td>
</tr>
<tr>
    
    
    <td> Payroll JE Posting</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["46"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 46)}
        />
    </td>
</tr> 
<tr>
    
    
    <td> Payments to Employees</td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["47"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 47)}
        />
    </td>
</tr> 
<tr>
<td rowspan="5">Accounting</td>
    <td >List down any considerations for Sales accounting which needs specific<br/> attention or treatment</td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["48"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 48)}
        />
    </td>
</tr>
<tr>

    <td >List down any considerations for Purchases 
        accounting which needs <br/>specific attention or treatment</td>
<td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["49"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 49)}
        />
    </td>
</tr>
<tr>
<td >Do you want us to do Prepayments and Accruals?  <br/>
     If yes, please mention materiality levels and specific  
     accruals to be done <br/>including depreciation, 
     interest, HP and others</td>
<td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["50"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 50)}
        />
    </td>
</tr>
<tr>
<td>Does each payments need to be supported by invoice. <br/> 
    In case No, please provide guidance or direction-  
    This can be discussed<br/> on a conference call</td>
<td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["51"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 51)}
        />
    </td>
</tr>
<tr>
<td >Shall we post any payments without supporting  
    invoice details <br/>to suspense or Query sheet leaving  
    these unreconciled
</td>
<td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["52"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 52)}
        />
    </td>
</tr>
<tr>
<td rowspan="5">Others</td>
    <td >Do you have Standard Operating Process which can be shared with us.</td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["53"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 53)}
        />
    </td>
</tr>
<tr>
<td >Do you require any specific reporting- If yes, please list down frequency
</td>
<td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["54"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 54)}
        />
    </td>
</tr>
<tr>
<td >Do you require management reporting. If yes, please list down frequency
</td>
<td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["55"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 55)}
        />
    </td>
</tr>
<tr>
<td >Do you want us to file VAT Return. If yes, please provide gateway <br/>access details
</td>
<td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["56"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 56)}
        />
    </td>
</tr>
<tr>
<td >Name of the contact person at your end along with contact details
</td>
<td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["57"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 57)}
        />
    </td>
</tr>
<tr>
<td rowspan="2"><b>Time lines</b></td>
    <td ><b>What are the budgeted hours for the above client on a monthly basis</b></td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["58"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 58)}
        />
    </td>
</tr>

<tr>

    <td ><b>Deadline date for completion</b></td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["59"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 59)}
        />
    </td>
</tr>
<tr>
<td ><b>Special Points specific to client</b></td>
    <td ></td>
    <td></td>
    <td>
        <Input
            bsSize="sm"
            name="form-remarks"
            placeholder="Remarks"
            id="example-date-input"
            type="textarea"
            class="quest_textarea"
            defaultValue={formdetails["60"]?.[0]?.remark ?? ''}
            onChange={(e) => inputhandler(e, 60)}
        />
    </td>
</tr>
                    
            </tbody>
              </Table>
              </Form>
            <br></br>
              
                <div class="d-flex">

            <Button color="primary" className="col-xl-2" style={{flex:"0 0 4.66667%"}} type="button" onClick={addsopform} disabled={disablestate}>
            {disablestate ? 'Sending...' : 'Save'} 
              </Button>
              </div>
        </React.Fragment>
      ); 
};

export default Questionnairecomponent;