import { useState, useEffect, useMemo ,useRef} from "react";
import React from 'react';
import ReactDatetime from "react-datetime";
import {Formloader} from 'components/Loader/PageLoader.js';
const axios = require("axios");
import { apiUrl } from "../../constant";
import { authtoken } from "global.js";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  PopoverBody,
  UncontrolledPopover
} from "reactstrap"; 
import { ToastContainer, toast } from "react-toastify";
import { AgGridReact, AllCommunityModules, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { userService } from "../../services/authentication";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { disposeEmitNodes, ExitStatus, forEachChild } from "typescript";
import { taskmanageService } from "../../services/taskmanageService";
import { usermanageService } from "../../services/usermanageService";
import { clientmanageService } from "../../services/clientmanageService";
import {todoService} from '../../services/todoService.js';
import { manageService } from "../../services/manageaccountservices";
import { permissions, rolename, roletype } from "global.js";
import { map } from "jquery";
import 'assets/css/themecustom-style.css';
import { connect } from "react-redux";
import moment from 'moment';
import Select from 'react-select';
import swal from 'sweetalert';
import Questionnairecomponent from 'components/Questionnairecomponent.js';
const Dashboard = (props) => {
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const history = useHistory();
  const [dashboarddata, setDashboarddata] = useState([]);
  const [tempdashboarddata, setTempDashboarddata] = useState([]);
  const [statuslist, setStatusdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [companieslist, setCompaniesData] = useState([]);
  const [tempcompanieslist, setTempCompaniesData] = useState([]);
  const [dashboardStatistics, setdashboardStatistics] = useState([]);
  const [phaselistsselection, setPhaseListsSelection] = useState([]);
  const [analystListsSelection, setAnalystListsSelection] = useState({assign_lead_id : '',due_date:''});
  const [processOrder, setprocessOrder] = useState(0);
  const [analysteamleaderlist, setAnalystTeamLeaderList] = useState([]);
  const [companylist, setCompanies] = useState([]);
  const [tempallcompanylist, setAlltempCompanieslist] = useState([]);
  const [total_page,setTotalData] = useState(0);
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [reviewChecklist,setReviewChecklistModalOpen] = useState(false);
  const [documentModalOpen, setdocumentModalOpen] = useState(false);
  const [rejectionModalOpen, setrejectionModalOpen] = useState(false);
  const [responseModalOpen, setresponseModalOpen] = useState(false);
  const [otherInfoModalOpen, setotherInfoModalOpen] = useState(false);
  const [expandedRows, setexpandedRows] = useState([]);
  const [commentlists, setCommentLists] = useState([]);
  const [documentlists, setDocumentLists] = useState([]);
  const [rejectionlists, setRejectionLists] = useState([]);
  const [queryresponselists, setQueryresponselists] = useState([]);
  const [dataloaded,setdataloaded] = useState(false);
  const [mainfilter,setmainfilter] = useState('assigned');
  const [status, setStatus] = useState(0); // 0: no show, 1: show yes, 2: show no.
  const [formloader,setformloader] = useState(false);
  const [check_review_disp,setreview] = useState(true);
  const formRef = useRef();
  const [hoddatatype,sethoddatatype] = useState(1);
  const [decesionmakingmatrixModalOpen, setotherDecisionmakingmatrixModalOpen] = useState(false);
  const initialStateData = {
    task_id: "",
    analyst_id: "",
    due_date: "",
  };
  const initialStateDataRejection = {
    id: "",
    type : "",
    value : "",
    comment : "",
    errors : []
  };
  const initialStateDataResponse = {
    task_id : "",
    responses : []
  };
  const initiaPhaseData = {
    phases: [],
  };
  const commentData = {
    task_id: "",
    comment: "",
    is_client : 0
  };

  const [inputFields, setInputfields] = useState(initialStateData);
  const [inputPhases, setinputPhases] = useState(initiaPhaseData);
  const [inputFieldsRejection, setInputfieldsRejection] = useState(initialStateDataRejection);
  const [inputPhasesRejection, setinputPhasesRejection] = useState(initiaPhaseData);
  const [inputPhasesResponse, setinputPhasesResponse] = useState(initialStateDataResponse);
  const [inputComments, setinputComments] = useState(commentData);
  const [error, setError] = useState(null);
  const [filterFields,setfilterFields] = useState([]);
  const [filtertype,setfiltertype] = useState('assigned');
  const [successMsg, setsuccessMsg] = useState(null);
  const [completedCount,setCompletedCount] = useState(0);
  const [errorcategory,seterrorcategory] = useState([]);
  
  const [disablestate, setdisablestate] = useState(false);
  const [checklistData, setCheckListData] = useState([]);
  const [reviewChecklistphase,setReviewChecklistphaseModalOpen] = useState(false);
  let start_d =  moment().startOf('month').format('YYYY-MM-DD');
  let end_d = moment().endOf('month').format('YYYY-MM-DD');
  const [errorbox,seterrorbox] = useState([
    (user_info.department == 'SME') ? 0 : 1
  ]);
  const errorDiv = error ? (
    <UncontrolledAlert className="alert-danger" fade={false}>
      {error}
    </UncontrolledAlert>
  ) : (
    ""
  );
  const successDiv = successMsg ? (
    <UncontrolledAlert className="alert-success" fade={false}>
      {successMsg}
    </UncontrolledAlert>
  ) : (
    ""
  );
  function inputhandler(e) {
    setInputfields({ ...inputFields, [e.target.name]: e.target.value });
  }

  function inputhandlercomment(e) {
    setinputComments({ ...inputComments, [e.target.name]: e.target.value });
  }

  

  const handleclick = (e, value) => {
    history.push({
      pathname: "addedit",
    });
  };
  const todohandleclick = (e, value) => {
    history.push({
      pathname: "todolist",
    });
  };
  useEffect(() => {
    if(!dataloaded){
      get_status_list();
      assignuser();
      get_companieslist();
      get_dashboard_data(hoddatatype);
    }
    search_all_company();
    return () => {
      setDashboarddata([]);
      setCompaniesData([]);
      setdashboardStatistics([]);
      setAnalystTeamLeaderList([]);
    };
  }, [props.name]);

  // sorting functon
  const [sortfield,setsortfield] = useState('');
  const [sortorder,setsortorder] = useState('asc');
  function sortTable(field){
    console.log(field)
    setsortfield(field);
    if(sortorder=='asc'){
      setsortorder('desc');
    }else{
      setsortorder('asc');
    }
  }

  useEffect(() => {
    if(dataloaded){
      get_list(1,null,filterFields,filtertype);
    }
  }, [sortorder]);

  // end sorting functon

  const get_list = async (pageNumber = 1,e,filterFields,filtertype='',hoddatatype,start_date='',end_date='') => {
    let  textbox = '';
    if(e != null){
       textbox = e.target.value;
    }
    taskmanageService.gettasks(pageNumber,textbox,filterFields,filtertype,sortfield,sortorder,hoddatatype,start_date,end_date).then((response) => {
     
      setDashboarddata(response.data.data.data);
      setTempDashboarddata(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setCompletedCount(response.data.completed_count)
      setLastPageData(response.data.data.last_page);
      
    });
  };
  
const [ assignuserlist, setassignuserlist ] = useState([])
function assignuser() {
  taskmanageService.get_assign_user().then((response) => {
    setassignuserlist(response.data.data);
  });
}
  function get_status_list() {
    taskmanageService.getstatus().then((response) => {
      setStatusdata(response.data.status);
    });
  }
  function get_dashboard_data(hoddatatype,phase='') {
    taskmanageService.getDashboardData(hoddatatype,phase).then((response) => {
      setdashboardStatistics(response.data.data);
    });
  }
  function get_companieslist(e) {
    let textbox = '';
    if(e != null){
       textbox = e.target.value;
       if(e.key === 'Enter'){
        e.preventDefault();
        taskmanageService.getcompanieslistsname(textbox).then((response) => {
          setCompaniesData(response.data.data);
          setTempCompaniesData(response.data.data);
          setdataloaded(true);
        });
      }else if(e.target.value.length <= 1){
        taskmanageService.getcompanieslistsname(textbox).then((response) => {
          setCompaniesData(response.data.data);
          setTempCompaniesData(response.data.data);
          setdataloaded(true);
        });  
      }
    }else{
      taskmanageService.getcompanieslistsname(textbox).then((response) => {
        setCompaniesData(response.data.data);
        setTempCompaniesData(response.data.data);
        setdataloaded(true);
      });
    }
  }

  function searchcompany(e){
    let  textbox = '';
    if(e != null){
       textbox = e.target.value;
    }
    let empty_arr = [];
    companieslist.map((value,index) => {
       if(value.name == textbox){
           empty_arr.push(value); 
       }else if(textbox == ''){
           empty_arr.push(value);    
       }
    });
    setTempCompaniesData(empty_arr); 
  }
   
  function getcompanies(){
    manageService.get_allcompanies_with_subclient().then((response)=>{
       setCompanies(response.data);
       setAlltempCompanieslist(response.data);
    });
  }

  function search_all_company(){
    let textbox = props.name;
    let pageNumber = 1;
    taskmanageService.gettasks(pageNumber,textbox,filterFields,filtertype,sortfield,sortorder,hoddatatype,'','').then((response) => {
      setDashboarddata(response.data.data.data);
      setTempDashboarddata(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      // setTotalData(response.data.data.total);
      setLastPageData(response.data.data.last_page);
    });
    // let empty_company_arr = [];
    // if(tempdashboarddata.length > 0){
    //    for(let i = 0;i<tempdashboarddata.length;i++){
    //      if(props.name == tempdashboarddata[i].company.name){
    //         empty_company_arr.push(tempdashboarddata[i]);
    //      }
    //    }
    //     if(props.name.length > 0){
    //         setTempDashboarddata(empty_company_arr);
    //     }else{
    //         setTempDashboarddata(dashboarddata);
    //     }
    //  }else{
    //       setTempDashboarddata(dashboarddata);
    //  }
  }

  function get_analystteamleaderlist() {
    taskmanageService.getanalystteamleaderlist().then((response) => {
      setAnalystTeamLeaderList(response.data.data);
    });
  }
  const [clicktasskid,setclicktasskid] = useState('');
  const get_phase_selection = async (task_id,assigned_only=false,phase_id = null) => {
    setclicktasskid(task_id);
    get_analystteamleaderlist();
    if(assigned_only == false || assigned_only =='tasks'){
    taskmanageService.getphaselistsselection(task_id).then((response) => {
    
        setPhaseListsSelection(response.data.data);
    
      setprocessOrder(response.data.process_order);
      if (response.data.analyst) {
        setAnalystListsSelection(response.data.analyst);
        let initdata = {
          task_id: task_id,
          analyst_id: response.data.analyst.assign_lead_id,
          due_date: response.data.analyst.due_date,
          budget : response.data.analyst.in_budget
        };
        setInputfields(initdata);
      } else {
        let initdata = {
          task_id: task_id,
        };
        setInputfields(initdata);
      }

      let cloneValues = [];
      if (response.data.data) {
        let pp = {};
        response.data.data.map((ele, i) => {
          if(phase_id != null){ // if rejection added by BR to IP
            if (ele.already_assign == true) {
              pp = {
                ["phase_id"]: ele.id,
                ["main_phase_id"] : phase_id,
                ["main_id"]: ele.main_id,
                ["assign_lead_id"]: ele.assign_lead_id,
                ["due_date"]: ele.due_date,
                ["budget"]: ele.in_budget,
              };
              cloneValues[i] = pp;
            }
          }else{
            if (ele.already_assign == true) {
              pp = {
                ["phase_id"]: ele.id,
                ["main_id"]: ele.main_id,
                ["assign_lead_id"]: ele.assign_lead_id,
                ["due_date"]: ele.due_date,
                ["budget"]: ele.in_budget,
              };
              cloneValues[i] = pp;
            }
          }
         
        });
      }
      setinputPhases(cloneValues);
      if(assigned_only==false){
        setCompanySelectionModal(!CompanySelectionModal);
      }else{
        setinputPhasesRejection(cloneValues);
      }
    });
  }
  };
  function add_phase_selection(e) {
    setdisablestate(true)
    taskmanageService.addphase(inputFields, inputPhases).then((response) => {
      // console.log(response);
      if (response.status == 200) {
        toast.success(response.data.message);
        setCompanySelectionModal(!CompanySelectionModal);
        setPhaseSelectionModalOpen(!PhaseSelectionModalOpen);
        setInputfields(initialStateData);
        setinputPhases(initiaPhaseData);
      } else if (response.status == 201) {
        toast.error(response.data.message);
      }
      setdisablestate(false)
    });
  }

  function skipPhase(){
    taskmanageService.skipphase(clicktasskid).then((response) => {
      // console.log(response);
      if (response.status == 200) {
        toast.success(response.data.message);
        setCompanySelectionModal(!CompanySelectionModal);
        setPhaseSelectionModalOpen(!PhaseSelectionModalOpen);
        setInputfields(initialStateData);
        setinputPhases(initiaPhaseData);
      } else if (response.status == 201) {
        toast.error(response.data.message);
      }
    });
  }

  function add_comment(e){
    if(e.target.form.comment.value!=''){
    taskmanageService.addcomment(inputComments).then((response) => {
      if (response.status == 200) {
        toast.success(response.data.message);
        setCommentModalOpen(!commentModalOpen);
        setinputComments(commentData);
      } else if (response.status == 201) {
        toast.error(response.data.message);
      }
    });
  }else{
    toast.error('Comment is required!'); 
  }
  }

  function addcommentform(id,index,type='',main_id=''){
    setinputComments({task_id:id});
    setCommentModalOpen(!commentModalOpen);
    get_comment_lists(id,type,main_id);
    let temp_state = [...dashboarddata];
    let temp_element = { ...temp_state[index] };
    temp_element.comment_count = 0;
    temp_state[index] = temp_element;
    setDashboarddata(temp_state);
  }

  function addreviewchecklistqa(id,index,type='',main_id=''){
    setCheckListData([]);
    getchecklistData(id,main_id)
    
  }
  function addreviewchecklist(id, main_id = '', type,frequency='') {
    if(frequency==''){
      toast.error('Frequency is not updated.')
    }else{
      let url = '';
      if (type === "tasks") {
        url = `/admin/checklistform/${id}/${frequency}`;
      } else {
        url = `/admin/checklistform/${id}/${frequency}/${main_id}/${type}`;
      }
      
      // Log the constructed URL for debugging
      console.log("Redirecting to URL:", url);
      
      // Redirect to the URL
      window.location.href = url;
    }
    
  }
  function getchecklistData(task_id,id){
    if(user_info.phase_id){
      
      taskmanageService.getchecklistDataforpahse(task_id,id).then((response) => {
        setCheckListData(response.data.list);
        
        setchecklistState({ ...checkstate,list: response.data.list,task_id: response.data.task,phase_id: response.data.phase_id});
        
      });
      setReviewChecklistphaseModalOpen(!reviewChecklist);
    }else{

      taskmanageService.getchecklistDataqa(task_id).then((response) => {
        setCheckListData(response.data.list);
        
        setchecklistState({ ...checkstate,list: response.data.list,task_id: response.data.task});
        
      });
      setReviewChecklistModalOpen(!reviewChecklist);
    }
  };
  function add_checklist_data(e) {

    e.preventDefault();
    taskmanageService.addChecklistDataqa(checkstate).then((response) => {
      console.log(response);
  
      if (response.status == 200) {
        toast.success(response.data.message);
        
      }
      if(user_info.phase_id){
        setReviewChecklistphaseModalOpen(!reviewChecklistphase);
      }else{
      setReviewChecklistModalOpen(!reviewChecklist);
      }
      
      setdisablestate(false)
    });
  }

  function get_comment_lists(task_id,type,id){
    taskmanageService.getcommentlists(task_id,type,id).then((response) => {
      setCommentLists(response.data.data);
    });
  };

  const radioHandler = (status) => {
    setStatus(status);
  };

  const formatDate = (dateStr) => {
    if(dateStr!=null && dateStr!=''){

      const [year, month, day] = dateStr.split('-');
      let newDate = `${day}-${month}-${year}`;
      return newDate;
    }
  };

  let button = "";
  let phase = "";
  let todo = "";
  let amendement = "";
  let taskapprove = false;
  let taskstatusedit = false;
  let phaseapprove = false;
  let phasestatusedit = false;
  let analystapprove = false;
  let analyststatusedit = false;
  let taskassign = false;
  let phaseassign = false;
  let analystassign = false;
  let taskedit = false;
  let phaseedit = false;
  let analystedit = false;
  let questionnaire="";
 
  if ((permissions.includes("company-create") == true && user_info.department != 'SME') || (user_info.department == 'SME' && user_info.role_id == 2)) {
    button = (
      <Col xl="2">
        <Button
          className="col-12 dash-button"
          color="primary"
          type="button"
          onClick={handleclick}
        >
          {" "}
         + Add Client{" "}
        </Button>{" "}
      </Col>
    );
    if(user_info.role_id == 2 || user_info.role_id == 3){
    phase = (
      <Col xl="3">
        <Button
          className="col-12 dash-button"
          color="light"
          type="button"
          onClick={() => {get_companieslist(),get_analystteamleaderlist(),setPhaseSelectionModalOpen(!PhaseSelectionModalOpen)}}
        >
          Phase selection
        </Button>{" "}
      </Col>
    );
    }
    amendement = (
      <Col xl="2">
        {/* <Button
          className="col-12 am-link"
          color="link"
          type="button"
          onClick={amendmentModal}
        >
          Amendement
        </Button> */}
      </Col>
    );
    
  }
  todo = (
    <Col xl="2">
      <Button
        className="col-12 dash-button"
        color="primary"
        type="button"
        onClick={todohandleclick}
      >
        {" "}
        Todo List{" "}
      </Button>{" "}
    </Col>
  );
 
  if (permissions.includes("task-approve") == true) {
    taskapprove = true;
  }
  if (
    permissions.includes("phase-approve") == true) {
    phaseapprove = true;
  }
  if (permissions.includes("analyst-approve") == true) {
    analystapprove = true;
  }
  if (permissions.includes("task-edit") == true) {
    taskedit = true;
  }
  if (permissions.includes("phase-status-edit") == true) {
    phasestatusedit = true;
  }
  if (permissions.includes("analyst-status-edit") == true) {
    analyststatusedit = true;
  }
  if (permissions.includes("task-status-edit") == true) {
    taskstatusedit = true;
  }
  if (permissions.includes("phase-edit") == true) {
    phaseedit = true;
  }
  if (permissions.includes("analyst-edit") == true) {
    analystedit = true;
  }
  if (permissions.includes("task-assign") == true) {
    taskassign = true;
  }
  if (permissions.includes("phase-assign") == true) {
    phaseassign = true;
  }
  if (permissions.includes("analyst-assign") == true) {
    analystassign = true;
  }

  const [
    PhaseSelectionModalOpen,
    setPhaseSelectionModalOpen,
    collapseOpen,
    setCollapseOpen,
  ] = useState(false);
  const [CompanySelectionModal, setCompanySelectionModal] = useState(false);
  const [isCheckedPhase, setIsCheckedPhase] = useState(false);
  const [isCheckedPhase_test, setIsCheckedPhase_test] = useState({});
  const [amendmentModalOpen, setAmendmentModal] = useState(false);
  const [amendmentsecondModal, setAmendmentsecondModal] = useState(false);
  const [opensecondpopup, setsecondpopup] = useState(false);
  const [editbox, seteditbox] = useState({ids: []});
  const [checkindex, setcheckindex] = useState([]);
  
  const initial_amend = {
    am_company: '',
    am_company_name: '',
    am_analyst: '',
    select_due_date: '',
    am_budget: '',
  };
  const [amendmentdata, setAmendmentdata] = useState(initial_amend);
  const [filterlist, setfilterlist] = useState([]);
  const [showfilterbox,setshowfilterbox] = useState(false);
  const [filterApplyFields,setfilterApplyFields] = useState([]);
  const [showRejection,setshowRejection] = useState(false);
  const [rejectionindex,setrejectionindex] = useState('');
  const [showResponse,setshowResponse] = useState(false);
  const [responseindex,setresponseindex] = useState('');
  const [inputfieldsResponse, setInputfieldsResponse] = useState({});
  useEffect(() => {
    if(filterApplyFields.length > 0){

      applyFilter('true');
    }
  }, [filterApplyFields]);


  function inputphasehandler(e, index) {
    const cloneValues = [...inputPhases];
    cloneValues[index] = {
      ...cloneValues[index],
      [e.target.name]: e.target.value,
    };
    setinputPhases(cloneValues);
  }

  const handleOnChange = (e, index) => {
    let temp_state = [...phaselistsselection];
    let temp_element = { ...temp_state[index] };
    temp_element.already_assign = temp_element.already_assign ? false : true;
    temp_state[index] = temp_element;
    setPhaseListsSelection(temp_state);
    if (temp_element.already_assign) {
      const cloneValues = [...inputPhases];
      cloneValues[index] = {
        ...cloneValues[index],
        [e.target.name]: e.target.value,
      };
      setinputPhases(cloneValues);
      setinputPhasesRejection(cloneValues);
    } else {
      let state = [...inputPhases];
      console.log(state)
      delete state[index];
      setinputPhases(state);
      let cloneValues = [...inputPhasesRejection];
      delete cloneValues[index];
      
      setinputPhasesRejection(cloneValues);
    }
   
  };

  function get_error_category(id=''){
    taskmanageService.geterrorcategoriesrolewise(id).then((response) => {
      seterrorcategory(response.data)
    })
  }
  function openResponseModal(task_id='',index){
    console.log(task_id)
    setshowResponse(!showResponse);
    const dataResponse = {
      task_id : task_id,
      responses : []
    };
    setinputPhasesResponse(dataResponse);
    if(task_id==''){
      setRowsError(1)
    }
    setresponseindex(index)
  }

  function inputresponsehandler(e, index) {
    const cloneValues = {...inputPhasesResponse};

  console.log(cloneValues)
    cloneValues.responses[index] = {
      ...cloneValues.responses[index],
      [e.target.name]: e.target.value,
    };
  
    setinputPhasesResponse(cloneValues);
  }

  function openRejectionModal(value='',id='',type='',task_id='',index){
    get_error_category();
    setshowRejection(!showRejection);
    const dataRejection = {
      id: id,
      type : type,
      value : value,
      comment : '',
      errors : []
    };
    setInputfieldsRejection(dataRejection);
    if(id==''){
      if(user_info.department == 'SME'){
        seterrorbox([0])
      }
      setRowsError(1)
    }
    setrejectionindex(index)
  }

  function openRejectionToQAModal(id,task_id,index){
    swal({
      title: "Are you sure?",
      text: "Once Rejected, it can not be undo!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willReject) => {
      if (willReject) {
        taskmanageService.rejectToQA(id,task_id).then((response) => {
          if (response.status == 200) {
            toast.success(response.data.message);
            let state = [...dashboarddata];
             delete state[index];
            setDashboarddata(state);
          }else{
            toast.error(response.data.message);
          }
        })
      } 
    });
    
  }
  function inputtaskrejectionhandler(e,index) {
    const cloneValues = {...inputFieldsRejection};
      cloneValues.errors[index] = {
        ...cloneValues.errors[index],
        [e.target.name]: e.target.value,
      };
    console.log(cloneValues)
    
    setInputfieldsRejection(cloneValues);
    if(e.target.name == 'no_error' && user_info.department == 'SME'){
      const errorsInput = [...errorbox];
      errorsInput[index] = e.target.value;
      seterrorbox(errorsInput)
    }
    
  }
  
  const [rowsError, setRowsError] = useState(1);
 
  const addCommetbox = ()=>{
    const setdata = rowsError+1;
      setRowsError(setdata)
     
        const errorsInput = [...errorbox];
        if(user_info.department == 'SME'){
          errorsInput[rowsError] = 0;
        }else{
          errorsInput[rowsError] = 1;
        }
        seterrorbox(errorsInput)
      
  }

  function inputrejectionhandler(e, index,file=null) {
    const cloneValues = [...inputPhasesRejection];
    if(file != null){
      cloneValues[index] = {
        ...cloneValues[index],
        [e.target.name]: e.target.files[0],
      };
    }else{
      cloneValues[index] = {
        ...cloneValues[index],
        [e.target.name]: e.target.value,
      };
    }
   
    console.log(cloneValues)
    setinputPhasesRejection(cloneValues);
    if(e.target.name == 'no_error' && user_info.department == 'SME'){
      const errorsInput = [...errorbox];
      errorsInput[index] = e.target.value;
      seterrorbox(errorsInput)
    }
    if(e.target.name=='phase_id'){
      const selectedIndex = e.target.selectedIndex;
      const selectedOption = e.target.options[selectedIndex];
      const itemId = selectedOption.getAttribute('data-id');
      get_error_category(itemId)
    }
  }
  function add_response(){
    taskmanageService.responsephase(inputPhasesResponse).then((response) => {
      // console.log(response);
      if (response.status == 200) {
        toast.success(response.data.message);
        let temp_st = [...dashboarddata];
        let temp_ele = { ...temp_st[responseindex] };
        temp_ele.flag = response.data.data.return_flag;
        temp_ele.approval = response.data.data.return_approval;
        Object.entries(statuslist).filter(([k, v]) => v.id == response.data.data.return_status).reduce((acc, [k, v]) => {
          temp_ele['status_name'] = v.name;
          temp_ele['status_color_code'] = v.color_code;
       }, {});
        temp_ele['status_id'] = response.data.data.return_status;
        temp_st[responseindex] = temp_ele;
        setDashboarddata(temp_st);
        setshowResponse(!showResponse);
        setinputPhasesResponse(initialStateDataResponse)
       
        setRowsError(1)
      } else if (response.status == 201) {
        toast.error(response.data.message);
      }
    });
  }

  function add_phase_rejection_selection(){
    setdisablestate(true)
    
    setinputPhasesRejection(inputPhasesRejection);
    taskmanageService.rejectionphase(inputFieldsRejection, inputPhasesRejection).then((response) => {
      // console.log(response);
      if (response.status == 200) {
        toast.success(response.data.message);
        let temp_st = [...dashboarddata];
        let temp_ele = { ...temp_st[rejectionindex] };
        temp_ele.flag = response.data.data.return_flag;
        temp_ele.approval = response.data.data.return_approval;
        Object.entries(statuslist).filter(([k, v]) => v.id == response.data.data.return_status).reduce((acc, [k, v]) => {
          temp_ele['status_name'] = v.name;
          temp_ele['status_color_code'] = v.color_code;
       }, {});
        temp_ele['status_id'] = response.data.data.return_status;
        temp_st[rejectionindex] = temp_ele;
        setDashboarddata(temp_st);
        setshowRejection(!showRejection);
        setInputfieldsRejection(initialStateDataRejection)
        setinputPhasesRejection(initiaPhaseData)
        if(user_info.department == 'SME'){
          seterrorbox([0])
        }
        setRowsError(1)
      } else if (response.status == 201) {
        toast.error(response.data.message);
      }
      setdisablestate(false)
    });
  }

  const editJob = (value, id, type, index) => {
    taskmanageService.editTask(value, id, type).then((response) => {
      if (response.status == 200) {
        toast.success(response.data.message);
        let temp_st = [...dashboarddata];
        let temp_ele = { ...temp_st[index] };
        temp_ele.flag = response.data.data.return_flag;
        temp_ele.approval = response.data.data.return_approval;
        Object.entries(statuslist).filter(([k, v]) => v.id == response.data.data.return_status).reduce((acc, [k, v]) => {
          temp_ele['status_name'] = v.name;
          temp_ele['status_color_code'] = v.color_code;
       }, {});
        temp_ele['status_id'] = response.data.data.return_status;
        temp_st[index] = temp_ele;
        setDashboarddata(temp_st);
      } else if (response.status == 201) {
        toast.error(response.data.message);
      }
    })
  }

  const handleOnCheckboxClick = (e,type,index) => {
    const { value, checked } = e.target;
    const { ids } = editbox;
    // Case 1 : The user checks the box
    if (checked) {
      seteditbox({
        ids: [...ids, e.target.value]
      });
      setcheckindex([...checkindex, index]);
    }
    // Case 2  : The user unchecks the box
    else {
      seteditbox({
        ids: ids.filter((e) => e !== value)
      });
      setcheckindex(checkindex.filter((e) => e !== index));
    }
    let temp_state = [...dashboarddata];
    let temp_element = { ...temp_state[index] };
    temp_element.rowcheckbox = temp_element.rowcheckbox ? false : true;
    temp_state[index] = temp_element;
    setDashboarddata(temp_state);
  };

  const removeeditbox = () => {
    seteditbox({ids: []});
    setcheckindex([]);
    let temp_state = [...dashboarddata];
    temp_state.map(element => element.rowcheckbox =  false);
    setDashboarddata(temp_state);
  };

  const archieveRows = () => {
    taskmanageService.archivetask(editbox).then((response) => {
      if(response.status == 200){
        seteditbox({ids: []});
        let state = [...dashboarddata];
        checkindex.map(element => delete state[element]);
        setDashboarddata(state);
        toast.success(response.data.message);
      }else{
        toast.error(response.data.message);
      }
    });
  }

  const recurringFlagUpdate = () => {
    taskmanageService.recurringFlagUpdate(editbox).then((response) => {
      if(response.status == 200){
        removeeditbox()
        seteditbox({ids: []});
       toast.success(response.data.message);
      }else{
        toast.error(response.data.message);
      }

    });
  }

  const handlerejeditRow = (index,type = '') => {
    if(phaseedit == true || taskedit == true ){
        let temp_state = '';
        if(type == 'response'){
          temp_state = [...queryresponselists];
        }else{
          temp_state = [...rejectionlists];
        }
        let temp_element = { ...temp_state[index] };
        temp_element.editing = true;
        temp_state[index] = temp_element;
        if(type == 'response'){
          setQueryresponselists(temp_state);
        }else{
          setRejectionLists(temp_state);
        }
    }
  };
  const handlemaineditRow = (index) => {
    if(phaseedit == true || taskedit == true){
      let temp_state = [...dashboarddata];
      let temp_element = { ...temp_state[index] };
      temp_element.mainediting = true;
      temp_state[index] = temp_element;
      setDashboarddata(temp_state);
      }

  };
  const handlephaseeditRow = (index) => {
    if(taskedit == true){
      const cloneValues = {...taskdetails};
      cloneValues.phases[index] = {
        ...cloneValues.phases[index],
        mainediting: true,
      };
    
    settaskdetails(cloneValues);
      }

  };
  const handleeditRow = (index) => {
    if(phasestatusedit == true || taskstatusedit == true){
      let temp_state = [...dashboarddata];
      let temp_element = { ...temp_state[index] };
      temp_element.editing = true;
      temp_state[index] = temp_element;
      setDashboarddata(temp_state);
      }

  };

  const handlePageClick = (e, index) => {
    e.preventDefault();
    get_list(index,null,filterFields,filtertype,hoddatatype);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_list(prev,null,filterFields,filtertype,hoddatatype);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_list(next,null,filterFields,filtertype,hoddatatype);
  };

  const getFilterList = () => {
    taskmanageService.getfilterlist().then((response) => {
      setfilterlist(response.data.filter);
      setshowfilterbox(!showfilterbox);
    })
  }
  const editRejField = (value, field, id,index,type='') => {
    taskmanageService.editRejectionRow(value, field, id,type).then((response) => {
      let temp_st = '';
      if(type == 'response'){
        temp_st = [...queryresponselists];
      }else{
        temp_st = [...rejectionlists];
      }
      let temp_ele = { ...temp_st[index] };
      if(response.status == 200){
        toast.success(response.data.message);
      }else{
          toast.error(response.data.message);
      }
      temp_ele.editing = false;
      temp_ele[field] = value;
      if (field == "status") {
        Object.entries(statuslist).filter(([k, v]) => v.id == value).reduce((acc, [k, v]) => {
          temp_ele['status_name'] = v.name;
       }, {});
       }
      temp_st[index] = temp_ele;
      if(type == 'response'){
        setQueryresponselists(temp_st);
      }else{
        setRejectionLists(temp_st);
      }
    })
  }
  const editField = (value, field, id, type, index,e='',phase='') => {
    taskmanageService.editRow(value, field, id, type).then((response) => {
    if(phase!=''){ // if task detail phase edit
        const cloneValues = {...taskdetails};
          var index1 = e.target.selectedIndex;
          if(index1){
            
            if(field=='assign_lead_id' || field == 'tl_id'){
              cloneValues.phases[index] = {
                ...cloneValues.phases[index],
                mainediting: false,
                leader : e.target[index1].text,
                assign_lead_id : value
              };
            }else{
              cloneValues.phases[index] = {
                ...cloneValues.phases[index],
                mainediting: false,
                member : e.target[index1].text,
                assign_member_id : value
              };
            }
           
          }
          settaskdetails(cloneValues);
          if(response.status == 200){
            toast.success(response.data.message);
          }else{
            toast.error(response.data.message);
          }
      }else{ // else dashboard field edit
      let temp_st = [...dashboarddata];
      let temp_ele = { ...temp_st[index] };
     
      if(response.status == 200){
          if (field == "approval") {
            if (value == 1) {
              temp_ele.approval = "Approved";
            } else if (value == 2) {
              temp_ele.approval = "Denied";
            }else{
              temp_ele.approval = "";
            }
          }
          if (field == "status") {
            Object.entries(statuslist).filter(([k, v]) => v.id == value).reduce((acc, [k, v]) => {
              temp_ele['status_name'] = v.name;
              temp_ele['status_color_code'] = v.color_code;
           }, {});
            temp_ele['status_id'] = value;
            temp_ele['approval'] = 0;
            if(value==3){
              temp_ele['flag'] = 0;
            }
            if(value==3){
              let newDate = new Date()
              let months = newDate.getMonth() + 1;
              let month = (months<10)?'0'+months:months;
              temp_ele['completed_date'] = newDate.getFullYear()+'-'+month+'-'+newDate.getDate();
            }
           }
          toast.success(response.data.message);
      }else{
          toast.error(response.data.message);
      }
      temp_ele.editing = false;
      temp_ele.mainediting = false;
      if (field != "status" && field != "approval") {
        temp_ele[field] = value;
      }
      
      // for assign user case
      if(e!='' && value!=''){
        var index1 = e.target.selectedIndex;
        if(index1){
          
          if(field=='assign_lead_id' || field == 'tl_id'){
            temp_ele.lead =  e.target[index1].text;
            temp_ele.lead_id =  value;
            
          }else{
            temp_ele.member =  e.target[index1].text;
            temp_ele.member_id =  value;
          }
         
        }
      }else{

        if(field=='assign_lead_id'){
          temp_ele.lead = 'Select Assign lead';
          temp_ele.lead_id =  value;
        }else{
          if(field != "status"){
            temp_ele.member =  'Select Assign member';
            temp_ele.member_id =  value;
          }
        }
      }

      temp_st[index] = temp_ele;
      setDashboarddata(temp_st);
      get_dashboard_data(hoddatatype);
    }
    });
  
  };


  function phaseModalCancel() {
    setCompanySelectionModal(!CompanySelectionModal);
    setInputfields(initialStateData);
    setinputPhases(initiaPhaseData);
  }

  const [taskdetails,settaskdetails] = useState({});
  const [taskdetailPopup,settaskdetailPopup] = useState(false);
  const [userid,setuserid] = useState(false);
  const taskDetail = (task_id,index,id,type) => {
    taskmanageService.gettaskdetail(task_id,id,type).then((response) => {
      settaskdetails(response.data.data);
      setDocumentLists(response.data.files);
      setRejectionLists(response.data.rejections);
      setQueryresponselists(response.data.query_responses);
      settaskdetailPopup(!taskdetailPopup);
      setuserid(response.data.data.user_id)
    })

  }

  const handleExpand = (task, index) => {
    let temp_state = [...dashboarddata];
    let temp_element = { ...temp_state[index] };
    temp_element.toggle = temp_element.toggle ? false : true;
    temp_state[index] = temp_element;
    setDashboarddata(temp_state);
  };

  const applyFilter = (e='') => {
    get_list(1,null,filterFields,filtertype,hoddatatype);
    if(e==''){
      setfilterApplyFields(filterFields);
      document.getElementById('tooltipfilter').click();
    }
  }
  const removefromFilter = (index) => {
    let state = {...filterFields};
    Object.entries(state) // converts each entry to [key, value]
      .filter(([k, v]) => v.field == index) // define the criteria to include/exclude items
      .reduce((acc, [k, v]) => {
         delete state[k];
         setfilterFields(state);
         setfilterApplyFields(state);
      }, {});
      applyFilter()
  }

 
  function filterInputHandler(e,index,op_name='') {
  const cloneValues = {...filterFields};
    if(op_name!=''){
      cloneValues[index] = {
        ...cloneValues[index],
        ['op_name']: op_name,
      };
    }
    var index1 = e.target.selectedIndex;
    if(index1){
      cloneValues[index] = {
        ...cloneValues[index],
        ['val_name']: e.target[index1].text,
      };
    }
    cloneValues[index] = {
      ...cloneValues[index],
      [e.target.name]: e.target.value,
    };

    console.log('filter data');
    console.log(cloneValues);
    setfilterFields(cloneValues);
  
  }
  function filterClickHandler(name,value,index) {
    const cloneValues = {...filterFields};
    cloneValues[index] = {
      ...cloneValues[index],
      [name]: value,
    };
    setfilterFields(cloneValues);
    // console.log(filterFields)
  }
  function amendmentModal() {
    getcompanies();
    get_analystteamleaderlist();
    setAmendmentModal(!amendmentModalOpen);
    // console.log(companieslist);
  }

  function amendmantsecond_modal() {
    setAmendmentsecondModal(!amendmentsecondModal);
    let model_opened = !amendmentsecondModal;
    // if(model_opened == false){
    //     setAmendmentdata(initial_amend);
    // }
  }

  function amenmentdatadata(e,cm='') {
    if(e!=null){
    if(cm!=''){
      setAmendmentdata({ ...amendmentdata, [cm]: e.value , ['am_company_name'] : e.label });
      setsecondpopup(true);
    }else{
      if (e.target.value != "") {
        setAmendmentdata({ ...amendmentdata, [e.target.name]: e.target.value });
        setsecondpopup(true);
      } else {
        setsecondpopup(false);
      }
    }
  }
  }

  function apply_amendment() {
    // console.log(amendmentdata);
    console.log(amendmentdata);
    // return;
    clientmanageService.create_amendment(amendmentdata).then((response) => {
      if (response.status == 200) {
        toast.success(response.data.message);
        setAmendmentModal(false);
        setAmendmentsecondModal(false);
      } else {
        toast.error(response.data.message);
      }
    });
  }

  function editclient(){
    // console.log('edit box');
    // console.log(editbox.ids[0]);
    history.push({
      pathname: "editclient/"+editbox.ids[0],
      // search: '?id='+editbox.ids[0],
    });

  }
function downloadReport(){
  console.log(filterFields)
  taskmanageService.download_report(filterFields,filtertype,hoddatatype,statesend.startDate,statesend.endDate).then((response) => {
  
  });
}

function filterallcom({type}){
   setfiltertype(type)
   setmainfilter(type);
   get_list(1,null,filterFields,type,hoddatatype);
}

function Companysearch(e){
    e.preventDefault();
    //  console.log('event target');
    //  console.log(e.target.compnayname.value);
    //  var company_data = document.getElementById('companydata').value;
    //  console.log(company_data);
}
const disablePastDate = () => {
  const today = new Date();
  const dd = String(today.getDate() + 1).padStart(2, "0") - 1;
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  console.log(yyyy + "-" + mm + "-" + dd)
  return yyyy + "-" + mm + "-" + dd;
};

const downloadfiles = () => {
   history.push('uploadedfiles/${}'); 
}
const downloadfile = (path,name,extension,task_id) => {
  taskmanageService.download_file(path,name,extension,task_id).then((response) => {
  
  });
}
const checklistdownloadfile = (path,name,extension) => {
  taskmanageService.checklist_download_file(path,name,extension).then((response) => {
  
  });
}
const [state, setState] = useState({startDate:null,endDate:null});
const [statesend, setStatesend] = useState({startDate:'',endDate:''});
const [checkstate, setchecklistState] = useState({task_id:null,list:null});
// date range
function setEndDateRange(e,index,field){
  var month = e._d.getMonth() + 1;
  var date = e._d.getDate();
  var year = e._d.getFullYear();
  var dd = date + '-' + month + '-' + year;
  setStatesend({ ...statesend,endDate: dd });
  setState({ ...state,endDate: e });

  const cloneValues = {...filterFields};
   
      cloneValues[index] = {
        ...cloneValues[index],
        ['op_name']: 'between',
        ['field'] : field,
        ['value'] : statesend.startDate + ' to ' + dd
      };
  
  
    console.log('filter data');
    console.log(cloneValues);
    setfilterFields(cloneValues);
}

function setStartDateRange(e,index){
  var month = e._d.getMonth() + 1;
  var date = e._d.getDate();
  var year = e._d.getFullYear();
  var dd = date + '-' + month + '-' + year;
  setStatesend({ ...statesend, startDate: dd })
  setState({ ...state, startDate: e })
}
function switchToType() {

  if(hoddatatype == 1){
    setphaselistflag(true);
    getphaselist();
    
    sethoddatatype(0)
    get_dashboard_data(0)
    get_list(1,null,filterFields,filtertype,0);
  }else{
    
    sethoddatatype(1)
    get_dashboard_data(1)
    get_list(1,null,filterFields,filtertype,1);
  }

  setdataloaded(true);
};

const [phaselist,setphaselist] = useState({});
const [phaselistflag,setphaselistflag] = useState(false);
function getphaselist(){
  userService.fetchPhases().then((response) => {
    if (response.status == 200) {
      console.log(response.data.phases)
      setphaselist(response.data.phases);
    } 
  });
}
const inputRef = useRef(null);
function onFileChange(e){
  setInputfields({ ...inputFields, [e.target.name]: e.target.files[0] });
}
function onRespondClick(){
  setformloader(true);
  const formData = new FormData();
  formData.append(
    "file",
    new Blob([inputFields.file], { type: inputFields.file.type }),
    inputFields.file.name || "file_1"
  );
  axios({
    method: "post",
    url: `${apiUrl}/importtasks`,
    data: formData,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "multipart/form-data" },
  })
    .then(
      function (response) {
        setInputfields(initialStateData)
        if (response.status == 200) {
          toast.success(response.data.message);
        }
        if(response.status = 201){
          toast.error(response.data.message);
        }
        inputRef.current.value = null;
        setformloader(false);
      },
      (error) => {
         inputRef.current.value = null;
        setformloader(false);
        return error.response;
      }
    )
    .catch(function (error) {});
}
const downloadformatfile = () => {
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/download_file?path=importformat.xlsx`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = 'importformat.xlsx';
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}
function inputhandlerchecklist(e,index,key) {
console.log(e)
   console.log(key+"=="+index)
  let temp_st = {...checkstate.list};
  console.log(temp_st)
  if(index=='file'){
    temp_st[key][index] = e.target.files[0];
  }else{
    temp_st[key][index] = e.target.value;
    
  }
   setchecklistState({ ...checkstate, list: temp_st });
}
const get_rejection_phase_selection = async (task_id,edittype,type=null) => {
    
  taskmanageService.getrejectionphaselists(task_id).then((response) => {
    if(type == null){
      setPhaseListsSelection(response.data.data);
    }else{
      setPhaseListsSelection([]);
    }
    let initdata = {
      task_id: task_id,
    };
    setInputfields(initdata);
    let cloneValues = [];
    if (response.data.data) {
      let pp = {};
      response.data.data.map((ele, i) => {
        if (ele.already_assign == true) {
          pp = {
            ["phase_id"]: ele.id,
            ["main_id"]: ele.main_id,
            ["assign_lead_id"]: ele.assign_lead_id,
            ["due_date"]: ele.due_date,
          };
          cloneValues[i] = pp;
        }
      });
    }
    setinputPhasesRejection(cloneValues);
  })
}
const editData = (value1, field, id, type, index,e='') => {
  if(type == 'tasks'){
    swal({
      title: "No error job",
      text: "Please select either this job is 'No Error Job' or not!",
      dangerMode: false,
      buttons : {
        cancel: "Cancel",
        yes: {
          text: "Yes, Error exists!",
          value: "yes",
        },
        no: {
          text : "No Error job!",
          value : "no"
        }
      }
    })
    .then((value) => {
      switch (value) {

        case "no":
           editField(value1, field, id, type, index,e,1)
          break;
     
        case "yes":
          openRejectionModal(
            2,
            id,
            type,
            id,index
          )
           get_rejection_phase_selection(id,type,'deny')
          break;
     
        default:
          null
      }
    
    })
  }else{
    editField(value1, field, id, type, index,e)
  }
};
const [formdetails,setformdetails] = useState({});
const [formdname,setformname] = useState({});
function getdocument(trans_id){
  taskmanageService.transitionForm(taskdetails.user_id,trans_id,taskdetails.company.sub_client).then((response) => {
    setformdetails(response.data);
    let name = (trans_id==2) ? 'Decision Making Matrix – Bookkeeping' : (trans_id==5) ? 'SOP' : 'VAT Policy';
    setformname(name);
    setotherDecisionmakingmatrixModalOpen(true);

  })
}
const [questModalOpen, setquestModalOpen] = useState(false);
function getquestform(){
  settaskdetailPopup(false);
  setquestModalOpen(true);
}
const [phasestate, setphaseState] = useState({});
const companyfilter = (e,index,field) => {
  const cloneValues = {...filterFields}; 
  cloneValues[index] = {
    ...cloneValues[index],
       field: e.target.name,
      value: e.target.value,
      operator: '='
  };
  // const cloneValues = {
  //   ...phasestate,
  //   [index]: {
  //     ...phasestate[index],
  //     field: e.target.name,
  //     value: e.target.value,
  //     operator: '='
  //   }
  // };
  console.log(cloneValues)
  setfilterFields(cloneValues);
  console.log(filterFields)
  //setphaseState(cloneValues);
  get_list(1,null,cloneValues,filtertype,hoddatatype);
  get_dashboard_data(hoddatatype,cloneValues)
  
}
function setEndDateRangeDash(e){
  var month = e._d.getMonth() + 1;
  var date = e._d.getDate();
  var year = e._d.getFullYear();
  var dd = date + '-' + month + '-' + year;
  setStatesend({ ...statesend,endDate: dd });
  setState({ ...state,endDate: e });
  get_list(1,null,filterFields,filtertype,hoddatatype,statesend.startDate, dd);
}
const [clienttransModalOpen, setClienttransModalOpen] = useState(false);
const [clienttranslists, setClienttransLists] = useState([]);
function addform(id,index,type='',main_id=''){
  setClienttransModalOpen(!clienttransModalOpen);
  taskmanageService.getclienttransByTask(id).then((response) => {
    setClienttransLists(response.data.data);
  });
}
const scrollContainerRef = useRef(null);
const scrollLeft = () => {
  if (scrollContainerRef.current) {
    scrollContainerRef.current.scrollLeft -= 100; // Adjust scroll distance as needed
  }
};

const scrollRight = () => {
  if (scrollContainerRef.current) {
    scrollContainerRef.current.scrollLeft += 100; // Adjust scroll distance as needed
  }
};

const [loading, setLoading] = useState(false);
const downloadAllfile = (userid,taskid) => {
  setLoading(true)
  taskmanageService.download_all_file(userid,taskid).then((response) => {
    setLoading(false)
  });
}
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
       
        <Row>
          {(user_info.role_id == 14) ?
        <Col xl="2">
        <Button
          className="col-12 phase-button qatl-main-btn qatl-main-btn-sec"
          color="light"
          type="button"
          onClick={() => switchToType()}
        >
          {(hoddatatype == 0) ? 'Switch To QA' : 'Switch to POD' }
        </Button>{" "}
      </Col> : null }
          {button}
          {/* {(user_info.role_id != 14) ? */}
          {phase}
          {/* :''} */}
          {todo}
          {/* {questionnaire} */}
          {/* {amendement} */}
          {/* <Col xl="6" className="">
              <input type="file" ref={inputRef} name="file" id="import_file" className="text-right" style={{width:"40%"}} onChange={onFileChange}  />
      
              <Button
                className="dash-button"
                color="default"
                type="button"
                disabled={formloader}
                onClick={onRespondClick.bind(this)}
              >
                Import Bulk jobs {formloader ? (<Formloader height={20} color='#ffffff'></Formloader>) : (<></>)}
              </Button>
              <a onClick={downloadformatfile} target="_blank" download ><i className="ni ni-cloud-download-95"></i> Format</a>
            </Col> */}
        </Row>
        {/* Buttons in row --end */}
        <br></br>

        {/* Phase selection modal --start */}
        <Row>
          <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => setPhaseSelectionModalOpen(!PhaseSelectionModalOpen)}
            isOpen={PhaseSelectionModalOpen}
          >
            <div className="modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
                Phase selection
              </h5>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => {
                  setPhaseSelectionModalOpen(!PhaseSelectionModalOpen);
                  setInputfields(initialStateData);
                }}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
            <p>Please press Enter after entering the search result</p>
            <Form onSubmit={Companysearch} className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0 col-md-12">
              <InputGroup className="input-group-alternative searchbox-container col-md-12">
                <Input placeholder="Search company..." id="companydata" name="compnayname" className="search-box" type="text" style={{fontWeight:"600",color:"black"}}
                onKeyUp={(e) => get_companieslist(e)}  
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Form><br></br>
              {tempcompanieslist && tempcompanieslist.length ? (
                tempcompanieslist.map((item, i) => (
                  <React.Fragment key={i}>
                    <Row key={i} className="d-flex justify-content-center">
                      <Button
                        type="button"
                        onClick={() => get_phase_selection(item.id)}
                      >
                        <span>
                          {item.name}({item.type})
                        </span>
                        <h6>{item.sub_client}</h6>
                      </Button>
                    </Row>
                    <br></br>
                  </React.Fragment>
                ))
              ) : (
                <p>No Data Found</p>
              )}
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>
        </Row>
        {/* Phase selection modal --end */}

        {/* Select phases for company form --start */}
        <Row>
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => setCompanySelectionModal(!CompanySelectionModal)}
            isOpen={CompanySelectionModal}
          >
            <div className="modal-header">
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => phaseModalCancel()}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <h3>Select Phases</h3>
              <h1>{isCheckedPhase_test["phase-1"]}</h1>
              {errorDiv}
              {successDiv}
              <Form
                role="form"
                id="phase-selection-form"
                onSubmit={add_phase_selection}
              >
                {phaselistsselection && phaselistsselection.length ? (
                  phaselistsselection.map((item, i) => (
                    <React.Fragment key={i}>
                      <Row key={i} className={(item.already_assign==false)?'disablerow':''}>
                        <Col md="1">
                           <FormGroup>
                            <input
                              id={`phasechecked-${i}`}
                              checked={item.already_assign}
                              type="checkbox"
                              name="phase_id"
                              value={item.id}
                              onChange={(e) => handleOnChange(e, i)}
                            ></input>{" "}
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <label id={`phase-${i}`}>{item.desc}</label>
                         
                        </Col>
                        <Col md="4">
                          <Input
                            bsSize="sm"
                            name="assign_lead_id"
                            required
                            type="select"
                            id={`phase-${i}`}
                            value={
                              item.already_assign ? item.assign_lead_id : ""
                            }
                            disabled={!item.already_assign}
                            onChange={(e) => inputphasehandler(e, i)}
                          >
                            <option key="lead_assign">Assign lead for phase</option>
                            {item.name == 'Phase 8' ? 
                            (<option key="lead_self_assign" value="complete" >Mark this phase as completed</option>)
                            : null }
                            {item.teamleader.map((teamleader) => {
                              return (
                                <option key={teamleader.id} value={teamleader.id}>
                                  {teamleader.name} ({teamleader.total_task_count})
                                </option>
                              );
                            })}
                          </Input>
                        </Col>
                        <Col md="2">
                          
                        <Input
                            bsSize="sm"
                            placeholder="Budget"
                            type="number"
                            name="budget"
                            defaultValue={item.already_assign ? item.in_budget : ''}
                            onChange={(e) => inputphasehandler(e, i)}
                            required
                            disabled={!item.already_assign}
                            inputmode="numeric"
                            />
                        </Col>
                        <Col md="3">
                          <Input
                            defaultValue={item.already_assign ? item.due_date : ""}
                            bsSize="sm"
                            name="due_date"
                            required
                            // value={item.already_assign ? item.due_date : ""}
                            placeholder="Select Due Date"
                            id="example-date-input"
                            type="date"
                            disabled={!item.already_assign}
                            onChange={(e) => inputphasehandler(e, i)}
                            // min={disablePastDate()}
                          ></Input>
                        </Col>
                      </Row>
                    </React.Fragment>
                  ))
                ) : (
                  <p>No Data Found</p>
                )}
                
              </Form>
            </ModalBody>
            <ModalFooter style={{justifyContent:'space-between'}}>
            
              <Button
                color="primary"
                type="button"
                onClick={add_phase_selection}
                disabled={disablestate}
              >
                {disablestate ? 'Sending...' : 'Apply'}
              </Button>
              <Button
                color="secondary"
                type="button"
                onClick={() => phaseModalCancel()}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Row>
        {/* Select phases for company form --end */}
        <Row className="dash-analy">
          <Col xl="2" md="6" xs="12"  className="dash-col-analy" >
           
                <Row className={(filtertype=='total' ? "align-items-center dash-inner-row active" : "align-items-center dash-inner-row")}>
                  <div className="col" onClick={() => {get_list(1,null,filterFields,'total',hoddatatype),setfiltertype('total')}}>
                    <h6 className="text-uppercase text-muted text-dash ls-1 mb-1">
                      Total
                    </h6>
                    <h1 style={{ color: "#0060B8" }}>
                      {dashboardStatistics.assign_tasks}
                    </h1>
                    <h2 className="mb-0" style={{ color: "#0060B8" }}>
                      Total Tasks
                    </h2>
                  </div>
                </Row>
             
          </Col>
          <Col xl="2" md="6" xs="12"  className="dash-col-analy" >
           
                <Row className={(filtertype=='assigned' ? "align-item-s-center dash-inner-row active" : "align-items-center dash-inner-row")}>
                  <div className="col" onClick={() => {get_list(1,null,filterFields,'assigned',hoddatatype),setfiltertype('assigned')}}>
                    <h6 className="text-uppercase text-muted text-dash ls-1 mb-1">
                      Pending
                    </h6>
                    <h1 style={{ color: "#FF1717" }}>
                      {dashboardStatistics.pending_tasks}
                    </h1>
                    <h2 className="mb-0" style={{ color: "#FF1717" }}>
                      Pending 
                    </h2>
                  </div>
                </Row>
             
          </Col>
          <Col xl="2" md="6" xs="12"  className="dash-col-analy" >
           
           <Row className={(filtertype=='yet_to_start' ? "align-item-s-center dash-inner-row active" : "align-items-center dash-inner-row")}>
             <div className="col" onClick={() => {get_list(1,null,filterFields,'yet_to_start',hoddatatype),setfiltertype('yet_to_start')}}>
               <h6 className="text-uppercase text-muted text-dash ls-1 mb-1">
                 Yet To Start
               </h6>
               <h1 style={{ color: "rgb(23 164 255)" }}>
                 {dashboardStatistics.yet_to_start}
               </h1>
               <h2 className="mb-0" style={{ color: "rgb(23 164 255)" }}>
               Yet To Start 
               </h2>
             </div>
           </Row>
        
     </Col>
          <Col xl="2" md="6" xs="12" className="dash-col-analy">
            {/* <Card className="shadow">
              <CardHeader className="bg-transparent"> */}
                <Row  className={(filtertype=='overdue' ? "align-items-center dash-inner-row active" : "align-items-center dash-inner-row")}>
                  <div className="col" onClick={() => {get_list(1,null,filterFields,'overdue'),setfiltertype('overdue')}}>
                    <h6 className="text-uppercase text-muted text-dash ls-1 mb-1">
                      Overdue
                    </h6>
                    <h1 style={{ color: "#FF8C00" }}>
                      {dashboardStatistics.overdue_tasks}
                    </h1>
                    <h2 style={{ color: "#FF8C00" }} className="mb-0">
                      Overdue 
                    </h2>
                  </div>
                </Row>
              {/* </CardHeader>
            </Card> */}
          </Col>
          <Col xl="2" md="6" xs="12" className="dash-col-analy">
             <Row  className={(filtertype=='completed' ? "align-items-center dash-inner-row active" : "align-items-center dash-inner-row")}>
                  <div className="col" onClick={() => {get_list(1,null,filterFields,'completed',hoddatatype),setfiltertype('completed')}}>
                    <h6 className="text-uppercase text-muted text-dash ls-1 mb-1">
                      Completed
                    </h6>
                    <h1 style={{ color: "#0DAF84" }}>
                      {dashboardStatistics.completed_tasks}
                    </h1>
                    <h2 style={{ color: "#0DAF84" }} className="mb-0">
                      Completed 
                    </h2>
                  </div>
                </Row>
           
          </Col>
          <Col xl="2" md="6" xs="12"className="dash-col-analy">
              <Row  className={(filtertype=='urgent' ? "align-items-center dash-inner-row active" : "align-items-center dash-inner-row")}>
                  <div className="col" onClick={() => {get_list(1,null,filterFields,'urgent',hoddatatype),setfiltertype('urgent')}}>
                    <h6 className="text-uppercase text-muted text-dash ls-1 mb-1">
                      On Hold
                    </h6>
                    <h1 style={{ color: "#FF1717" }}>
                      {dashboardStatistics.urgent_tasks}
                    </h1>
                    <h2 style={{ color: "#FF1717" }} className="mb-0">
                      On Hold 
                    </h2>
                  </div>
                </Row>
          </Col>
        </Row>


        <Row className="mt-4">
          <button type="button" style={filtertype == 'assigned' ?
          { backgroundColor:'#0A5AC2',color:'#fff',fontFamily:'inherit',margin: '7px 0px'} : {fontFamily:'inherit',margin: '7px 0px'}} onClick={() => filterallcom({type:'assigned'})} className=" btn btn-secondary text-center">
            Pending
          </button>
          {/* <button type="button" style={filtertype == 'yet_to_start' ?
          { backgroundColor:'#0A5AC2',color:'#fff',fontFamily:'inherit',margin: '7px 0px'} : {fontFamily:'inherit',margin: '7px 0px'}} onClick={() => filterallcom({type:'yet_to_start'})} className=" btn btn-secondary text-center">
            Yet to start
          </button> */}
          {(user_info.role_id == 2 || user_info.role_id == 3 || (user_info.role_id == 14 && hoddatatype == 1)) ? 
          <button type="button" style={filtertype == 'queries_sent' ?
          { backgroundColor:'#0A5AC2',color:'#fff',fontFamily:'inherit',margin: '7px 0px'} : {fontFamily:'inherit',margin: '7px 0px'}} onClick={() => filterallcom({type:'queries_sent'})}  className=" btn btn-secondary text-center">
            Queries Sent
          </button>  : null }
          <button type="button" style={filtertype == 'completed' ?
          { backgroundColor:'#0A5AC2',color:'#fff',fontFamily:'inherit',margin: '7px 0px'} : {fontFamily:'inherit',margin: '7px 0px'}} onClick={() => filterallcom({type:'completed'})} className=" btn btn-secondary text-center">
            Completed
           {(completedCount > 0) ? 
            <Badge title="Approval Pending" className="badge-circle" color="danger" size="sm" style={{"position": "absolute","right": "-8px","bottom": "-7px","height":"1.0rem","width":"1.0rem","fontSize":"10px","color": "#fff","backgroundColor": "red"}}>
              {completedCount}
            </Badge> : null }
          </button>
        </Row>
      
        <Row className="mt-4">
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="filter" style={{padding:'0 1%'}}>
               <div className="filter">
                    <h3 className="mb-0" style={{fontSize:'20px'}}>Task Master &nbsp;&nbsp;</h3><br></br>
                    <div   style={{maxWidth:'50%'}}>
                    <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                    </Button>
                    </div>
                    {phaselistflag ? 
                    <div   style={{maxWidth:'30%',marginRight:'5px',marginLeft:'5px'}}>
                    <select className="form-control" name="phase" onChange={(e) => { companyfilter(e,8,'phase');}}   >
                                  <option value="" key="al_key_1">Select Phase</option>
                                  {
                                  (phaselist) ? 
                                  Object.entries(phaselist).map(([valuel,k]) =>
                                      <option key={k} value={k.id}>
                                        {k.desc}
                                      </option>
                                      
                                  )
                                :''
                                }
                                  
                              </select>
                    </div>: ''}
                    {assignuserlist ? 
                    <div   style={{maxWidth:'30%'}}>
                    <select className="form-control" name="assignuser" onChange={(e) => { companyfilter(e,9,'assignuser');}}   >
                                  <option value="" key="al_key_1">Select User</option>
                                  {
                                  (assignuserlist) ? 
                                  Object.entries(assignuserlist).map(([valuel,k]) =>
                                      <option key={k} value={k.id}>
                                        {k.name}
                                      </option>
                                      
                                  )
                                :''
                                }
                                  
                              </select>
                    </div>: ''}
                    </div>
                    <div className="">
                    <label>Start Date</label>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Start date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setStartDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                    <div >
                      <label>End Date</label>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "End date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setEndDateRangeDash(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div >
                    <div></div>
                    <div>
                      <button onClick={(e) => getFilterList()}  type="button" className="btn-icon-clipboard" data-clipboard-text="bold-down" title="Copy to clipboard" style={{padding: 10}}>
                        <div>
                          <img alt="..." src={require("../../assets/img/brand/filter.png")} style={{height:15, width:15}}/>
                          <span style={{"fontSize":16,fontFamily:"inter"}}>Filters</span>&nbsp;
                          {(showfilterbox) ? (<i className="ni ni-bold-up"></i>) : 
                            (<i className="ni ni-bold-down"></i>)}
                        </div>
                      </button>
                    </div>
                   
                  
                </Row>
              
                {(showfilterbox== true)?
                <Row style={{paddingLeft:'1%',textAlign:'right'}}>
                  <Col xl="12">
                   {(filterApplyFields) ?
                    (Object.values(filterApplyFields)).map((t,k) => (
                        <button key={k} className="btn btn-icon btn-3 btn-primary filter-button" type="button" style={{color:'#3F3F3F'}}>
                          <span className="btn-inner--text"><b>{t.field} #</b> {t.op_name} {(t.val_name)?t.val_name:t.value}</span>
                          <span className="btn-inner--icon" onClick={() => removefromFilter(t.field)}><i className="ni ni-fat-remove"></i></span>
                        </button>
                      )):null} 
                    <button style={{color:'#3F3F3F'}} type="button" className="btn btn-icon btn-primary filter-button" data-placement="right" id="tooltipfilter" >
                        +
                    </button>
                    {(filterlist.length > 0) ?
                    (<UncontrolledPopover placement="right" target="tooltipfilter">
                      <PopoverBody>
                      {filterlist.map((filter, i) => (
                      <React.Fragment key={i}>
                      <p key="para" data-placement="right" id={`tooltipraised-${i}`} style={{cursor:"pointer"}} name="field" value={filter.field} onClick={(e) => {filterClickHandler('field',filter.field,i),setfiltertype('')}}>{filter.field}</p>
                      {/* {(filter.values.length > 0)? */}
                      <UncontrolledPopover key={i} placement="right"  target={`tooltipraised-${i}`}>
                      <PopoverBody>
                         <Form>
                         {(filter.operators).map((operator,j) => (
                         <div key={j} className="custom-control custom-radio mb-3" style={{paddingLeft:'1rem'}}>
                          <input id="customRadio5"  value={operator.symbol} name="operator" type="radio" onChange={(e) => filterInputHandler(e,i,operator.value)} />
                          {/* <label className="custom-control-label" htmlFor="customRadio5"> */}
                           &nbsp;&nbsp; {operator.value}
                          {/* </label> */}
                        </div>
                         )) }
                          <div id="textboxes" >
                        {(filter.values.length > 0)?
                          <select className="form-control" name="value" onChange={(e) => filterInputHandler(e,i)}>
                              <option value="" key="al_key_1">Select values</option>
                              {(filter.values).map((valuel,k) =>
                                  <option key={k} value={valuel.id}>
                                    {valuel.name}
                                  </option>
                              )}
                          </select>
                        : (filter.type == 'daterange') ?  
                        <>
                        <Col xs={12}>
                        <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Start date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setStartDateRange(e,i)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "End date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setEndDateRange(e,i,filter.field)}
                          />
                        </InputGroup>
                      </FormGroup>
                      </Col>
                      </>
                        :
                        <input className="form-control" type={filter.type} name="value" onChange={(e) => filterInputHandler(e,i)} />
                        }
                        <br></br>
                        <button type="button" className="col-8 dash-button btn btn-primary" onClick={(e) => applyFilter()} > Apply </button>
                        {/* <button type="button" className="col-6 phase-button  btn btn-light">Cancel</button> */}
                         </div>
                         </Form>
                      </PopoverBody>
                    </UncontrolledPopover>
                       {/* :null }  */}
                     </React.Fragment>
                      ))}
                        </PopoverBody>
                    </UncontrolledPopover>):null }   
                  
                  </Col>  
                </Row> : null }
                {(editbox.ids.length > 0)?
                <div className="edit-row row" style={{background:"#F6F6F6",margin:'1%',paddingLeft:'1%'}}> 
                  <span onClick={(e) => removeeditbox()}  style={{border: '1px solid #3F3F3F',padding:'0px 1px',borderRadius:'4px',height:'20px',marginTop:'11px'}}><i className="ni ni-fat-delete"></i></span>
                  {(permissions.includes("company-edit") == true && editbox.ids.length == 1)?
                  <span onClick={editclient}><i className="far fa-edit"></i> Edit</span> : null }
                  {(permissions.includes("task-delete") == true)?
                  <span onClick={(e) => archieveRows()}><i className="far fa-file-archive"></i> Archive</span> : null }
                   {(permissions.includes("task-edit") == true)?
                  <span onClick={(e) => recurringFlagUpdate()}><i classNzame="far fa-edit"></i> Stop Recurring</span> : null }
              </div>:null }
              <div className="scroll-div"><button className="scroll-button" onClick={scrollLeft}><i className="fa fa-angle-left"></i></button>&nbsp;&nbsp;<button className="scroll-button" onClick={scrollRight}><i className="fa fa-angle-right"></i></button></div>
              </CardHeader>

              <div className="scroll-container"  ref={scrollContainerRef}>
              <div className="scroll-content">
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                <tr>
                {(permissions.includes("company-edit") == true || permissions.includes("task-delete") == true || permissions.includes("task-edit") == true)?
                    <th></th> : null }
                    <th scope="col" className="whitespace sortclass" onClick={(e) => sortTable('name')}>Client Name</th>
                    <th scope="col" className="whitespace sortclass" onClick={(e) => sortTable('sub_client')}>Sub Client</th>
                    {/* {user_info.department == 'SME' ?  */}
                    <th>Task Type</th>
                    {/* } */}
                    {/* {user_info.department != 'SME' ? 
                    <th>Type</th>: null
                    } */}
                   
                    <th>checklist</th>
                    <th scope="col" className="whitespace">Status</th>
                    <th scope="col">Action</th>
                    <th scope="col"><i className="ni ni-chat-round"></i></th>
                    
                    {(user_info.role_id == 4 || user_info.role_id == 5 || user_info.role_id == 6 || user_info.role_id == 7 || user_info.role_id == 10 ) ? 
                    <th scope="col" className="whitespace" >Job Type</th>
                    : null }
                    {(user_info.role_id == 10 || (user_info.role_id == 14 && hoddatatype == 0)) ? 
                    <th scope="col" className="whitespace sortclass">Phase Name</th>
                    : null }
                    {/* <th scope="col">Year endDate</th>  */}
                    <th scope="col">Raised on</th>
                    {/* {user_info.role_id > 3 ? */}
                    <th scope="col">Assigned on</th> 
                    {/* : null } */}
                    <th scope="col" className="sortclass" onClick={(e) => sortTable('due_date')} >Due Date</th>
                    <th scope="col" className="sortclass" onClick={(e) => sortTable('completed_date')}>Comp. Date</th>
                    <th scope="col" className="whitespace">Raised by</th>
                    <th>Assign Lead</th> 
                    <th className="whitespace" scope="col">Assign TM</th>
                    
                    {(user_info.role_id == 3 || user_info.role_id == 2 || user_info.role_id == 11 || user_info.role_id == 14) ? 
                    <th className="whitespace">Task List</th>
                   : null}
                   <th className="whitespace">Scope of work</th>
                   <th className="whitespace">Remarks</th>
                   {(user_info.role_id == 3 || user_info.role_id == 2 || user_info.role_id == 11) ? 
                    <th className="whitespace">Analytics review</th>
                    : null}
                     {(user_info.role_id == 3 || user_info.role_id == 2 || user_info.role_id == 11 || user_info.role_id == 14) ? 
                    <>
                    <th className="whitespace">Year End</th></>
                    : null }
                     
                    {/* {(user_info.role_id >= 4 || user_info.role_id <= 7 || (user_info.role_id == 14 && hoddatatype == 0)) ?
                    <th scope="col" className="whitespace"> Budget</th> : <th scope="col" className="whitespace">QA Budget</th>
                    } */}
                    {/* <th scope="col" className="whitespace">Tot. Budget</th> */}
                    {/* <th scope="col" className="whitespace">BK. s/w</th> */}
                    
                    {(user_info.role_id == 3 || user_info.role_id == 2 ) ? 
                    <>
                    <th className="whitespace">Final info received date</th> 
                    <th className="whitespace">BK Checklist</th>
                    </>
                    : null}
                    
                    
                    {/* <th scope="col" className="sortclass" onClick={(e) => sortTable('due_date')} >Due Days</th> */}
                   
                    
                    
                   
                    
                    {/* <th>Priority</th> */}
                   
                    {((user_info.role_id >= 4 && user_info.role_id <= 7) || user_info.role_id == 10 || (user_info.role_id == 14 && hoddatatype == 0)) ?
                    <>
                    <th className="whitespace">Total Volume</th>
                    <th className="whitespace">Query Volume</th>
                    </> : null }
                   
                   
                    <th scope="col">Client Transition Log</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboarddata &&
                    dashboarddata.length &&
                    dashboarddata != null && dashboarddata != undefined ? (
                      dashboarddata.map((item, i) => (
                        item != undefined ? 
                        <React.Fragment key={i}>
                      {/* className={(item.status )?((item.status.id == 1)? 'blue-row':(item.status.id == 2)?'orange-row':'purple-row'):null } */}
                        <tr key={i} className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') } edit-type={item != undefined ? item.edittype : ''} edit-id={item != undefined ? item.id : ''}>
                        {(permissions.includes("company-edit") == true || permissions.includes("task-delete") == true || permissions.includes("task-edit") == true)?
                         <td><input type="checkbox"
                              name="id"
                              className="rowcheckbox"
                              value={item != undefined ? item.task_id : ''}
                              checked={item != undefined ? item.rowcheckbox : ''}
                              onChange={(e) => handleOnCheckboxClick(e,item != undefined ? item.edittype : '',i)} /></td> : null }
                          <td scope="row"  className="whitespace">
                            <span className="mb-0 text-sm text-bold"  style={{cursor:'pointer',fontWeight:'600'}}  onClick={() => taskDetail(item.task_id, i,item.id,item.edittype)}>
                              {item != undefined ? item.name : ''} { (item !== undefined && user_info.department !== 'SME' && item.type) ? `(${item.type})` : '' } &nbsp;&nbsp;
                            
                            </span>

                            {(item != undefined ? item.phases : '') && item.phases.length ? (
                              <Button
                                color=""
                                type="button"
                                style={{
                                  padding: "revert"
                                }}
                                onClick={() => handleExpand(item, i)}
                              >
                                {item.toggle ? <i className="fa fa-angle-up"></i> : <i className="fa fa-angle-down"></i>}
                              </Button>
                            ) : null}
                          </td>
                          <td className="whitespace">{item.sub_client}</td>
                          {/* {user_info.department == 'SME' ?  */}
                          <td>{item.type == 'Other Reports' ? (item.other_report_for_task_type) : item.type}</td> 
                          {/* } */}
                            {/* {user_info.department != 'SME' ? 
                          <td>{(item.add_type == 0) ? 'O' : 'R' }</td>
                          :null} */}
                          
                          <td>
                          <div className="icon-container" style={{"position": "relative",'width':'20px'}}>
                          {/* <a style={{height:20, width:20}} id={item.id} onClick={(e) => addreviewchecklist(item.task_id,item.phase_id,item.edittype,item.frequency)}><i class="fa fa-list-alt" aria-hidden="true"></i></a> */}
                          <a style={{ height: 20, width: 20 }}
                            id={item.id}
                            onClick={(e) => {
                              if (item.edittype == 'tasks' || user_info.department == 'SME') {
                                addreviewchecklistqa(item.task_id, i, item.edittype,item.main_id);
                              }else{
                                addreviewchecklist(item.task_id, item.phase_id, item.edittype, item.frequency);
                              }
                            }}
                          >
                            <i className="fa fa-list-alt" aria-hidden="true"></i>
                          </a>
                          </div>
                          </td>
                          {(taskstatusedit == true ||
                          phasestatusedit == true ) && (item.approval != 'Approved') ? (
                            <td className="whitespace" style={{textAlign : 'center'}}>
                            
                              {
                              (item.status_id == 1 && (!item.editing || item.editing == false))? (<img alt="..." src={require('../../assets/img/brand/inprogress.svg').default} onClick={()=> handleeditRow(i)}/>) : (' ')
                              }
                              {
                                 (item.status_id == 2 && (!item.editing || item.editing == false))? <img alt="..." src={require('../../assets/img/brand/onhold.svg').default} onClick={()=> handleeditRow(i)}/>: null
                              }
                              {
                              (item.status_id == 3 && (!item.editing || item.editing == false))? <img alt="..." src={require('../../assets/img/brand/complete.svg').default} onClick={()=> handleeditRow(i)}/>: null
                              }
                               {(item.status_id <= 3 && (!item.editing  || item.editing == false))?
                              (<span style={{color: (item.status_color_code!=null)?`${item.status_color_code}`:'#000'}}><br></br>{item.status_name}</span>)
                              : null }
                              {((item.status_id > 3 || item.status_id == null) || item.editing== true)?
                              <select
                              style={{background: (item.status_color_code!=null)?`${item.status_color_code}`:'none',color:(item.status_color_code!=null)?'#fff':'#000'}}
                                className="form-control"
                                onChange={(e) =>
                                  editField(
                                    e.target.value,
                                    "status",
                                    item.id,
                                    item.edittype,
                                    i
                                  )
                                }
                                defaultValue={item.status_id}
                              >
                                <option value="0" key="0" style={{background:'#fff',color:'#000'}}>Select Status</option>
                                {statuslist &&
                                statuslist.length &&
                                statuslist != null ? (
                                  statuslist.map((status) =>
                                  
                                      <option key={status.id} value={status.id} style={{background:`${status.color_code}`,color:'#fff'}}>
                                        {status.name}
                                      </option>
                                  )
                                ) : (
                                  <option>No data Found</option>
                                )}
                              </select>
                              : null }
                            </td>
                          ) : (
                            <td className="whitespace" style={{textAlign : 'center'}}>
                            
                            {
                            (item.status_id == 1)? (<img alt="..." src={require('../../assets/img/brand/inprogress.svg').default} />) : (' ')
                            }
                            {
                               (item.status_id == 2)? <img alt="..." src={require('../../assets/img/brand/onhold.svg').default}/>: null
                            }
                            {
                            (item.status_id == 3)? <img alt="..." src={require('../../assets/img/brand/complete.svg').default}/>: null
                            }
                             {(item.status_id >= 3)?
                            (<span style={{color: (item.status_color_code!=null)?`${item.status_color_code}`:'#000'}}><br></br>{item.status_name}</span>)
                            : null }
                            </td>
                          )}
                          {(taskapprove == true ||
                            phaseapprove == true ) &&
                          (user_info.role_id != 3 && user_info.role_id != 15) &&
                          (item.approval == "") && (item.status_id == 3 || item.status_id == 4 || item.status_id == 6) ? (
                            <td>
                            <Button
                              color="primary"
                              style={{
                                background: "#02C779",
                                border: "#02C779",
                                borderRadius: "18px",
                              }}
                              size="sm"
                              type="button"
                              onClick={(e) =>
                                editData(
                                  1,
                                  "approval",
                                  item.id,
                                  item.edittype,
                                  i
                                )
                              }
                            >
                              Approve
                            </Button>
                              { user_info.role_id == 2 ?
                              
                              <Button
                                color="primary"
                                style={{
                                  border: "1px solid #FF6430",
                                  background:'none',
                                  color: "#FF1717",
                                  borderRadius: "18px",
                                }}
                                size="sm"
                                type="button"
                                onClick={(e) =>
                                  { openRejectionModal(
                                     2,
                                     item.id,
                                     item.edittype,
                                     item.task_id,i
                                   ), get_rejection_phase_selection(item.task_id,item.edittype,'deny') }
                                 }
                              >
                                Deny
                              </Button>
                              :
                              <Button
                                color="primary"
                                style={{
                                  border: "1px solid #FF6430",
                                  background:'none',
                                  color: "#FF1717",
                                  borderRadius: "18px",
                                }}
                                size="sm"
                                type="button"
                                onClick={(e) =>
                                  editField(
                                    2,
                                    "approval",
                                    item.id,
                                    item.edittype,
                                    i
                                  )
                                }
                                
                              >
                                Deny
                              </Button>
                           
                              }
                            </td>
                          ) : (
                            item.approval != "" ? (
                            <td style={{textAlign:'center'}}>
                              <span
                                  color="primary"
                                  style={{
                                    color:
                                      item.approval == "Approved"
                                        ? "#02C779"
                                        : "#FF1717",
                                        textAlign: "center",
                                  }}
                                >
                                  {item.approval}
                                </span>
                            </td>
                            ) :
                            <td style={{textAlign:'center'}}>
                            {(item.flag > 0)?
                               (item.flag == 1 && item.edittype=='tasks' && user_info.role_id != 15 && user_info.role_id != 16)? <span onClick={() => { get_phase_selection(item.task_id),setPhaseSelectionModalOpen(true) }} style={{ color : "rgb(254, 167, 50)",cursor : 'pointer' }}>Ready for Phase<br></br> selection</span>
                               : (item.flag == 2 && item.edittype=='tasks')? <span onClick={() => { openResponseModal(item.task_id,i), get_phase_selection(item.task_id,item.edittype) }} style={{ color : "rgb(254, 167, 50)",cursor : 'pointer' }}>Add Query Response </span>
                               : (item.flag == 1 && item.edittype=='phases')? <span style={{ color : "#FF1717",cursor : 'pointer' }}>Rejected </span>
                               : (item.flag == 2 && item.edittype=='phases')? <span style={{ color : "rgb(254, 167, 50)",cursor : 'pointer' }} onClick={() => taskDetail(item.task_id, i,item.id,item.edittype)}>Query response added </span>
                               : ((item.flag == 3 && item.edittype=='tasks')) ?<span style={{ color : "#02C779" }}> Ready for review <br></br>
                               <Button
                                color="primary"
                                style={{
                                  background: "#02C779",
                                  border: "#02C779",
                                  borderRadius: "18px",
                                }}
                                size="sm"
                                type="button"
                                onClick={(e) =>
                                  editJob(
                                    1,
                                    item.id,
                                    item.edittype,
                                    i
                                  )
                                }
                              >
                                Approve
                              </Button>
                              <Button
                                color="primary"
                                style={{
                                  border: "1px solid #FF6430",
                                  background:'none',
                                  color: "#FF1717",
                                  borderRadius: "18px",
                                }}
                                size="sm"
                                type="button"
                                onClick={(e) =>
                                 { openRejectionModal(
                                    2,
                                    item.id,
                                    item.edittype,
                                    item.task_id,i
                                  ), get_rejection_phase_selection(item.task_id,item.edittype) }
                                }
                              >
                                Reject
                              </Button>
                               </span>
                            : (item.flag == 4) ?<span style={{ color : "#FF6430",cursor : 'pointer' }}>Reviewed</span>
                            : (item.flag == 5) ?<span style={{ color : "#02C779",cursor : 'pointer' }}>Completed</span> : null
                            : 
                            (item.flag == 0 && item.edittype=='phases')? <span style={{ color : "#FF6430",cursor : 'pointer' }}>
                            <Button
                                color="primary"
                                style={{
                                  border: "1px solid #FF6430",
                                  background:'none',
                                  color: "#FF6430",
                                  borderRadius: "18px",
                                }}
                                size="sm"
                                type="button"
                                onClick={(e) =>
                                 { openRejectionToQAModal(
                                    item.id,
                                    item.task_id,i
                                  ) }
                                }
                              >
                                Reject to QA
                              </Button>
                              {(user_info.role_id == 6 || user_info.role_id == 7) ? 
                              <Button
                                color="primary"
                                style={{
                                  border: "1px solid #FF6430",
                                  background:'none',
                                  color: "#FF1717",
                                  borderRadius: "18px",
                                }}
                                size="sm"
                                type="button"
                                onClick={(e) =>
                                 { openRejectionModal(
                                    2,
                                    item.task_id,
                                    'tasks',
                                    item.task_id,i
                                  ), get_phase_selection(item.task_id,'tasks',item.id) }
                                }
                              >
                                Reject To IP
                              </Button> : null }
                            </span> : null 
                            }
                            </td>
                          
                          )}
                          <td>
                          <div className="icon-container" style={{"position": "relative",'width':'20px'}}>
                          <img alt="..." src={require("../../assets/img/brand/chat-bubble.png")} style={{height:20, width:20}} id={item.id} onClick={(e) => addcommentform(item.task_id,i,item.edittype,item.id)}/>
                          {item.comment_count != null && item.comment_count > 0 ? (
                            <Badge className="badge-circle" color="danger" size="sm" style={{"position": "absolute","right": "-8px","bottom": "-7px","height":"1.0rem","width":"1.0rem","fontSize":"10px","color": "#fff","backgroundColor": "red"}}>
                              {item.comment_count}
                            </Badge>
                          ) : null}
                            </div>
                          </td>
                         
                          {(user_info.role_id == 4 || user_info.role_id == 5 || user_info.role_id == 6 || user_info.role_id == 7 || user_info.role_id == 10 ) ? 
                          
                          <td onClick={()=> handlemaineditRow(i)}>
                            {item.mainediting ? (
                                <select
                                  className="form-control"
                                  onChange={(e) =>
                                    editField(
                                      e.target.value,
                                      'job_type',
                                      item.id,
                                      item.edittype,
                                      i,e
                                    )
                                  }
                                  defaultValue={item.job_type}
                                >
                                 <option value="">Select Option</option>
                              <option  value="small">Small</option>
                              <option value="medium">Medium</option>
                              <option value="large">Large</option>
                                </select>
                           
                            ) : (
                              <span>{ item.job_type }</span>
                            )}
                            </td> 

                         
                           : null }
                          {(user_info.role_id == 10 || (user_info.role_id == 14 && hoddatatype == 0)) ? 
                          <td className="whitespace">{(item.phases_list) ? item.phases_list.desc : null}</td>
                          : null }
                          {/* <td>{formatDate(item.year_end_date)}</td>  */}
                          <td onClick={()=> handlemaineditRow(i)} >
                            {item.mainediting && item.due_date == null && (user_info.role_id==2 || user_info.role_id==3) ? (
                              <input type="date"
                              className="form-control"
                                defaultValue={item.raised_date}
                              
                                onBlur={(e) =>
                                  editField(
                                    e.target.value,
                                    "raised_date",
                                    item.id,
                                    item.edittype,
                                    i
                                  )
                                }
                              />
                            ) : (
                              <span>{formatDate(item.raised_date)}</span>
                            )}
                          </td> 
                          {/* {user_info.role_id > 3 ? */}
                          <td className="whitespace">{item.assign_date}</td>
                           {/* : null} */}
                                                  <td  onClick={()=> handlemaineditRow(i)} >
                            {item.mainediting ? (
                              <input type="date"
                              className="form-control"
                                value={item.due_date}
                              
                                onChange={(e) =>
                                  editField(
                                    e.target.value,
                                    "due_date",
                                    item.id,
                                    item.edittype,
                                    i
                                  )
                                }
                                min={disablePastDate()}
                              />
                            ) : (
                              <span>{formatDate(item.due_date)}</span>
                            )}
                          </td>
                          <td onClick={()=> handlemaineditRow(i)} >
                            {item.mainediting && item.status == 3 ? (
                              <input type="date"
                              className="form-control"
                                defaultValue={item.completed_date}
                              
                                onBlur={(e) =>
                                  editField(
                                    e.target.value,
                                    "completed_date",
                                    item.id,
                                    item.edittype,
                                    i
                                  )
                                }
                              />
                            ) : (
                              <span>{formatDate(item.completed_date)}</span>
                            )}
                          </td>
                          <td className="whitespace">{item.raised_by}</td>
                          <td className="whitespace" onClick={()=> handlemaineditRow(i)}>
                           {item.assign_leads && item.assign_leads.length && (item.mainediting || item.lead==null) ? (
                             <select
                               className="form-control"
                               onChange={(e) =>
                                 editField(
                                   e.target.value,
                                   item.assign_lead_field,
                                   item.id,
                                   item.edittype,
                                   i,e
                                 )
                               }
                               defaultValue={item.lead_id}
                             >
                               <option value="" key="asslead_key_1">Select Assign Lead</option>
                               {item.assign_leads.map((user) =>
                               
                                   <option key={user.id} value={user.id}>
                                     {user.name}
                                   </option>
                               )}
                             </select>
                           ) : (item.lead!=null)?item.lead:''}
                         </td>
                                                  
                         {taskassign == true ||
                          phaseassign == true ? (
                            <td className="whitespace" onClick={()=> handlemaineditRow(i)}>
                              {item.assign_members && item.assign_members.length && (item.mainediting || item.member==null) ? (
                                <select
                                  className="form-control"
                                  onChange={(e) =>
                                    editField(
                                      e.target.value,
                                      item.assign_field,
                                      item.id,
                                      item.edittype,
                                      i,e
                                    )
                                  }
                                  defaultValue={item.member_id}
                                >
                                  <option value="" key="assign_mem">Select Assign member</option>
                                  {item.assign_members.map((user) =>
                                   
                                      <option key={user.id} value={user.id}>
                                        {user.name} ({user.total_task_count})
                                      </option>
                                  )}
                                </select>
                              ) : (item.member!=null)?item.member:''}
                            </td>
                          ) : (
                            <td>{item.member}</td>
                          )}
                          
                          {(user_info.role_id == 3 || user_info.role_id == 2 || user_info.role_id == 11 || user_info.role_id == 14) ? 
                            <><td>{item.task_list}</td>
                       </> : null }
                       <td>{item.business_nature}</td>
                       <td onClick={()=> handlemaineditRow(i)} >
                            {item.mainediting ? (
                              <input type="text"
                              className="form-control"
                              defaultValue={item.remarks}
                              
                                onBlur={(e) =>
                                  editField(
                                    e.target.value,
                                    "remarks",
                                    item.task_id,
                                    'tasks',
                                    i
                                  )
                                }
                              />
                            ) : (
                              <span>{item.remarks}</span>
                            )}
                          </td>
                          {(user_info.role_id == 3 || user_info.role_id == 2 || user_info.role_id == 11 ) ? 
                           <td onClick={()=> handlemaineditRow(i)}>
                            {item.mainediting ? (
                                <select
                                  className="form-control"
                                  onChange={(e) =>
                                    editField(
                                      e.target.value,
                                      'review_analytics',
                                      item.id,
                                      item.edittype,
                                      i,e
                                    )
                                  }
                                  defaultValue={item.review_analytics}
                                >
                                  <option value="0" key="ra_no">No</option>
                                  <option value="1" key="ra_yes">Yes</option>
                                </select>
                           
                            ) : (
                              <span>{(item.review_analytics == 1) ? 'Yes' : 'No'}</span>
                            )}
                            </td> 
                            : null }
                            {(user_info.role_id == 3 || user_info.role_id == 2 || user_info.role_id == 11 || user_info.role_id == 14) ? 
                            <>
                        <td>{item.year_end_date}</td></> : null }
                        
                          {/* <td className="whitespace">{item.in_budget}</td> 
                          <td className="whitespace">{item.budget}</td> */}
                          {/* <td className="whitespace">{item.book_keeping_software}</td> */}
                         
                         
                          {(user_info.role_id == 3 || user_info.role_id == 2) ? 
                          <>
                          <td onClick={()=> handlemaineditRow(i)}>
                            {item.mainediting ? (
                              <input type="date"
                              className="form-control"
                                value={item.doc_received_date}
                              
                                onChange={(e) =>
                                  editField(
                                    e.target.value,
                                    "doc_received_date",
                                    item.id,
                                    item.edittype,
                                    i
                                  )
                                }
                              />
                            ) : (
                              <span>{formatDate(item.doc_received_date)}</span>
                            )}
                            </td> 
                            <td onClick={()=> handlemaineditRow(i)}>
                            {item.mainediting ? (
                                <select
                                  className="form-control"
                                  onChange={(e) =>
                                    editField(
                                      e.target.value,
                                      'bk_checklist',
                                      item.id,
                                      item.edittype,
                                      i,e
                                    )
                                  }
                                  defaultValue={item.bk_checklist}
                                >
                                  <option value="0" key="bk_no">No</option>
                                  <option value="1" key="bk_yes">Yes</option>
                                </select>
                           
                            ) : (
                              <span>{(item.bk_checklist == 0) ? 'No' : 'Yes'}</span>
                            )}
                            </td> 
                            </>
                          : null }
                          
                         
  
                          {/* <td>{(item.status_id != 3 && item.status_id != 4 && item.status_id != 6)? item.due_days : ''}</td> */}
                         


                          
                          
                          
                          {/* <td onClick={()=> handlemaineditRow(i)} >
                            {item.mainediting ? (
                              <input type="number"
                              className="form-control"
                              defaultValue={item.priority_no}
                              
                                onBlur={(e) =>
                                  editField(
                                    e.target.value,
                                    "priority_no",
                                    item.id,
                                    item.edittype,
                                    i
                                  )
                                }
                              />
                            ) : (
                              <span>{item.priority_no}</span>
                            )}
                          </td> */}
                          
                           
                          {((user_info.role_id >= 4 && user_info.role_id <= 7) || user_info.role_id == 10 || (user_info.role_id == 14 && hoddatatype == 0)) ?
                          <>
                          <td onClick={()=> handlemaineditRow(i)} >
                            {item.mainediting ? (
                              <input type="number"
                              className="form-control"
                              defaultValue={item.total_volume}
                              
                                onBlur={(e) =>
                                  editField(
                                    e.target.value,
                                    "total_volume",
                                    item.id,
                                    item.edittype,
                                    i
                                  )
                                }
                              />
                            ) : (
                              <span>{item.total_volume}</span>
                            )}
                          </td>
                          <td onClick={()=> handlemaineditRow(i)} >
                            {item.mainediting ? (
                              <input type="number"
                              className="form-control"
                              defaultValue={item.query_volume}
                              
                                onBlur={(e) =>
                                  editField(
                                    e.target.value,
                                    "query_volume",
                                    item.id,
                                    item.edittype,
                                    i
                                  )
                                }
                              />
                            ) : (
                              <span>{item.query_volume}</span>
                            )}
                          </td>
                          </>
                          : null }
                            
                          

<td>
                          <div className="icon-container" style={{"position": "relative",'width':'20px'}}>
                          <img alt="..." src={require("../../assets/img/brand/chat-bubble.png")} style={{height:20, width:20}} id={item.id} onClick={(e) => addform(item.user_id)}/>
                          {item.dmm_count != null && item.dmm_count > 0 ? (
                            <Badge className="badge-circle" color="danger" size="sm" style={{"position": "absolute","right": "-8px","bottom": "-7px","height":"1.0rem","width":"1.0rem","fontSize":"10px","color": "#fff","backgroundColor": "red"}}>
                              {item.dmm_count}
                            </Badge>
                          ) : null}
                            </div>
                          </td>

                        </tr>

                        {item.phases && item.phases.length
                          ? // <div id={"collapse"+i} className="thead-light" style={{display: item.toggle ? 'block' : 'none' }}                                >
                            item.phases.map((phase,j) => (
                              <tr key={j} className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') }
                              id={"collapse" + i}
                                style={{
                                  display: item.toggle ? "revert" : "none"
                                }}
                              >
                                {(permissions.includes("company-edit") == true || permissions.includes("task-delete") == true || permissions.includes("task-edit") == true)?
                                <td></td> : null }
                                <td scope="row">
                                  <span className="mb-0 text-sm">
                                    {phase.phases_list.desc}
                                  </span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{item.raised_by}</td>
                                <td>{item.raised_date}</td>
                                <td>{phase.due_date}</td>
                                <td></td>
                                <td>{phase.member}</td>
                                <td>{phase.completed_date}</td>
                                <td>{phase.status ? phase.status.name : ""}</td>
                                <td>{phase.approval==1 ? 'Approved' : phase.approval==2 ? 'Denied' : ''}</td>
                              </tr>
                            ))
                          : //  </div>
                           ( null)}
                        </React.Fragment>
                      : ''
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              </div>
              </div>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
        <Row>
       
          
          {/* Comment Modal Start */}
            <Modal toggle={(e) => addcommentform(item.task_id,i)} isOpen={commentModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Comment Box
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setCommentModalOpen(!commentModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <Form role="form" id="add_comment_form" onSubmit={add_comment}> 
                <FormGroup>
                <div className="col-md-12">
                   <Row> 
                     <Input type="textarea" rows="1" className="col-9" placeholder="Comment" name ="comment" onChange={inputhandlercomment}/>
                  &nbsp;&nbsp;<Button color="primary" type="button" className="col-2"  onClick={add_comment}>Send</Button>
                  </Row> 
                </div>
                <br/>
                
                <div className="col-md-12">
                  <Row style={{marginTop:"6px"}}> 
                 <b> Send to : </b>&nbsp;&nbsp;
                <input style={{maxWidth:"3%"}} type="radio" className="col-1" name="is_client" value="0" onChange={inputhandlercomment}  checked /> &nbsp;Internal team
                &nbsp;<input style={{maxWidth:"3%"}} type="radio" className="col-1" name="is_client" value="1" onChange={inputhandlercomment} /> &nbsp;Client
                  </Row> 
                </div>
                </FormGroup>
              </Form>
              <Table>
                <thead>
                  <tr>
                    <th className=" text-center">Name</th>
                    <th>Comment</th>
                  </tr>
                </thead>
                <tbody>
                {commentlists.length > 0 && commentlists  ? (
                        commentlists.map((comment, i) => (
                  <React.Fragment key={i}>
                  <tr key={i}>
                    <td className="text-center">{comment.name}</td>
                    <td>{comment.comment}</td>
                  </tr>
                  </React.Fragment>
                  ))
                 ) : (
                  <tr>
                  <td>No Data Found</td>
                  </tr>
                )}
                </tbody>
              </Table>
            </ModalBody>
          </Modal>
          {/* Comment Modal End */}
        </Row>
        {/* Response Modal Start */}
        <Modal className="taskdetailmodal" toggle={() => setshowResponse(!showResponse)} isOpen={showResponse} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Response Query
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => openResponseModal()}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <Form role="form" id="add_response_form" onSubmit={add_response}> 
              {phaselistsselection && phaselistsselection.length ? (
                 <>
                  <Row>  
				<Col md="1">
            <a onClick={(e) => addCommetbox()}>+ ADD</a>
          </Col></Row><br></br>
        {(() => {
            const arr1 = [];
            for (let k = 0; k < rowsError; k++) {
                arr1.push(
                  <>
                  <Row key={k}>
                
                      <Col>
                          <FormGroup>
                            <Input
                              size="sm"
                              name="phase_id"
                              type="select"
                              onChange={(e) => inputresponsehandler(e,k)}
                            >
                            <option>Select Phase  </option>
                              {phaselistsselection.map((item, i) => {
                                return (
                                  (item.already_assign == true)?
                                  <option value={item.main_id}>
                                  {item.desc}
                                </option>
                                  : null
                              );
                            })}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col>
                        <Input
                          size="sm"
                          type="number"
                          placeholder="Additional Budget"
                          // defaultValue="0"
                          name="budget"
                          onChange={(e) => inputresponsehandler(e,k)}
                          inputmode="numeric"
                          min="0"
                        />
                        </Col>
                        <Col>
                        <Input 
                          size="sm"
                          type="date"
                          placeholder="Select due date"
                          name="due_date"
                          onChange={(e) => inputresponsehandler(e,k)}
                          // min={disablePastDate()}
                        />
                        </Col>
                       
                          <Col md="6">
                            <Input type="textarea" rows="1"  placeholder="Comment" name="comment"  onChange={(e) => inputresponsehandler(e,k)}/>
                          </Col>
                        </Row> 
                        </>
                );
              }
              return arr1;
            })()}
            </>
                   ) : null}
              </Form>
              
            </ModalBody>
            <ModalFooter>
            
              <Button color="secondary" type="button" onClick={() => openResponseModal()}>Close</Button>
              <Button color="primary" type="button"  onClick={add_response} disabled={disablestate}
              >
                {disablestate ? 'Sending...' : 'Save'}</Button>
            </ModalFooter>
          </Modal>
          {/* Response Modal End */}
        <Row>
          <Modal
          className="taskdetailmodal"
            bsSize="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => setshowRejection(!showRejection)}
            isOpen={showRejection}
          >
            <div className="modal-header">
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => openRejectionModal()}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <h3>Add comment for rejection</h3>
              <h1>{isCheckedPhase_test["phase-1"]}</h1>
              {errorDiv}
              {successDiv}
              <Form
                role="form"
                id="phase-selection-form"
                onSubmit={add_phase_rejection_selection}
              >
                {phaselistsselection && phaselistsselection.length ? (
                 <>
                  <Row>  
				<Col md="1">
            <a onClick={(e) => addCommetbox()}>+ ADD</a>
          </Col></Row><br></br>
        {(() => {
            const arr1 = [];
            for (let k = 0; k < rowsError; k++) {
                arr1.push(
                  <Row key={k}>
                    <Col md="1">
                          <Input
                            size="sm"
                            name="actionable"
                            type="select"
                            onChange={(e) => inputrejectionhandler(e,k)}
                          >
                            <option>Actionable </option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </Input>
                        </Col> 
				  <Col md="1">
                          <FormGroup>
						  <Input
                            size="sm"
                            name="phase_id"
                            type="select"
                            onChange={(e) => inputrejectionhandler(e,k)}
                          >
                           <option>Select Phase  </option>
                             {phaselistsselection.map((item, i) => {
                              return (
                                (item.already_assign == true)?
                                <option value={item.main_id} data-id={item.id}>
                                {item.desc}
                              </option>
                                : null
                            );
							})}
                          </Input>
                            
                          </FormGroup>
                        </Col>
                  <Col md="1">
                          <Input
                            size="sm"
                            name="category_id"
                            type="select"
                            onChange={(e) => inputrejectionhandler(e,k)}
                          >
                            <option>Category </option>
                            {errorcategory.map((category) => {
                              return (
                                <option value={category.id}>
                                  {category.name}
                                </option>
                              );
                            })}
                          </Input>
                        </Col> 
                        <Col md="1">
                          <Input
                            size="sm"
                            name="amount"
                            type="number"
                            min="0"
                            placeholder="amount"
                            onChange={(e) => inputrejectionhandler(e,k)}
                          ></Input>
                        </Col> 
                        <Col md="1">
                          <Input
                            size="sm"
                            name="no_error"
                            type="number"
                            min="1"
                            placeholder="errors"
                            onChange={(e) => inputrejectionhandler(e,k)}
                          ></Input>
                        </Col>
                        <Col md="7">   
                        <>
          {(() => {
              const arr = [];
              for (let j = 0; j < errorbox[k]; j++) {
                  arr.push(
                    <>
                    <Row>
                    <Col md="3">
                    <Input
                    size="sm"
                    name={"comment"+j}
                    required
                    placeholder="Feedback"
                    id="example-date-input"
                    type="textarea"
                    onChange={(e) => inputrejectionhandler(e,k)}
                  ></Input>                  
                </Col>
                <Col md="3">
                    <Input
                    size="sm"
                    name={"root_cause"+j}
                    required
                    placeholder="Root Cause"
                    type="textarea"
                    onChange={(e) => inputrejectionhandler(e,k)}
                  ></Input>                  
                </Col>
                <Col md="2">
                    <Input
                    size="sm"
                    name={"action_plan"+j}
                    required
                    placeholder="Action Plan"
                    type="textarea"
                    onChange={(e) => inputrejectionhandler(e,k)}
                  ></Input>                  
                </Col>
                <Col md="2">
                <Input
                    size="sm"
                    name={"document"+j}
                    required
                    placeholder="Document"
                    type="file"
                    onChange={(e) => inputrejectionhandler(e,k,'file')}
                  ></Input> 
                    </Col> 
                    </Row>
                    </>
                  );
              }
              return arr;
          })()}
    </>             
                 </Col>
                 </Row>
				  );
            }
            return arr1;
            
        })()}
                 </>
                ) : (
                  <>
                <Row>  <Col md="1">
            <a onClick={(e) => addCommetbox()}>+ ADD</a>
          </Col></Row><br></br>
        {(() => {
            const arr1 = [];
            for (let k = 0; k < rowsError; k++) {
                arr1.push(
                  <Row key={k}>
                      <Col md="1">
                          <Input
                            size="sm"
                            name="actionable"
                            type="select"
                            onChange={(e) => inputtaskrejectionhandler(e,k)}
                          >
                            <option>Actionable </option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </Input>
                        </Col> 
                  <Col md="1">
                          <Input
                            size="sm"
                            name={"category_id"}
                            type="select"
                            onChange={(e) => inputtaskrejectionhandler(e,k)}
                          >
                            <option>Category </option>
                            {errorcategory.map((category) => {
                              return (
                                <option value={category.id}>
                                  {category.name}
                                </option>
                              );
                            })}
                          </Input>
                        </Col> 
                        <Col md="1">
                          <Input
                            size="sm"
                            name="amount"
                            type="number"
                            min="0"
                            placeholder="amount"
                            onChange={(e) => inputtaskrejectionhandler(e,k)}
                          ></Input>
                        </Col>  
                        <Col md="1">
                          <Input
                            size="sm"
                            name="no_error"
                            type="number"
                            min="1"
                            placeholder="errors"
                            onChange={(e) => inputtaskrejectionhandler(e,k)}
                          ></Input>
                        </Col> 
                        <Col md="8"> 
                        <>
          {(() => {
              const arr = [];
              for (let j = 0; j < errorbox[k]; j++) {
                  arr.push(
                    <>
                    <Row>
                  <Col md="4">
                 
                  <Input
                  size="sm"
                  name={"comment"+j}
                  required
                  placeholder="Feedback"
                  id="example-date-input"
                  type="textarea"
                  onChange={(e) => inputtaskrejectionhandler(e,k)}
                ></Input>
                              
                        </Col>
                        <Col md="4">
                      <Input
                      size="sm"
                      name={"root_cause"+j}
                      required
                      placeholder="Root Cause"
                      type="textarea"
                      onChange={(e) => inputtaskrejectionhandler(e,k)}
                    ></Input>                  
                  </Col>
                  <Col md="4">
                      <Input
                      size="sm"
                      name={"action_plan"+j}
                      required
                      placeholder="Action Plan"
                      type="textarea"
                      onChange={(e) => inputtaskrejectionhandler(e,k)}
                    ></Input>                  
                  </Col>
                  </Row>
                  </>
                  );
              }
              return arr;
          })()}
    </>       </Col>    
                 </Row>
                );
            }
            return arr1;
            
        })()}
    </>  
    
                )}
               
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type="button"
                onClick={add_phase_rejection_selection}
                // disabled={disablestate}
              >
                {disablestate ? 'Send back...' : 'Send'}
              </Button>
              <Button
                color="secondary"
                type="button"
                onClick={() => openRejectionModal()}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Row>

        {/* task detail popup */}
        <Row>
          <Modal
            size="lg"
            className="taskdetailmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => settaskdetailPopup(!taskdetailPopup)}
            isOpen={taskdetailPopup}
          >
            <div className="modal-header">
            <h3>Task Detail</h3>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => settaskdetailPopup(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{paddingTop:'0px'}}>
            {documentlists.length > 0 ?
            <button type="button" onClick={() => setdocumentModalOpen(true) } title="Download Documents" class="btn-icon-clipboard btn btn-secondary col-md-2" style={{textAlign:"center"}}>
              <i class="fas fa-eye" style={{fontSize:"14px"}}></i> View Documents
            </button>
            : null }
            {rejectionlists.length > 0 ?
            <button type="button" onClick={() => setrejectionModalOpen(true) } title="View Rejections" class="btn-icon-clipboard btn btn-secondary col-md-2" style={{textAlign:"center"}}>
              <i class="fas fa-eye" style={{fontSize:"14px"}}></i> View Rejections
            </button>
            : null }
            {queryresponselists.length > 0 ?
            <button type="button" onClick={() => setresponseModalOpen(true) } title="View Query Responses" class="btn-icon-clipboard btn btn-secondary col-md-3" style={{textAlign:"center"}}>
              <i class="fas fa-eye" style={{fontSize:"14px"}}></i> View Query Responses
            </button>
            : null }
             {taskdetails.user_id != null ?
            <button type="button" onClick={() => setotherInfoModalOpen(true) } title="Other Info" class="btn-icon-clipboard btn btn-secondary col-md-3" style={{textAlign:"center"}}>
              <i class="fas fa-eye" style={{fontSize:"14px"}}></i> Client Other Info
            </button>
            : null }
            <button type="button" onClick={() => getdocument(2)} title="Decision making matrix" class="btn-icon-clipboard btn btn-secondary col-md-3" style={{textAlign:"center"}}>
              <i class="fas fa-eye" style={{fontSize:"14px"}}></i> Decision making matrix
            </button>
            <button type="button" onClick={() => getdocument(4) } title="VAT Policy" class="btn-icon-clipboard btn btn-secondary col-md-2" style={{textAlign:"center"}}>
              <i class="fas fa-eye" style={{fontSize:"14px"}}></i> VAT Policy
            </button>
            <button type="button" onClick={() => getdocument(5) } title="SOP" class="btn-icon-clipboard btn btn-secondary col-md-1" style={{textAlign:"center"}}>
              <i class="fas fa-eye" style={{fontSize:"14px"}}></i> SOP
            </button>
            <button type="button" onClick={() => getquestform(6) } title="Questionnaire" class="btn-icon-clipboard btn btn-secondary col-md-3" style={{textAlign:"center"}}>
              <i class="fas fa-eye" style={{fontSize:"14px"}}></i> Questionnaire
            </button>
          {(taskdetails.company)?  <p><b>Book Keeping Software :</b> {taskdetails.company.book_keeping_software} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <b>Invoice Processing Software</b> : {taskdetails.company.account_prod_software} &nbsp;&nbsp;&nbsp; <b>Frequency</b> : {taskdetails.frequency}</p>  :null }
              <Table>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th className="whitespace">Assigned Leader</th>
                    <th className="whitespace">Assigned Member</th>
                    <th>Budget</th>
                    <th>Assign date</th>
                    <th>Due date</th>
                    <th>Start date</th>
                    <th>Comp. date</th>
                    <th>Total Vol.</th>
                    <th>Query Vol.</th>
                    <th className="whitespace">Status</th>
                    <th>Approval</th>
                  </tr>
                </thead>
                <tbody>
                      {taskdetails.phases && taskdetails.phases.length
                          ?                               
                          taskdetails.phases.map((phase,d) => (
                              <tr key={d} className={((d % 2 == 0)? 'purple-row': 'orange-row' )}>
                                <td className="whitespace">{phase.phases_list.desc}</td>
                                <td className="whitespace" onClick={()=> handlephaseeditRow(d)}>
                                  {phase.assign_leads && phase.assign_leads.length && (phase.mainediting ) ? (
                                    <select
                                      className="form-control"
                                      onChange={(e) =>
                                        editField(
                                          e.target.value,
                                          'assign_lead_id',
                                          phase.id,
                                          'phases',
                                          d,e,'phaseedit'
                                        )
                                      }
                                      defaultValue={phase.assign_lead_id}
                                    >
                                      <option value="" key="asslead_key_1">Select Assign Lead</option>
                                      {phase.assign_leads.map((user) =>
                                      
                                          <option key={user.id} value={user.id}>
                                            {user.name}
                                          </option>
                                      )}
                                    </select>
                                  ) : (phase.leader!=null)?phase.leader:''}
                                </td>
                                <td className="whitespace" onClick={()=> handlephaseeditRow(d)}>
                                  {phase.assign_members && phase.assign_members.length && (phase.mainediting ) ? (
                                    <select
                                      className="form-control"
                                      onChange={(e) =>
                                        editField(
                                          e.target.value,
                                          'assign_member_id',
                                          phase.id,
                                          'phases',
                                          d,e,'phaseedit'
                                        )
                                      }
                                      defaultValue={phase.assign_member_id}
                                    >
                                      <option value="" key="asslead_key_1">Select Assign Member</option>
                                      {phase.assign_members.map((user) =>
                                      
                                          <option key={user.id} value={user.id}>
                                            {user.name}
                                          </option>
                                      )}
                                    </select>
                                  ) : (phase.member!=null)?phase.member:''}
                                </td>
                                <td>{phase.in_budget}</td>
                                <td className="whitespace" onClick={()=> handlephaseeditRow(d)} >
                            {phase.mainediting  ? (
                              <input type="date"
                              className="form-control"
                                defaultValue={phase.assign_date}
                              
                                onBlur={(e) =>
                                  editField(
                                    e.target.value,
                                    "created_at",
                                    phase.id,
                                    'phases',
                                    d,e,'phaseedit'
                                  )
                                }
                              />
                            ) : (
                              <span>{phase.assign_date}</span>
                            )}
                          </td>
                                {/* <td className="whitespace">{phase.assign_date}</td> */}
                                <td>{phase.due_date}</td>
                                <td>{phase.start_date}</td>
                                <td className="whitespace">{phase.completed_date}</td>
                                <td>{phase.total_volume}</td>
                                <td>{phase.query_volume}</td>
                                <td  className="whitespace">{phase.status ? phase.status.name : ""}</td>
                                <td>{phase.approval==1 ? 'Approved' : phase.approval==2 ? 'Denied' : ''}
                                    {phase.flag==1 ? 'Rejected' : ''}
                                </td>
                              </tr>
                            ))
                          :
                           ( <p>No Data Found</p> )}
                </tbody>
              </Table>
            </ModalBody>
           </Modal>
           
           <Modal className="taskdetailmodal"  isOpen={documentModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Documents 
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setdocumentModalOpen(!documentModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <Table>
              <thead>
                  <tr>
                    <th className="whitespace">Document Checklist</th>
                    <th className="whitespace">Remarks</th>
                    <th className="whitespace">Document</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                {documentlists.length > 0 && documentlists  ? (
                        documentlists.map((document, i) => (
                  <React.Fragment key={i}>
                  <tr key={i}>
                    <td className="whitespace" >{document.doc_type}</td>
                    <td className="whitespace">{document.remark}</td>
                    <td className="whitespace"><a onClick={() => downloadfile(document.doc_path,document.file_name,document.extension,taskdetails.id)} download>Download</a></td>
                    <td className="whitespace">{document.date}</td>
                  </tr>
                  </React.Fragment>
                  ))
                 ) : (
                  <tr>
                  <td>No Data Found</td>
                  </tr>
                )}
                </tbody>
              </Table>
            </ModalBody>
          </Modal>

          <Modal className="taskdetailmodal"  isOpen={rejectionModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Rejections 
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setrejectionModalOpen(!rejectionModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <Table>
              <thead>
                  <tr>
                    <th className="whitespace">Rejection for</th>
                    <th className="whitespace">Actionable</th>
                    <th className="whitespace">Phase</th>
                    <th className="whitespace">Category</th>
                    <th className="whitespace">Amount</th>
                    <th className="whitespace">No. of errors</th>
                    <th className="whitespace">Feedback</th>
                    <th className="whitespace">Root cause</th>
                    <th className="whitespace">Action plan</th>
                    <th className="whitespace">Status</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                {rejectionlists.length > 0 && rejectionlists  ? (
                        rejectionlists.map((rejection, i) => (
                  <React.Fragment key={i}>
                  <tr key={i} className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') }>
                    <td className="whitespace">{rejection.type}</td>
                    <td className="whitespace">{(rejection.actionable == 1) ? 'Yes' : 'No'}</td>
                    <td className="whitespace">{rejection.phase_name}</td>
                    <td className="whitespace">{rejection.cat_name}</td>
                    <td className="whitespace">{rejection.amount}</td>
                    <td className="whitespace">{rejection.no_error}</td>
                    <td className="whitespace">{rejection.comment}</td>
                    <td className="whitespace">{rejection.root_cause}</td>
                    <td className="whitespace">{rejection.action_plan}</td>
                    <td className="whitespace" onClick={()=> handlerejeditRow(i)}>
                    {rejection.editing ? (
                    <select
                                className="form-control"
                                onChange={(e) =>
                                  editRejField(
                                    e.target.value,
                                    "status",
                                    rejection.id,
                                    i
                                  )
                                }
                                defaultValue={rejection.status}
                              >
                                <option value="0" key="0" >Select Status</option>
                                {statuslist &&
                                statuslist.length &&
                                statuslist != null ? (
                                  statuslist.map((status) =>
                                  
                                      <option key={status.id} value={status.id} >
                                        {status.name}
                                      </option>
                                  )
                                ) : (
                                  <option>No data Found</option>
                                )}
                              </select> ) : (
                                <p>{rejection.status_name}</p>
                              )} 
                              </td>
                  <td className="whitespace">{rejection.date}</td>
                  </tr>
                  </React.Fragment>
                  ))
                 ) : (
                  <tr>
                  <td>No Data Found</td>
                  </tr>
                )}
                </tbody>
              </Table>
            </ModalBody>
          </Modal>
          <Modal className="taskdetailmodal"  isOpen={documentModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Documents 
              </h5>
              
              <button aria-label="Close" className=" close" type="button" onClick={() => setdocumentModalOpen(!documentModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
              
            </div>
            
            <ModalBody>
            {(loading == true) ? 
                      <a style={{color:"#2FC3B9"}}  >Downloading please wait.. </a>
                      :
                      <button type="button" onClick={() => downloadAllfile(taskdetails.user_id,taskdetails.id)} title="Download All Documents" class="btn-icon-clipboard btn btn-secondary col-md-3" style={{textAlign:"center"}}>
                      <i class="fas fa-download" style={{fontSize:"14px"}}></i> Download All Documents
                    </button>
}
              <Table>
              <thead>
                  <tr>
                    <th className="whitespace">Document Checklist</th>
                    <th className="whitespace">Remarks</th>
                    <th className="whitespace">Document</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                {documentlists.length > 0 && documentlists  ? (
                        documentlists.map((document, i) => (
                  <React.Fragment key={i}>
                  <tr key={i}>
                    <td className="whitespace">{document.doc_type}</td>
                    <td className="whitespace">{document.remark}</td>
                    <td className="whitespace"><a onClick={() => downloadfile(document.doc_path,document.file_name,document.extension,taskdetails.id)} download>Download</a></td>
                    <td className="whitespace">{document.date}</td>
                  </tr>
                  </React.Fragment>
                  ))
                 ) : (
                  <tr>
                  <td>No Data Found</td>
                  </tr>
                )}
                </tbody>
              </Table>
            </ModalBody>
          </Modal>

          <Modal className="otherinfomodal"  isOpen={otherInfoModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              All Info 
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setotherInfoModalOpen(!otherInfoModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <p> Client Manager : {taskdetails.client_manager}</p><br></br>
              <p> Whether VAT register : {(taskdetails.vat_register == 1) ? 'YES' : 'NO'}</p><br></br>
              <p> VAT Scheme : {taskdetails.vat_scheme}</p><br></br>
              <p> Point of contact : {taskdetails.point_of_contact}</p><br></br>
              <p> Whether Bookkeeping Done : {(taskdetails.bookkeeping_done == 1) ? 'YES' : 'NO'}</p><br></br>
              <p> Whether to be completed bookkeeping? : {(taskdetails.need_bookkeeping == 1) ? 'YES' : 'NO'}</p><br></br>
              <p> Whether Account Production Done : {(taskdetails.account_production_done == 1) ? 'YES' : 'NO'}</p><br></br>
              <p> Whether YE Journals to be accounted in Bkg Software? : {(taskdetails.ye_journals == 1) ? 'YES' : 'NO'}</p><br></br>
              <p> Access Details : {taskdetails.access_details}</p><br></br>
              <p> Priority No. : {taskdetails.priority_no}</p><br></br>
              <p> Plan : {taskdetails.plan}</p><br></br>
              <p> Last document updated date : {taskdetails.last_doc_update_date}</p><br></br>
            </ModalBody>
          </Modal>
          
          <Modal className="taskdetailmodal"  isOpen={responseModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Query Responses 
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setresponseModalOpen(!responseModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <Table>
              <thead>
                  <tr>
                    <th className="whitespace">Phase</th>
                    <th className="whitespace">Due date</th>
                    <th className="whitespace">Additional Budget</th>
                    <th className="whitespace">Comment</th>
                    <th className="whitespace">Status</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                {queryresponselists.length > 0 && queryresponselists  ? (
                        queryresponselists.map((response, i) => (
                  <React.Fragment key={i}>
                  <tr key={i} className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') }>
                    <td className="whitespace">{response.phase_name}</td>
                    <td className="whitespace">{response.due_date}</td>
                    <td className="whitespace">{response.budget}</td>
                    <td className="whitespace">{response.comment}</td>
                    <td className="whitespace" onClick={()=> handlerejeditRow(i,'response')}>
                    {response.editing ? (
                    <select
                                className="form-control"
                                onChange={(e) =>
                                  editRejField(
                                    e.target.value,
                                    "status",
                                    response.id,
                                    i,
                                    'response'
                                  )
                                }
                                defaultValue={response.status}
                              >
                                <option value="0" key="0" >Select Status</option>
                                {statuslist &&
                                statuslist.length &&
                                statuslist != null ? (
                                  statuslist.map((status) =>
                                  
                                      <option key={status.id} value={status.id} >
                                        {status.name}
                                      </option>
                                  )
                                ) : (
                                  <option>No data Found</option>
                                )}
                              </select> ) : (
                                <p>{response.status_name}</p>
                              )} 
                              </td>
                  <td className="whitespace">{response.date}</td>
                  </tr>
                  </React.Fragment>
                  ))
                 ) : (
                  <tr>
                  <td>No Data Found</td>
                  </tr>
                )}
                </tbody>
              </Table>
            </ModalBody>
          </Modal>

          <Modal  className="taskdetailmodal"  toggle={(e) => addreviewchecklist(item.task_id,i)} isOpen={reviewChecklist} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Review Checklist
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setReviewChecklistModalOpen(!reviewChecklist)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <Form role="form" ref={formRef} id="add_checklist_form" > 
              
              <Table style={{width:"100%"}}>
                <thead>
                  <tr>
                    <th >Sr. No.</th>
                    <th >Review Checklist</th>
                    <th >Status</th>
                    <th >Remarks</th>
                    <th >Upload</th>
                  </tr>
                </thead>
                <tbody>
                {checklistData.length > 0 && checklistData  ? (
                        checklistData.map((list, i) => (
                <tr>
                  <td>{list.id}</td>
                  <td>
                  {list.is_bold ? (
                  <b>{list.check_review}</b>
                  ) :
                 
                    (list.master_id!=3 ) ? (
                     <span>{list.check_review}</span> 
                    ): <Input type="text" defaultValue={list.check_review}  placeholder="name" name ="check_review" 
                    onChange={(e) =>
                      inputhandlerchecklist(
                        e,
                        "check_review",
                        i
                      )} />}
                      </td>
                    
                  <td >
                  {!list.is_bold ? (
                  <select
                                  className="form-control"
                                  onChange={(e) =>
                                    inputhandlerchecklist(
                                      e,
                                      "status",
                                      i
                                    )} 
                                  name="status"
                                  defaultValue={list.status}
                                >
                                  <option value="0" key="rc_no">No</option>
                                  <option value="1" key="rc_yes">Yes</option>
                                  <option value="" key="rc_no_yes">N/A</option>
                                </select>
                  ) : ''}
                  </td>
                  <td>
                  {!list.is_bold ? (
                    <Input type="textarea" placeholder="Remarks" name ="remark"   defaultValue={list.remark}
                    onChange={(e) =>
                      inputhandlerchecklist(
                        e,
                        "remark",
                        i
                      )} />
                  ):''}
                  </td>
                  {list.is_upload ? (
                <td><input type="file"  name="file" id="import_file" className="text-right"  onChange={(e) =>
                  inputhandlerchecklist(
                    e,
                    "file",
                    i
                  )}  />
                  {list.upload_file ? (
                  <a style={{cursor:"pointer",color:"#2FC3B9"}} onClick={() => checklistdownloadfile(list.upload_file,list.file_name,list.ext)}>Download</a>
                  ):''}
                  </td>
                  ) : <td></td>
                }
                </tr>
                  ))
                  ) : (
                   <tr>
                   <td>No Data Found</td>
                   </tr>
                 )}
                
            
                </tbody>
              </Table>
              </Form>
              <Button color="primary" type="button" onClick={add_checklist_data} disabled={disablestate}>
            {disablestate ? 'Sending...' : 'Save'} 
              </Button>
            </ModalBody>
          </Modal>

          <Modal className="otherinfomodal" style={{'maxWidth' : '900px'}}  isOpen={decesionmakingmatrixModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              {formdname}
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setotherDecisionmakingmatrixModalOpen(!decesionmakingmatrixModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
            <Form
                role="form"
                id="phase-selection-form"
              
            
              >
              <Table  className="align-items-center table-flush" responsive>
                <thead>
                  <tr style={{border : "1px solid #000"}}>
                    <th style={{border : "1px solid #000"}}>Sr.No.</th> 
                    <th style={{border : "1px solid #000"}} className="whitespace">Particulars</th>
                    <th style={{border : "1px solid #000"}} className="whitespace">{(formdname == 'SOP') ? 'Area' : 'scenario' }</th>
                    {(formdname != 'SOP') ? <th style={{border : "1px solid #000"}} className="whitespace">Procedures</th> : null }
                    <th style={{border : "1px solid #000"}} className="whitespace">Client Remarks</th>
                  </tr>
                </thead>
                <tbody>
                

      
      {(formdetails && Object.keys(formdetails).length > 0) ? (
         Object.entries(formdetails).map(([key, value],index) =>
      
           
           <>
             {value.map((g,i) => (
            <tr className="whitespace" style={{border : "1px solid #000"}}>
              {(i ==0)?
              <><td style={{border : "1px solid #000"}}>{index+1}</td><td style={{border : "1px solid #000"}}>{key}</td></>:<><td style={{border : "1px solid #000"}}></td><td style={{border : "1px solid #000"}}></td></>}
              
             <td className="whitespace" style={{border : "1px solid #000"}}>{g.scenerio}</td>
             {(formdname != 'SOP') ?
             <td className="whitespace" style={{border : "1px solid #000"}}>{g.procedures}</td>
            : null }
             <td className="whitespace" style={{border : "1px solid #000"}}>{g.remarks}</td>
             </tr>
           ))}
           </>
        
       )
      ) : (<tr><td colSpan={4}>No Data found...</td></tr>)
      }
    


                        
                </tbody>
              </Table>
              </Form>
            </ModalBody>
          </Modal>

          <Modal  className="taskdetailmodal"  toggle={(e) => addreviewchecklistphase(item.task_id,i)} isOpen={reviewChecklistphase} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Phase Review Checklist
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setReviewChecklistphaseModalOpen(!reviewChecklistphase)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <Form role="form"  id="add_checklistphase_form" > 
              
              <table style={{width:"100%"}}>
                <thead>
                  <tr>
                    <th style={{width:'5%'}}>Sr. No.</th>
                    <th style={{width:'50%'}}>Review Checklist</th>
                    <th style={{width:"10%"}}>Status</th>
                    <th style={{width:"10%px"}}>Remarks</th>
                    
                  </tr>
                </thead>
                <tbody>
                {checklistData.length > 0 && checklistData  ? (
                        checklistData.map((list, i) => (
                <tr>
                  <td>{list.id}</td>
                  <td>
                  {list.is_bold ? (
                  <b>{list.check_review}</b>
                  ) :
                 
                    
                     <span>{list.check_review}</span> 
                  }
                      </td>
                    
                  <td >
                  {((!list.is_bold && user_info.phase_id==1) || user_info.phase_id==2)  ? (
                  <select
                                  className="form-control"
                                  onChange={(e) =>
                                    inputhandlerchecklist(
                                      e,
                                      "status",
                                      i
                                    )} 
                                  name="status"
                                  defaultValue={(list.status) ? list.status : 0}
                                >
                                  <option value="0" key="rc_no">Not Done</option>
                                  <option value="1" key="rc_yes">Done</option>
                                  <option value="2" key="rc_no_yes">N/A</option>
                                </select>
                  ) : ''}
                  </td>
                  <td>
                  {((!list.is_bold && user_info.phase_id==1) || user_info.phase_id==2)  ? (
                    <Input type="textarea" placeholder="Remarks" name ="remark"   defaultValue={list.remark}
                    onChange={(e) =>
                      inputhandlerchecklist(
                        e,
                        "remark",
                        i
                      )} />
                  ):''}
                  </td>
                  
                </tr>
                  ))
                  ) : (
                   <tr>
                   <td>No Data Found</td>
                   </tr>
                 )}
                
            
                </tbody>
              </table>
              </Form>
              <Button color="primary" type="button" onClick={add_checklist_data} disabled={disablestate}>
            {disablestate ? 'Sending...' : 'Save'} 
              </Button>
            </ModalBody>
          </Modal>
          {/* questform */}
          <Modal className="questmodal" style={{'maxWidth' : '1200px'}}  isOpen={questModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Questionnaire
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setquestModalOpen(!questModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
            <Questionnairecomponent client_id={taskdetails.user_id} sub_client={taskdetails.company?.sub_client}/>
            </ModalBody>
          </Modal>
          {/* questformend */}
          {/* Client Trans Modal Start */}
          <Modal  isOpen={clienttransModalOpen} size="lg" scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Details
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setClienttransModalOpen(!clienttransModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <Table>
                <thead>
                  <tr>
                    
                    <th>Client Name</th>
                    <th>Transition Name</th>
                    <th>Field Name</th>
                    <th>Scenerio</th>
                    <th>Procedures</th>
                    <th>Old Remark</th>
                    <th>New Remark</th>
                  </tr>
                </thead>
                <tbody>
                {clienttranslists.length > 0 && clienttranslists  ? (
                        clienttranslists.map((item, i) => (
                  <React.Fragment key={i}>
                  <tr key={i}>
                  
                    <td className="text-center">{item.client_name}</td>
                    <td className="text-center">{item.trans_name}</td>
                    <td className="text-center">{item.field_name}</td>
                    <td className="text-center">{item.scenerio}</td>
                    <td className="text-center">{item.procedures}</td>
                    <td className="text-center">{item.old_value}</td>
                    <td className="text-center">{item.new_value}</td>
                  </tr>
                  </React.Fragment>
                  ))
                 ) : (
                  <tr>
                  <td>No Data Found</td>
                  </tr>
                )}
                </tbody>
              </Table>
            </ModalBody>
           
          </Modal>
          {/* Client trans Modal End */}
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  name: state.companysearch.name,
});

export default connect(mapStateToProps)(Dashboard);