import { useState, useEffect,useRef } from "react";
import { useParams } from "react-router-dom";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { toast } from 'react-toastify';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import Select from 'react-select';
const axios = require("axios");
import jsPDF from "jspdf";
import "jspdf-autotable";

const EditInternalGovernanceCall = (props) => {
  
  const [clientdata, setClientdata] = useState([]);
  const [clientsearch, setclientsearch] = useState([{'label':'All Clients',value:'al_key_1'}]);
  const [disablestate, setdisablestate] = useState(false);
  const [userclientlist, setusersclientlist] = useState({'users':''});

  const [disablestatebtn, setdisablestatebtn] = useState(false);
useEffect(() => {
  get_unique_companieslist();
  get_user_client_list();
  getsingledata();
  get_user_list();
}, []);

const months = [{value:'1',label : 'January'},{value:'2',label :'February'},{value:'3',label :'March'},{value:'4',label :'April'},{value:'5',label :'May'},{value:'6',label :'June'},{value:'7',label :'July'},{value:'8',label :'August'},{value:'9',label :'September'},{value:10,label :'October'},{value:11,label :'November'},{value:12,label :'December'}];
const [monthdata, setmonthdata] = useState(months);
let { id } = useParams();
const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
function get_unique_companieslist(){
  clientmanageService.getuniqueclient().then((response)=>{
    setUniqueCompaniesData(response.data);
  });
}

function get_user_client_list(){
  clientmanageService.getusersclientlist().then((response)=>{
    console.log(response.data.users)
    //alert("d")
    setusersclientlist(response.data.users);
  });
}
const ratings = [
  { value: '5', label: 'Very Happy' },
  { value: '4', label: 'Happy' },
  { value: '3', label: 'Neutral' },
  { value: '2', label: 'Not Happy' },
  { value: '1', label: 'Unhappy' },
];

const getRatingLabel = (ratingValue) => {
  const selectedRating = ratings.find(rating => rating.value == ratingValue);
  return selectedRating ? selectedRating.label : '';
};

const [userlist, setUserListData] = useState([]);
function get_user_list(){
    clientmanageService.getusers().then((response) => {
    setUserListData(response.data)
  });
}
const initialData = {
  client_id : '',
  month : '',
  action_items : [],
  notes : [],
  client_attendees : [],
  corient_attendees : [],
  delete_action_item:[]
}
const [inputFields, setInputfields] = useState(initialData);


function getsingledata(){
  clientmanageService.getinternalgovernance(id).then((response) => {
    setInputfields(response.data.data)
    setRateBox(response.data.data.action_items.length)
    setNoteBox(response.data.data.notes.length)
    setCorientBox(response.data.data.corient_attendees.length)
    setClientBox(response.data.data.client_attendees.length)
    setOriginalRateBox(response.data.data.action_items.length)
   
  })
}
console.log(inputFields)
 function inputhandler1(e,field) {
  setInputfields((prevInputFields) => ({
    ...prevInputFields,
    [field]: e.value,
  }));

}
function inputhandler(e) {
  let phseup = {}
  phseup = { ...inputFields, [e.target.name]: e.target.value }    
  setInputfields(phseup);
}
  function setclient(e){ 
    setclientsearch(e.label);
    setClientdata([]);
    setInputfields((prevInputFields) => ({
      ...prevInputFields,
      ['client_id']: e.value,
    }));
   
  }

  const [ratebox, setRateBox] = useState(0);
  const [originalratebox, setOriginalRateBox] = useState(0);
  const addRatebox = ()=>{
    const setdata = ratebox+1;
    setRateBox(setdata)
   
  }
  const removeRatebox = (index,id=0)=>{
    
    if (id) {
      const action_items = [...inputFields.action_items];
      action_items[index].deleted = true;
      setInputfields({ ...inputFields, action_items,delete_action_item: [...inputFields.delete_action_item, id] });

     
    }else{
      if(ratebox > 0){
        const setdata = ratebox-1;
        setRateBox(setdata)
      }
    }
    console.log(inputFields)
  
    

  }
  const [notebox, setNoteBox] = useState(0);
 
  const addNotebox = ()=>{
    const setdata = notebox+1;
    setNoteBox(setdata)
   
  }
  const removeNotebox = ()=>{
    if(notebox > 0){
      const setdata = notebox-1;
      setNoteBox(setdata)
    }
  }
  const [corientbox, setCorientBox] = useState(0);
 
  const addCorientbox = ()=>{
    const setdata = corientbox+1;
    setCorientBox(setdata)
   
  }
  const removeCorientbox = ()=>{
    if(corientbox > 0){
      const setdata = corientbox-1;
      setCorientBox(setdata)
    }
  }
  
  function inputcorienthandler(e, index) {
    const { name, value } = e.target;
    const corient_attendees = [...inputFields.corient_attendees];
    if (!corient_attendees[index]) {
      corient_attendees[index] = {}; // Initialize the user object if it doesn't exist
    }
    corient_attendees[index] = value;
    setInputfields({ ...inputFields, corient_attendees });
  }

  const [clientbox, setClientBox] = useState(0);
 
  const addClientbox = ()=>{
    const setdata = clientbox+1;
    setClientBox(setdata)
   
  }
  const removeClientbox = ()=>{
    if(clientbox > 0){
      const setdata = clientbox-1;
      setClientBox(setdata)
    }
  }
  
  function inputclienthandler(e, index) {
    const { name, value } = e.target;
    const client_attendees = [...inputFields.client_attendees];
    if (!client_attendees[index]) {
      client_attendees[index] = {}; // Initialize the user object if it doesn't exist
    }
    client_attendees[index] = value;
    setInputfields({ ...inputFields, client_attendees });
  }

  function inputactionhandler(e, index) {
    const { name, value } = e.target;
    const action_items = [...inputFields.action_items];
    if (!action_items[index]) {
      action_items[index] = {}; // Initialize the user object if it doesn't exist
    }
    action_items[index][name] = value;
    setInputfields({ ...inputFields, action_items });
  }
  function inputnotehandler(e, index) {
    const { name, value } = e.target;
    const notes = [...inputFields.notes];
    if (!notes[index]) {
      notes[index] = {}; // Initialize the user object if it doesn't exist
    }
    notes[index][name] = value;
    setInputfields({ ...inputFields, notes });
  }

  function editgovernancecall(e){
    let flag = e.target.value;
    e.preventDefault();
    const confirmAndExecute = () => {
      swal({
        title: "Are you sure?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((isConfirm) => {
        if (isConfirm) {
          executeAction();
        }else{setdisablestatebtn(false)}
      });
    };
    const executeAction = () => {
   
    clientmanageService.editinternalgovernancetransdata(inputFields,flag).then((response)=>{
      if(response.status == 200){
         toast.success(response.data.message); 
      }else{
        console.log(response.data);
         toast.error(response.data.message);
      }
      setdisablestate(false)
    });
  };
    if (flag == 1) {
      setdisablestatebtn(true);
      confirmAndExecute();
    } else {
      setdisablestate(true);
      executeAction();
    }
  }
  const getMonthName = (monthValue) => {
   
    const selectedMonth = months.find(month => month.value == monthValue);
    return selectedMonth ? selectedMonth.label : '';
  };
   // State for controlling PDF generation
   const [pdfData, setPdfData] = useState(null);

   // Function to handle PDF generation
   const generatePdf = () => {
    try {
      const doc = new jsPDF();
     // const selectedMonthName = getMonthName(inputFields.month);

    //   const selectedRatingLabel = getRatingLabel(inputFields.rating);
      // Add content to the PDF
      doc.text(`Governance Call month : ${inputFields.month}`, 20, 20);
  
      let currentHeight = 60; // Initial height for the first page
  
      // Function to add attendees to the PDF
      const addAttendeesToPdf = (attendees, sectionTitle) => {
        doc.text(sectionTitle, 20, currentHeight);
        currentHeight += 10;
  
        attendees.forEach((attendee, index) => {
          doc.text(`${index + 1}. ${attendee.name}`, 30, currentHeight);
          currentHeight += 10;
  
          // Check if adding another line will exceed the page height
          if (currentHeight + 10 > doc.internal.pageSize.height) {
            doc.addPage(); // Start a new page
            currentHeight = 20; // Reset the height for the new page
          }
        });
  
        currentHeight += 10; // Add extra space between sections
      };
  
      // Add Corient attendees
      addAttendeesToPdf(inputFields.corient_attendees_label, "Corient Attendees");
  
      // Add Client attendees
      addAttendeesToPdf(inputFields.client_attendees_label, "Client Attendees");
  
      // Add Action Items
      doc.text("Action Items", 20, currentHeight);
      currentHeight += 10;
      inputFields.action_items.forEach((item, index) => {
        doc.text(`${index + 1}. ${item.action_item}`, 30, currentHeight);
        // doc.text(`Responsibility: ${item.responsibility}`, 30, currentHeight + 10);
        // doc.text(`Target Date: ${item.target_date}`, 30, currentHeight + 20);
        // doc.text(`Remarks: ${item.remarks}`, 30, currentHeight + 30);
        currentHeight += 10;
  
        // Check if adding another line will exceed the page height
        if (currentHeight + 10 > doc.internal.pageSize.height) {
          doc.addPage(); // Start a new page
          currentHeight = 20; // Reset the height for the new page
        }
      });
  
      // Add Notes
      doc.text("Notes", 20, currentHeight);
      currentHeight += 10;
      inputFields.notes.forEach((step, index) => {
        doc.text(`${index + 1}. ${step.note}`, 30, currentHeight);
        currentHeight += 10;
  
        // Check if adding another line will exceed the page height
        if (currentHeight + 10 > doc.internal.pageSize.height) {
          doc.addPage(); // Start a new page
          currentHeight = 20; // Reset the height for the new page
        }
      });
  
      // Save the PDF
      // doc.save("minutes_of_meeting.pdf");
      // Get the raw PDF data
      const pdfData = doc.output('blob');

      // Create a Blob from the PDF data
      const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
  
      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);
  
      // Open the PDF in a new window
      window.open(pdfUrl, '_blank');
  
      // Open the print dialog
      // window.print();
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };
  
  const selectedMonthName = getMonthName(inputFields.month);
  function inputactionresphandler(e, index){
   
    const action_items = [...inputFields.action_items];
    if (!action_items[index]) {
      action_items[index] = {}; // Initialize the user object if it doesn't exist
    }
    action_items[index]['responsibility'] = e.value;
    setInputfields({ ...inputFields, action_items });
  }
  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
    
        <Row className="mt-5">
       
          <div className="col" style={{padding:'0%'}}>
              
            <Card className="shadow">
            {/* <Button
                      className="col-2 mt-2"
                      color="success"
                      onClick={generatePdf}
                    >
                      Generate PDF
                    </Button>
                    <br></br> */}
            <Form role="form" id="adduser-form" onSubmit={editgovernancecall}>
              <CardHeader className="border-0">
              <Row >
                
              <div className="col-xl-4 col-md-4 col-xs-12">
                    <FormGroup>
                        <label>Weekly call Date : {inputFields.month}</label>
                      
                      </FormGroup> 
                    
                      <label className="clientformtag"> Client : {inputFields.client_name}</label>
                    </div>

                    
                  <div className="col-xl-4 col-md-2 col-xs-12">
                    <FormGroup>
                        <label>Department</label>
                        <select class="form-control" name="department" value={inputFields.department} onChange={(e) => inputhandler(e)} required>
                              <option value=""> Department</option>
                              <option value="BookKeeping">BookKeeping</option>
                                <option value="SME">SME</option>
                              </select>
                      </FormGroup> 
                  </div>
                  <div className="col-xl-3 col-md-2 col-xs-12">
                    <FormGroup>
                        <label>Meeting Title</label>
                        <Input
                     placeholder="Meeting Title"
                     type="text"
                     name="meeting_title"
                     onChange={(e) => inputhandler(e)}
                     value={inputFields.meeting_title}
                     required
                   />
                      </FormGroup> 
                  </div>
                    <br></br>
                  <div className="col-md-12">&nbsp;</div>
                  {(() => {
            const arr1 = [];
            for (let k = 0; k < corientbox; k++) {
                arr1.push(
                 <>
                    
                    <div className="col-md-3">
                       <FormGroup>
                       {/* <label>Level</label> */}
                       <div className="row">
                     
                  <div className="col-xl-12 col-md-12 col-xs-12">
                    <FormGroup style={{"display": "flex",
   "justify-content": "center",
   "align-items": "center",
   "gap": "5px"}}>
    <div style={{"width":"100%"}}>
                        {(userclientlist && userclientlist.length > 0)?
                        <InputGroup className="input-group-alternative mb-3">
                            <select className="form-control" name="team_user" placeholder="User" value={inputFields.corient_attendees[k] ? inputFields.corient_attendees[k] : ''} onChange={(e) => inputcorienthandler(e, k)}>
                              <option key="user_1"  value="">Select user manager for reporting</option>
                              {userclientlist.map(team =>
                                  <option key={team.value} value={team.value}>{team.label}</option>
                              )}
                            </select>
                          </InputGroup>
                        
                        
                    //   <Select 
                    
                    //   className="input-group-alternative mb-3" 
                    //   name="name"
                    //   options={userclientlist['users']} // set list of the data
                    //   value={inputFields.corient_attendees[k] ? inputFields.corient_attendees[k] : ''}
                    //   searchable
                    //   onChange={(e) => inputcorienthandler(e, k)}
                    //   isClearable={false}
                    // />
                    
                          : null }
                          </div>
                          <div>
                          {k >= inputFields.corient_attendees.length && (
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => removeCorientbox(k)}
        >
          <i className="fa fa-remove"></i>
        </button>
       )}
                         
                         </div> 
                      </FormGroup>      
                  </div>

                 
      
                        </div>
                      </FormGroup>    
                    </div> 
                    </>
                );
            }
            return arr1;
            
        })()}
                    <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addCorientbox()}>+ Add Corient Attendees </a>
          </Col>
               

                    {/* <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addClientbox()}>+ Add Client Attendees </a>
          </Col> */}
               

                  <br></br>
                  <br></br>
                  <div className="col-md-12">&nbsp;</div>
                  {(() => {
            const arr1 = [];
            for (let k = 0; k < clientbox; k++) {
                arr1.push(
                 <>
                    
                    <div className="col-md-3">
                       <FormGroup>
                       {/* <label>Level</label> */}
                       <div className="row">
                     
                  <div className="col-xl-12 col-md-12 col-xs-12">
                    <FormGroup style={{"display": "flex",
   "justify-content": "center",
   "align-items": "center",
   "gap": "5px"}}>
    <div style={{"width":"100%"}}>
                        <label>Name</label>
                        <div className="d-flex">
                         
                          <Input
                            placeholder="Name"
                            type="text"
                            name="name"
                            value={inputFields.client_attendees[k] ? inputFields.client_attendees[k] : ''}
                            onChange={(e) => inputclienthandler(e, k)}
                            required
                          />
                        
                        {/* {(userclientlist && userclientlist['clients'].length > 0)?
                      
                      <InputGroup className="input-group-alternative mb-3">
                            <select className="form-control" name="name" placeholder="Client" value={inputFields.client_attendees[k] ? inputFields.client_attendees[k] : ''} onChange={(e) => inputclienthandler(e, k)}>
                              <option key="user_1"  value="">Select Client</option>
                              {userclientlist['clients'].map(client =>
                                  <option key={client.value} value={client.value}>{client.label}</option>
                              )}
                            </select>
                          </InputGroup>
                    //   <Select 
                    
                    //   className="input-group-alternative mb-3" 
                    //   name="name"
                    //   options={userclientlist['clients']} // set list of the data
                    //   defaultValue={inputFields.client_attendees[k] ? inputFields.client_attendees[k] : ''}
                    //   value={{label: amendmentdata.am_company_name,value: amendmentdata.am_company}}
                    //   searchable
                    //   onChange={(e) => inputclienthandler(e, k)}
                    //   isClearable={false}
                    // />
                          : null }
                           */}
                          {k >= inputFields.client_attendees.length && (
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => removeClientbox(k)}
        >
          <i className="fa fa-remove"></i>
        </button>
       )}
                         </div> 
                          </div>
                      </FormGroup>      
                  </div>

                 
                    
                               </div>
                      </FormGroup>    
                    </div> 
                    </>
                );
            }
            return arr1;
            
        })()}
                    <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addClientbox()}>+ Add Client Attendees </a>
          </Col>
                  <div className="col-md-12">&nbsp;</div>
                  {(() => {
            const arr1 = [];
            for (let k = 0; k < ratebox; k++) {
              
                arr1.push(
                 <>
                    
                    <div className="col-md-12" key={k} style={{ display: inputFields.action_items[k]?.deleted ? 'none' : 'block' }}>
                       <FormGroup>
                       {/* <label>Level</label> */}
                       <div className="row">
                       <div className="col-xl-3 col-md-2 col-xs-12">
                       <FormGroup>
                        <label>Feedback</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Feedback"
                            type="text"
                            name="remarks"
                            defaultValue={inputFields.action_items[k] ? inputFields.action_items[k].remarks : ''}
                            onChange={(e) => inputactionhandler(e, k)}
                            
                          />
                        </InputGroup>
                      </FormGroup>
                    
                  </div>
                  <div className="col-xl-3 col-md-2 col-xs-12">
                    <FormGroup>
                        <label>Responsibility</label>
                        {(k <= originalratebox) && (inputFields.action_items[k]?.responsibility_check) ?
                        <Input
                            placeholder="Responsibility"
                            type="text"
                            name="responsibility"
                            defaultValue={inputFields.action_items[k] ? inputFields.action_items[k].responsibility : ''}
                            onChange={(e) => inputactionhandler(e, k)}
                            
                          />
                          :
                        (userlist.length > 0)?
                    // <select  class="form-control" name="responsibility" value={inputFields.action_items[k] ? inputFields.action_items[k].responsibility : ''}
                    // onChange={(e) => inputactionhandler(e, k)} >
                    // <option value=""> Select User</option>
                    // {(userlist).map((valuel,k) =>
                    //               <option key={k} value={valuel.value}>
                    //                 {valuel.label}
                    //               </option>
                    //           )}
                    // </select>
                    <Select 
                    
                    className="input-group-alternative mb-3" 
                    name="responsibility"
                    options={userlist} // set list of the data
                    defaultValue={
                      userlist.find(obj => obj.value === inputFields.action_items[k]?.responsibility) || null
                    }
                    searchable
                    onChange={(e) => inputactionresphandler(e, k)}
                    isClearable={false}
                  />
                        : null }
                      </FormGroup> 
                  </div>
                  <div className="col-xl-2 col-md-2 col-xs-12">
                    <FormGroup>
                        <label>Target date</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Target date"
                            type="date"
                            name="target_date"
                            defaultValue={inputFields.action_items[k] ? inputFields.action_items[k].target_date : ''}
                            onChange={(e) => inputactionhandler(e, k)}
                            
                          />
                        </InputGroup>
                      </FormGroup> 
                  </div>
                       
                  <div className="col-xl-3 col-md-2 col-xs-12">
                  <FormGroup>
                        <label>Action Item</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Action Item"
                            type="text"
                            name="action_item"
                            defaultValue={inputFields.action_items[k] ? inputFields.action_items[k].action_item : ''}
                            onChange={(e) => inputactionhandler(e, k)}
                            
                          />
                        </InputGroup>
                      </FormGroup>   
                  </div>

                 
                    
                    <Col md="1">
                    <br></br>
         {k < inputFields.action_items.length ? ( 
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => removeRatebox(k,inputFields.action_items[k].id)}
        >
          <i className="fa fa-remove"></i>
        </button>
        ) :<button
        type="button"
          className="btn btn-danger"
          onClick={() => removeRatebox(k)}
        >
          <i className="fa fa-remove"></i>
        </button> } 
    </Col>
                        </div>
                      </FormGroup>    
                    </div> 
                    </>
                );
              
            }
            return arr1;
            
        })()}
                    <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addRatebox()}>+ Add action items </a>
          </Col>

          <br></br>
                  <div className="col-md-12">&nbsp;</div>
                  {(() => {
            const arr1 = [];
            for (let k = 0; k < notebox; k++) {
                arr1.push(
                 <>
                    
                    <div className="col-md-12">
                       <FormGroup>
                       {/* <label>Level</label> */}
                       <div className="row">
                     
                  <div className="col-xl-10 col-md-12 col-xs-12">
                    <FormGroup>
                        {/* <label>Note</label> */}
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Note"
                            type="textarea"
                            name="note"
                            defaultValue={inputFields.notes[k] ? inputFields.notes[k].note : ''}
                            onChange={(e) => inputnotehandler(e, k)}
                            required
                          />
                        </InputGroup>
                      </FormGroup>      
                  </div>

                 
                    
                    <Col md="1"><br></br>
        {k >= inputFields.notes.length && (
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => removeNotebox(k)}
        >
          <i className="fa fa-remove"></i>
        </button>
      )} 
    </Col>
                        </div>
                      </FormGroup>    
                    </div> 
                    </>
                );
            }
            return arr1;
            
        })()}
                    <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addNotebox()}>+ Add Notes </a>
          </Col>
               
                </Row>

                
                <div className="col-md-12">
                        <label>&nbsp;</label>
                        <Button className="col-2 " color="primary" type="submit" disabled={disablestate}>
                          {disablestate ? 'Sending...' : 'Save'} 
                         </Button>
                         <Button className="col-4 " color="primary" type="submit" value={2} onClick={(e) => editgovernancecall(e)} disabled={disablestatebtn}>
                          {disablestatebtn ? 'Sending...' : 'Save & Mail to Internal Team'} 
                         </Button>
                         <Button className="col-4 " color="primary" type="submit" value={3} onClick={(e) => editgovernancecall(e)} disabled={disablestatebtn}>
                          {disablestatebtn ? 'Sending...' : 'Save & Mail to External Team'} 
                         </Button>
                    </div>
                    
              </CardHeader>
              </Form>
             </Card>
             </div>
             
        </Row>
      </Container>
    </>
  );
};


export default EditInternalGovernanceCall;
